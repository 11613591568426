<div class="col-12">
	<div class="card">
		<div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
			<div class="text-900 text-xl font-semibold mb-3 md:mb-0">User ({{userList.totalCount}} records)</div>
			<div class="inline-flex align-items-center">
				<div class="flex flex-wrap gap-2">
					<button pButton pRipple type="button" label="Add User" (click)="addNewUserButtonClick()"></button>
				</div>
			</div>
		</div>

		<p-table #dt1 [value]="userList.record" dataKey="id" [loading]="loading"
			styleClass="p-datatable-gridlines" 
			[globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
			<ng-template pTemplate="header">
				<tr>
					<th style="min-width: 12rem">
						<div class="flex justify-content-between align-items-center">
							{{labelConstant.LABEL.USER}}
						</div>
					</th>
					<th style="min-width: 12rem">
						<div class="flex justify-content-between align-items-center">
							{{labelConstant.LABEL.IS_ADMIN}}
						</div>
					</th>
					<th style="min-width: 12rem">
						<div class="flex justify-content-between align-items-center">
							{{labelConstant.LABEL.ACTION}}
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-user>
				<tr>
					<td>{{user.displayName}}</td>
					<td>{{user.isAdmin}}</td>
					<td class="flex flex-wrap gap-2">
						<p-confirmPopup></p-confirmPopup>
						<button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
							[routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/nyx-user-manager/edit/' + user.id]"></button>
						<button pButton pRipple type="button" icon="pi pi-times"
							class="p-button-rounded p-button-danger p-button-text"
							(click)="onNyxUserListingDeleteButtonClicked($event, user.id)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="loadingbody">
				<tr>
					<td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
				</tr>
			</ng-template>
		</p-table>
		<p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalUser"></p-paginator>
	</div>

</div>