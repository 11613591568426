import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-dashboard-tab-pane',
  templateUrl: './dashboard-tab-pane.component.html',
  styleUrls: ['./dashboard-tab-pane.component.scss']
})
export class DashboardTabPaneComponent {
  setupMenuNavList = [
    {
      "Title": "Dashboard",
      "Navigation": "/dashboard/dashboard-manager/overview"
    },
    {
      "Title": "Reports",
      "Navigation": "/report/report-manager/overview"
    },
  ];
  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }

  constructor(private nyxAPIService: NyxAPIService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  getStateFromUrl(){
    this.state.organizationName = this.activatedRoute.snapshot.paramMap.get('organization')!;
  }
}
