import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-numeric-field-configuration',
  templateUrl: './numeric-field-configuration.component.html',
  styleUrls: ['./numeric-field-configuration.component.scss']
})
export class NumericFieldConfigurationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = ""

  //Todo: check system integer min and max value
  minIntegerValue = "-2147483648";
  maxIntegerValue = "2147483647";

  defaultValue: number | null = null;
  minValue: number | null = null;
  maxValue: number | null = null;

  ngOnInit(): void {
    this.checkDefaultValue();

    console.log(this.formGroup.controls);
  }

  checkDefaultValue(){
    if(this.selectedFieldType.minValue != "" || this.selectedFieldType.manValue != "" || this.selectedFieldType.defaultValue != ""){
      this.defaultValue = this.selectedFieldType.defaultValue == "" ? null : this.selectedFieldType.defaultValue;
      this.minValue = this.selectedFieldType.minValue == "" ? null : this.selectedFieldType.minValue;
      this.maxValue = this.selectedFieldType.maxValue == "" ? null : this.selectedFieldType.maxValue;
      this.fieldFormControl.defaultValue.patchValue(this.selectedFieldType.defaultValue);
      this.fieldFormControl.minValue.patchValue(this.selectedFieldType.minValue);
      this.fieldFormControl.maxValue.patchValue(this.selectedFieldType.maxValue);
    }
  }

  get fieldFormControl() {
    return this.formGroup.controls;
  }

  keyPressNumbers(event:any, value:any){
    if(this.selectedFieldType.fieldTypeName == 'integer'){
      this.keyPressNumbersWithInteger(event, value);
    }else{
      this.keyPressNumbersWithDecimal(event, value);
    }
  }

  // Only Integer Numbers
  keyPressNumbersWithInteger(event:any, value:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      if( charCode == 45 && value == '' ){
        return true;
      }else{
        event.preventDefault();
        return false;
      }
    } else {
      return true;
    }
  }

  // Only Numbers with Decimals
  keyPressNumbersWithDecimal(event:any, value:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      if( charCode == 45 && value == '' ){
        return true;
      }else{
        event.preventDefault();
        return false;
      }
    }
    return true;
  }
}
