<div class="flex align-items-center justify-content-between mb-3">
  <div class="text-900 text-xl font-semibold">
    <ng-container [ngSwitch]="currentPage">
      <ng-container *ngSwitchCase="'dashboard_overview'">{{ dataList.name }}</ng-container>
      <ng-container *ngSwitchCase="'query_editor'">Query Editor ({{dataList.data.nyxRecord.length}} records)</ng-container>
      <ng-container *ngSwitchCase="'report_view'">Report View ({{dataList.data.nyxRecord.length}} records)</ng-container>
    </ng-container>
  </div>
  <div class="flex gap-2" *ngIf="currentPage == 'dashboard_overview'">
    <p-calendar [showIcon]="true" (focus)="calendar.overlayVisible && calendar.toggle()" (onSelect)="filterDate()"
        #calendar placeholder="Filter Date Range" [(ngModel)]="rangeDates" selectionMode="range" readonlyInput="true"></p-calendar>

    <button pbutton="" pripple="" type="button" icon="pi pi-plus" class="p-element p-ripple p-button-outlined p-button-sm p-button p-component" (click)="navigateToWidgetDetailPage()" >
      <span class="p-button-label">Edit Widget</span>
      <span class="p-ink"></span>
    </button>

    <p-confirmPopup></p-confirmPopup>
      <p-button icon="pi pi-times" severity="danger" (click)="onNyxWidgetDeleteButtonClicked($event)"></p-button>
  </div>

  <div class="inline-flex align-items-center" *ngIf="currentPage == 'report_view'">
    <button pButton pRipple type="button" label="Download Excel" class="p-button-primary" (click)="downloadFile()"></button>
  </div>
</div>


<div class="col-12" *ngIf="dataList != undefined">
  <ng-container *ngIf="currentPage == 'dashboard_overview'">
    <p-table #dt1 [value]="tableDataList" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let tableColumn of dataList.tableConfiguration">
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{ tableColumn }}
              </div>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <ng-container *ngFor="let column of dataList.tableConfiguration">
            <td>{{ data[column] }}</td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
        <td colspan="8">No data found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
        <td colspan="8">Loading data. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <ng-container *ngIf="currentPage == 'query_editor' || currentPage == 'report_view'">
    <p-table #dt1 [value]="dataList.data.nyxRecord" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let column of dataList.fields">
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{ column }}
            </div>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <ng-container *ngFor="let column of dataList.fields; let i = index">
          <td>{{ data[column] }}</td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
      <td colspan="8">No data found.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <tr>
      <td colspan="8">Loading data. Please wait.</td>
      </tr>
    </ng-template>
    </p-table>
  </ng-container>
</div>

