import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-polar-area-chart',
  templateUrl: './polar-area-chart.component.html',
  styleUrls: ['./polar-area-chart.component.scss']
})
export class PolarAreaChartComponent extends BaseUrlState {
  @Input() widgetMetadata: any;

  messageBoxConstant = MessageBoxConstant;

  polarData: any;
  polarOptions: any;

  constructor(private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
    private nyxConfirmationService: NyxConfirmationService,
    private notificationService: GlobalNotificationService,
    public messageService: MessageService,
    private router:Router){
      super(activatedRoute);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getDataByReportId();
  }

  initCharts(){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.polarData = {
      datasets: [{
          data: [],
          backgroundColor: [
              documentStyle.getPropertyValue('--indigo-500'),
              documentStyle.getPropertyValue('--purple-500'),
              documentStyle.getPropertyValue('--teal-500'),
              documentStyle.getPropertyValue('--orange-500')
          ],
          label: 'My dataset'
      }],
      labels: []
    };

    this.polarOptions = {
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
  }

  getDataByReportId(){
    this.nyxAPIService.getReportQueryDataByReportId(this.urlState.organizationName!, this.widgetMetadata.reportId).subscribe((result:any) => {

      this.initCharts();

      // Loop into report data. Find object key = x & y, push into array
      if(result.data.nyxRecord != null && result.data.nyxRecord.length > 0){
        result.data.nyxRecord.forEach((row: any) => {

          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.X){
              this.polarData.labels.push(row[key]);
            }
          });

          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.Y){
              this.polarData.datasets[0].data.push(row[key]);
            }
          });
        });
      }
    });
  }
  navigateToWidgetDetailPage(){
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + this.urlState.dashboardId + '/overview/widget/edit/' + this.widgetMetadata.id]);
  }

  deleteWidget(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxWidgetById(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.nyxAPIService.onLoadWidgetEvent(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_ERROR);
    });
  }

  onNyxWidgetDeleteButtonClicked(event: Event) {
    console.log(this.widgetMetadata);
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_CONFIRMATION, this, this.deleteWidget, this.widgetMetadata.id, null)
  }
}
