<ng-container *ngIf="pageMode == generalConstant.PAGE_MODE.CREATE || pageMode == generalConstant.PAGE_MODE.EDIT ">
  <div class="grid" [formGroup]="reportForm">
    <div class="col-12 md:col-12 mt-3">
      <div class="card p-fluid">
        <h5>{{labelConstant.REPORT.REPORT_LABEL}}</h5>
        <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.NAME}}</label>
          <div class="col-12 md:col-10">
            <input pInputText id="name3" type="text" formControlName="name"
            [ngClass]="{
              'ng-invalid ng-dirty': reportFormControl.name.invalid,
              '': reportFormControl.name.valid
            }"/>
            <small class="p-error" *ngIf="(reportFormControl.name.touched || submitted) && reportFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
            </small>
          </div>
        </div>
        <div class="field grid">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.DESCRIPTION}}</label>
          <div class="col-12 md:col-10">
            <input pInputText id="name3" type="text" formControlName="description" />
          </div>
        </div>
        <div class="field grid">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{generalConstant.QUERY.QUERY_LABEL}}</label>
          <div class="col-12 md:col-10">
            <textarea rows="5" cols="30"
            placeholder="select <field api name>, <field api name>, <field api name> &#10;from <object name> &#10;where <field api name> &#10;group by <field api name>" pInputTextarea
            formControlName="userQuery"
            [ngClass]="{
              'ng-invalid ng-dirty': reportFormControl.userQuery.invalid,
              '': reportFormControl.userQuery.valid
              }"></textarea>
            <small class="p-error" *ngIf="(reportFormControl.userQuery.touched || submitted) && reportFormControl.userQuery.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
            </small>
            <small class="p-error" *ngIf="validatedQueryResult.errorMessage !='' && reportForm.get('userQuery').value != ''">
              {{validatedQueryResult.errorMessage}}
            </small> 
          </div>
        </div>

      <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3 mt-5">
        <div class="inline-flex align-items-center">
        </div>
        <div class="p-fluid p-formgrid grid col-6">
          <div class="field col">
            <button pButton pRipple type="button" label="Validate & Preview Query" class="p-button-success"
            (click)="onValidateAndPreviewQueryButtonClick()"></button>
          </div>
          <div class="field col">
            <button pButton pRipple type="button" label="Clear Cache" class="p-button-secondary"
            (click)="onClearCacheButtonClick()"></button>
          </div>
          <div class="field col">
            <button pButton pRipple type="button" label="Submit"
            (click)="submit()"></button>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="validatedQueryResult.status == 'OK'">
        <div class="card">
          <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
            <div class="text-900 text-xl font-semibold mb-3 md:mb-0">
              {{labelConstant.REPORT.PREVIEW_DATA_LABEL}}
            </div>
          </div>

          <p-table #dt1 [value]="validatedQueryResult.data" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
            <ng-container *ngIf="validatedQueryResult.status == 'OK'">
            <ng-template pTemplate="header">
              <tr>
                <ng-container *ngFor="let field of validatedQueryResult.fields">
                  <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                      {{ field }}
                    </div>
                  </th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
              <tr>
                <ng-container *ngFor="let field of validatedQueryResult.fields">
                  <td>{{ row[field] }}</td>
                </ng-container>
              </tr>
            </ng-template>
            </ng-container>
            <ng-template pTemplate="emptymessage">
              <tr>
              <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
              <tr>
              <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="" *ngIf="validatedQueryResult.status == 'ERROR'">
              <tr>
              <td colspan="8">{{validatedQueryResult.errorMessage}}</td>
              </tr>
            </ng-template>
            </p-table>
        </div>

        </div>


    </div>
  </div>
  </div>
</ng-container>
