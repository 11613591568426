import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';


interface ObjectOption{
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-nyx-record-view-detail',
  templateUrl: './nyx-record-view-detail.component.html',
  styleUrls: ['./nyx-record-view-detail.component.scss']
})
export class NyxRecordViewDetailComponent extends BaseUrlState {
  messageBoxConstant = MessageBoxConstant;
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  viewForm: FormGroup;
  nyxObject: any = null;
  nyxView:any = null;
  objectList: any[] = [];
  objectOptions: ObjectOption[] = [];

  currentObject: any;
  fullFieldsList: any[] = [];
  schemaFields: any[] = [];

  submitted = false;
  selectedObjectId: any;
  pageMode = "edit";
  checkBox: any = null;

  checked: boolean = true;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
    private notificationService: GlobalNotificationService,
  ){
    super(activatedRoute);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.initPageOperation();
    this.initForm();
    this.initConfiguration();
  }

  get viewFormControl() {
    return this.viewForm.controls;
  }

  initPageOperation(){
    var currentUrl = this.router.url;
    var tokenizedUrl = currentUrl.split('/');
    if( tokenizedUrl[tokenizedUrl.length -1] == 'create' ){
      this.pageMode = tokenizedUrl[tokenizedUrl.length -1];
    }
  }
  
  initForm() {
    const group: any = {};
    group["viewName"] = new FormControl("", Validators.required);
    group["objectId"] = new FormControl("", Validators.required);
    // group["fields"] = new FormArray([]);

    this.fullFieldsList.forEach((field:any) => {
      group[field.FieldAPIName] = new FormControl("");
    });
    
    this.viewForm = this.fb.group(group);
    
    if(this.pageMode == 'edit'){
      this.viewForm.get('objectId')?.disable();
    }
  }

  async initConfiguration() {
    await this.nyxAPIService
    .getNyxObjects(this.urlState.organizationName!)
    .subscribe((data: any) => {
      this.objectList = data;
      this.currentObject = this.objectList.find(item => item.id == this.urlState.objectId);
      this.fullFieldsList.push(...this.systemDefaultFields.fieldGroup, ...this.currentObject.schema);

      //Loop Object into DropdownOption
      let i = 0;
      this.objectList.forEach((obj:any) => {
        this.objectOptions.push({
          index: i,
          label: obj.name,
          value: obj.id
        });
        i++;
      });
      
      this.initForm();
      this.viewForm.get('objectId')?.patchValue(this.urlState.objectId);

      if(this.pageMode == 'edit'){
        this.getNyxView();
      }
    });
  }

  async getNyxView(){
    await this.nyxAPIService.getNyxRecordViewById(this.urlState.organizationName!, this.urlState.recordId!).subscribe((data) => {
      this.nyxView = data;
      this.setNyxViewRecordsIntoForm();
    });
  }

  setNyxViewRecordsIntoForm(){
    
    console.log("Check View: ", this.nyxView);
    
    this.fullFieldsList.forEach((field:any) => {
      if(this.nyxView.fields.includes(field.FieldAPIName)){
        this.viewForm.patchValue({
          [field.FieldAPIName]: true,
        });
      }
    });

    this.viewForm.patchValue({
      ['viewName']: this.nyxView.name,
      ['objectId']: this.nyxView.nyxObjectId,
      // ['fields']: formArray.value
    });

  }
  onChangeObject(){
    //Reset fields list and push the fields based on objectID
    this.fullFieldsList = [];
    this.currentObject = this.objectList.find(item => item.id == this.selectedObjectId);
    this.fullFieldsList.push(...this.systemDefaultFields.fieldGroup, ...this.currentObject.schema);
    this.initForm();
    this.viewForm.get('objectId')?.patchValue(this.selectedObjectId);
  }

  submit(){
    this.submitted = true;
    if(this.viewForm.valid){

      let finalFields: any[] = [];

      this.fullFieldsList.forEach((field:any) => {
        if(this.viewForm.controls[field.FieldAPIName].value){
          finalFields.push(field.FieldAPIName);
        }
      });

      var nyxViewPayload:any = {
        Name: this.viewForm.controls["viewName"].value,
        Fields: finalFields,
      };

      var nyxViewPromise = this.pageMode == 'create' ? this.nyxAPIService.createRecordView(this.urlState.organizationName!,
         this.viewForm.get('objectId')?.value, nyxViewPayload).subscribe((data: any) => {
          this.navigateToNyxRecordListingPage(data.id);
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_SUCCESS.replace('{{ name }}', data.name)));
        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_ERROR);
        })
      : this.nyxAPIService.updateNyxRecordView(this.urlState.organizationName!, this.urlState.recordId!, nyxViewPayload).subscribe((data: any) => {
        this.navigateToNyxRecordListingPage(data.id);
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_SUCCESS.replace('{{ name }}', data.name)));
      }, (error: any) => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_ERROR);
      });

      // nyxViewPromise.subscribe((data:any) => {
      //   this.navigateToNyxRecordListingPage(data.id);
      // });
    }
  }

  reset(event: Event){
    // event.preventDefault();
    
    // this.viewForm.get('viewName')?.setValue(this.nyxView.name);
    // this.fullFieldsList.forEach((field:any) => {
    //   if(this.nyxView.fields.includes(field.FieldAPIName)){
    //     this.viewForm.patchValue({
    //       [field.FieldAPIName]: true,
    //     });
    //   }else{
    //     this.viewForm.patchValue({
    //       [field.FieldAPIName]: false,
    //     });
    //   }
    // });

    // the code above if creating new view from the view manager cannot redirect back to the listing 
    // However ,if the view is created in listing then need to perform edit function. In edit, it can be cancelled and redirected to the listing

    this.navigateToNyxRecordListingPage(this.urlState.objectId);
  }

  navigateToNyxRecordListingPage(id:any){
    this.router.navigate([`${this.urlState.organizationName!}/app/nyx-record-view-manager/${this.urlState.objectId}/listing`]);
  }
}
