import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { CentricLayoutComponent } from './layouts/centric-layout/centric-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { RegistrationLayoutComponent } from './layouts/registration-layout/registration-layout.component';
import { BgCoverCenterLayoutComponent } from './layouts/bg-cover-center-layout/bg-cover-center-layout.component';
import { NyxObjectManagerComponent } from './pages/nyx-object-manager/nyx-object-manager.component';
import { NyxRecordComponent } from './pages/nyx-record/nyx-record.component';
import { NewFieldEventService, NewLayoutSectionEventService } from './core/services/event.service';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalInterceptorConfiguration, MsalGuardConfiguration, MsalRedirectComponent, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor, MsalModule, MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
import { TokenInterceptor } from './core/Interceptors/TokenInterceptor';
import { NyxPermissionManagerComponent } from './pages/nyx-permission-manager/nyx-permission-manager.component';
import { NyxRoleManagerComponent } from './pages/nyx-role-manager/nyx-role-manager.component';
import { NyxUserManagerComponent } from './pages/nyx-user-manager/nyx-user-manager.component';
import { PermissionEventService } from './core/services/permission.service';
import { NyxLogsComponent } from './pages/nyx-logs/nyx-logs.component';
import { NyxRecordViewManagerComponent } from './pages/nyx-record-view-manager/nyx-record-view-manager.component';
import { DatePipe } from '@angular/common';
import { QueryEditorManagerComponent } from './pages/query-editor-manager/query-editor-manager.component';
import { NyxOverallSummaryComponent } from './pages/nyx-overall-summary/nyx-overall-summary.component';
import { ApiAccessManagerComponent } from './pages/api-access-manager/api-access-manager.component';
import { ToastModule } from 'primeng/toast';
import { GlobalNotificationService } from './core/services/notification.service';
import { LayoutService } from './core/services/layout.service';
import { GlobalModule } from './shared/global/global.module';
import { NyxConfirmationService } from './core/services/confirmation.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { RecordEventService } from './core/services/record.service';
import { ErrorCatchingInterceptor } from './core/Interceptors/ErrorCatchingInterceptor';
import { ParameterizedQueryManagerComponent } from './pages/parameterized-query-manager/parameterized-query-manager.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      knownAuthorities: [environment.msalConfig.auth.authorityDomain],
      redirectUri: '/auth',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/auth/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NyxObjectManagerComponent,
    NyxRecordComponent,
    NyxPermissionManagerComponent,
    NyxRoleManagerComponent,
    NyxUserManagerComponent,
    CentricLayoutComponent,
    MainLayoutComponent,
    RegistrationLayoutComponent,
    BgCoverCenterLayoutComponent,
    NyxLogsComponent,
    NyxRecordViewManagerComponent,
    QueryEditorManagerComponent,
    NyxOverallSummaryComponent,
    ApiAccessManagerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    SharedModule,
    GlobalModule,
    HttpClientModule,
    MsalModule,
    ToastModule,
    ConfirmPopupModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NewFieldEventService,
    PermissionEventService,
    RecordEventService,
    NewLayoutSectionEventService,
    GlobalNotificationService,
    ConfirmationService,
    NyxConfirmationService,
    LayoutService,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
