<div class="col-12">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">{{labelConstant.WEBHOOK.WEBHOOK_LOGS_LABEL}}</div>
    </div>

    <div class="card-body py-4">
      <p-table #dt1 [value]="dataListing" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.EVENT_LABEL}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.STATUS_LABEL}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.NAME}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.URL_LABEL}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LOGS.CREATED_TIME}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.CREATED_BY}}
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-data>
          <tr>
            <td>{{data.event}}</td>
            <td>{{data.statusCode}}</td>
            <td>{{data.payload.Record.Name}}</td>
            <td>{{data.url}}</td>
            <td>{{data.createdDatetime + 'Z' | date: generalConstant.TABLE_DATA.DATE}}</td>
            <td>{{data.createdBy}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
          <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
          <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

