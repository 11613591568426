<div class="card">
  <form [formGroup]="automationForm">
    <div class="grid grid-nogutter">
      <div class="col-12 px-4 mt-4 md:mt-6 md:px-6">
        <span class="text-900 block font-bold text-xl">{{labelConstant.AUTOMATION.AUTOMATION}}</span>
      </div>
      <div class="col-12 lg:col-6 h-full px-4 py-4 md:px-6">
        <ul class="flex list-none flex-wrap p-0 mb-6">
        </ul>
        <div class="grid formgrid">
          <div class="col-12 field mb-0">
            <span class="text-900 text-2xl block font-medium mb-5">{{labelConstant.AUTOMATION.AUTOMATION_INFO}}</span>
          </div>

          <div class="col-12 field mb-4">
            <label htmlFor="name3">Name</label>
            <input id="name" placeholder="" type="text" class="p-inputtext w-full mb-0" formControlName="name">
            <small class="p-error"
              *ngIf="(automationFormControl.name.touched || submitted) && automationFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
            </small>
          </div>

          <div class="col-12 field mb-4">
            <label htmlFor="name3">{{labelConstant.LABEL.DESCRIPTION}}</label>
            <input id="description" placeholder="" type="text" class="p-inputtext w-full mb-0"
              formControlName="description">
            <small class="p-error"
            *ngIf="(automationFormControl.description.touched || submitted) && automationFormControl.description.errors?.required">
            {{generalConstant.REQUIRED_MESSAGE.DESCRIPTION_REQUIRED}}
            </small>
          </div>

          <div class="col-12 field mb-6">
            <label htmlFor="name3">{{labelConstant.LABEL.OBJECT}}</label>
            <p-dropdown formControlName="objectId" placeholder="Select an Object" styleClass="w-full" autoWidth="false"
              [options]="objectList" optionLabel="label" optionValue="value" [(ngModel)]="objectValue"
              name="selectedField" [filter]="true" filterBy="index"></p-dropdown>

            <small class="p-error"
              *ngIf="(automationFormControl.objectId.touched || submitted) && automationFormControl.objectId.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.OBJECT_REQUIRED}}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-4 py-4 md:px-6 mt-8">
        <div class="py-2 mt-2">
          <div class="col-12 flex justify-content-between align-items-center mb-3">
            <span class="text-900 font-medium">{{labelConstant.LABEL.ENABLED}}</span>
            <p-inputSwitch class="p-2" formControlName="enabled"></p-inputSwitch>
          </div>
          <div class="col-12 field mb-6">
            <label htmlFor="name3">{{labelConstant.AUTOMATION.EVENT_TYPE}}</label>
            <p-dropdown formControlName="eventType" placeholder="Select an Event Type" styleClass="w-full" autoWidth="false"
              [options]="eventTypeList" optionLabel="label" optionValue="value" [(ngModel)]="eventTypeValue"
              name="selectedField" [filter]="true" filterBy="index"></p-dropdown>

            <small class="p-error"
              *ngIf="(automationFormControl.eventType.touched || submitted) && automationFormControl.eventType.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.EVENT_TYPE_REQUIRED}}
            </small>
          </div>
        </div>
      </div>
    </div>
  </form>


  <!-- <div class="col-12 lg:col-12 h-full px-4 py-4 md:px-6">
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{generalConstant.AUTOMATION.CODE_EDITOR}}</label>
          <div class="col-12 md:col-10">
              <ngx-codemirror #codemirror
              [(ngModel)]="content"
              className="fullscreen-content"
              [options]="{ lineNumbers: true, theme: 'material'}"></ngx-codemirror>
          </div>
        </div>

        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-0 mt-3">
            <div class="inline-flex align-items-center">
            </div>
            <div class="p-fluid p-formgrid grid col-2">
              <div class="field col">
                <button pButton pRipple type="button" label="Create"
                (click)="submit()"></button>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="isSubmit"></i>
              </div>
            </div>
          </div>
      </div> -->

  <div class="col-12 lg:col-12 h-full px-4 py-4 md:px-6">
    <ul class="flex list-none flex-wrap p-0 mb-0">
    </ul>
    <div class="grid formgrid">
      <div class="col-12 field mb-2">
        <span class="text-900 text-2xl block font-medium mb-5">{{labelConstant.AUTOMATION.CODE_EDITOR}}</span>
      </div>

      <div class="col-12 lg:col-12 field mb-4">
        <!-- <label htmlFor="protocol">{{labelConstant.AUTOMATION.CODE_EDITOR}}</label> -->
        <div class="col-12 md:col-112">
          <!-- [disabled]="canUserEdit()" -->
            <ngx-codemirror #codemirror
            [(ngModel)]="codeContent"
            className="fullscreen-content"
            [options]="{ lineNumbers: true, theme: 'material'}"></ngx-codemirror>
            <div class="mt-2">
              <small class="p-error"
              *ngIf="codeContent == '' && submitted">
              {{generalConstant.REQUIRED_MESSAGE.AUTOMATION_REQUIRED}}
              </small>
            </div>
        </div>
      </div>

      <div class="col-12 flex flex-column lg:flex-row justify-content-center align-items-center lg:justify-content-end my-2">
        <button pButton pRipple class="p-button-primary w-full lg:w-auto flex-order-1 lg:flex-order-1 lg:mr-4"
          label="Save Changes" (click)="submit()"></button>
        <!-- <button pButton pRipple
          class="p-button-secondary p-button-outlined mt-3 lg:mt-0 w-full lg:w-auto flex-order-2 lg:flex-order-2 "
          label="Cancel" (click)="discard()"></button> -->
      </div>
    </div>
  </div>
</div>
