import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nyx-field-renderer',
  templateUrl: './nyx-field-renderer.component.html',
  styleUrls: ['./nyx-field-renderer.component.scss']
})
export class NyxFieldRendererComponent {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";
  @Input() isVersion: boolean = false;
}
