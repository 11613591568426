import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent extends BaseUrlState {

  reportId: any = "";
  reportData:any;
  currentPage: string = 'report_view';
  loading: boolean = true;

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe(param => {
      this.reportId = this.activatedRoute.snapshot.paramMap.get('reportId');
      this.initReportFormData();
    })
  }

  initReportFormData(){
    this.nyxAPIService.getReportQueryDataByReportId(this.urlState.organizationName!, this.reportId).subscribe((data:any) => {
      this.reportData = data;
      this.loading = false;
    });
  }
}
