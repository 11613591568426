import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { RecordEventService } from 'src/app/core/services/record.service';
import { ActivatedRoute } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-import-record',
  templateUrl: './modal-import-record.component.html',
  styleUrls: ['./modal-import-record.component.scss']
})
export class ModalImportRecordComponent extends BaseUrlState implements OnDestroy{
  @ViewChild('recordFileUpload') recordFileUpload: FileUpload;
  generalConstant = GeneralConstant;
  
  modalShow: boolean = true;
  modalOperation: string;
  importForm: FormGroup;
  formSubmitted: boolean = false;
  uploadedFiles: any[] = [];

  showDialog: Subscription;
  
  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    public recordEvent: RecordEventService,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.eventSubjectSubscription();
  }

  ngOnDestroy(): void {
    this.showDialog.unsubscribe();
  }

  eventSubjectSubscription(){
    this.showDialog = this.recordEvent.showImportRecordDialog.subscribe((show:boolean) => {
      this.modalShow = show;
    });
  }

  initForm(){
    const group: any = {};
    group['importFile'] = new FormControl([], Validators.required);
    this.importForm = this.fb.group(group);
  }

  onSelectFile(event: any) {
    for(let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.importForm.get("importFile")?.setValue(this.uploadedFiles);
  }

  onUpload(){
    this.formSubmitted = true;
    const formData = new FormData();
    this.uploadedFiles.forEach((file: any) => {
      formData.append('file', file, file.name);
    });

    if(this.importForm.valid){
      this.nyxAPIService.importRecord(this.urlState.organizationName!, this.urlState.objectId, formData).subscribe((response:any) => {
        this.closeModal();
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.IMPORT_RECORD_SUCCESS));
      }, (error: any) => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_ERROR);
      });
    }
  }
  
  closeModal() {
    this.recordEvent.showImportRecordDialogEvent(false);
    this.clearFileUpload();
  }
  
  clearFileUpload(){
    if(this.uploadedFiles.length > 0 || this.importForm.get("importFile")?.value.length > 0){
      this.uploadedFiles = [];
      this.importForm.get("importFile")?.setValue([]);
      // FileUpload Clear function must put at the last, else will run infinite 
      this.recordFileUpload.clear();
    }
    this.formSubmitted = false;
  }
}
