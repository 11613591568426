<!--begin::Card body-->
<!-- <div *ngIf="message != ''" class="card-body border-top p-0"> -->
<!--begin::Notice-->
<div *ngIf="message != '' && status == 'Success'" class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
  <!--begin::Icon-->
  <i class="ki-duotone ki-information text-primary fs-2tx me-4">
    <span class="path1"></span>
    <span class="path2"></span>
    <span class="path3"></span>
  </i>
  <!--end::Icon-->
  <!--begin::Wrapper-->
  <div class="d-flex flex-stack flex-grow-1">
    <!--begin::Content-->
    <div class="fw-semibold">
      <h4 class="text-gray-900 fw-bold">{{ message }}</h4>
      <div class="fs-6 text-gray-700">{{ message }}
      <br />
      <!-- <a class="fw-bold" href="#">Learn more</a></div> -->
    </div>
    <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Notice-->
</div>
<!--end::Card body-->

<!--begin::Card body-->
<!--begin::Notice-->
<div *ngIf="message != '' && status == 'Failed'" class="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6">
  <!--begin::Icon-->
  <i [class]="textClass" class="ki-duotone ki-information text-danger fs-2tx me-4">
    <span class="path1"></span>
    <span class="path2"></span>
    <span class="path3"></span>
  </i>
  <!--end::Icon-->
  <!--begin::Wrapper-->
  <div class="d-flex flex-stack flex-grow-1">
    <!--begin::Content-->
    <div class="fw-semibold">
      <h4 class="text-gray-900 fw-bold">{{ message }}</h4>
      <div class="fs-6 text-gray-700">{{ message }}
      <br />
      <!-- <a class="fw-bold" href="#">Learn more</a></div> -->
    </div>
    <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Notice-->
</div>
<!--end::Card body-->

<!--begin::Card body-->
<!--begin::Notice-->
<div *ngIf="message != '' && status == 'Warning'" class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
  <!--begin::Icon-->
  <i [class]="textClass" class="ki-duotone ki-information text-warning fs-2tx me-4">
    <span class="path1"></span>
    <span class="path2"></span>
    <span class="path3"></span>
  </i>
  <!--end::Icon-->
  <!--begin::Wrapper-->
  <div class="d-flex flex-stack flex-grow-1">
    <!--begin::Content-->
    <div class="fw-semibold">
      <h4 class="text-gray-900 fw-bold">{{ message }}</h4>
      <div class="fs-6 text-gray-700">{{ message }}
      <br />
      <!-- <a class="fw-bold" href="#">Learn more</a></div> -->
    </div>
    <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Notice-->
</div>
<!--end::Card body-->
