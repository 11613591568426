import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { MenuService } from 'src/app/core/services/menu.service';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent extends BaseUrlState {
  messageBoxConstant = MessageBoxConstant;
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  reportId: any = "";
  submitted = false;
  reportForm: FormGroup;
  pageMode: any = "edit";
  reportData: any = {};
  validatedQueryResult = {};
  requiredFillQuery: boolean = false;
  messages: Message[] | undefined;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private notificationService: GlobalNotificationService,
    private nyxAPIService: NyxAPIService,
    private menuService: MenuService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.reportId = this.activatedRoute.snapshot.paramMap.get('reportId');
    this.pageMode = this.urlState.operation != null ? this.urlState.operation : "edit";
    this.initForm();
    if (this.pageMode == 'edit') {
      this.initReportFormData();
    }
  }

  initReportFormData() {
    this.nyxAPIService.getReportById(this.urlState.organizationName!, this.reportId).subscribe((data: any) => {
      this.reportData = data;
      console.log('get report data ', data);
      this.patchReportDataIntoForm();
    });
  }

  patchReportDataIntoForm() {
    this.reportForm.patchValue({
      ['name']: this.reportData.name,
      ['description']: this.reportData.description,
      ['userQuery']: this.reportData.userQuery,
    });
  }

  get reportFormControl() {
    return this.reportForm.controls;
  }

  initForm() {
    this.reportForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.maxLength(100)]],
      userQuery: ['', [Validators.required,]],
    });
  }

  onValidateAndPreviewQueryButtonClick() {
    this.validatedQueryResult = {};

    if (this.reportForm.value.userQuery == '') {
      this.requiredFillQuery = true;
    } else {
      var payload = {
        UserQuery: this.reportForm.value.userQuery,
        IsPreview: true
      };
      this.nyxAPIService.validateAndPreviewQuery(this.urlState.organizationName!, payload).subscribe((data: any) => {
        this.validatedQueryResult = data;
      });
    }
  }

  onClearCacheButtonClick() {
    this.nyxAPIService.cacheClearByReportId(this.urlState.organizationName!, this.reportId).subscribe((result: any) => {
      console.log(result);

      if (result.status == "OK") {
        // this.messageBox.showMessageBox = true;
        // this.messageBox.messageBoxMessage = 'Cache Cleared';
        // this.messageBox.messageBoxStatus = this.messageBoxConstant.STATUS.SUCCESS;
        this.messages = [{ severity: 'success', summary: 'Success', detail: 'Cache Cleared' }];
      } else {
        // this.messageBox.showMessageBox = true;
        // this.messageBox.messageBoxMessage = result.errorMessage;
        // this.messageBox.messageBoxStatus = this.messageBoxConstant.STATUS.FAILED;
        this.messages = [{ severity: 'error', summary: 'Error', detail: result.errorMessage }];
      }
    });
  }

  submit() {
    this.submitted = true;
    if (this.reportForm.valid) {
      var reportPayload = {
        Name: this.reportForm.value.name,
        Description: this.reportForm.value.description,
        UserQuery: this.reportForm.value.userQuery
      };

      let reportPromise = this.pageMode == 'edit' ? this.nyxAPIService.updateReport(this.urlState.organizationName!, this.reportId, reportPayload) : this.nyxAPIService.createReport(this.urlState.organizationName!, reportPayload)

      reportPromise.subscribe((data: any) => {
        this.navigateToReportListingPage();
        this.initForm();
        this.urlState.operation! == 'create' ? this.menuService.onLoadMenu(true) : null; 
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.REPORT_MESSAGE.EDIT_REPORT_SUCCESS.replace('{{ name }}', data.name)));
      }, (error: any) => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.REPORT_MESSAGE.EDIT_REPORT_ERROR);

        console.log(error);
      });
    }
  }

  navigateToReportListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName!}/${this.urlState.sectionName!}/report-manager/overview`,
    ]);
  }
}
