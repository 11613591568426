<div class="card card-flush">
  <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
    <div class="card-title">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">API Access ({{totalApiAcess}} records)</div>
      <div class="d-flex align-items-center position-relative my-1 me-5">
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <button pButton pRipple type="button" class="btn btn-light-primary" (click)="onAddNewAPIAccessButtonClick()">
        <i>
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </i>{{labelConstant.API.ADD_API_LABEL}}</button>
    </div>
  </div>
  <div class="card-body pt-0">
    <p-table #dt1 [value]="dataListing.record" value="index" dataKey="id" [loading]="loading" [rowHover]="true"
      styleClass="p-datatable-gridlines"  responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.NAME}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.DESCRIPTION}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.API.API_KEY_LABEL}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.CREATED_BY}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.CREATE_DATE}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.API.ACTIVE_LABEL}}
            </div>
          </th>
          <th style="min-width: 12rem" class="text-end min-w-100px">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.ACTION}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{data.name}}</td>
          <td>{{data.description}}</td>
          <td>{{data.apiKey}}</td>
          <td>{{data.createdBy}}</td>
          <td>{{data.createdDatetime +'Z' | date: generalConstant.TABLE_DATA.DATE }}</td>
          <td>
            <p-inputSwitch class="p-inputswitch p-component p-inputswitch-checked p-2"
              (onChange)="changeAPIAccessKeyStatus(data)" *ngIf="data.isActive"></p-inputSwitch>
          </td>
          <td>
            <p-confirmPopup></p-confirmPopup>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
              [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/api-access-manager/edit/' + data.id]">
            </button>
            <button pButton pRipple type="button" icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              (click)="onApiAccessListingDeleteButtonClicked($event, data.id)">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalApiAcess"></p-paginator>
  </div>
</div>