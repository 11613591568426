import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nyx-number-field',
  templateUrl: './nyx-number-field.component.html',
  styleUrls: ['./nyx-number-field.component.scss']
})
export class NyxNumberFieldComponent {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";

  // Only Numbers with Decimals
  keyPressNumbersWithDecimal(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
