import { Component } from '@angular/core';
import { BaseLayout } from 'src/app/core/bases/BaseLayout';

@Component({
  selector: 'app-centric-layout',
  templateUrl: './centric-layout.component.html',
  styleUrls: ['./centric-layout.component.scss']
})
export class CentricLayoutComponent extends BaseLayout {

}
