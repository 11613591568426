<ng-container
  *ngIf="urlState.operation! == generalConstant.PAGE_MODE.CREATE || urlState.operation == generalConstant.PAGE_MODE.EDIT">
  <div class="grid" [formGroup]="roleForm">
    <div class="col-12 md:col-12 mt-3">
      <div class="card p-fluid">
        <h5>{{labelConstant.LABEL.ROLE}}</h5>
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.ROLE.ROLE_NAME}}</label>
          <div class="col-12 md:col-10">
            <input pInputText type="text" id="name" formControlName="name" placeholder="" value="" [ngClass]="{
                          'ng-dirty ng-invalid': roleFormControl.name.invalid,
                          'is-valid': roleFormControl.name.valid
                        }" />
            <small id="username-help" class="p-error"
              *ngIf="(roleFormControl.name.touched || submitted) && roleFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.ROLE_REQUIRED}}
            </small>

          </div>
        </div>

        <div class="field grid mt-5">
          <!-- <div class="mt-6 field grid col-12 md:col-2"> -->
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.PERMISSION}}</label>
          <!-- </div> -->
          <div class="mt-6 field grid col-12 md:col-10">
            <div class="grid formgrid">
              <div class="col-12 md:col-5" *ngFor="let permission of permissionList">
                <div class="field-checkbox">
                  <p-inputSwitch class="p-2" [inputId]="permission.id" [name]="permission.id"
                    [formControlName]="permission.id"
                    (onChange)="onPermissionUpdated($event,permission.id)"></p-inputSwitch>
                  <label [htmlFor]="permission.id">{{permission.name}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>


        <td class="field grid">
          <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" label="Submit" (click)="submit()"></button>
          </div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" type="reset" label="Cancel" class="p-button-secondary"
              (click)="redirectToRoleListingPage()"></button>
          </div>
        </td>

      </div>

    </div>

  </div>
</ng-container>