import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';


@Component({
  selector: 'app-nyx-unique-id-field',
  templateUrl: './nyx-unique-id-field.component.html',
  styleUrls: ['./nyx-unique-id-field.component.scss']
})
export class NyxUniqueIdFieldComponent extends BaseUrlState {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
}
