<!-- <div class="grid" *ngFor="let set of setOfRow">
    <div class="col-12 md:col-6 lg:col-4" *ngFor="let obj of set | keyvalue">
        <div class="card" style="height:22rem">
            <h3>
                <span class="font-bold text-2xl">{{ objectLabel[obj.key] }}</span>
            </h3>
            <div class="text-center p-6 text-6xl font-bold">{{ obj.value }}</div>
        </div>
    </div>
</div> -->

<div class="grid">
    <div class="col-12 md:col-6 lg:col-4" *ngFor="let metric of metrics">
        <div class="card h-full">
            <div class="flex align-items-center justify-content-between mb-3">
                <span class="text-900 text-xl font-semibold">{{metricLabels[metric]}}</span>
            </div>
            <div>
                <div class="border-round bg-gray-100" style="height: 6px">
                    <div class="h-full border-round"
                        [ngClass]="calculateColorCode(nyxOverallSummary?.planUsageLimit.usage[metric], nyxOverallSummary?.planUsageLimit.cummulativePlanLimit[metric])" 
                        [ngStyle]="{'width': calculateStyleWidthPercentage(nyxOverallSummary?.planUsageLimit.usage[metric], nyxOverallSummary?.planUsageLimit.cummulativePlanLimit[metric])}"></div>
                </div>
                <div class="flex align-item-center justify-content-between">
                    <span class="text-900 mt-3 text-md font-medium">{{nyxOverallSummary?.planUsageLimit.usage[metric]}}</span>
                    <span class="text-900 mt-3 text-md font-medium">{{nyxOverallSummary?.planUsageLimit.cummulativePlanLimit[metric]}}</span>
                </div>
            </div>
        </div>
    </div>
</div>