<ng-container *ngIf="reportData != undefined && reportData.status == 'OK'">
  <div class="card">
    <app-table
    [dataList]="reportData"
    [currentPage]="currentPage"
    [reportId]="reportId"
    ></app-table>
  </div>
</ng-container>

<ng-container *ngIf="reportData != undefined && reportData.status == 'ERROR'">
  <label for="">{{ reportData.errorMessage }}</label>
</ng-container>
