import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';

interface Fields{
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-attachment-configuration',
  templateUrl: './attachment-configuration.component.html',
  styleUrls: ['./attachment-configuration.component.scss']
})
export class AttachmentConfigurationComponent extends BaseUrlState {
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = "";

  constructor(
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
