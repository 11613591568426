import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NewLayoutSectionEventArg,
  NewLayoutSectionEventService,
} from 'src/app/core/services/event.service';

@Component({
  selector: 'app-modal-nyx-layout-create-section',
  templateUrl: './modal-nyx-layout-create-section.component.html',
  styleUrls: ['./modal-nyx-layout-create-section.component.scss'],
})
export class ModalNyxLayoutCreateSectionComponent {
  public newLayoutSection = new NewLayoutSectionEventArg();
  columnTypeOptions: any[] = [
    { name: 'Single Column', value: 'Single' },
    { name: 'Two Column', value: 'Two' },
  ];

  @Input() modalShow: boolean = false;
  @Output() modalShowEvent: any = new EventEmitter();

  constructor(
    private newLayoutSectionEventService: NewLayoutSectionEventService
  ) {
    this.initNewLayoutSectionEventArg();
  }

  initNewLayoutSectionEventArg() {
    this.newLayoutSection.SectionName = '';
    this.newLayoutSection.ColumnType = 'Single';
  }

  onCreateNewLayoutSectionButtonClicked() {
    if (this.newLayoutSection.SectionName != '') {
      this.closeModal();
      var eventArgs = Object.assign({}, this.newLayoutSection);
      this.newLayoutSectionEventService.createNewLayoutSectionField(eventArgs);
      this.initNewLayoutSectionEventArg();
    }
  }

  closeModal() {
    this.modalShow = false;
    this.modalShowEvent.emit(this.modalShow);
  }
}
