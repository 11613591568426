<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="isRequired" class="col-12 mb-2 md:col-2 md:mb-0">
                Required?
            </label>
            <div class="col-12 md:col-10">
                <p-inputSwitch inputId="isRequired" name="isRequired" formControlName="isRequired"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="defaultValue" class="col-12 mb-2 md:col-2 md:mb-0">
                Default Value
            </label>
            <div class="col-12 md:col-10">
                <p-calendar [showButtonBar]="true" [showIcon]="true" formControlName="defaultValue" value=""
                    dateFormat="dd M yy"
                    *ngIf="selectedFieldType.fieldType == 'date' || selectedFieldType.fieldTypeName == 'date'"></p-calendar>
                <p-calendar [showButtonBar]="true" [showTime]="true" [showIcon]="true" formControlName="defaultValue"
                    value="" dateFormat="dd M yy, " hourFormat="12"
                    *ngIf="selectedFieldType.fieldType == 'datetime' || selectedFieldType.fieldTypeName == 'datetime'"></p-calendar>
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="regexRestriction" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />

</form>