<div class="col-12">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Permission ({{nyxPermissions.totalCount}} records)</div>
      <div class="inline-flex align-items-center">
        <div class="flex flex-wrap gap-2">
          <button pButton pRipple type="button" label="Add Permission" (click)="addPermissionButtonClick()"></button>
        </div>
      </div>
    </div>
    <p-table #dt1 [value]="nyxPermissions.record" [loading]="loading" [rowHover]="true"
      styleClass="p-datatable-gridlines" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.NAME}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.OBJECT}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.PERMISSION.READ}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.PERMISSION.UPDATE}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.PERMISSION.DELETE}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.PERMISSION.SENSITIVE_READ}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.ACTION}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-permission>
        <tr>
          <td>{{permission.name}}</td>
          <td>{{permission.nyxObjectName}}</td>
          <td>
            <label class="form-check form-check-sm form-check-custom form-check-solid">
              <p-checkbox #checkboxes [(ngModel)]="permission.read" binary="true" inputId="read" [disabled]="true">
              </p-checkbox>
            </label>
          </td>
          <td>
            <label class="form-check form-check-sm form-check-custom form-check-solid">
              <p-checkbox #checkboxes [(ngModel)]="permission.update" binary="true" inputId="update" [disabled]="true">
              </p-checkbox>
            </label>
          </td>
          <td>
            <label class="form-check form-check-sm form-check-custom form-check-solid">
              <p-checkbox #checkboxes [(ngModel)]="permission.delete" binary="true" inputId="delete" [disabled]="true">
              </p-checkbox>
            </label>
          </td>
          <td>
            <label class="form-check form-check-sm form-check-custom form-check-solid">
              <p-checkbox #checkboxes [(ngModel)]="permission.sensitiveRead" binary="true" inputId="sensitiveRead"
                [disabled]="true">
              </p-checkbox>
            </label>
          </td>
          <td class="flex flex-wrap gap-2">
            <p-confirmPopup></p-confirmPopup>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
              [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/nyx-permission-manager/edit/' + permission.id]"></button>
            <button pButton pRipple type="button" icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              (click)="onNyxPermissionListingDeleteButtonClicked($event, permission.id)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalPermission"></p-paginator>
  </div>
</div>