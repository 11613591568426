import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
declare var KTChartsWidget28: any;
declare var am5: any;
declare var KTUtil:any;

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent extends BaseUrlState {
  @ViewChild('calendar') private calendar: any;
  @Input() widgetMetadata: any;

  messageBoxConstant = MessageBoxConstant;

  rangeDates: Date[] | undefined;
  xAxisList: any = [];
  yAxisList: any = [];

  data: any;
  options: any;

  constructor(
    private datePipe: DatePipe,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    private notificationService: GlobalNotificationService,
    public messageService: MessageService,
    public override activatedRoute: ActivatedRoute){
      super(activatedRoute);
    }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initLineGraph();
    this.getDataByReportId();
  }

  initLineGraph(){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: [],
      datasets: [
          {
              label: 'Data',
              data: [],
              fill: false,
              borderColor: documentStyle.getPropertyValue('--blue-500'),
              tension: 0.4
          },
      ]
    };

    this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
  }

  filterDate(){
    if(this.rangeDates != undefined){
      if(this.rangeDates[0] != null && this.rangeDates[1] != null){
        const startDate = this.datePipe.transform(this.rangeDates[0], 'dd-MM-yyyy');
        const endDate = this.datePipe.transform(this.rangeDates[1], 'dd-MM-yyyy');

        console.log('startDate: ' + startDate, 'endDate: ' + endDate)
        this.calendar.toggle();
      }
    }
  }

  getDataByReportId(){
    this.nyxAPIService.getReportQueryDataByReportId(this.urlState.organizationName!,
       this.widgetMetadata.reportId).subscribe((result:any) => {

      // Loop into report data. Find object key = x & y, push into array
      if(result.data.nyxRecord != null && result.data.nyxRecord.length > 0){
        result.data.nyxRecord.forEach((row: any) => {

          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.X){
              this.data.labels.push(row[key]);
            }
          });

          // let max = 0;
          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.Y){
              // Set the maximum of Y
              // if(max == 0){
              //   max = row[key];
              // }else{
              //   max = row[key] > max ? row[key] : max;
              // }

              this.data.datasets[0].data.push(row[key]);
            }
          });
        });
      }
    });
  }

  navigateToWidgetDetailPage(){
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + this.urlState.dashboardId + '/overview/widget/edit/' + this.widgetMetadata.id]);
  }

  deleteWidget(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxWidgetById(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.nyxAPIService.onLoadWidgetEvent(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_ERROR);
    });
  }

  onNyxWidgetDeleteButtonClicked(event: Event) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_CONFIRMATION, this, this.deleteWidget, this.widgetMetadata.id, null)
  }
}
