import { Component } from '@angular/core';

@Component({
  selector: 'app-api-access-manager',
  templateUrl: './api-access-manager.component.html',
  styleUrls: ['./api-access-manager.component.scss']
})
export class ApiAccessManagerComponent {

}
