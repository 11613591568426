export class IdConstant {
  static PLAN = {
    COMMUNITY_PLAN_ID: '91500a2a-475d-4cdb-b14e-feac935e2e32',
    MONTHLY_STARTUP_PLAN_ID: "835c509a-b853-4e19-97b5-e98b5ff318e0",
    MONTHLY_PROFESSIONAL_PLAN_ID: "26590457-6982-4bf3-b21a-a8f708e82213",
    MONTHLY_ENTERPRISE_PLAN_ID: "72756437-6dff-4b24-a68b-379cadae1d65",
    YEARLY_STARTUP_PLAN_ID: "292b8aaa-5314-4030-bb01-87146f5e55bd",
    YEARLY_PROFESSIONAL_PLAN_ID: "53fe35fb-8f61-4cc0-a726-c844d2de71ff",
    YEARLY_ENTERPRISE_PLAN_ID: "329971dd-6464-44c7-9178-ab9cb1ce0735",
    TESTING_ANNUAL_PLAN_ID: "e729047e-4d32-4e52-b173-1e8370016441"
  }

  static GENERAL = {
    EMPTY_GUID: '00000000-0000-0000-0000-000000000000'
  }
}
