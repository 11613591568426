import { GeneralConstant } from './../../core/constants/general-constant';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-record-relation-list',
  templateUrl: './record-relation-list.component.html',
  styleUrls: ['./record-relation-list.component.scss']
})
export class RecordRelationListComponent extends BaseUrlState implements OnInit {
  @Output() passNavigationInfo = new EventEmitter();
  generalConstant = GeneralConstant;

  objectData: any = {};
  relatedRecordList: any = [];
  groupedByRelatedRecord: any = {};
  groupedByKeyList: any = [];
  groupedByViewMenuItems: any = {};
  groupedByCurrentFields: any = {};

  viewMenuItems: MenuItem[] = [];
  nyxObject: any;
  nyxObjectRecords: any = [];
  nyxRecordView: any = null;
  nyxCustomView: any[] = [];


  loading: boolean = true;
  objectId: any;

  currentFields: any[] = [  //system default
    { FieldAPIName: 'id', Label: 'Record ID' },
    { FieldAPIName: 'name', Label: 'Name' },
    { FieldAPIName: 'modifiedBy', Label: 'Last Modified By' },
    { FieldAPIName: 'modifiedDatetime', Label: 'Last Modified Datetime' }
  ];


  datetimeGroup: any = {
    modifiedDatetime: true,
    createdDatetime: true,
  };

  datetimeField: any[] = ['modifiedDatetime', 'createdDatetime', 'datetime'];

  constructor(
    protected router: Router,
    protected nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // This is to handle when navigate from parent record to child related record
    this.activatedRoute.params.subscribe((params: any) => {
      super.ngOnInit();
      this.initObjectManager();


      this.loading = false;
    });

  }


  initObjectManager() {
    this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.objectData = data;
      var lookupMetadata = data.lookupMetadata;
      if (lookupMetadata.length > 0) {
        for (var i = 0; i < lookupMetadata.length; i++) {
          this.initRelatedRecords(lookupMetadata[i].ChildObjectId, lookupMetadata[i].ChildFieldAPIName);
        }
      }
    });
  }


  initRelatedRecords(childObjectId: string, childFieldAPIName: string) {
    this.nyxAPIService
      .getRelatedRecord(this.urlState.organizationName!, childObjectId, childFieldAPIName, this.urlState.recordId)
      .subscribe((data: any) => {
        var objectMetadata = data.objectMetadata;
        this.groupedByRelatedRecord[objectMetadata.name] = data;
        this.groupedByKeyList.push(objectMetadata.name);
        this.groupedByCurrentFields[objectMetadata.name] = this.currentFields;


        this.setMenuItems(objectMetadata);

        if (data.recordViewListing.length > 0) {
          data.recordViewListing.forEach((view: any) => {
            this.viewMenuItems.push({
              label: view.name,
              icon: 'pi pi-fw pi-list',
              command: () => {
                this.onChangeViewColumn(view.fields, objectMetadata);
              }
            });
            this.groupedByViewMenuItems[objectMetadata.name] = this.viewMenuItems;
          });
        } else {
          this.groupedByViewMenuItems[objectMetadata.name] = this.viewMenuItems;
        }
      });
  }

  //view
  onViewRecordButtonClicked(objectId: string, recordId: string) {
    this.router.navigate(['/' + this.urlState.organizationName + '/app/nyx-record/' + objectId + '/edit/' + recordId]);
  }

  onChangeViewColumn(data: any, object: any) {
    // this.currentFields = [];
    this.groupedByCurrentFields[object.name] = [];
    if (data == 'systemDefault') {

      this.groupedByCurrentFields[object.name].push({ FieldAPIName: 'id', Label: 'Record ID' });
      this.groupedByCurrentFields[object.name].push({ FieldAPIName: 'name', Label: 'Name' });
      this.groupedByCurrentFields[object.name].push({ FieldAPIName: 'modifiedBy', Label: 'Last Modified By' });
      this.groupedByCurrentFields[object.name].push({ FieldAPIName: 'modifiedDatetime', Label: 'Last Modified Datetime' });
    } else {
      
      data.forEach((field: any) => {

        let findFromBasic = this.systemDefaultFields.fieldGroup.find(x => x.FieldAPIName == field);
        let findFormObject = object.schema.find((i: any) => i.FieldAPIName == field);

        if (findFromBasic) {
          this.groupedByCurrentFields[object.name].push(findFromBasic);
        } else if (findFormObject) {
          if (this.datetimeField.includes(findFormObject.FieldType)) this.datetimeGroup[findFormObject.FieldAPIName] = true;
          this.groupedByCurrentFields[object.name].push(findFormObject);
        }

      });
    }
  }

  setMenuItems(object: any) {
    this.viewMenuItems = [
      {
        label: 'System Default View',
        icon: 'pi pi-fw pi-list',
        command: () => {
          this.onChangeViewColumn('systemDefault', object);
        }
      }
    ];
  }

}
