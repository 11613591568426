import {
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';
import { IdConstant } from 'src/app/core/constants/id-constant';

@Component({
  selector: 'app-nyx-lookup-field',
  templateUrl: './nyx-lookup-field.component.html',
  styleUrls: ['./nyx-lookup-field.component.scss'],
})
export class NyxLookupFieldComponent extends BaseUrlState implements OnDestroy {

  @Input() nyxObject: any = {};
  @Input() nyxFieldSchema: any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted: boolean = false;
  @Input() controlName: string = '';
  @Input() isVersion:boolean = false;

  visible:boolean = false;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  //Function
  searchFromBackend: any = null;
  recordNotFound: boolean = false;
  componentState: any = {
    isTyping: false,
    isSearching: false,
    searchKeyword: '',
    searchResult: [],
    lookupRecord: {
      id: '',
      name: '',
    },
    lookupObject: null,
    isLoadingLookupRecord: false //Use when to load lookup record in editing mode
  };

  recordDataReady: Subscription;

  constructor(
    private nyxAPIService: NyxAPIService,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.eventSubjectSubscription();
    this.initObjects();
  }

  ngOnDestroy(): void {
    this.recordDataReady.unsubscribe();
  }

  eventSubjectSubscription(){
    this.recordDataReady = this.recordEvent.recordDataReadySubject.subscribe((load:any) => {
      if(load && (this.urlState.operation! == 'kanban' || this.urlState.operation! == 'edit')){
        this.initRecordForEditing();
      }
    });
  }

  onSearchKeyUp($event: any) {
    this.componentState.isTyping = true;

    clearTimeout(this.searchFromBackend);

    this.searchFromBackend = setTimeout(() => {
      this.componentState.isTyping = false;
      this.search();
    }, 1000);
  }

  search() {
    if (this.componentState.searchKeyword.trim() != '') {
      this.componentState.isSearching = true;
      this.nyxAPIService
        .searchNyxRecord(
          this.urlState.organizationName!,
          this.nyxFieldSchema.ObjectId,
          this.componentState.searchKeyword.trim()
        )
        .subscribe((data: any) => {
          this.componentState.searchResult = data;
          this.componentState.isSearching = false;
          console.log(this.componentState);
        });
    }
  }

  selectLookupButtonClick(id: string, name: string) {
    this.componentState.lookupRecord.id = id;
    this.componentState.lookupRecord.name = name;

    this.formGroup.patchValue({
      [this.controlName]: id,
    });

    this.closeModal();
  }

  initObjects() {
    this.nyxAPIService
      .getNyxObjectById(
        this.urlState.organizationName!,
        this.nyxFieldSchema.ObjectId
      )
      .subscribe((data: any) => {
        this.componentState.lookupObject = data;
      });
  }

  initRecordForEditing() {
    var lookupValue = this.formGroup.controls[this.controlName]?.value;
    if (lookupValue != null && lookupValue != '' && lookupValue != IdConstant.GENERAL.EMPTY_GUID) {
      this.componentState.isLoadingLookupRecord = true;
      this.nyxAPIService
        .getNyxRecord(this.urlState.organizationName!, lookupValue)
        .subscribe((data: any) => {
          if(data != null && data != undefined){
            this.componentState.lookupRecord = {
              id: data.id,
              name: data.name
            };
          }else{
            this.recordNotFound = true;
          }
          this.componentState.isLoadingLookupRecord = false;
        });
    }
  }

  onDeleteLookupRecord() {
    this.componentState.lookupRecord = {
      id: '',
      name: '',
    };

    this.formGroup.patchValue({
      [this.controlName]: '',
    });
  }

  openModal(){
    this.visible = true;
  }

  closeModal() {
    this.visible = false;
  }
}
