import { Component, OnInit } from '@angular/core';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  labelConstant = LabelConstant;
  message: string = '';
  constructor(private nyxService: NyxAPIService) { }

  ngOnInit() {
    this.nyxService.errorPageMessage.subscribe((msg:any) => {
      this.message = msg;
    });
  }

  redirectToLogin(){
    window.location.href = "https://nyxhub.b2clogin.com/nyxhub.onmicrosoft.com/b2c_1_signin_flow/oauth2/v2.0/authorize?client_id=fd96e626-0aa7-439d-bd1e-3ffd1fae1d07&scope=openid%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth&client-request-id=400eb165-3e20-4406-82a0-5b44daefc8f6&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=3.3.0&client_info=1&code_challenge=K4ccd9yn_RnFreI7_iqlYMiMkOBhGNcX0g2Yf_7FSSQ&code_challenge_method=S256&nonce=ef4d3a40-40cd-4871-947c-60c62d782644&state=eyJpZCI6ImFlNTYxZmE2LWU4YWQtNDI5NC1iNjlkLTVmMzMyMzc2M2RmNCIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D";
  }

}
