import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';

interface Fields {
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-automation-detail',
  templateUrl: './automation-detail.component.html',
  styleUrls: ['./automation-detail.component.scss']
})

export class AutomationDetailComponent extends BaseUrlState implements AfterViewInit {
  @ViewChild('codemirror')
  codeMirror: CodemirrorComponent;
  codeContent: any = '';

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  automationForm: FormGroup;
  submitted: boolean = false;
  objectList: Fields[] = [];
  objectValue: Fields = this.objectList[0];
  eventTypeList: Fields[] = [{
    index: 0,
    label: 'CREATE',
    value: 'CREATE'
  },
  {
    index: 1,
    label: 'UPDATE',
    value: 'UPDATE'
  },
  {
    index: 3,
    label: 'DELETE',
    value: 'DELETE'
  }];
  eventTypeValue: Fields;
  isSubmit: boolean = false;
  operation: any = 'create';
  automationId: any = '';
  automationData: any;

  constructor(
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: GlobalNotificationService,
    private router: Router
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe((result:any) =>{
      this.operation = this.activatedRoute.snapshot.paramMap.get('operation');
      this.automationId = this.activatedRoute.snapshot.paramMap.get('automationId');
      this.initForm();
      this.initConfiguration();

      if(this.operation == 'edit'){
        this.initAutomation();
      }
    });
  }

  ngAfterViewInit(){
    this.codeMirror.registerOnChange((val)=>{
      this.codeContent = val;
    });
  }

  initForm(){
    this.automationForm = this.fb.group({
      objectId: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.required]],
      eventType: ['', [Validators.required]],
      enabled: [true]
    });
  }

  get automationFormControl() {
    return this.automationForm.controls;
  }

  initConfiguration() {
    this.nyxAPIService.getNyxObjects(this.urlState.organizationName!).subscribe((data: any) => {

      let i = 0;
      data.forEach((obj: any) => {
        this.objectList.push({
          index: i,
          label: obj.name,
          value: obj.id
        });
        i++;
      });
    });
  }

  initAutomation(){
    this.nyxAPIService.getNyxAutomationById(this.urlState.organizationName!, this.automationId).subscribe((result:any) => {
      this.automationData = result;
      this.patchAutomationData();
    });

    this.nyxAPIService.getNyxAutomationBlobContent(this.urlState.organizationName!, this.automationId).subscribe((result:any) => {
      this.codeContent = result.blobContentString;
    });
  }

  patchAutomationData(){
    this.automationForm.patchValue({
      objectId: this.automationData.nyxObjectId,
      name: this.automationData.name,
      description: this.automationData.description,
      eventType: this.automationData.eventType,
      enabled: this.automationData.enabled
    });

    if(this.operation == 'edit'){
      this.automationForm.get('objectId')?.disable();
      this.automationForm.get('eventType')?.disable();
    }
  }

  submit(){
    this.submitted = true;

    if(this.codeContent != '' && this.automationForm.valid){
      var payload = {
        ObjectId: this.operation == 'edit' ? this.automationForm.get('objectId')?.getRawValue() : this.automationForm.value.objectId,
        Name: this.automationForm.value.name,
        Description: this.automationForm.value.description,
        Enabled: this.automationForm.value.enabled,
        EventType: this.operation == 'edit' ? this.automationForm.get('eventType')?.getRawValue() : this.automationForm.value.eventType,
        Code: this.codeContent
      };

      if (this.operation == 'create'){
        this.nyxAPIService.createNyxAutomation(this.urlState.organizationName!, payload).subscribe((result:any) => {
          console.log(result);
          this.router.navigate([`/${this.urlState.organizationName}/${this.urlState.sectionName}/nyx-automation-manager/overview`]);
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.AUTOMATION_MESSAGE.CREATE_AUTOMATION_SUCCESS));
        },
        (error:any) => {
          console.log('createAutomation error ', error);
          this.notificationService.displayErrorMessage(error?.error, NotificationMessage.GENERAL.ERROR);
        });
      }else{
        this.nyxAPIService.updateNyxAutomation(this.urlState.organizationName!, this.automationData.id, payload).subscribe((result:any) => {
          console.log(result);
          this.router.navigate([`/${this.urlState.organizationName}/${this.urlState.sectionName}/nyx-automation-manager/overview`]);
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.AUTOMATION_MESSAGE.UPDATE_AUTOMATION_SUCCESS));
        },
        (error:any) => {
          console.log('updateNyxAutomation error ', error);
          this.notificationService.displayErrorMessage(error?.error, NotificationMessage.GENERAL.ERROR);
        });
      }

    }
  }

}
