
<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Container-->
  <div class="container-xxl" id="kt_content_container">
    <app-nyx-overall-summary-listing></app-nyx-overall-summary-listing>
  </div>
  <!--end::Container-->
</div>
<!--end::Content-->
