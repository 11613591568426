import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from '../../../environments/environment'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
    constructor(private authService: MsalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let activeAccount = this.authService.instance.getActiveAccount();
    let aud = activeAccount?.idTokenClaims?.aud;
    if( aud == ''){
      return this.setAPIKeyOnly(req, next);
    }
    let msalAudTokens = JSON.parse(localStorage.getItem('msal.token.keys.' + aud) || '{}');
    if( msalAudTokens.idToken == undefined || msalAudTokens.idToken == null ){
      return this.setAPIKeyOnly(req, next);
    }else{
      let msalToken = JSON.parse(localStorage.getItem(msalAudTokens.idToken[0]) || '{}')
      let msalSecret = msalToken?.secret;
      return this.setAPIKeyAndAuthorization(msalSecret, req, next);
    }
  }

  setAPIKeyOnly(req: HttpRequest<any>, next: HttpHandler){
    const modifiedReq = req.clone({ 
      headers: req.headers.set('api-key', `${environment.APZURE_APIM_KEY}`),
    });
    return next.handle(modifiedReq);
  }

  setAPIKeyAndAuthorization(msalSecret:string, req: HttpRequest<any>, next: HttpHandler){
    const modifiedReq = req.clone({ 
      headers: req.headers.set('Authorization', `Bearer ${msalSecret}`).set('api-key', `${environment.APZURE_APIM_KEY}`),
    });
    return next.handle(modifiedReq);
  }
}