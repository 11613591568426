import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import '@stripe/stripe-js';
import { EmailService } from 'src/app/core/services/email.service';
import { Message } from 'primeng/api';
declare var Stripe: any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent extends BaseUrlState {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  generalConstant = GeneralConstant;
  billingDetailsForm: FormGroup = this.fb.group({
    addressLine1: ['', [Validators.required]],
    addressLine2: [''],
    city: ['', [Validators.required]],
    postcode: ['', [Validators.required]],
    state: ['', [Validators.required]],
    country: ['', [Validators.required]],
  });
  paymentDetails: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    card: ['', [Validators.required]],
    expMonth: ['', [Validators.required]],
    expYear: ['', [Validators.required]],
    cvc: ['', [Validators.required]]
  });
  routeItems = [
    { label: 'Billing' }, //, routerLink: 'personal'
    { label: 'Invoice' },
  ];
  userBillingData: any;
  stripeData: any;
  paymentData: any;
  settingSubmitted: boolean = false;
  billingSubmitted: boolean = false;
  stripeCardValid: boolean = false;
  stripeCardErrorMessage: string = '';
  cardLastFourDigit: number;
  messages: Message[] | undefined;
  countryList: any = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba",
    "Cyprus", "Czechia", "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor",
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica",
    "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho",
    "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "North Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives",
    "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria",
    "North Korea", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
    "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan",
    "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago",
    "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States",
    "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 400,
        fontFamily: 'Circular',
        fontSize: '14px',
        iconColor: '#666EE8',
        color: '#002333',
        '::placeholder': {
          color: '#919191',
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private nyxApiService: NyxAPIService,
    private router: Router,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private notificationService: GlobalNotificationService,
    public override activatedRoute: ActivatedRoute,
    public emailService: EmailService
  ) {
    super(activatedRoute);
  }

  override ngOnInit(){
    super.ngOnInit();
    this.initUserBilling();
  }

  get billingDetailsFormControl(){
    return this.billingDetailsForm.controls;
  }

  get paymentDetailsFormControl(){
    return this.paymentDetails.controls;
  }

  initUserBilling(){
    this.nyxApiService.getBillingByOrganizationName(this.urlState.organizationName!).subscribe((result: any) => {
      this.userBillingData = result;

      // To get billing address information
      this.nyxApiService.getStripeCustomerById(this.urlState.organizationName!, this.userBillingData.stripeUserId).subscribe((stripeData: any) => {
        this.stripeData = stripeData;
        this.patchFormValue();
      });
    });
  }

  patchFormValue(){
    if(this.stripeData.address != null){
      this.billingDetailsForm.patchValue({
        addressLine1: this.stripeData.address.line1,
        addressLine2: this.stripeData.address.line2,
        city: this.stripeData.address.city,
        postcode: this.stripeData.address.postalCode,
        state: this.stripeData.address.state,
        country: this.stripeData.address.country
      });
    }

    this.paymentDetails.patchValue({
      name: this.stripeData.name,
      email: this.stripeData.email,
      address: this.stripeData.address
    });
  }

  submitSetting(){
    this.settingSubmitted = true;

    if(this.billingDetailsForm.valid){
      var payload = {
        Address: {
          City: this.billingDetailsForm.value.city,
          Country: this.billingDetailsForm.value.country,
          Line1: this.billingDetailsForm.value.addressLine1,
          Line2: this.billingDetailsForm.value.addressLine2,
          PostalCode: this.billingDetailsForm.value.postcode,
          State: this.billingDetailsForm.value.state
        }
      }
      this.nyxApiService.updateStripeCustomer(this.urlState.organizationName!, this.stripeData.id, payload).subscribe((result) => {
        if(result != undefined && result != null){
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS ,NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.BILLING_MESSAGE.EDIT_BILLING_DETAILS_SUCCESS));
        }else{
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR ,NotificationMessage.GENERAL.ERROR, MessageBoxConstant.BILLING_MESSAGE.EDIT_BILLING_DETAILS_FAILED));
        }
      });
    }
  }

  updatePaymentMethod(){
    this.emailService.changeCreditCardEmail(this.urlState.organizationName!).subscribe((result:any) => {
      this.messages = [
        {
          severity: 'success',
          summary: 'Success',
          detail: "Our admin have received your update payment details request. You will received an email to update your payment details soon"
        },
      ];
    });
  }

  onChangeCardDetail(event: StripeCardElementChangeEvent){
    if(event.error){
      this.stripeCardErrorMessage = event.error.message;
    }else{
      this.stripeCardErrorMessage = '';
    }
    this.stripeCardValid = event.complete;
  }
}
