import { AfterViewInit, Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';

interface Fields {
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-webhook-detail',
  templateUrl: './webhook-detail.component.html',
  styleUrls: ['./webhook-detail.component.scss'],
})
export class WebhookDetailComponent extends BaseUrlState {
  generalConstant = GeneralConstant;
  labelConstant = LabelConstant;

  webhookProtocolList: any = ['GET', 'POST', 'PUT'];
  webhookId: any = '';
  webhookData: any = {};
  webhookForm: FormGroup;
  submitted = false;
  operation: any = 'create';

  objectList: Fields[] = [];
  objectValue: Fields = this.objectList[0];

  quantities: number[] = [1, 1, 1];
  value: string = '';
  checked: boolean = true;
  checked2: boolean = true;
  cities = [
    { name: 'USA / New York', code: 'NY' },
    { name: 'Italy / Rome', code: 'RM' },
    { name: 'United Kingdoom / London', code: 'LDN' },
    { name: 'Turkey / Istanbul', code: 'IST' },
    { name: 'France / Paris', code: 'PRS' }
  ];
  selectedCity: string = '';

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.operation = this.activatedRoute.snapshot.paramMap.get('operation');
    this.webhookId = this.activatedRoute.snapshot.paramMap.get('webhookId');
    this.initForm();
    this.initConfiguration();

    if (this.operation == 'edit') {
      this.initWebhook();
    }
  }

  get webhookFormControl() {
    return this.webhookForm.controls;
  }

  get webhookHeadersControl() {
    return this.webhookForm.controls['headers'] as FormArray;
  }

  addHeader(key: string, value: string) {
    const headerForm = this.fb.group({
      key: [key],
      value: [value],
    });

    this.webhookHeadersControl.push(headerForm);
  }

  initConfiguration() {
    this.nyxAPIService
      .getNyxObjects(this.urlState.organizationName!)
      .subscribe((data: any) => {
        // this.objectList = data;

        console.log('objectList ', data)

        let i = 0;
        data.forEach((obj: any) => {
          this.objectList.push({
            index: i,
            label: obj.name,
            value: obj.id
          });
          i++;
        });
      });
  }

  initWebhook() {
    this.nyxAPIService
      .getNyxWebhookById(this.urlState.organizationName!, this.webhookId)
      .subscribe((data: any) => {
        this.webhookData = data;
        this.setWebhookIntoForm(data);
      });
  }

  initForm() {
    this.webhookForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.maxLength(100)]],
      objectId: ['', [Validators.required]],
      enabled: [false],
      onCreate: [false],
      onUpdate: [false],
      onDelete: [false],
      protocol: ['', [Validators.required]],
      url: ['', [Validators.required]],
      headers: new FormArray([]),
    });

    if (this.operation == 'create') {
      //Default to show 1 header during create
      //During edit, directly triggerred by other method
      this.addHeader('', '');
    }
  }

  setWebhookIntoForm(data: any) {
    console.log(data);

    this.webhookForm.patchValue({
      ['objectId']: data.nyxObjectId,
      ['name']: data.name,
      ['description']: data.description,
      ['enabled']: data.enabled,
      ['onCreate']: data.onCreate,
      ['onUpdate']: data.onUpdate,
      ['onDelete']: data.onDelete,
      ['protocol']: data.protocol,
      ['url']: data.url,
    });

    // this.objectValue = data.nyxObjectId

    if (data.headers.length == 0) {
      this.addHeader('', '');
    } else {
      data.headers.forEach((header: any) => {
        this.addHeader(header.Key, header.Value);
      });
    }
  }

  submit() {
    this.submitted = true;

    console.log('submit clicked')
    if (this.webhookForm.valid) {
      var newWebhookPayload = {
        Name: this.webhookForm.value.name,
        Description: this.webhookForm.value.description,
        Enabled: this.webhookForm.value.enabled,
        ObjectId: this.objectValue,
        OnCreate: this.webhookForm.value.onCreate,
        OnUpdate: this.webhookForm.value.onUpdate,
        OnDelete: this.webhookForm.value.onDelete,
        Protocol: this.webhookForm.value.protocol,
        URL: this.webhookForm.value.url,
        Headers: [],
      };

      var headersPayload: any = [];
      this.webhookForm.value.headers.forEach((header: any) => {
        if (header.key != '' && header.key != '') {
          var headerPayload: any = { Key: header.key, Value: header.value };
          headersPayload.push(headerPayload);
        }
      });

      newWebhookPayload.Headers = headersPayload;

      console.log(newWebhookPayload)
      if (this.operation == 'create') {
        this.nyxAPIService
          .createNyxWebhook(this.urlState.organizationName!, newWebhookPayload)
          .subscribe((data: any) => {
            this.redirectToWebhookListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WEBHOOK_MESSAGE.CREATE_WEBHOOK_SUCCESS.replace('{{ name }}', data.name)));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WEBHOOK_MESSAGE.CREATE_WEBHOOK_ERROR);
          });
      } else {
        this.nyxAPIService
          .updateNyxWebhook(
            this.urlState.organizationName!,
            this.webhookId,
            newWebhookPayload
          )
          .subscribe((data: any) => {
            this.redirectToWebhookListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WEBHOOK_MESSAGE.EDIT_WEBHOOK_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WEBHOOK_MESSAGE.EDIT_WEBHOOK_ERROR);
          });
      }
    } else {
      console.log(this.webhookForm)
    }
  }

  discard() {
    this.webhookForm.patchValue({
      ['objectId']: '',
      ['name']: '',
      ['description']: '',
      ['enabled']: false,
      ['onCreate']: false,
      ['onUpdate']: false,
      ['onDelete']: false,
      ['protocol']: '',
      ['url']: ''
    });

    const headerArray = <FormArray>this.webhookForm.controls.headers;
    headerArray.controls = [];
    this.addHeader('', '');
    this.redirectToWebhookListingPage();

  }

  redirectToWebhookListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName!}/setup/webhook-manager/overview`,
    ]);
  }
}
