export class GeneralConstant {
  //Input error label
  static REQUIRED_MESSAGE = {
    USERNAME_REQUIRED: 'Username is required',
    NAME_REQUIRED: 'Name is required',
    ROLE_REQUIRED: 'Role Name is required',
    EMAIL_REQUIRED: 'Email is required',
    PASSWORD_REQUIRED: 'Password is required',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
    CONFIRM_PASSWORD_NOT_MATACH: 'Confirm Password is not match',
    ORGANIZATION_REQUIRED: 'Organization Name is required',
    ORGANIZATION_NOT_VALID: 'Invalid format. Only lowercase letters are allowed.',
    FIELD_REQUIRED: 'This Field is required',
    DESCRIPTION_REQUIRED: 'Description is required',
    PERMISSION_REQUIRED: 'Permission is required',
    OBJECT_REQUIRED: 'Object is required',
    WEBHOOK_REQUIRED: 'Webhook name is required',
    OBJECT_REQUIRED_MESSAGE: 'Invalid format. Only lowercase letters and numeric are allowed.',
    WEBHOOK_MESSAGE: 'This webhook will trigger when a record',
    PROTOCOL_REQUIRED: 'Protocol is required',
    URL_REQUIRED: 'URL is required',
    QUERY_REQUIRED: 'Query is required',
    NAME_REQUIRED_SYMBOL: 'Name should have maximum 100 symbols',
    RECORD_VIEW_NAME_REQUIRED: 'View name is required',
    FILE_REQUIRED: 'File is required',
    EVENT_TYPE_REQUIRED: 'Event Type is required',
    AUTOMATION_REQUIRED: 'Automation code is required',
    PARAMETERIZED_QUERY_REQUIRED: 'Parameterized query is required'
  };

  // Input hint message
  static HINT_MESSAGE = {
    OBJECT_FILE_IMPORT_HINT: 'Accepted file type: JSON.',
    RECORD_FILE_IMPORT_HINT: 'Accepted file types: JSON / Excel.'
  }

  static FORM_GROUP_LABEL = {
    API_ACCESS_FORM: 'apiAccessForm',
  };
  static PAGE_MODE = {
    EDIT: 'edit',
    CREATE: 'create',
  };

  static INVALID_MESSAGE = {
    EMAIL_INVALID: 'Enter valid Email address',
    FIELD_RESTRICT: 'Value does not match regex expression',
    SYSTEM_DEFAULT_FIELD_MESSAGE: 'This Field API Name is System Default Name, please rename the Field Label.',
  };

  static QUERY = {
    QUERY_LABEL: 'Report Query',
  };

  static TABLE_DATA = {
    DATE: 'dd MMM yyyy, h:mm a',
    NO_RECORD_FOUND: 'No records found.',
    LOADING: 'Loading data. Please wait.',
    SEARCH_BY_ID: 'Try to search by name or record id',
  };

  static UNIQUE_ID_FIELD = {
    FIELD_READONLY: 'This field is readonly',
    FIELD_AUTO_GENERATE: 'This field will Auto-generate',
  };

  static DASHBOARD = {
    DASHBOARD_OVERVIEW: 'dashboard_overview'
  }

  static ORGANIZATION = {
    DIRECT_ENGAGE: 'Direct'
  }

}
