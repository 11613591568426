<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="fileMode" class="col-12 mb-2 md:col-2 md:mb-0">
                File Mode
            </label>
            <div class="col-12 md:col-10">
                
                <p-inputNumber  id="fileMode" type="number" formControlName="fileMode" min="1"></p-inputNumber>
                <small class="p-2">
                    Value 1 = support single file; Value more than > 1 = support multiple files.
                </small>
                <small class="p-error p-2" *ngIf="formGroup.controls['fileMode'].errors?.required && (formGroup.controls['fileMode']?.touched || formSubmitted)">
                    This field is required.
                </small>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="fileExtension" class="col-12 mb-2 md:col-2 md:mb-0">
                Extensions
            </label>
            <div class="col-12 md:col-10">
                <p-chips formControlName="fileExtension"></p-chips>
                <small>
                    Press enter to add extensions value; Keep empty will allow all type extendsion.
                </small>
            </div>
        </div>
    </div>


    <input type="hidden" formControlName="isRequired" value="false" />
    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />

</form>