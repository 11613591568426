import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datetime-field-configuration',
  templateUrl: './datetime-field-configuration.component.html',
  styleUrls: ['./datetime-field-configuration.component.scss']
})
export class DatetimeFieldConfigurationComponent {
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = ""

}
