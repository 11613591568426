import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NewFieldEventService } from 'src/app/core/services/event.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-nyx-object-schema-listing',
  templateUrl: './nyx-object-schema-listing.component.html',
  styleUrls: ['./nyx-object-schema-listing.component.scss'],
})
export class NyxObjectSchemaListingComponent extends BaseUrlState {
  @Output() passNavigationInfo = new EventEmitter();
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;


  nyxObject: any = null;
  schemas: any[] = [];

  loading: boolean = true;

  navigationInfo: any = {
    pageName: 'Settings',
    pageRouting: ''
  }

  constructor(
    private notificationService: GlobalNotificationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private newFieldEventService: NewFieldEventService,
    private nyxConfirmationService: NyxConfirmationService,
    private datePipe: DatePipe,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
    this.subscribeToRefreshSchemaListing();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.getNyxObject();
    // this.navigationInfo.pageRouting = '/' + this.urlState.organizationName;
    this.passNavigationInfo.emit(this.navigationInfo);
  
    this.loading = false;
  }

  getNyxObject() {
    if (this.urlState.objectId == null || this.urlState.objectId == undefined || this.urlState.objectId == '') {
      return;
    }

    this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId).subscribe((data: any) => {
      this.nyxObject = data;
      this.schemas = Object.entries(data.schema);

      this.schemas.sort((a, b) => {
        const labelA = a[1].Label.toLowerCase();
        const labelB = b[1].Label.toLowerCase();

        if (labelA < labelB) {
          return -1;
        } else if (labelA > labelB) {
          return 1;
        } else {
          return 0;
        }
      });

      // console.log("this.schemas", this.schemas);
      // console.log("this.nyxObject", this.nyxObject);
    });
  }

  onFieldDelete(thisContext: any, fieldAPIName: string) {
    thisContext.nyxAPIService.deleteNyxField(thisContext.urlState.organizationName!, thisContext.urlState.objectId, fieldAPIName).subscribe((data: any) => {
      thisContext.getNyxObject();
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.OBJECTFIELD_MESSAGE.DELETE_OBJECTFIELD_SUCCESS));

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECTFIELD_MESSAGE.DELETE_OBJECTFIELD_ERROR);
    });
  }

  onObjectSchemaListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.OBJECTFIELD_MESSAGE.DELETE_OBJECTFIELD_CONFIRMATION, this, this.onFieldDelete, id, null)
  }

  subscribeToRefreshSchemaListing() {
    this.newFieldEventService.newFieldSubject.subscribe(
      value => {
        this.getNyxObject();
      }
    );
  }

  downloadSchma(){
    this.nyxAPIService.getSchemaDownload(this.urlState.organizationName!, this.urlState.objectId!).subscribe((response:any) => {
      if(response.body != undefined){
        // Get current Datetime and convert to specific format
        const currentDatetime = new Date();
        const finalDatetime = this.datePipe.transform(currentDatetime, 'yyyyMMdd');
        // Create a temporary element to download the file
        const downloadFile = new Blob([response.body], {type: response.body.type});
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'object_' + finalDatetime + '_' + this.urlState.objectId;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });
  }

}
