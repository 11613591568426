<div class="card">
  <form [formGroup]="webhookForm">
    <div class="grid grid-nogutter">
      <div class="col-12 px-4 mt-4 md:mt-6 md:px-6">
        <span class="text-900 block font-bold text-xl">{{labelConstant.WEBHOOK.WEBHOOK_LABEL}}</span>
      </div>
      <div class="col-12 lg:col-6 h-full px-4 py-4 md:px-6">
        <ul class="flex list-none flex-wrap p-0 mb-6">
        </ul>
        <div class="grid formgrid">
          <div class="col-12 field mb-0">
            <span class="text-900 text-2xl block font-medium mb-5">{{labelConstant.WEBHOOK.WEBHOOK_INFO_LABEL}}</span>
          </div>

          <div class="col-12 field mb-4">
            <label htmlFor="name3">Name</label>
            <input id="description" placeholder="" type="text" class="p-inputtext w-full mb-0" formControlName="name">
            <small class="p-error"
              *ngIf="(webhookFormControl.name.touched || submitted) && webhookFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.WEBHOOK_REQUIRED}}
            </small>
          </div>

          <div class="col-12 field mb-0">
            <label htmlFor="name3">{{labelConstant.LABEL.DESCRIPTION}}</label>
            <input id="description" placeholder="" type="text" class="p-inputtext w-full mb-4"
              formControlName="description">
          </div>

          <div class="col-12 field mb-6">
            <label htmlFor="name3">{{labelConstant.LABEL.OBJECT}}</label>
            <p-dropdown formControlName="objectId" placeholder="Select an Object" styleClass="w-full" autoWidth="false"
              [options]="objectList" optionLabel="label" optionValue="value" [(ngModel)]="objectValue"
              name="selectedField" [filter]="true" filterBy="index"></p-dropdown>

            <small class="p-error"
              *ngIf="(webhookFormControl.objectId.touched || submitted) && webhookFormControl.objectId.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.OBJECT_REQUIRED}}
            </small>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-4 py-4 md:px-6 mt-8">
        <div class="py-2 mt-2">
          <div class="col-12 flex justify-content-between align-items-center mb-3">
            <span class="text-900 font-medium">{{labelConstant.WEBHOOK.ENABLED}}</span>
            <p-inputSwitch class="p-2" formControlName="enabled"></p-inputSwitch>
          </div>
          <div class="col-12 flex justify-content-between align-items-center mb-0">
            <span class="text-900 font-medium">{{labelConstant.WEBHOOK.ON_CREATE}}</span>
            <p-inputSwitch class="p-2" formControlName="onCreate"></p-inputSwitch>
          </div>
          <span class=" col-12 mb-3">{{generalConstant.REQUIRED_MESSAGE.WEBHOOK_MESSAGE}}</span>

          <div class="col-12 flex justify-content-between align-items-center mb-0">
            <span class="text-900 font-medium">{{labelConstant.WEBHOOK.ON_UPDATE}}</span>
            <p-inputSwitch class="p-2" formControlName="onUpdate"></p-inputSwitch>
          </div>
          <span class=" col-12 mb-3">{{generalConstant.REQUIRED_MESSAGE.WEBHOOK_MESSAGE}}</span>

          <div class="col-12 flex justify-content-between align-items-center mb-0">
            <span class="text-900 font-medium">{{labelConstant.WEBHOOK.ON_DELETE}}</span>
            <p-inputSwitch class="p-2" formControlName="onDelete"></p-inputSwitch>
          </div>
          <span class=" col-12 mb-3">{{generalConstant.REQUIRED_MESSAGE.WEBHOOK_MESSAGE}}</span>
        </div>
      </div>

      <div class="col-12 lg:col-12 h-full px-4 py-4 md:px-6">
        <ul class="flex list-none flex-wrap p-0 mb-6">
        </ul>
        <div class="grid formgrid">
          <div class="col-12 field mb-4">
            <span class="text-900 text-2xl block font-medium mb-5">{{labelConstant.WEBHOOK.WEBHOOK_CONFIG_LABEL}}</span>
          </div>

          <div class="col-12 lg:col-3 field mb-4">
            <label htmlFor="protocol">{{labelConstant.WEBHOOK.WEBHOOK_PROTOCOL_LABEL}}</label>
            <p-dropdown [options]="webhookProtocolList" placeholder="Select a protocol" [showClear]="true"
              styleClass="w-full" formControlName="protocol"></p-dropdown>
            <small class="p-error"
              *ngIf="(webhookFormControl.protocol.touched || submitted) && webhookFormControl.protocol.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.PROTOCOL_REQUIRED}}
            </small>
          </div>
          <div class="col-12 lg:col-9 field mb-4">
            <label htmlFor="url">{{labelConstant.WEBHOOK.URL_LABEL}}</label>
            <input id="url" placeholder="" type="text" class="p-inputtext w-full" formControlName="url">
            <small class="p-error"
              *ngIf="(webhookFormControl.url.touched || submitted) && webhookFormControl.url.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.URL_REQUIRED}}
            </small>
          </div>

          <div class="col-12 lg:col-12 field mb-0">
            <label htmlFor="key">{{labelConstant.WEBHOOK.HEADERS_LABEL}}</label>
            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-text"
              (click)="addHeader('','')"></button>
          </div>
          <ng-container *ngFor="let headerControl of webhookHeadersControl.controls">
            <ng-container [formGroup]="headerControl">
              <div class="col-12 lg:col-5 field mb-4">
                <input id="key" placeholder="Key" type="text" class="p-inputtext w-full" formControlName="key">
              </div>
              <div class="col-12 lg:col-7 field mb-4">
                <input id="value" placeholder="Value" type="text" class="p-inputtext w-full" formControlName="value">
              </div>
            </ng-container>
          </ng-container>

          <div
            class="col-12 flex flex-column lg:flex-row justify-content-center align-items-center lg:justify-content-end my-6">
            <button pButton pRipple class="p-button-primary w-full lg:w-auto flex-order-1 lg:flex-order-1 lg:mr-4"
              label="Save Changes" (click)="submit()"></button>
            <button pButton pRipple
              class="p-button-secondary p-button-outlined mt-3 lg:mt-0 w-full lg:w-auto flex-order-2 lg:flex-order-2 "
              label="Cancel" (click)="discard()"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>