import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';




@Component({
  selector: 'app-webhook-log-listing',
  templateUrl: './webhook-log-listing.component.html',
  styleUrls: ['./webhook-log-listing.component.css']
})
export class WebhookLogListingComponent extends BaseUrlState {
  // messageBoxConstant = MessageBoxConstant;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  webhookId: any = "";
  submitted = false;
  pageMode = "edit";
  dataListing: any;
  payload: any = [];
  validatedQueryResult = {};
  messageBox = {
    showMessageBox: false,
    messageBoxMessage: '',
    messageBoxStatus: ''
  }
  loading: boolean = true;

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.webhookId = this.activatedRoute.snapshot.paramMap.get('webhookId');
    this.initWebhookLogData();
  }

  initWebhookLogData() {
    this.nyxAPIService.getNyxWebhookLogById(this.urlState.organizationName!, this.webhookId).subscribe((result: any) => {
      if (result != undefined && result.length > 0) {
        this.dataListing = result;

        console.log(result)
        this.dataListing.forEach((log: any) => {
          // Convert stringify json into json object. Then replace itself
          log.payload = JSON.parse(log.payload);
          //this.payload.push(JSON.parse(log.payload));
        });
        console.log(this.payload)
      }
    });
    this.loading = false;
  }
}
