<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="isRequired" class="col-12 mb-2 md:col-2 md:mb-0">
                Required?
            </label>
            <div class="col-12 md:col-10">
                <p-inputSwitch inputId="isRequired" name="isRequired" formControlName="isRequired"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="objectId" class="col-12 mb-2 md:col-2 md:mb-0">
                Lookup Object
            </label>
            <!-- <div class="col-12 md:col-10"> -->
                <div class="col-12 md:col-10">
                    <p-dropdown formControlName="objectId" placeholder="Select" styleClass="col-md-12" autoWidth="false"
                        [options]="nyxObjectList" optionLabel="label" optionValue="value" [(ngModel)]="selectedObject"
                        name="selectedField" [filter]="true" filterBy="label"></p-dropdown>

                    <small class="p-error"
                        *ngIf="(fieldFormControl.objectId.touched || formSubmitted) && fieldFormControl.objectId.errors?.required">
                        Object is required
                    </small>
                </div>
            <!-- </div> -->
        </div>
    </div>

    <input type="hidden" formControlName="isRequired" value="false" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="defaultValue" value="" />
    <input type="hidden" formControlName="regexRestriction" value="" />
    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />
</form>