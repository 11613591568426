import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import {
  GlobalNotification,
  NotificationMessage,
  GlobalNotificationService,
  GlobalNotificationSeverity,
} from 'src/app/core/services/notification.service';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MenuService } from 'src/app/core/services/menu.service';
import { UrlMetadata } from 'src/app/core/constants/urlMetadata';

@Component({
  selector: 'app-modal-upsert-dashboard',
  templateUrl: './modal-upsert-dashboard.component.html',
  styleUrls: ['./modal-upsert-dashboard.component.scss'],
})
export class ModalUpsertDashboardComponent extends BaseUrlState {
  @Output() callParentCloseModal = new EventEmitter();
  @Input() modalShow: boolean = false;
  @Input() modalOperation: string;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
  messageBoxConstant = MessageBoxConstant;

  formSubmission: boolean = false;
  dashboardId: any = '';
  dashboardForm: FormGroup;
  pageMode = UrlMetadata.OPERATION.EDIT;
  dashboardData: any = {};
  tableColumnList: any = [];
  hideBackButton: boolean = false;
  isCreating = false;
  setDefault = false;

  widgetList: any = [
    {
      id: 1,
      name: 'Table',
      className: 'ki-tablet',
    },
    {
      id: 2,
      name: 'Line Graph',
      className: 'ki-graph-3',
    },
    {
      id: 3,
      name: 'Bar Chart',
      className: 'ki-graph-2',
    },
  ];

  messageBox = {
    showMessageBox: false,
    messageBoxMessage: '',
    messageBoxStatus: '',
  };
  validatedQueryResult = {};
  items: MenuItem[] | undefined;

  constructor(
    private fb: FormBuilder,
    private nyxAPIService: NyxAPIService,
    protected router: Router,
    private notificationService: GlobalNotificationService,
    public messageService: MessageService,
    public menuService: MenuService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe((params) => {
      this.urlState.dashboardId =
        this.activatedRoute.snapshot.paramMap.get('dashboardId');
      this.pageMode =
        this.urlState.operation != null ? this.urlState.operation : 'edit';
      this.initForm();
      if (this.pageMode == 'edit') {
        this.initDashboardFormData();
      }
    });
  }

  initDashboardFormData() {
    this.nyxAPIService
      .getDashboardById(this.urlState.organizationName!, this.urlState.dashboardId!)
      .subscribe((data: any) => {
        this.dashboardData = data;
        if(this.dashboardData != null){
          this.patchDashboardDataIntoForm();
        }
      });
  }

  patchDashboardDataIntoForm() {
    this.dashboardForm.patchValue({
      ['name']: this.dashboardData.name,
      ['description']: this.dashboardData.description,
      ['isDefault']: this.dashboardData.isDefault,
    });
    this.setDefault = this.dashboardData.isDefault;
  }

  get dashboardFormControl() {
    return this.dashboardForm.controls;
  }

  initForm() {
    this.dashboardForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: [''],
      isDefault: [false],
    });
  }

  submit() {
    this.formSubmission = true;
    if (this.dashboardForm.valid) {
      const payload = {
        Name: this.dashboardForm.value.name,
        Description: this.dashboardForm.value.description,
        IsDefault: this.setDefault,
      };

      if(this.modalOperation == 'edit'){
        this.nyxAPIService.updateDashboard(this.urlState.organizationName!, this.urlState.dashboardId!, payload).subscribe((data: any) => {
          this.menuService.onLoadMenu(true);
          this.closeModal();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.DASHBOARD_MESSAGE.EDIT_DASHBOARD_SUCCESS));
        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.DASHBOARD_MESSAGE.EDIT_DASHBOARD_ERROR);
        });
      }else{
        this.nyxAPIService.createDashboard(this.urlState.organizationName!, payload).subscribe((data: any) => {
          this.menuService.onLoadMenu(true);
          this.router.navigate([`${this.urlState.organizationName!}/app/dashboard-manager/${data.id}/overview`]);
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.DASHBOARD_MESSAGE.CREATE_DASHBOARD_SUCCESS));
        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.DASHBOARD_MESSAGE.CREATE_DASHBOARD_ERROR);
        });
      }
    }
  }

  closeModal() {
    this.modalShow = false;
    this.callParentCloseModal.emit(this.modalShow);
  }

  clearFormValue() {
    this.hideBackButton = false;
    this.isCreating = false;
    this.messageBox.showMessageBox = false;
    this.tableColumnList = [];
    this.dashboardForm.patchValue({
      name: '',
      description: '',
      isDefault: false,
    });
  }
}
