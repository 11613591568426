export class MessageBoxConstant{
  static RECORD_MESSAGE={
    CREATE_RECORD_SUCCESS: 'New record {{ name }} created',
    CREATE_RECORD_ERROR: 'Fail to create new record',
    DELETE_RECORD_SUCCESS: 'Record deleted sucessfully',
    DELETE_RECORD_ERROR: 'Record deleted failed',
    EDIT_RECORD_SUCCESS: 'Record updated sucessfully',
    EDIT_RECORD_ERROR: 'Record update failed',
    DELETE_RECORD_CONFIRMATION: 'Do you want to delete this record?',
    IMPORT_RECORD_SUCCESS: 'Record import successfully',
    DELETE_FILE_SUCCESS: 'File deleted successfully',
    ATTACHMENT_NOT_FOUND: 'Attachment not found',
  }

  static FILE_ATTACHMENT_MESSAGE= {
    DELETE_FILE_ATTACHMENT_CONFIRMATION: 'Do you want to delete this attachment?'
  }

  static OBJECT_MESSAGE={
    CREATE_OBJECT_SUCCESS: 'New Object {{ name }} created',
    CREATE_OBJECT_ERROR: 'Fail to create new object',
    DELETE_OBJECT_SUCCESS: 'Object deleted sucessfully',
    DELETE_OBJECT_ERROR: 'Object deleted failed',
    DELETE_OBJECT_CONFIRMATION: 'Do you want to delete this object?',
    IMPORT_OBJECT_SUCCESS: 'Object import successfully'
  }

  static REPORT_MESSAGE={
    CREATE_REPORT_SUCCESS: 'New report {{ name }} created',
    CREATE_REPORT_ERROR: 'Fail to create new report',
    DELETE_REPORT_SUCCESS: 'Report deleted sucessfully',
    DELETE_REPORT_ERROR: 'Report deleted failed',
    EDIT_REPORT_SUCCESS: 'Report {{ name }} updated',
    EDIT_REPORT_ERROR: 'Fail to edit report',
    DELETE_REPORT_CONFIRMATION: 'Do you want to delete this report?'
  }

  static USER_MESSAGE={
    INVITE_USER_SUCCESS: 'Invite user sucessfully',
    INVITE_USER_ERROR: 'Invite user failed',
    CREATE_USER_SUCCESS: 'New User {{ name }} created',
    CREATE_USER_ERROR: 'Fail to create new User',
    DELETE_USER_SUCCESS: 'User deleted sucessfully',
    DELETE_USER_ERROR: 'User deleted failed',
    EDIT_USER_SUCCESS: 'User Role updated',
    EDIT_USER_ERROR: 'Fail to edit User Role',
    REMOVE_USER_SUCCESS: 'User Role removed',
    REMOVE_USER_ERROR: 'Fail to remove User Role',
    DELETE_USER_CONFIRMATION: 'Do you want to delete this user?',
  }

  static ROLE_MESSAGE={
    CREATE_ROLE_SUCCESS: 'New Role {{ name }} created',
    CREATE_ROLE_ERROR: 'Fail to create new Role',
    DELETE_ROLE_SUCCESS: 'Role deleted sucessfully',
    DELETE_ROLE_ERROR: 'Role deleted failed',
    EDIT_ROLE_SUCCESS: 'Role permission updated',
    EDIT_ROLE_ERROR: 'Fail to edit Role permission',
    REMOVE_ROLE_SUCCESS: 'Role permission removed',
    REMOVE_ROLE_ERROR: 'Fail to remove Role permission',
    DELETE_ROLE_CONFIRMATION: 'Do you want to delete this role?'
  }

  static PERMISSION_MESSAGE={
    CREATE_PERMISSION_SUCCESS: 'New Permission {{ name }} created',
    CREATE_PERMISSION_ERROR: 'Fail to create new Permission',
    DELETE_PERMISSION_SUCCESS: 'Permission deleted sucessfully',
    DELETE_PERMISSION_ERROR: 'Permission delete failed',
    EDIT_PERMISSION_SUCCESS: 'Permission updated',
    EDIT_PERMISSION_ERROR: 'Fail to update permission',
    DELETE_PERMISSION_CONFIRMATION: 'Do you want to delete this permission?'
  }

  static WEBHOOK_MESSAGE={
    CREATE_WEBHOOK_SUCCESS: 'New Webhook {{ name }} created',
    CREATE_WEBHOOK_ERROR: 'Fail to create new Webhook',
    DELETE_WEBHOOK_SUCCESS: 'Webhook deleted sucessfully',
    DELETE_WEBHOOK_ERROR: 'Webhook delete failed',
    EDIT_WEBHOOK_SUCCESS: 'Webhook update sucessfully',
    EDIT_WEBHOOK_ERROR: 'Webhook update failed',
    DELETE_WEBHOOK_CONFIRMATION: 'Do you want to delete this Webhook?'
  }

  static PARAMTERIZED_QUERY_MESSAGE={
    CREATE_PARAMETERIZED_QUERY_SUCCESS: 'New parameterized query {{ name }} created',
    CREATE_PARAMETERIZED_QUERY_ERROR: 'Fail to create new parameterized query',
    DELETE_PARAMETERIZED_QUERY_SUCCESS: 'Parameterized query deleted sucessfully',
    DELETE_PARAMETERIZED_QUERY_ERROR: 'Parameterized query delete failed',
    EDIT_PARAMETERIZED_QUERY_SUCCESS: 'Parameterized query update sucessfully',
    EDIT_PARAMETERIZED_QUERY_ERROR: 'Parameterized query update failed',
    DELETE_PARAMETERIZED_QUERY_CONFIRMATION: 'Do you want to delete this parameterized query?'
  }

  static APIACCESS_MESSAGE={
    CREATE_APIACCESS_SUCCESS: 'New API Access {{ name }} created',
    CREATE_APIACCESS_ERROR: 'Fail to create new API Access',
    DELETE_APIACCESS_SUCCESS: 'API Access deleted sucessfully',
    DELETE_APIACCESS_ERROR: 'API Access delete failed',
    EDIT_APIACCESS_SUCCESS: 'API Access update sucessfully',
    EDIT_APIACCESS_ERROR: 'API Access update failed',
    DELETE_APIACCESS_CONFIRMATION: 'Do you want to delete this API Access?'
  }

  static OBJECTFIELD_MESSAGE={
    GET_OBJECTFIELD_ERROR: 'Object Field not found',
    CREATE_OBJECTFIELD_SUCCESS: 'New Object Field created',
    CREATE_OBJECTFIELD_ERROR: 'Fail to create new Object Field',
    DELETE_OBJECTFIELD_SUCCESS: 'Object Field deleted sucessfully',
    DELETE_OBJECTFIELD_ERROR: 'Object Field delete failed',
    EDIT_OBJECTFIELD_SUCCESS: 'Object Field update sucessfully',
    EDIT_OBJECTFIELD_ERROR: 'Object Field update failed',
    DELETE_OBJECTFIELD_CONFIRMATION: 'Do you want to delete this Object Field?'
  }

  static PAYMENT_MESSAGE={
    PAYMENT_SUCCESS: 'Payment success',
    SUCCEEDED: 'succeeded',
    ACTIVE: 'active'
  }

  static PLAN = {
    PAYMENT_PROCESSING: 'Payment is processing. You will receive an email for the payment result',
    SUCCESS_UPGRADE_PLAN: 'Upgrade plan successfully',
    FAILED_TO_UPGRADE_PLAN: 'Have some problem when upgrade plan. Please contact our admin',
    SUCCESS_DOWNGRADE_PLAN: 'Downgrade plan successfully',
    FAILED_TO_DOWNGRADE_PLAN: 'Have some problem when downgrade plan. Please contact our admin',
    CANCEL_PLAN_CONFIRMATION: 'Are you sure want to cancel current plan?',
    CANCEL_PLAN_CONFIRMATION_POPUP: 'Cancel plan confirmation'
  }

  static BILLING_MESSAGE={
    EDIT_BILLING_DETAILS_SUCCESS: 'Billing details updated successfully',
    EDIT_BILLING_DETAILS_FAILED: 'Failed to update billing details',
    EDIT_CARD_PAYMENT_SUCCESS: 'Payment details updated successfully',
    EDIT_CARD_PAYMENT_FAILED: 'Failed to update payment details'
  }

  static MESSAGE = {
    REQUIRED_TO_UPLOAD_DATASOURCE: 'Required to upload datasource',
    UPLOAD_FAILED: 'Upload Failed'
  }

  static WIDGET_MESSAGE={
    CREATE_WIDGET_SUCCESS: 'Widget created successful',
    CREATE_WIDGET_ERROR: 'Fail to create widget',
    EDIT_WIDGET_SUCCESS: 'Widget update successful',
    EDIT_WIDGET_ERROR: 'Fail to edit widget',
    DELETE_WIDGET_SUCCESS: 'Widget deleted sucessfully',
    DELETE_WIDGET_ERROR: 'Widget delete failed',
    DELETE_WIDGET_CONFIRMATION: 'Do you want to delete this widget?'
  }

  static DASHBOARD_MESSAGE = {
    CREATE_DASHBOARD_SUCCESS: 'New dashboard created successful',
    CREATE_DASHBOARD_ERROR: 'Fail to create new dashboard',
    EDIT_DASHBOARD_SUCCESS: 'Dashboard update successful',
    EDIT_DASHBOARD_ERROR: 'Fail to edit dashboard',
    DELETE_DASHBOARD_SUCCESS: 'Dashboard deleted sucessfully',
    DELETE_DASHBOARD_ERROR: 'Dashboard delete failed',
    DELETE_DASHBOARD_CONFIRMATION: 'Do you want to delete this dashboard?'
  }

  static INVITATION_MESSAGE = {
    VERIFIED_FAILED: 'You have an exists account in our system or the organization name does not exist'
  }

  static AUTOMATION_MESSAGE = {
    CREATE_AUTOMATION_SUCCESS: 'Automation created successful',
    CREATE_AUTOMATION_ERROR: 'Automation created failed',
    UPDATE_AUTOMATION_SUCCESS: 'Automation updated sucessfully',
    UPDATE_AUTOMATION_ERROR: 'Automation updated failed',
    DELETE_AUTOMATION_CONFIRMATION: 'Do you want to delete this Automation?',
    DELETE_AUTOMATION_SUCCESS: 'Automation deleted sucessfully',
    DELETE_AUTOMATION_ERROR: 'Automation deleted failed',
  }

  static STATUS = {
    SUCCESS: 'Success',
    WARNING: 'Warning',
    FAILED: 'Failed',
    INFO: 'info',
    ERROR: 'error',
  }
}
