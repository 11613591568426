<form [formGroup]="formGroup">

    <!-- When no attachment in record versioning -->
    <input *ngIf="isVersion && currentFile == 0" pInputText type="text" name="" value="No attachment record" [disabled]="true"/>

    <p-toast></p-toast>
    <!-- Multiple attachment uploader -->
    <p-fileUpload  class="p-fileUpload" *ngIf="showUpload && !isVersion" name="demo[]" [accept]="extensionAllowed" (onSelect)="onFileSelect($event)" (onClear)="onFileClear($event)"
        [showUploadButton]="false" fileLimit="{{fileModeCheck}}" [multiple]="true" [maxFileSize]="1000000">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length" class="">
                <li *ngFor="let file of uploadedFiles" style="display: none;">{{file.name}} - {{file.size}} bytes</li>
            </ul>
        </ng-template>
    </p-fileUpload>

    <br>
    <!-- Uploaded Attachment display -->
    <div class="grid" *ngIf="existingFiles?.length > 0">
        <div *ngFor="let file of existingFiles" class="col-12 md:col-6 xl:col-4">
            <div
                class="p-3 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round">
                <div class="flex align-items-center">
                    <span>{{file.name}}</span>
                </div>
                <div class="flex">
                    <p-confirmPopup></p-confirmPopup>
                    <button pButton pRipple icon="pi pi-download"
                        class="p-button-primary p-button-text p-button-rounded"
                        (click)="downloadFile(file.fullname)"></button>
                    <button *ngIf="!isVersion" pButton pRipple icon="pi pi-times" class="p-button-danger p-button-text p-button-rounded"
                        (click)="onNyxAttachmentFieldDeleteButtonClicked($event, file.fullname)"></button>
                </div>
            </div>
        </div>
    </div>


</form>