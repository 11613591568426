

<div class="grid" *ngIf="fieldForm" >
    <div class="col-12 md:col-12 mt-3">
        <form [formGroup]="fieldForm" (ngSubmit)="submit()">
            <div class="card p-fluid">

                <h3 class="mb-5 field grid">
                    {{labelConstant.OBJECT.NEW_OBJECT_LABEL}}
                </h3>
    
                <div class="field grid">
                    <label htmlFor="fieldLabel" class="col-12 mb-2 md:col-2 md:mb-0">
                        {{labelConstant.OBJECT.FIELD_LABEL}}
                    </label>
                    <div class="col-12 md:col-10">
                        <input pInputText id="fieldLabel" type="text" formControlName="label" (change)="onLabelInputChanged($event)"
                        [ngClass]="{'ng-invalid ng-dirty': fieldFormControl.label?.invalid, '': fieldFormControl.label?.valid}"/>
                        <small class="p-error" *ngIf="(fieldFormControl.label?.touched || submitted) && fieldFormControl.label?.errors?.required">
                            {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                        </small>
                    </div>
                </div>

                <div class="field grid">
                    <label htmlFor="fieldAPIName" class="col-12 mb-2 md:col-2 md:mb-0">
                        {{labelConstant.OBJECT.FIELD_API_LABEL}}
                    </label>
                    <div class="col-12 md:col-10">
                        <input pInputText id="fieldAPIName" type="text" formControlName="fieldAPIName"
                        [ngClass]="{'ng-invalid ng-dirty': fieldFormControl.fieldAPIName?.invalid, '': fieldFormControl.fieldAPIName?.valid}"/>
                        <small class="p-error" *ngIf="(fieldFormControl.fieldAPIName?.touched || submitted) && fieldFormControl.fieldAPIName?.errors?.required">
                            {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                        </small>
                        <small class="p-error" *ngIf="(fieldFormControl.label?.touched || submitted) && validateDefaultField">
                            {{generalConstant.INVALID_MESSAGE.SYSTEM_DEFAULT_FIELD_MESSAGE}}
                        </small>
                    </div>
                </div>

                <div class="field grid">
                    <label htmlFor="fieldAPIName" class="col-12 mb-2 md:col-2 md:mb-0">
                        {{labelConstant.OBJECT.FIELD_TYPE_LABEL}}
                    </label>
                    <div class="col-12 md:col-10">
                        <p-dropdown
                        formControlName="fieldType"
                        placeholder="Select"
                        styleClass="col-md-12"
                        autoWidth="false"
                        [options]="availableFieldType" 
                        optionLabel="label"
                        optionValue="value"
                        [(ngModel)]="selectedFieldTypeDD"
                        name="selectedField"
                        [filter]="true"
                        filterBy="index"
                        (onChange)="onFieldTypeSelected($event)"
                        [ngClass]="{'ng-invalid ng-dirty': fieldForm.controls['fieldType']?.invalid && (fieldForm.controls['fieldType'].touched || submitted), 'is-valid': fieldForm.controls['fieldType']?.valid}"></p-dropdown>
                        <small class="p-error p-2" *ngIf="fieldForm.controls['fieldType'].errors?.required && (fieldForm.controls['fieldType']?.touched || formSubmitted)">
                            This field is required.
                        </small>
                    </div>
                </div>

                <app-text-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'textbox'"></app-text-field-configuration>
                <app-text-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'textarea'"></app-text-field-configuration>
                <app-email-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'email'"></app-email-field-configuration>
                <app-numeric-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'number'"></app-numeric-field-configuration>
                <app-numeric-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'integer'"></app-numeric-field-configuration>
                <app-datetime-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'date'"></app-datetime-field-configuration>
                <app-datetime-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'datetime'"></app-datetime-field-configuration>
                <app-formula-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'formula'"></app-formula-field-configuration>
                <app-lookup-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'lookup'"></app-lookup-field-configuration>
                <app-dropdown-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'dropdown'" [hasWorkflow]="hasWorkflow"></app-dropdown-field-configuration>
                <!-- <app-dropdown-field-configuration (formSubmitted)="submit" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'checkbox'"></app-dropdown-field-configuration> // CHECKBOX FIELD - PENDING FIX -->
                <app-unique-id-configuration (formSubmitted)="submit" [submitted]="submitted" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'uniqueid'"></app-unique-id-configuration>
                <app-attachment-configuration (formSubmitted)="submit" [submitted]="submitted" [formGroup]="fieldForm" [selectedFieldType]="selectedFieldType" *ngIf="fieldType == 'attachment'"></app-attachment-configuration>
        
                <div class="field grid">
                    <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
                    <div class="col-12 mb-2 md:col-2 md:mb-0">
                        <button pButton pRipple type="button" label="Save" (click)="submit()"></button>
                    </div>
                    <div class="col-12 mb-2 md:col-2 md:mb-0">
                        <button pButton pRipple type="button" label="Cancel" class="p-button-secondary" (click)="resetFormInput()"></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>