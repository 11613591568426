import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { RecordEventService } from 'src/app/core/services/record.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-nyx-record-versions',
  templateUrl: './nyx-record-versions.component.html',
  styleUrls: ['./nyx-record-versions.component.scss']
})
export class NyxRecordVersionsComponent extends BaseUrlState {
  nyxRecordVersions: any = [];
  loading: boolean = true;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.getNyxRecordVersions();
    this.loading = false;
  }

  getNyxRecordVersions() {
    this.nyxAPIService.getNyxRecordVersions(this.urlState.organizationName!, this.urlState.recordId!).subscribe((data) => {
      this.nyxRecordVersions = data;
    });
  }

  triggerSidebar(version: any) {
    let objectData = {
      operation: "versions",
      data: version
    }

    this.recordEvent.recordVersionsEvent(objectData);
    this.recordEvent.showSidebarVersioningEvent(true);
  }
}
