<div class="col-12">
    <div class="col-12 md:col-12 h-full mb-5">
        <p-steps [model]="items" [readonly]="false" [activeIndex]="stepState"
            (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
    </div>

    <ng-container *ngIf="stepState == 0">
        <div class="col-12 md:col-12 mt-5 md:mt-0">
            <form [formGroup]="detailForm">
                <div class="p-fluid">
                    <h5 class="mb-6">{{labelConstant.DASHBOARD.WIDGET_CONFIG}}</h5>
                    <div class="field grid">
                        <label htmlFor="name3"
                            class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.DASHBOARD.WIDGET_NAME}}</label>
                        <div class="col-12 md:col-8">
                            <input pInputText id="name3" type="text" name="name" formControlName="name" [ngClass]="{
                'is-invalid': detailFormControl.name.invalid,
                'is-valid': detailFormControl.name.valid
              }" />
                            <small class="p-error"
                                *ngIf="(detailFormControl.name.touched || formSubmission[0]) && detailFormControl.name.errors?.required">
                                {{labelConstant.DASHBOARD.WIDGET_REQUIRED}}
                            </small>
                            <small class="p-error"
                                *ngIf="(detailFormControl.name.touched || formSubmission[0]) && detailFormControl.name.errors?.maxlength">
                                {{labelConstant.DASHBOARD.WIDGET_INVALID}}
                            </small>
                        </div>
                    </div>
                    <div class="field grid">
                        <label htmlFor="email3"
                            class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.DASHBOARD.REPORT}}</label>
                        <div class="col-12 md:col-8">
                            <p-dropdown formControlName="report" placeholder="Select an Report" styleClass="w-full"
                                autoWidth="false" [options]="dropdownReportListing" optionLabel="label" optionValue="value"
                                [(ngModel)]="selectedReportId" name="selectedField">
                                   </p-dropdown>
                            <small class="p-error"
                                *ngIf="(detailFormControl.report.touched || formSubmission[0]) && detailFormControl.report.errors?.required">
                                {{labelConstant.DASHBOARD.REPORT_REQUIRED}}
                            </small>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

    <ng-container *ngIf="stepState == 1">
        <div class="col-12 md:col-12 mt-5 md:mt-0">
            <div class="p-fluid">
                <h5 class="mb-6">{{labelConstant.DASHBOARD.CHART_TYPE}}</h5>
                <div class="field grid">
                    <label htmlFor="name3"
                        class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.DASHBOARD.WIDGET_NAME}}</label>
                    <div class="col-12 md:col-8">
                        <form class="flex flex-column gap-3" [formGroup]="chartForm">
                            <div *ngFor="let widget of widgetList" class="field-checkbox">
                                <p-radioButton [inputId]="widget.id" [value]="widget.name"
                                    formControlName="chartType"
                                    (change)="onChartRadioChangeEvent(widget.name)"></p-radioButton>
                                <label [for]="widget.id" class="ml-2">{{ widget.name }}</label>
                            </div>
                            <small class="p-error"
                                *ngIf="(chartFormControl.chartType.touched || formSubmission[1]) && chartFormControl.chartType.errors?.required">
                                {{labelConstant.DASHBOARD.CHART_REQUIRED}}
                            </small>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!--begin::Step 3 - Table -->
    <ng-container *ngIf="stepState == 2 && chartFormControl.chartType.value == 'Table'">
        <div class="col-12 md:col-12 mt-5 md:mt-0">
            <div class="p-fluid">
                <h5 class="mb-6">{{labelConstant.DASHBOARD.CHART_CONFIG}}</h5>
                <div class="field grid">
                    <label htmlFor="name3"
                        class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.DASHBOARD.WIDGET_NAME}}</label>
                    <form [formGroup]="configurationForm">
                        <div class="field-checkbox" *ngFor="let column of reportColumns">
                            <!-- <p-checkbox name="group" [value]="column" [(ngModel)]="selectedCategories" [inputId]="column"
                formControlName="tableColumn" (click)="changeCheckboxEvent(column)"></p-checkbox> -->
                            <!-- <p-checkbox formControlName="tableColumn" [inputId]="column" [(ngModel)]="selectedCategories"
                 [binary]="true" label="set as default"></p-checkbox>   -->

                            <p-checkbox [formControlName]="column.value" [value]="column.value" [binary]="true"
                                [inputId]="column.value"></p-checkbox>
                            <label [for]="column">{{ column.value }}</label>
                        </div>
                        <small class="col-12 md:col-6 p-error"
                            *ngIf="!isConfigurationFormValid">
                            {{labelConstant.DASHBOARD.COLUMN_REQUIRED}}
                        </small>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>
    <!--end::Step 3 - Table -->

    <!--begin::Step 3 - Line Graph & Bar Chart -->
    <ng-container *ngIf="stepState == 2 &&
  (chartFormControl.chartType.value == 'Line Graph' ||
  chartFormControl.chartType.value == 'Bar Chart' ||
  chartFormControl.chartType.value == 'Pie Chart' ||
  chartFormControl.chartType.value == 'Polar Area Chart' ||
  chartFormControl.chartType.value == 'Doughnut Chart')">
        <div class="col-12 md:col-12 mt-5 md:mt-0">
            <form [formGroup]="configurationForm">
                <div class="p-fluid">
                    <h5 class="mb-6">{{labelConstant.CHART.CHART_LABEL}}</h5>
                    <div class="field grid">
                        <label htmlFor="name3"
                            class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.CHART.X_AXIS}}</label>
                        <div class="col-12 md:col-8">
                            <p-dropdown [options]="reportColumns"
                                placeholder="Select X Axis" [showClear]="true" formControlName="xAxis"></p-dropdown>
                            <small class="p-error"
                                *ngIf="(configurationFormControl.xAxis.touched || formSubmission[2]) && configurationFormControl.xAxis.errors?.required">
                                {{labelConstant.CHART.X_AXIS_REQUIRED}}
                            </small>
                        </div>
                    </div>
                    <div class="field grid">
                        <label htmlFor="email3"
                            class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.CHART.Y_AXIS}}</label>
                        <div class="col-12 md:col-8">
                            <p-dropdown [options]="reportColumns"
                                placeholder="Select Y Axis" [showClear]="true" formControlName="yAxis"></p-dropdown>
                            <small class="p-error"
                                *ngIf="(configurationFormControl.yAxis.touched || formSubmission[2]) && configurationFormControl.yAxis.errors?.required">
                                {{labelConstant.CHART.Y_AXIS_REQUIRED}}
                            </small>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <!--end::Step 3 - Line Graph & Bar Chart -->

    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3 mt-5">
        <div class="p-fluid p-formgrid grid col-3">
            <div class="field col">
                <button pButton pRipple type="button" label="Back" class="p-button-secondary"
                    (click)="onPrevButtonClick()" *ngIf="stepState != 0"></button>
            </div>
        </div>
        <div class="p-fluid p-formgrid grid col-3">
            <div class="field col">
                <button pButton pRipple type="button" label="Continue" (click)="onNextButtonClick()"
                    *ngIf="stepState < 2"></button>
                <button pButton pRipple type="button" label="Submit" (click)="onNextButtonClick()"
                    *ngIf="stepState == 2"></button>
            </div>
        </div>
    </div>

</div>