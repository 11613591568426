import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-nyx-tab-pane',
  templateUrl: './nyx-tab-pane.component.html',
  styleUrls: ['./nyx-tab-pane.component.scss']
})
export class NyxTabPaneComponent {
  nyxRecordsMenuNavList = [
    {
      "Title": "Object Manager",
      "Navigation": ""
    },
    {
      "Title": "Settings",
      "Navigation": ""
    },
    {
      "Title": "Users",
      "Navigation": ""
    },
    {
      "Title": "Roles",
      "Navigation": ""
    },
    {
      "Title": "Permissions",
      "Navigation": ""
    }
  ];

  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }

  constructor(private nyxAPIService: NyxAPIService, private router: Router, private activatedRoute: ActivatedRoute) {}
  nyxObjectList:any = []

  ngOnInit(): void {
    this.getStateFromUrl();
    this.getNyxObject();
  }

  getNyxObject() {
    this.nyxAPIService.getNyxObjects(this.state.organizationName).subscribe((data) => {
      this.nyxObjectList = data;
    });
  }

  getStateFromUrl(){
    this.state.organizationName = this.activatedRoute.snapshot.paramMap.get('organization')!;
  }

  onNyxObjectClicked(id:string){
    this.router.navigate([`/${this.state.organizationName}/app/nyx-record/${id}/overview`]);
  }
}
