<p-table #dt1 [value]="nyxRecordVersions" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true"
    styleClass="p-datatable-gridlines" [paginator]="true"
    [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width: 12rem">
                <div class="flex justify-content-between align-items-center">
                    {{labelConstant.RECORD.RECORD_ID}}
                </div>
            </th>
            <th style="min-width: 12rem">
                <div class="flex justify-content-between align-items-center">
                    {{labelConstant.LABEL.NAME}}
                </div>
            </th>
            <th style="min-width: 12rem">
                <div class="flex justify-content-between align-items-center">
                    {{labelConstant.RECORD.LAST_MODIFIED_DATE}}
                </div>
            </th>
            <th style="min-width: 12rem">
                <div class="flex justify-content-between align-items-center">
                    {{labelConstant.RECORD.LAST_MODIFIED_BY}}
                </div>
            </th>
            <th style="min-width: 12rem">
                <div class="flex justify-content-between align-items-center">
                    {{labelConstant.LABEL.ACTION}}
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-version>
        <tr style="min-height: 12rem;">
            <td>{{version.id}}</td>
            <td>{{version.name}}</td>
            <td>{{version.modifiedBy}}</td>
            <td>{{version.modifiedDatetime + 'Z' | date:generalConstant.TABLE_DATA.DATE }}</td>
            <td class="flex flex-wrap gap-2">
                <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text"
                    (click)="triggerSidebar(version)">
                </button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
        <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
        </tr>
    </ng-template>
</p-table>
<app-nyx-record-versions-sidebar></app-nyx-record-versions-sidebar>