import { Component } from '@angular/core';
import { BaseLayout } from 'src/app/core/bases/BaseLayout';


@Component({
  selector: 'app-registration-layout',
  templateUrl: './registration-layout.component.html',
  styleUrls: ['./registration-layout.component.scss']
})
export class RegistrationLayoutComponent extends BaseLayout {

  constructor(){
    super();
  }

}
