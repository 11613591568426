import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfigComponent } from './config/config.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu/menuitem.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';



@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent,
    BreadcrumbComponent,
    ProfileMenuComponent,
    ConfigComponent,
    MenuComponent,
    MenuItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    BadgeModule,
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    BreadcrumbComponent,
    ProfileMenuComponent,
    ConfigComponent,
    MenuComponent,
    MenuItemComponent
  ]
})
export class GlobalModule { }
