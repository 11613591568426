<form [formGroup]="formGroup">
    <div class="grid formgrid">
        <div class="col-12 md:col-4" *ngFor="let checkbox of nyxFieldSchema.CheckboxValues">
            <div class="field-checkbox">
                <!-- <p-inputSwitch
                [inputId]="checkbox" 
                [name]="checkbox" 
                [formControlName]="checkbox"
                ></p-inputSwitch>
                <label [htmlFor]="checkbox">{{ checkbox }}</label> -->

                <!-- 
                    Checkbox value onChange with (ArraayObject), 
                    checkbox values cannot patch into checkbox   
                -->
                <p-checkbox
                    #checkboxes
                    [formArrayName]="controlName"
                    binary="true"
                    [(ngModel)]="convertedObject[checkbox]"
                    [inputId]="checkbox"
                    (onChange)="checkboxValues($event, checkbox)"
                ></p-checkbox>
                <label [for]="checkbox">{{ checkbox }}</label>
            </div>
        </div>
    </div>
        
    
    <!-- <input class="form-check-input" type="checkbox" value="" [formControlName]="controlName" [ngClass]="{'is-invalid': formGroup.controls[controlName].invalid, 'is-valid': formGroup.controls[controlName].valid}"/>

    <span class="text-danger" *ngIf="formGroup.controls[controlName].errors?.required">This field is required </span> -->
</form>
