import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

declare var KTChartsWidget15: any;
declare var am5: any;
declare var KTUtil:any;

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent extends BaseUrlState {
  @ViewChild('calendar') private calendar: any;
  @Input() widgetMetadata: any;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
  messageBoxConstant = MessageBoxConstant;

  rangeDates: Date[] | undefined;
  barData: any;
  barOptions: any;

  constructor(
    private datePipe: DatePipe,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    private notificationService: GlobalNotificationService,
    public messageService: MessageService,
    public override activatedRoute: ActivatedRoute){
      super(activatedRoute);
    }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getDataByReportId();
  }

  initBarChart(){
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.barData = {
      labels: [],
      datasets: [
          {
              label: 'Data',
              data: [],
              backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
              borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
              borderWidth: 1
          }
      ]
    };

    this.barOptions = {
      plugins: {
          legend: {
              labels: {
                  color: textColor
              }
          }
      },
      scales: {
          y: {
              beginAtZero: true,
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          },
          x: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
    };
  }

  filterDate(){
    if(this.rangeDates != undefined){
      if(this.rangeDates[0] != null && this.rangeDates[1] != null){
        const startDate = this.datePipe.transform(this.rangeDates[0], 'dd-MM-yyyy');
        const endDate = this.datePipe.transform(this.rangeDates[1], 'dd-MM-yyyy');

        console.log('startDate: ' + startDate, 'endDate: ' + endDate)
        this.calendar.toggle();
      }
    }
  }

  getDataByReportId(){
    this.nyxAPIService.getReportQueryDataByReportId(this.urlState.organizationName!, this.widgetMetadata.reportId).subscribe((result:any) => {

      // Loop into report data. Find object key = x & y, push into array
      if(result.data.nyxRecord != null && result.data.nyxRecord.length > 0){

        this.initBarChart();
        result.data.nyxRecord.forEach((row: any, index: number) => {
          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.X){
              this.barData.labels.push(row[key]);
            }
          });

          Object.keys(row).forEach((key: any) => {
            if(key == this.widgetMetadata.chartConfiguration.Y){
              this.barData.datasets[0].data.push(row[key])
            }
          });
        });
      }
    });
  }

  navigateToWidgetDetailPage(){
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + this.urlState.dashboardId + '/overview/widget/edit/' + this.widgetMetadata.id]);
  }

  deleteWidget(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxWidgetById(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.nyxAPIService.onLoadWidgetEvent(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_ERROR);
    });
  }

  onNyxWidgetDeleteButtonClicked(event: Event) {
    console.log(this.widgetMetadata);
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_CONFIRMATION, this, this.deleteWidget, this.widgetMetadata.id, null)
  }
}
