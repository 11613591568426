import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-nyx-objects-overview2',
  templateUrl: './nyx-objects-overview2.component.html',
  styleUrls: ['./nyx-objects-overview2.component.scss']
})

export class NyxObjectsOverview2Component implements OnInit {
  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }

  nyxObjectList:any = []

  constructor(private nyxAPIService: NyxAPIService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getStateFromUrl();
    this.getNyxObject();
  }

  getNyxObject() {
    this.nyxAPIService.getNyxObjects(this.state.organizationName).subscribe((data) => {
      this.nyxObjectList = data;
    });
  }

  getStateFromUrl(){
    this.state.organizationName = this.activatedRoute.snapshot.paramMap.get('organization')!;
  }

  onNyxObjectClicked(id:string){
    this.router.navigate(['/nyx-record/' + id + "/overview"]);
  }
}