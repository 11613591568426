import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

interface Fields {
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-nyx-role-detail',
  templateUrl: './nyx-role-detail.component.html',
  styleUrls: ['./nyx-role-detail.component.scss']
})
export class NyxRoleDetailComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  objectList: Fields[] = [];
  objectValue: Fields = this.objectList[0];
  permissionList: any = [];
  roleData: any = {};
  MaxPageSize: number = 100;
  PageNumber: number=1;
  PageSize: number=100;

  roleForm: FormGroup;

  submitted = false;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.initConfiguration();


    // if(this.urlState.operation! == 'create'){
    //   this.initForm();
    // }else{
    //   this.initRole();
    // }
  }

  get roleFormControl() {
    return this.roleForm.controls;
  }

  initConfiguration() {
    this.nyxAPIService
      .getNyxPermissionListing(this.urlState.organizationName!, this.PageNumber, this.MaxPageSize,this.PageSize)
      .subscribe((data: any) => {
        console.log(data);
        this.permissionList = data.record;

        this.initForm();
      });
  }

  initForm() {
    const group: any = {};

    group['name'] = this.urlState.operation! == 'edit' ?
      new FormControl({ value: "", disabled: false }) :
      new FormControl("", [Validators.required, Validators.maxLength(100)]);

    this.permissionList.forEach((permission: any) => {
      group[permission.id] = new FormControl("");
    });

    this.roleForm = this.fb.group(group);

    if (this.urlState.operation! == 'edit') {
      this.initRole();
    }
  }

  initRole() {
    this.nyxAPIService.getNyxRole(this.urlState.organizationName!, this.urlState.roleId!).subscribe((data: any) => {
      this.roleData = data;
      this.setRolesDataIntoForm(this.roleData);
    });
  }

  setRolesDataIntoForm(data: any) {
    this.roleForm.patchValue({
      ['name']: this.roleData.name
    });

    this.permissionList.forEach((permission: any) => {
      if (data.nyxRolePermissions.some((x: any) => x.nyxPermissionId == permission.id)) {
        this.roleForm.patchValue({ [permission.id]: true });
      } else {
        this.roleForm.patchValue({ [permission.id]: false });
      }
    });

  }

  onNewPermissionSelected(event: any) {
    const formArray: FormArray = this.roleForm.get('permissionIds') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: any) => {
        if (ctrl instanceof FormControl && ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  submit() {
    this.submitted = true;
    if (this.roleForm.valid) {

      let finalPermissions: any[] = [];

      this.permissionList.forEach((permission: any) => {
        if (this.roleForm.controls[permission.id].value) {
          finalPermissions.push(permission.id);
        }
      });

      var newRolePayload = {
        Name: this.roleForm.value.name,
        PermissionIds: finalPermissions,
      };
      if(this.urlState.operation! == 'create'){
      this.nyxAPIService.createNyxRoles(this.urlState.organizationName!, newRolePayload).subscribe((data: any) => {
        this.redirectToRoleListingPage();
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.ROLE_MESSAGE.CREATE_ROLE_SUCCESS.replace('{{ name }}', data.name)));
      }, (error: any)  => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.ROLE_MESSAGE.CREATE_ROLE_ERROR);
      });
      } else {
        this.nyxAPIService
          .updateNyxRole(this.urlState.organizationName!, this.roleData.id, newRolePayload)
          .subscribe((data: any) => {
            this.redirectToRoleListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PERMISSION_MESSAGE.EDIT_PERMISSION_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PERMISSION_MESSAGE.EDIT_PERMISSION_ERROR);
          });
      }
    }
  }

  redirectToRoleListingPage() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-role-manager/overview`]);
  }

  roleHasPermission(id: string) {
    var result = this.roleData.nyxRolePermissions?.filter((x: any) => x.nyxPermissionId.toLowerCase() == id.toLowerCase());
    return result != undefined ? result.length > 0 : false;
  }

  onPermissionUpdated(event: any, value: any) {
    if (this.urlState.operation! == 'edit') {

      if (event.checked) {
        var newRolePermissionPayload = {
          RoleId: this.urlState.roleId!,
          PermissionId: value
        };
        this.nyxAPIService
          .addNewRolePermission(this.urlState.organizationName!, newRolePermissionPayload)
          .subscribe((data: any) => {
            console.log(data);
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.ROLE_MESSAGE.EDIT_ROLE_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.ROLE_MESSAGE.EDIT_ROLE_ERROR);
          });
      } else {
        //Remove user role
        this.nyxAPIService
          .deleteNewRolePermission(this.urlState.organizationName!, this.urlState.roleId!, value)
          .subscribe((data: any) => {
            console.log(data);
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.ROLE_MESSAGE.REMOVE_ROLE_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.ROLE_MESSAGE.REMOVE_ROLE_ERROR);
          });
      }
    }
  }

}
