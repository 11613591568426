<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="isRequired" class="col-12 mb-2 md:col-2 md:mb-0">
                Required?
            </label>
            <div class="col-12 md:col-10">
                <p-inputSwitch inputId="isRequired" name="isRequired" formControlName="isRequired"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="defaultValue" class="col-12 mb-2 md:col-2 md:mb-0">
                Default Value
            </label>
            <div class="col-12 md:col-10">
                <p-inputNumber  id="defaultValue" type="number" formControlName="defaultValue" [(ngModel)]="defaultValue" 
                [useGrouping]="false" [minFractionDigits]="0" [maxFractionDigits]="6" [allowEmpty]="true" 
                (keypress)="keyPressNumbers($event, fieldFormControl.defaultValue.value)"></p-inputNumber>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="valueRange" class="col-12 mb-2 md:col-2 md:mb-0">
                Value Range
            </label>
            <div class="p-formgrid grid col-12 mb-2 md:col-10 mt-3" id="valueRange">
                <div class="field col">
                    <p-inputNumber id="minValue" type="number" value="" placeholder="min value" formControlName="minValue" [(ngModel)]="minValue"
                    [useGrouping]="false" [minFractionDigits]="0" [maxFractionDigits]="6" [allowEmpty]="true" [showButtons]="true"
                    (keypress)="keyPressNumbers($event, fieldFormControl.minValue.value)"></p-inputNumber>
                </div>
                <div class="field col">
                    <p-inputNumber id="maxValue" type="number" value="" placeholder="max value" formControlName="maxValue" [(ngModel)]="maxValue"
                    [useGrouping]="false" [minFractionDigits]="0" [maxFractionDigits]="6" [allowEmpty]="true"  [showButtons]="true"
                    (keypress)="keyPressNumbers($event, fieldFormControl.maxValue.value)"></p-inputNumber>
                </div>
            </div>
        </div>
    </div>

    <!-- <input type="number" class="form-control form-control-solid" [formControlName]="controlName" value="" [ngClass]="{'is-invalid': formGroup.controls[controlName].invalid, 'is-valid': formGroup.controls[controlName].valid}" (keypress)="keyPressNumbersWithDecimal($event)"/> -->

    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="regexRestriction" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />

</form>