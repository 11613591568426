import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import {
  GlobalNotification,
  NotificationMessage,
  GlobalNotificationService,
  GlobalNotificationSeverity,
} from 'src/app/core/services/notification.service';
import { UrlMetadata } from 'src/app/core/constants/urlMetadata';


@Component({
  selector: 'app-widget-detail',
  templateUrl: './widget-detail.component.html',
  styleUrl: './widget-detail.component.scss',
})
export class WidgetDetailComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  messageBoxConstant = MessageBoxConstant;

  formSubmission = [false, false, false];
  detailForm: FormGroup;
  chartForm: FormGroup;
  configurationForm: FormGroup;

  // This holds the full list of the report
  reportListing: any = [];

  // Convert from report listing to dropdownReportListing for dropdown display
  dropdownReportListing: any = [];
  selectedReportId: any;
  reportColumns: any = [];
  widgetData: any;

  widgetList: any = [
    {
      id: 1,
      name: 'Table',
    },
    {
      id: 2,
      name: 'Line Graph',
    },
    {
      id: 3,
      name: 'Bar Chart',
    },
    {
      id: 4,
      name: 'Pie Chart',
    },
    {
      id: 5,
      name: 'Polar Area Chart',
    },
    {
      id: 5,
      name: 'Doughnut Chart',
    },
  ];

  steps: any = [
    {
      id: 1,
      name: 'Detail',
      description: 'Widget configuration',
    },
    {
      id: 2,
      name: 'Chart',
      description: 'Select chart type',
    },
    {
      id: 3,
      name: 'Configuration',
      description: 'Chart configuration',
    },
  ];
  stepState = 0;
  messageBox = {
    showMessageBox: false,
    messageBoxMessage: '',
    messageBoxStatus: '',
  };

  items: MenuItem[] = [
    {
      label: 'Detail',
    },
    {
      label: 'Chart',
    },
    {
      label: 'Configuration',
    },
  ];
  activeIndex: number = 0;
  isConfigurationFormValid: boolean = true;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public messageService: MessageService,
    private notificationService: GlobalNotificationService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.pagedRecord.pageSize = 100;
    this.pagedRecord.maxPageSize = 100;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe((params) => {
      this.urlState.operation =
        this.activatedRoute.snapshot.paramMap.get('operation');
      this.urlState.dashboardId =
        this.activatedRoute.snapshot.paramMap.get('dashboardId');
      this.urlState.widgetId =
        this.activatedRoute.snapshot.paramMap.get('widgetId');
      this.initReportListing();
      this.initForm();

      if (this.urlState.operation == UrlMetadata.OPERATION.EDIT) {
        this.initWidgetFormData();
      }
    });
  }

  initWidgetFormData() {
    this.dropdownReportListing = [];

    if (this.urlState.widgetId != null && this.urlState.widgetId != undefined) {
      this.nyxAPIService
        .getNyxWidgetById(
          this.urlState.organizationName!,
          this.urlState.widgetId
        )
        .subscribe((data: any) => {
          this.widgetData = data;
          setTimeout(() => {
            this.patchWidgetDataIntoForm();
          }, 500);
        });
    }
  }

  patchWidgetDataIntoForm() {
    this.detailForm.patchValue({
      ['name']: this.widgetData.name,
      ['report']: this.widgetData.reportId,
    });

    this.chartForm.patchValue({
      ['chartType']: this.widgetData.chartType,
    });

    // Patch X & Y Axis value after selecting report id
    this.configurationForm.patchValue({
      ['xAxis']: this.widgetData.chartConfiguration.X,
      ['yAxis']: this.widgetData.chartConfiguration.Y,
    });

    // patch table column into configuration form (For checkbox display)
    this.widgetData.tableConfiguration.forEach((columnName: any) => {
      this.configurationForm.patchValue({
        [columnName]: true
      });
    });
  }

  initReportListing() {
    this.nyxAPIService
      .getNyxReportPageSize(this.urlState.organizationName!, this.pagedRecord.pageNumber, this.pagedRecord.maxPageSize, this.pagedRecord.pageSize)
      .subscribe((data: any) => {

        this.reportListing = data.record;

        let i = 0;
        data.record.forEach((obj: any) => {
          this.dropdownReportListing.push({
            index: i,
            label: obj.name,
            value: obj.id,
          });
          i++;
        });
      });
  }

  getReportColumnBySelectedReportId() {
    //Filter the report detail
    var selectedReport = this.reportListing.find(
      (x: any) => x.id == this.selectedReportId
    );
    this.reportColumns = [];
    let i = 0;
    selectedReport.queryColumns.forEach((obj: any) => {
      this.reportColumns.push({
        index: i,
        label: obj,
        value: obj,
      });

      // Add report column to configuration form (this form control will be displayed using checkbox)
      this.configurationForm.addControl(obj, new FormControl(false))
      i++;
    });

    // Set X & Y axis value if the selected report id is identical to the existing report id
    if (this.urlState.operation == 'edit' && this.widgetData.reportId == this.selectedReportId) {
      this.configurationForm.patchValue({
        ['xAxis']: this.widgetData.chartConfiguration.X,
        ['yAxis']: this.widgetData.chartConfiguration.Y,
      });

      this.widgetData.tableConfiguration.forEach((columnName: any) => {
        this.configurationForm.patchValue({
          [columnName]: true
        });
      });
    }
  }

  get detailFormControl() {
    return this.detailForm.controls;
  }

  get chartFormControl() {
    return this.chartForm.controls;
  }

  get configurationFormControl() {
    return this.configurationForm.controls;
  }

  initForm() {
    this.detailForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      report: ['', [Validators.required]],
    });

    this.chartForm = this.fb.group({
      chartType: ['', [Validators.required]],
    });

    this.configurationForm = this.fb.group({
      xAxis: [''],
      yAxis: [''],
    });
  }

  onChartRadioChangeEvent(chartType: string) {
    if (chartType == 'Table') {
      this.configurationForm.get('xAxis')?.clearValidators();
      this.configurationForm.get('yAxis')?.clearValidators();
    } else {
      this.configurationForm.get('xAxis')?.addValidators([Validators.required]);
      this.configurationForm.get('yAxis')?.addValidators([Validators.required]);
    }
    this.configurationForm.get('xAxis')?.updateValueAndValidity();
    this.configurationForm.get('yAxis')?.updateValueAndValidity();
  }

  onActiveIndexChange(event: number) {
    this.activeIndex = event;
  }

  onPrevButtonClick() {
    this.stepState -= 1;
    this.onActiveIndexChange(this.stepState);
    this.formSubmission[2] = false;
  }

  onNextButtonClick() {
    if (this.stepState == 0) {
      if (this.detailForm.valid) {
        this.stepState += 1;
        this.formSubmission[0] = false;
        this.onActiveIndexChange(this.stepState);
        this.getReportColumnBySelectedReportId();
      } else {
        this.formSubmission[0] = true;
      }
    } else if (this.stepState == 1) {
      if (this.chartForm.valid) {
        this.stepState += 1;
        this.formSubmission[1] = false;
        this.onActiveIndexChange(this.stepState);
        this.onChartRadioChangeEvent(this.chartForm.value.chartType);
      } else {
        this.formSubmission[1] = true;
      }
    } else if (this.stepState == 2) {
      if (this.configurationForm.valid) {
        // this.stepState += 1;
        this.formSubmission[2] = false;
        this.submit();
      } else {
        this.formSubmission[2] = true;
      }
    }
  }

  submit() {
    var invalidForm = this.formSubmission.filter((x) => x == true);
    if (invalidForm.length == 0 && this.isAtLeastColumnCheckedForTableWidget()) {
      if (this.urlState.operation == 'edit') {
        this.submitEditNyxWidget();
      } else {
        this.submitCreateNyxWidget();
      }
    } else {
      this.isConfigurationFormValid = false;
    }
  }

  isAtLeastColumnCheckedForTableWidget() {
    var hasAtLeastOne = false;
    if (this.chartForm.value.chartType == 'Table') {
      this.reportColumns.forEach((element: any) => {
        var isChecked = this.configurationFormControl[element.value].value;
        if (isChecked) {
          hasAtLeastOne = true;
        }
      });
    } else {
      hasAtLeastOne = true;
    }

    return hasAtLeastOne;
  }

  getSelectedTableColumnForTableWidget() {
    var selectedColums: any[] = [];
    this.reportColumns.forEach((element: any) => {
      var isChecked = this.configurationFormControl[element.value].value;
      if (isChecked) {
        selectedColums.push(element.value)
      }
    });

    return selectedColums;
  }

  submitCreateNyxWidget() {
    const payload = {
      DashboardId: this.urlState.dashboardId,
      Name: this.detailForm.value.name,
      ReportId: this.selectedReportId,
      ChartType: this.chartForm.value.chartType,
      ChartConfiguration: {
        X: this.configurationForm.value.xAxis,
        Y: this.configurationForm.value.yAxis,
      },
      TableConfiguration: this.getSelectedTableColumnForTableWidget(),
    };
    this.nyxAPIService
      .createNyxWidget(this.urlState.organizationName, payload)
      .subscribe((result: any) => {
        if (result != null && result != undefined) {
          this.navigateToDashboardPage();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.CREATE_WIDGET_SUCCESS));
        }
      },(error : any) => {
      this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.CREATE_WIDGET_ERROR);
     });
  }

  submitEditNyxWidget() {
    const payload = {
      Id: this.widgetData.id,
      DashboardId: this.urlState.dashboardId,
      Name: this.detailForm.value.name,
      ReportId: this.selectedReportId,
      ChartType: this.chartForm.value.chartType,
      ChartConfiguration: {
        X: this.configurationForm.value.xAxis,
        Y: this.configurationForm.value.yAxis,
      },
      TableConfiguration: this.getSelectedTableColumnForTableWidget(),
    };

    this.nyxAPIService
      .updateNyxWidget(
        this.urlState.organizationName!,
        this.urlState.dashboardId!,
        payload
      )
      .subscribe((result: any) => {
        if (result != null && result != undefined) {
          this.navigateToDashboardPage();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.EDIT_WIDGET_SUCCESS));
        }
      },(error : any) => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.EDIT_WIDGET_ERROR);
      });
  }

  navigateToDashboardPage() {
    this.router.navigate([
      '/' +
      this.urlState.organizationName! +
      '/' +
      this.urlState.sectionName! +
      '/dashboard-manager/' +
      this.urlState.dashboardId +
      '/overview',
    ]);
  }

  navigateToReportListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName}/${this.urlState.sectionName}/dashboard-manager/report/overview`,
    ]);
  }
}
