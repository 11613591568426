<!-- <div class="grid" *ngIf="nyxRecordFormGroup || urlState.operation! != 'kanban-view'" [formGroup]="nyxRecordFormGroup"> -->

<div class="grid" *ngIf="nyxRecordFormGroup" [formGroup]="nyxRecordFormGroup">
  <p-steps
    class="col-12 md:col-12 mt-3"
    *ngIf="workflow.isWorkflow"
    [model]="workflow.workflowStages"
    [readonly]="false"
    [activeIndex]="workflow.activeIndex">
  </p-steps>
  <div class="col-12 md:col-12 mt-3">
    <div class="p-fluid">

        <div class="field grid mb-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.NAME}}<span class="p-error">&nbsp; *</span></label>
          <div class="col-12 md:col-10">
            <input pInputText id="name3" type="text" formControlName="recordName"
            [ngClass]="{'ng-invalid ng-dirty': nyxRecordFormControls['recordName']?.invalid && (nyxRecordFormControls['recordName']?.touched || submitted), 'is-valid': nyxRecordFormControls['recordName']?.valid}"/>
            <small class="p-error" *ngIf="(nyxRecordFormControls['recordName']?.touched || submitted) && nyxRecordFormControls['recordName']?.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
            </small>
          </div>
        </div>

        <div *ngFor="let layout of nyxObject.layout.Layout">
          <h5>
            {{layout.SectionName}}
          </h5>

          <div *ngIf="layout.ColumnType == 'Single' ">
            <div *ngFor="let field of layout.Fields['Fields']">
              <div class="field grid mb-5" *ngIf="schemaKeyValuePair[field] != null">
                <label htmlFor="fields" class="col-12 mb-2 md:col-2 md:mb-0" >
                  {{schemaKeyValuePair[field].Label}} <span *ngIf="schemaKeyValuePair[field].IsRequired" class="p-error">&nbsp; *</span>
                </label>
                <app-nyx-field-renderer class="col-12 md:col-10" [formSubmitted]="submitted" [formGroup]="nyxRecordFormGroup" [controlName]="field" [nyxObject]="nyxObject" [nyxFieldSchema]="schemaKeyValuePair[field]" [isVersion]="isVersion"></app-nyx-field-renderer>
              </div>

            </div>
          </div>

          <div *ngIf="layout.ColumnType == 'Two'">
            <div class="p-formgrid grid mb-5">
              <div class="field col-12 md:col-6" *ngFor="let field of layout.Fields['LeftFields']">
                <div *ngIf="schemaKeyValuePair[field] != null">
                  <label htmlFor="fields" >
                    {{schemaKeyValuePair[field].Label}} <span *ngIf="schemaKeyValuePair[field].IsRequired" class="p-error">&nbsp; *</span>
                  </label>
                  <app-nyx-field-renderer [formSubmitted]="submitted" [formGroup]="nyxRecordFormGroup" [controlName]="field" [nyxObject]="nyxObject" [nyxFieldSchema]="schemaKeyValuePair[field]" [isVersion]="isVersion"></app-nyx-field-renderer>
                </div>

              </div>
              <div class="field col-12 md:col-6" *ngFor="let field of layout.Fields['RightFields']">
                <div *ngIf="schemaKeyValuePair[field] != null">
                  <label htmlFor="fields" >
                    {{schemaKeyValuePair[field].Label}} <span *ngIf="schemaKeyValuePair[field].IsRequired" class="text-900 font-semibold">&nbsp; *</span>
                  </label>
                  <app-nyx-field-renderer [formSubmitted]="submitted" [formGroup]="nyxRecordFormGroup" [controlName]="field" [nyxObject]="nyxObject" [nyxFieldSchema]="schemaKeyValuePair[field]" [isVersion]="isVersion"></app-nyx-field-renderer>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field grid" *ngIf="urlState.operation! != 'create'">
          <label htmlFor="id" class="col-12 mb-3 md:col-3 md:mb-0"> {{ labelConstant.RECORD.LAST_MODIFIED_BY }} </label>
          <div class="col-12 md:col-3">
            <span class="text-700 p-2">{{ nyxRecord?.modifiedBy }}</span>
          </div>

          <label htmlFor="id" class="col-12 mb-3 md:col-3 md:mb-0"> {{ labelConstant.RECORD.LAST_MODIFIED_DATE }} </label>
          <div class="col-12 md:col-3">
            <span class="text-700 p-2">{{ nyxRecord?.modifiedDatetime + 'Z' | date: generalConstant.TABLE_DATA.DATE }}</span>
          </div>
        </div>

        <div class="field grid" *ngIf="urlState.operation! != 'create'">
          <label htmlFor="id" class="col-12 mb-3 md:col-3 md:mb-0"> {{ labelConstant.RECORD.CREATED_BY }} </label>
          <div class="col-12 md:col-3">
            <span class="text-700 p-2">{{ nyxRecord?.createdBy }}</span>
          </div>

          <label htmlFor="id" class="col-12 mb-3 md:col-3 md:mb-0"> {{ labelConstant.RECORD.CREATED_DATETIME }} </label>
          <div class="col-12 md:col-3">
            <span class="text-700 p-2">{{ nyxRecord?.createdDatetime + 'Z' | date: generalConstant.TABLE_DATA.DATE }}</span>
          </div>
        </div>

        <div class="field grid">
          <div class="col-12 mb-2 md:col-3 md:mb-0"></div>
          <div class="col-12 mb-2 md:col-3 md:mb-0">
            <button *ngIf="!isVersion" pButton pRipple type="button" label="Save" (click)="submit(false)"></button>
          </div>
          <div class="col-12 mb-2 md:col-3 md:mb-0">
            <button *ngIf="!isVersion" pButton pRipple type="button" label="Cancel" class="p-button-secondary" (click)="resetFormInput()"></button>
          </div>
          <div class="col-12 mb-2 md:col-3 md:mb-0"></div>
        </div>

    </div>
  </div>
</div>
