import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { RecordEventService } from 'src/app/core/services/record.service';
import { MenuService } from 'src/app/core/services/menu.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-objects-overview',
  templateUrl: './nyx-objects-overview.component.html',
  styleUrls: ['./nyx-objects-overview.component.scss'],
})
export class NyxObjectsOverviewComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  nyxObjectList: any = [];
  loading: boolean = true;
  modalShow: boolean = false;

  totalObject: number;

  constructor(
    private notificationService: GlobalNotificationService,
    private nyxConfirmationService: NyxConfirmationService,
    private nyxAPIService: NyxAPIService,
    public recordEvent: RecordEventService,
    public menuService: MenuService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.eventSubjectSubscription();
    this.getNyxObjectPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    this.loading = false;
  }

  eventSubjectSubscription(){
    this.recordEvent.showImportObjectDialog.subscribe((show:boolean) => {
      if(!show){ // When Dialog hide then refresh object listing data.
        this.getNyxObjectPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
        this.loading = false;
      }
    });
  }

  getNyxObjectPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxObjectsWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data: any) => {
      this.nyxObjectList = data;
      this.totalObject = this.nyxObjectList.totalCount;
    });
  }

  getSchemaTotalFields(schema: any): number {
    var object_dict = Object.entries(schema);
    return object_dict.length;
  }

  onCreateNewObject(event: Event) {
    event.preventDefault();

    this.modalShow = true;
  }

  onCloseModal(value: any) {
    this.modalShow = value;
    this.getNyxObjectPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  onNyxObjectDelete(thisContext: any, id: any) {
    thisContext.nyxAPIService.deleteNyxObject(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.getNyxObjectPagination(thisContext.pagedRecord.pageNumber,thisContext.pagedRecord.pageSize);
      thisContext.menuService.onLoadMenu(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.OBJECT_MESSAGE.DELETE_OBJECT_SUCCESS));
      thisContext.getNyxObjectPagination(thisContext.pagedRecord.pageNumber, thisContext.pagedRecord.pageSize);
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECT_MESSAGE.DELETE_OBJECT_ERROR);
    });
  }

  onNyxObjectDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.OBJECT_MESSAGE.DELETE_OBJECT_CONFIRMATION, this, this.onNyxObjectDelete, id, null)
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalObject > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    this.getNyxObjectPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  importJson(){
    this.recordEvent.showImportObjectDialogEvent(true);
  }

}
