<ng-container
  *ngIf="operation == generalConstant.PAGE_MODE.CREATE || urlState.operation == generalConstant.PAGE_MODE.EDIT">
  <div class="grid" [formGroup]="permissionForm">
    <div class="col-12 md:col-12 mt-3">
      <div class="card p-fluid">
        <h5>{{labelConstant.LABEL.PERMISSION}}</h5>
        <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.PERMISSION}}</label>
          <div class="col-12 md:col-10">
            <input pInputText type="text" id="name" formControlName="name" placeholder="" value="" [ngClass]="{
                          'ng-dirty ng-invalid': permissionFormControl.name.invalid,
                          'is-valid': permissionFormControl.name.valid
                        }" />
            <small id="username-help" class="p-error"
              *ngIf="(permissionFormControl.name.touched || submitted) && permissionFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.PERMISSION_REQUIRED}}
            </small>
          </div>
        </div>
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.OBJECT}}</label>
          <div class="col-12 md:col-10">
            <p-dropdown formControlName="objectId" placeholder="Select an Object" styleClass="w-full" autoWidth="false"
              [options]="objectList" optionLabel="label" optionValue="value" [(ngModel)]="objectValue"
              name="selectedField" [filter]="true" filterBy="label">
            </p-dropdown>


            <small class="p-error"
              *ngIf="(permissionFormControl.objectId.touched || submitted) && permissionFormControl.objectId.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.OBJECT_REQUIRED}}
            </small>
          </div>
        </div>

        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.PERMISSION.ACCESS}}</label>
          <div class="flex align-items-center gap-1">
            <p-inputSwitch class="p-2" [name]="read" formControlName="read"></p-inputSwitch>
            <label class="col-12 md:col-10">{{labelConstant.PERMISSION.READ}}</label>
          </div>
          <div class="flex align-items-center gap-1">
            <p-inputSwitch class="p-2" [name]="update" formControlName="update"></p-inputSwitch>
            <label class="col-12 md:col-10">{{labelConstant.PERMISSION.UPDATE}}</label>
          </div>
          <div class="flex align-items-center gap-1">
            <p-inputSwitch class="p-2" [name]="delete" formControlName="delete"></p-inputSwitch>
            <label class="col-12 md:col-10">{{labelConstant.PERMISSION.DELETE}}</label>
          </div>
          <div class="flex align-items-center gap-1">
            <p-inputSwitch class="p-2" [name]="sensitiveRead" formControlName="sensitiveRead"></p-inputSwitch>
            <label class="col-12 md:col-10">{{labelConstant.PERMISSION.SENSITIVE_READ}}</label>
          </div>
        </div>

        <td class="field grid mt-5">
          <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" label="Submit" (click)="submit()"></button>
          </div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" type="reset" label="Cancel" class="p-button-secondary"
              (click)="redirectToPermissionListingPage()"></button>
          </div>
        </td>

      </div>

    </div>

  </div>
</ng-container>