import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends BaseUrlState {

  @ViewChild('calendar') private calendar: any;
  @Input() dataList: any;
  @Input() currentPage: string = '';
  @Input() widgetMetadata: any;
  @Input() reportId:any;

  messageBoxConstant = MessageBoxConstant;

  tableDataList: any[] = [
  ];
  rangeDates: Date[] | undefined;

  constructor(
    private datePipe: DatePipe,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    private notificationService: GlobalNotificationService,
    public messageService: MessageService,
    public override activatedRoute: ActivatedRoute){
      super(activatedRoute);
    }

  override ngOnInit(): void {
    super.ngOnInit();

    if(this.currentPage == 'dashboard_overview'){
      this.getDataByReportId();
    }
  }

  filterDate(){
    if(this.rangeDates != undefined){
      if(this.rangeDates[0] != null && this.rangeDates[1] != null){
        const startDate = this.datePipe.transform(this.rangeDates[0], 'dd-MM-yyyy');
        const endDate = this.datePipe.transform(this.rangeDates[1], 'dd-MM-yyyy');
        this.calendar.toggle();
      }
    }
  }

  getDataByReportId(){
    this.nyxAPIService.getReportQueryDataByReportId(this.urlState.organizationName!, this.dataList.reportId).subscribe((data:any) => {
      this.tableDataList = data.data.nyxRecord;
    });
  }

  navigateToWidgetDetailPage(){
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + this.urlState.dashboardId + '/overview/widget/edit/' + this.widgetMetadata.id]);
  }

  deleteWidget(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxWidgetById(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.nyxAPIService.onLoadWidgetEvent(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_ERROR);
    });
  }

  onNyxWidgetDeleteButtonClicked(event: Event) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.WIDGET_MESSAGE.DELETE_WIDGET_CONFIRMATION, this, this.deleteWidget, this.widgetMetadata.id, null)
  }

  downloadFile() {
    this.nyxAPIService.getReportDownload(this.urlState.organizationName!, this.reportId).subscribe((response:any) => {
      if(response.body != undefined){
        // Get current Datetime and convert to specific format
        const currentDatetime = new Date();
        const finalDatetime = this.datePipe.transform(currentDatetime, 'yyyyMMdd');
        // Create a temporary element to download the file
        const downloadFile = new Blob([response.body], {type: response.body.type});
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'report_' + finalDatetime + '_' + this.reportId;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });
  }
}
