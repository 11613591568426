<p-dialog header="Dashboard" [(visible)]="modalShow" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [style]="{width: '36rem'}"
[draggable]="false" [resizable]="false" [modal]="true" (onHide)="closeModal()" [closable]="true">
  <div class="col-12">
    <!-- <div class="col-12 md:col-12 mt-5 md:mt-0"> -->
      <form [formGroup]="dashboardForm">
        <div class="p-fluid">
        <div class="field grid">
          <label htmlFor="name3" class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.LABEL.NAME}}</label>
          <div class="col-12 md:col-8">
            <input pInputText id="name3" type="text" name="name" formControlName="name"
            [ngClass]="{
              'is-invalid': dashboardFormControl.name.invalid,
              'is-valid': dashboardFormControl.name.valid
            }"/>
            <small class="p-error"
            *ngIf="(dashboardFormControl.name.touched || formSubmission) && dashboardFormControl.name.errors?.required">
            {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
            </small>
            <small class="p-error"
            *ngIf="(dashboardFormControl.name.touched || formSubmission) && dashboardFormControl.name.errors?.maxlength">
            {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED.SYMBOL}}
            </small>
          </div>
        </div>

        <div class="field grid">
          <label htmlFor="name3" class="col-12 mb-2 md:col-4 md:mb-0">{{labelConstant.LABEL.DESCRIPTION}}</label>
          <div class="col-12 md:col-8">
            <input pInputText id="name3" type="text" name="description" formControlName="description"
            [ngClass]="{
              'is-invalid': dashboardFormControl.description.invalid,
              'is-valid': dashboardFormControl.description.valid
            }"/>
          </div>
        </div>

        <div class="field grid">
          <div class="field-checkbox">
            <p-checkbox formControlName="isDefault" inputId="isDefault" [(ngModel)]="setDefault" [binary]="true" label="set as default"></p-checkbox>
          </div>
        </div>
      </div>
      </form>
      <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between">
        <div class="inline-flex align-items-center">
        </div>
        <div class="p-fluid p-formgrid grid col-4">
          <div class="field col">
            <button pButton pRipple type="button" label="Submit" (click)="submit()"></button>
          </div>
        </div>
      </div>
    <!-- </div> -->


  </div>
</p-dialog>
