<div class="cardcard-body py-0">
  <div class="table-responsive">
      <p-table #dt1 [value]="invoiceList" [rows]="10" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true" responsiveLayout="scroll">
          <ng-template pTemplate="header">
              <tr>
                <th style="min-width: 12rem">
                  <div class="flex justify-content-between align-items-center">
                    {{labelConstant.INVOICE.CREATED_AT}}
                  </div>
                </th>
                <th style="min-width: 12rem">
                  <div class="flex justify-content-between align-items-center">
                    {{labelConstant.INVOICE.DESCRIPTION}}
                  </div>
                </th>
                <th style="min-width: 12rem">
                  <div class="flex justify-content-between align-items-center">
                    {{labelConstant.INVOICE.AMOUNT}}
                  </div>
                </th>
                <th style="min-width: 12rem">
                  <div class="flex justify-content-between align-items-center">
                    {{labelConstant.INVOICE.PAID}}
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <ng-container *ngIf="data.effectiveAt != null">
                  <td class="pe-0 min-w-200px">{{ data.effectiveAt | date: generalConstant.TABLE_DATA.DATE}}</td>
                </ng-container>
                <ng-container *ngIf="data.effectiveAt == null">
                  <td class="pe-0 min-w-200px">{{ data.created | date: generalConstant.TABLE_DATA.DATE}}</td>
                </ng-container>
                <ng-container *ngIf="data.lines.length > 0">
                  <td>{{ data.lines[0].description }}</td>
                </ng-container>
                <td>{{ data.amountDue/ 100 + ' ' + data.currency | uppercase }}</td>
                <ng-container *ngIf="data.paid || data.lines[0].amount == 0">
                  <td>Success</td>
                </ng-container>
                <ng-container *ngIf="!data.paid && data.lines[0].amount != 0">
                  <td>Failed</td>
                </ng-container>
              </tr>
            </ng-template>
      </p-table>
  </div>
</div>
