<!--begin::Card-->
<div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
        <div class="text-900 text-xl font-semibold mb-3 md:mb-0">
            <h2>{{labelConstant.LOGS.LOGS_LABEL}}</h2>
        </div>
        <div class="flex flex-wrap gap-2">
            <button pButton pRipple type="button" label="Download Report"
          (click)="downloadFile()"></button>
        </div>
    </div>
    <div class="cardcard-body py-0">
        <div class="table-responsive">
            <p-table #dt1 [value]="logsList.record" [loading]="loading" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                      <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                          {{labelConstant.LOGS.MODULE_LABEL}}
                        </div>
                      </th>
                <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                          {{labelConstant.LOGS.OPERATION_LABEL}}
                        </div>
                      </th>
                <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                          {{labelConstant.LOGS.CREATED_TIME}}
                        </div>
                      </th>
                      <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                          {{labelConstant.LABEL.CREATED_BY}}
                        </div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                    <tr>
                      <td>{{ data.module}}</td>
                      <td>{{ data.operation}}</td>
                      <td class="pe-0 min-w-200px">{{ data.createdDatetime + 'Z' | date: generalConstant.TABLE_DATA.DATE }}</td>
                      <td>{{ data.createdBy }}</td>
                    </tr>
                  </ng-template>
            </p-table>
            <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalLogs"></p-paginator>
        </div>
    </div>
</div>