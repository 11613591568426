<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
        <span class="mb-2 font-semibold">Welcome</span>
        <span class="text-color-secondary font-medium mb-5">{{this.organizationProfile.displayName}}</span>

        <ul class="list-none m-0 p-0">
          <li>
              <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                  <span>
                      <i class="pi pi-power-off text-xl text-primary"></i>
                  </span>
                  <div class="ml-3">
                      <span class="mb-2 font-semibold" (click)="logout(false)">Sign Out</span>
                      <!-- <p class="text-color-secondary m-0">Sed ut perspiciatis</p> -->
                  </div>
              </a>
          </li>
        </ul>
    </div>

    <div class="flex flex-column mt-5 mx-auto md:mx-0">
      <ng-container *ngIf="organzationAccessList.length > 0 && userBilling.length > 0">
        <span class="mb-2 font-semibold">Organization</span>
        <span class="text-color-secondary font-medium mb-5">Your organization access</span>

        <ul class="list-none m-0 p-0">

            <li *ngFor="let org of organzationAccessList; let i = index">
                <a (click)="redirectToOrgDashboard(org.name)" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                  <span>
                    <i class="pi pi-user text-xl text-primary"></i>
                  </span>
                  <div class="ml-3">
                      <span class="mb-2 font-semibold">{{ org.name }}</span>
                      <p class="text-color-secondary m-0">{{ userBilling[i].plan.description }}</p>
                  </div>
                  <!-- <p-badge value="3" class="ml-auto"></p-badge> -->
                </a>
            </li>
        </ul>
      </ng-container>
    </div>
</p-sidebar>
