import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { PermissionEventService } from 'src/app/core/services/permission.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

interface Fields {
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-nyx-permission-detail',
  templateUrl: './nyx-permission-detail.component.html',
  styleUrls: ['./nyx-permission-detail.component.scss']
})
export class NyxPermissionDetailComponent extends BaseUrlState {
  messageBoxConstant = MessageBoxConstant;
  generalConstant = GeneralConstant;
  labelConstant = LabelConstant;
  @Output() passNavigationInfo = new EventEmitter();

  objectList: Fields[] = [];
  objectValue: Fields = this.objectList[0];
  permissionId: any = "";

  navigationInfo: {
    pageName: 'Permissions',
    pageRouting: any
  }

  permissionForm: FormGroup;

  submitted = false;
  operation: any = 'create';

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.operation = this.activatedRoute.snapshot.paramMap.get('operation');
    this.permissionId = this.activatedRoute.snapshot.paramMap.get('permissionId');

    this.initConfiguration();
    this.initForm();

    if (this.operation == 'edit') {
      this.getPermissionById();
    }
  }

  get permissionFormControl() {
    return this.permissionForm.controls;
  }

  initConfiguration() {
    this.nyxAPIService
      .getNyxObjects(this.urlState.organizationName!)
      .subscribe((data: any) => {
        // this.objectList = data;
        let i = 0;
        data.forEach((obj: any) => {
          this.objectList.push({
            index: i,
            label: obj.name,
            value: obj.id
          });
          i++;
        });
      });
  }

  initForm() {
    this.permissionForm = this.fb.group({
      objectId: ['', [Validators.required, Validators.maxLength(100)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      create: [false],
      read: [false],
      update: [false],
      delete: [false],
      sensitiveRead: [false],
    });
  }

  submit() {
    this.submitted = true;
    if (this.permissionForm.valid) {
      var permissionPayload = {
        ObjectId: this.permissionForm.value.objectId,
        Name: this.permissionForm.value.name,
        Create: this.permissionForm.value.create,
        Read: this.permissionForm.value.read,
        Update: this.permissionForm.value.update,
        Delete: this.permissionForm.value.delete,
        SensitiveRead: this.permissionForm.value.sensitiveRead,
      };

      if (this.operation == 'create') {
        this.nyxAPIService
          .createNyxPermissions(this.urlState.organizationName!, permissionPayload)
          .subscribe((data: any) => {
            this.redirectToPermissionListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PERMISSION_MESSAGE.CREATE_PERMISSION_SUCCESS.replace('{{ name }}', data.name)));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PERMISSION_MESSAGE.CREATE_PERMISSION_ERROR);
          });
      } else {
        this.nyxAPIService
          .updateNyxPermissions(this.urlState.organizationName!, this.permissionId, permissionPayload)
          .subscribe((data: any) => {
            this.redirectToPermissionListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PERMISSION_MESSAGE.EDIT_PERMISSION_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PERMISSION_MESSAGE.EDIT_PERMISSION_ERROR);
          });
      }
    }
  }

  getPermissionById() {
    this.nyxAPIService
      .getNyxPermission(this.urlState.organizationName!, this.permissionId)
      .subscribe((data: any) => {
        this.setNyxPermissionIntoForm(data);
      });
  }

  setNyxPermissionIntoForm(data: any) {
    this.permissionForm.patchValue({
      ['objectId']: data.nyxObjectId,
      ['name']: data.name,
      ['create']: data.create,
      ['read']: data.read,
      ['update']: data.update,
      ['delete']: data.delete,
      ['sensitiveRead']: data.sensitiveRead,
    });
  }

  redirectToPermissionListingPage() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-permission-manager/overview`]);
  }
}
