<div class="grid" *ngIf="viewForm">
    <div class="col-12 md:col-12 mt-3">
        <form [formGroup]="viewForm" (ngSubmit)="submit()">
            <div class="card p-fluid">
                <div class="field grid">
                    <label htmlFor="viewName" class="col-12 mb-2 md:col-2 md:mb-0">
                        {{labelConstant.RECORD.RECORD_VIEW_NAME}}
                    </label>
                    <div class="col-12 md:col-10">
                        <input pInputText id="viewName" type="text" formControlName="viewName"
                            [ngClass]="{'ng-invalid ng-dirty': viewFormControl.viewName?.invalid, '': viewFormControl.viewName?.valid}" />
                        <small class="p-error"
                            *ngIf="(viewFormControl.viewName?.touched || submitted) && viewFormControl.viewName?.errors?.required">
                            {{generalConstant.REQUIRED_MESSAGE.RECORD_VIEW_NAME_REQUIRED}}
                        </small>
                    </div>
                </div>

                <div class="field grid">
                    <label htmlFor="viewName" class="col-12 mb-2 md:col-2 md:mb-0">
                        {{labelConstant.LABEL.OBJECT}}
                    </label>
                    <p-dropdown class="col-12 md:col-10" formControlName="objectId" placeholder="Select"
                        styleClass="col-md-12" autoWidth="false" [options]="objectOptions" optionLabel="label"
                        optionValue="value" [(ngModel)]="selectedObjectId" name="selectedField" [filter]="true"
                        filterBy="index" (onChange)="onChangeObject($event)">
                    </p-dropdown>
                </div>

                <div class="mt-6 field grid">
                    <div class="grid formgrid">
                        <div class="col-12 md:col-4" *ngFor="let field of fullFieldsList">
                            <div class="field-checkbox">
                                <p-inputSwitch class="p-2" [inputId]="field.FieldAPIName" [name]="field.FieldAPIName"
                                    [formControlName]="field.FieldAPIName"></p-inputSwitch>
                                <label [htmlFor]="field.FieldAPIName">{{ field.Label }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <td class="field grid">
                    <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
                    <div class="col-12 mb-2 md:col-2 md:mb-0">
                        <button pButton pRipple type="button" type="submit" label="Submit"></button>
                    </div>
                    <div class="col-12 mb-2 md:col-2 md:mb-0">
                        <button pButton pRipple type="button" type="reset" label="Cancel" class="p-button-secondary"
                            (click)="reset($event)"></button>
                    </div>
                </td>
            </div>
        </form>
    </div>
</div>