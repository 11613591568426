<div class="card h-full">
    <div class="flex align-items-center justify-content-between mb-3">
        <div class="text-900 text-xl font-semibold">{{ widgetMetadata.name }}</div>
        <div class="flex gap-2">
            <button pbutton="" pripple="" type="button" icon="pi pi-plus"
                class="p-element p-ripple p-button-outlined p-button-sm p-button p-component"
                (click)="navigateToWidgetDetailPage()">
                <span class="p-button-label">Edit Widget</span>
                <span class="p-ink"></span>
            </button>

            <p-confirmPopup></p-confirmPopup>
            <p-button icon="pi pi-times" severity="danger" (click)="onNyxWidgetDeleteButtonClicked($event)"></p-button>
        </div>
    </div>
    
    
    <p-chart type="bar" [data]="barData" [options]="barOptions" [height]="350"></p-chart>
</div>