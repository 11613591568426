import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-nyx-setup-tab-pane',
  templateUrl: './nyx-setup-tab-pane.component.html',
  styleUrls: ['./nyx-setup-tab-pane.component.scss']
})
export class NyxSetupTabPaneComponent implements OnInit {

  setupMenuNavList = [
    {
      "Title": "Object Manager",
      "Navigation": "/setup/nyx-object-manager/overview"
    },
    {
      "Title": "Settings",
      "Navigation": ""
    },
    {
      "Title": "Users",
      "Navigation": "/setup/nyx-user-manager/overview"
    },
    {
      "Title": "Roles",
      "Navigation": "/setup/nyx-role-manager/overview"
    },
    {
      "Title": "Permissions",
      "Navigation": "/setup/nyx-permission-manager/overview"
    },
    {
      "Title": "Automation",
      "Navigation": "/setup/nyx-automation-manager/overview"
    },
    {
      "Title": "Webhook",
      "Navigation": "/setup/webhook-manager/overview"
    },
    {
      "Title": "Logs",
      "Navigation": "/setup/nyx-logs"
    },
    {
      "Title": "Query Editor",
      "Navigation": "/setup/query-editor-manager/overview"
    },
    {
      "Title": "Overall Summary",
      "Navigation": "/setup/nyx-overall-summary/overview"
    },
    {
      "Title": "API Access",
      "Navigation": "/setup/api-access-manager/overview"
    }
  ];
  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }

  constructor(private nyxAPIService: NyxAPIService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  getStateFromUrl(){
    this.state.organizationName = this.activatedRoute.snapshot.paramMap.get('organization')!;
  }

}
