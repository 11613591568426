<div class="card card-flush">
  <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
    <div class="card-title">
      <div class="d-flex align-items-center position-relative my-1 me-5">
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <button pButton pRipple type="button" class="btn btn-light-primary" (click)="onAddNewReportButtonClick()">
        <i>
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </i>{{labelConstant.REPORT.ADD_REPORT_LABEL}}</button>
    </div>
  </div>
  <div class="card-body pt-0">
    <p-table #dt1 [value]="reportListing.record" dataKey="id" styleClass="p-datatable-gridlines"
     responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.NAME}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.DESCRIPTION}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.CREATED_BY}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.CREATE_DATE}} 
            </div>
          </th>
          <th style="min-width: 12rem" class="text-end min-w-100px">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.ACTION}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report>
        <tr>
          <td>{{report.name}}</td>
          <td>{{report.description}}</td>
          <td>{{report.createdBy}}</td>
          <td>{{report.createdDatetime + 'Z' | date: generalConstant.TABLE_DATA.DATE }}</td>
          <td class="text-end">
            <p-confirmPopup></p-confirmPopup>
            <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text"
              [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/report-manager/view/' + report.id]"></button>
            <button pButton pRipple type="button" icon="pi pi-pencil"
              class="p-button-rounded p-button-secondary p-button-text"
              [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/report-manager/edit/' + report.id]"></button>
            <button pButton pRipple type="button" icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              (click)="onReportListingDeleteButtonClicked($event,report.id)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalReport"></p-paginator>

  </div>
</div>