import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { Board } from 'src/app/core/models/board.model';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MenuItem } from 'primeng/api';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { DatePipe } from '@angular/common';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Paginator } from 'primeng/paginator';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-record-listing',
  templateUrl: './nyx-record-listing.component.html',
  styleUrls: ['./nyx-record-listing.component.scss'],
})
export class NyxRecordListingComponent extends BaseUrlState implements OnDestroy {
  @Output() passNavigationInfo = new EventEmitter();
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  @ViewChild('paginator') paginator: Paginator;

  nyxObject: any;
  nyxObjectRecords: any = [];
  nyxRecordView: any = null;
  nyxCustomView: any[] = [];

  currentFields: any[] = [
    { FieldAPIName: 'id', Label: (this.labelConstant.RECORD.RECORD_ID) },
    { FieldAPIName: 'name', Label: (this.labelConstant.LABEL.NAME) },
    { FieldAPIName: 'modifiedBy', Label: (this.labelConstant.RECORD.LAST_MODIFIED_BY) },
    { FieldAPIName: 'modifiedDatetime', Label: (this.labelConstant.RECORD.LAST_MODIFIED_DATE) }
  ];

  datetimeGroup: any = {
    modifiedDatetime: true,
    createdDatetime: true,
  };

  datetimeField: any[] = ['modifiedDatetime', 'createdDatetime', 'datetime'];

  kanbanView: boolean = false;
  isWorkflow: any;
  hasWorkflow: boolean = false;
  columnId: any[] = [];
  public board: Board = new Board('Test Board', []);

  routeSub: Subscription;

  loading: boolean = true;

  quickMenuItems: MenuItem[] = [];
  viewMenuItems: MenuItem[] = [];
  viewItemObject: any;

  totalRecords: any;

  searchValue = "";
  searchFromBackend: any = null;
  componentState: any = {
    isTyping: false,
    searchKeyword: '',
  };

  resetEvent: Subscription;
  importEvent: Subscription;

  constructor(
    private notificationService: GlobalNotificationService,
    private nyxConfirmationService: NyxConfirmationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private datePipe: DatePipe,
    private recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute,
    private nyxService: NyxAPIService,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe(param => {
      this.urlState.objectId = this.activatedRoute.snapshot.paramMap.get('objectId')!;

      this.eventSubjectSubscription();
      this.getNyxObject();
      this.getNyxObjectRecords();
      this.setMenuItems();
      this.getNyxRecordPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
      this.getNyxRecordViewList();
      this.loading = false; 
    });
   
  }

  eventSubjectSubscription(){
    this.importEvent = this.recordEvent.showImportRecordDialog.subscribe((show:boolean) => {
      if(!show){
        this.getNyxObject();
        this.getNyxObjectRecords();
        this.setMenuItems();
      }
    });

    this.resetEvent = this.recordEvent.resetObjectListing.subscribe((set:boolean) => {
      if(set){
        this.onChangeViewColumn('systemDefault');
      }
    });
  }

  ngOnDestroy(): void {
    this.importEvent.unsubscribe();
    this.resetEvent.unsubscribe();
  }

  getNyxObject() {
    this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxObject = data;
      this.passNavigationInfo.emit(this.nyxObject.name);
    });
  }

  getNyxObjectRecords() {
    this.nyxAPIService.getNyxObjectRecordsByObjectId(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxObjectRecords = data;
    });
  }

  getNyxRecordPagination(PageNumber: any, PageSize: any) {
    this.nyxService.getNyxRecordWithPagination(this.urlState.organizationName!, this.urlState.objectId!, PageNumber, PageSize).subscribe((data:any) => {
      this.nyxObjectRecords = data;
      this.totalRecords = this.nyxObjectRecords.totalCount;
    });
  }

  getNyxSearchRecordPagination(PageNumber: any, PageSize: any){
    this.nyxService.getNyxSearchRecordWithPagination(this.urlState.organizationName!, this.urlState.objectId!, PageNumber, PageSize, this.componentState.searchKeyword).subscribe((data:any) => {
      this.nyxObjectRecords = data;
      this.totalRecords = this.nyxObjectRecords.totalCount;
    });
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalRecords > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    if(this.componentState.searchKeyword != ""){
      this.getNyxSearchRecordPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    }else{
      this.getNyxRecordPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    }

  }

  onNyxRecordDelete(thisContext: any, recordId: any) {
    thisContext.nyxAPIService.deleteNyxRecord(thisContext.urlState.organizationName!, thisContext.urlState.objectId!, recordId).subscribe((data: any) => {
      thisContext.getNyxObjectRecords();
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_SUCCESS));

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_ERROR);
    });
  }

  onNyxRecordListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_CONFIRMATION, this, this.onNyxRecordDelete, id, null)
  }

  getNyxRecordViewList() {
    this.nyxAPIService.getNyxRecordViewByObjectId(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxRecordView = data;

      // Check hasWorkflow then let show KanbanView option in the Menu
      let hasWorkflow = this.nyxObject.schema.filter((field:any) => field.Workflow);
      if(hasWorkflow.length == 0){
        this.viewMenuItems = this.viewMenuItems.filter((item:any) => item.label != 'Kanban View');
      }

      this.nyxRecordView.forEach((view: any) => {
        if(view.isDefault){
          this.currentFields = [];

          view.fields.forEach((field: any) => {
            let findFromBasic = this.systemDefaultFields.fieldGroup.find(x => x.FieldAPIName == field);
            let findFormObject = this.nyxObject.schema.find((i: any) => i.FieldAPIName == field);
    
            if (findFromBasic) {
              this.currentFields.push(findFromBasic);
            } else if (findFormObject) {
              if (this.datetimeField.includes(findFormObject.FieldType)) this.datetimeGroup[findFormObject.FieldAPIName] = true;
              this.currentFields.push(findFormObject);
    
              //Lookup need to get related record Name
              if(findFormObject.FieldType == "lookup"){
                this.currentFields.push({ FieldAPIName: findFormObject.FieldAPIName + '_lookupname', Label: findFormObject.Label + '_Name' });
              }
            }
          });
        }

        this.viewMenuItems.push({
          label: view.name,
          icon: 'pi pi-fw pi-list',
          command: () => {
            this.onChangeViewColumn(view.fields);
          }
        });
      });
    });

  }

  onChangeViewColumn(data: any) {
    this.currentFields = [];

    if (data == 'systemDefault') {
      this.currentFields.push({ FieldAPIName: 'id', Label: 'Record ID' });
      this.currentFields.push({ FieldAPIName: 'name', Label: 'Name' });
      this.currentFields.push({ FieldAPIName: 'modifiedBy', Label: 'Last Modified By' });
      this.currentFields.push({ FieldAPIName: 'modifiedDatetime', Label: 'Last Modified Datetime' });
    } else {
      data.forEach((field: any) => {

        let findFromBasic = this.systemDefaultFields.fieldGroup.find(x => x.FieldAPIName == field);
        let findFormObject = this.nyxObject.schema.find((i: any) => i.FieldAPIName == field);

        if (findFromBasic) {
          this.currentFields.push(findFromBasic);
        } else if (findFormObject) {
          if (this.datetimeField.includes(findFormObject.FieldType)) this.datetimeGroup[findFormObject.FieldAPIName] = true;
          this.currentFields.push(findFormObject);

          //Lookup need to get related record Name
          if(findFormObject.FieldType == "lookup"){
            this.currentFields.push({ FieldAPIName: findFormObject.FieldAPIName + '_lookupname', Label: findFormObject.Label + '_Name' });
          }
        }
      });
    }
  }

  setMenuItems() {
    this.quickMenuItems = [
      {
        label: 'Download JSON',
        icon: 'pi pi-fw pi-download',
        command: () => {
          this.downloadFile('Json');
        }
      },
      {
        label: 'Download Excel',
        icon: 'pi pi-fw pi-download',
        command: () => {
          this.downloadFile('Excel');
        }
      },
      {
        separator: true
      },
      {
        label: 'Import Record',
        icon: 'pi pi-fw pi-upload',
        command: () => {
          this.recordEvent.showImportRecordDialogEvent(true);
        }
      },
    ];

    this.viewMenuItems = [
      {
        label: 'View Manager',
        icon: 'pi pi-fw pi-plus-circle',
        routerLink: ['/' + this.urlState.organizationName + '/app/nyx-record-view-manager/' + this.urlState.objectId + '/listing']
      },
      {
        separator: true
      },
      {
        label: 'System Default View',
        icon: 'pi pi-fw pi-list',
        command: () => {
          this.onChangeViewColumn('systemDefault');
        }
      },
      {
        label: 'Kanban View',
        icon: 'pi pi-fw pi-list',
        routerLink: ['/' + this.urlState.organizationName + '/app/nyx-record/' + this.urlState.objectId + '/kanban']
      }
    ];
  }

  onSearch(PageNumber: any, PageSize: any, event: any = null){
    this.componentState.searchKeyword = event.target.value;

    if (this.componentState.searchKeyword.trim() != '') {
      this.paginator.changePage(0);
      this.getNyxSearchRecordPagination(PageNumber, PageSize);
    }else{
      this.paginator.changePage(0);
      this.getNyxRecordPagination(PageNumber, PageSize);
    }

    this.loading = false;
  }

  onSearchKeyUp(PageNumber: any, PageSize: any, $event: any) {
    this.componentState.isTyping = true;

    clearTimeout(this.searchFromBackend);
    this.loading = true;
    this.searchFromBackend = setTimeout(() => {
      this.componentState.isTyping = false;
      this.onSearch(PageNumber, PageSize, $event);
    }, 1000);
  }

  downloadFile(fileType: any) {
    this.nyxAPIService.getFileDownload(this.urlState.organizationName!, this.urlState.objectId!, fileType).subscribe((response:any) => {
      if(response.body != undefined){
        // Get current Datetime and convert to specific format
        const currentDatetime = new Date();
        const finalDatetime = this.datePipe.transform(currentDatetime, 'yyyyMMdd');
        // Create a temporary element to download the file
        const downloadFile = new Blob([response.body], {type: response.body.type});
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'record_' + finalDatetime + '_' + this.urlState.objectId;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });
  }
}
