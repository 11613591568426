import { Component } from '@angular/core';

@Component({
  selector: 'app-nyx-user-manager',
  templateUrl: './nyx-user-manager.component.html',
  styleUrls: ['./nyx-user-manager.component.scss']
})
export class NyxUserManagerComponent {

}
