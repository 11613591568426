import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.scss']
})
export class AsideNavComponent implements OnInit {
  state: UrlState = {
    organizationName: '',
    sectionName: 'app',
    objectId: '',
    recordId: '',
  };

  constructor(
    protected router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  getStateFromUrl() {
    //Get from current route
    var currentRouteOrganizationParam: any =
      this.activatedRoute.snapshot.paramMap.get('organization');
    if (currentRouteOrganizationParam == null) {
      //If current route param not available, get from parent
      currentRouteOrganizationParam =
        this.activatedRoute?.parent?.snapshot.paramMap.get('organization');
    }
    this.state.organizationName = currentRouteOrganizationParam;
    this.state.sectionName = this.activatedRoute.snapshot.paramMap.get('section');
  }
}
