import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

interface Object{
  index: number,
  label: string,
  value: string
}

@Component({
  selector: 'app-lookup-field-configuration',
  templateUrl: './lookup-field-configuration.component.html',
  styleUrls: ['./lookup-field-configuration.component.scss']
})
export class LookupFieldConfigurationComponent extends BaseUrlState {
  
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;

  nyxObjectList: Object[] = [];
  selectedObject: Object = this.nyxObjectList[0];

  constructor(
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  get fieldFormControl() {
    return this.formGroup.controls;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initObjects();
    this.setFormulaFieldAsRequired();
  }

  setFormulaFieldAsRequired(){
    this.fieldFormControl.objectId.setValidators([Validators.required]);
  }

  initObjects(){
    this.nyxAPIService.getNyxObjects(this.urlState.organizationName!).subscribe((data:any) => {
      let i = 0;
      data.forEach((object:any) => {
        this.nyxObjectList.push({
          index: i,
          label: object.name,
          value: object.id
        });
        i++;
      });
    });
  }
}
