import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nyx-logs',
  templateUrl: './nyx-logs.component.html',
  styleUrls: ['./nyx-logs.component.scss']
})
export class NyxLogsComponent implements OnInit {

  navigationInfo: any = {
    pageName: 'Logs',
    pageRouting: ''
  }

  ngOnInit() {
  }
}
