import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

interface Fields{
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-nyx-dropdown-field',
  templateUrl: './nyx-dropdown-field.component.html',
  styleUrls: ['./nyx-dropdown-field.component.scss']
})
export class NyxDropdownFieldComponent implements OnInit {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
  
  data: any = [];

  availableFields: Fields[] = [];

  ngOnInit(): void {
    let optionSchema = this.nyxObject.schema.find((x:any)=>x.FieldType == 'dropdown' && x.FieldAPIName == this.controlName);
    // this.selectedField = optionSchema.DefaultValue;
    this.formGroup.get(this.controlName)?.patchValue(optionSchema.DefaultValue);
    let i = 0;
    if(optionSchema.Options != null || optionSchema.Option != undefined){
      optionSchema.Options.forEach((field:any) => {
        this.availableFields.push({
          index: i,
          label: field,
          value: field,
        });
        i++;
      });
    }
  }
}
