import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-report-listing',
  templateUrl: './report-listing.component.html',
  styleUrls: ['./report-listing.component.scss']
})
export class ReportListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  reportListing: any = [];
  totalReport:any;

  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // this.getReportListing();
    this.getNyxReportPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize)
  }

  getNyxReportPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxReportWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.reportListing = data;
      this.totalReport = this.reportListing.totalCount;
      console.log(data);
    });
  }

  deleteReport(thisContext: any, id: any) {
    thisContext.nyxAPIService.deleteReport(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.getNyxReportPagination(thisContext.pagedRecord.pageNumber, thisContext.pagedRecord.pageSize);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.REPORT_MESSAGE.DELETE_REPORT_SUCCESS));

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.REPORT_MESSAGE.DELETE_REPORT_ERROR);

    });
  }

  onReportListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.REPORT_MESSAGE.DELETE_REPORT_CONFIRMATION, this, this.deleteReport, id, null)
  }

  onAddNewReportButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName!}/${this.urlState.sectionName!}/report-manager/create`])
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalReport > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxReportPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }
}
