import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-dashboard-listing',
  templateUrl: './dashboard-listing.component.html',
  styleUrls: ['./dashboard-listing.component.scss']
})
export class DashboardListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }
  reportListing:any = [];
  totalDashboard:any;
  MaxPageSize: number = 100;
  PageNumber: number = 1;
  first: number = 0;
  rows: number = 10;

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override  activatedRoute: ActivatedRoute){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getNyxReportPagination(this.PageNumber,this.MaxPageSize)
  }

  onAddNewDashboardButtonClick(){
    this.router.navigate([`/${this.state.organizationName}/${this.state.sectionName}/dashboard-manager/create`])
  }

  getNyxReportPagination(PageNumber: any, MaxPageSize: any) {
    this.nyxAPIService.getNyxDashboardWithPagination(this.urlState.organizationName!, PageNumber, MaxPageSize).subscribe((data) => {
      this.reportListing = data;
      this.totalDashboard = this.reportListing.totalCount;
    });
  }

}
