import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-user-listing',
  templateUrl: './nyx-user-listing.component.html',
  styleUrls: ['./nyx-user-listing.component.scss']
})
export class NyxUserListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;



  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  userList: any = [];
  totalUser:any;


  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //this.subscribeToEditPermission();
    this.getNyxUserPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    // this.initForm();
  }

  getNyxUserPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxUserWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.userList = data;
      this.totalUser = this.userList.totalCount;
      this.loading = false;
      console.log(data);
    });
  }

  addNewUserButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-user-manager/create`]);
  }

  onUserDeleteButtonClick(thisContext: any, id: any) {
    thisContext.nyxAPIService.deleteNyxUser(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.getNyxUserPagination(thisContext.pagedRecord.pageNumber,thisContext.pagedRecord.pageSize);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.USER_MESSAGE.DELETE_USER_SUCCESS));

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.USER_MESSAGE.DELETE_USER_ERROR);
    });
  }

  onNyxUserListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.USER_MESSAGE.DELETE_USER_CONFIRMATION, this, this.onUserDeleteButtonClick, id, null)
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalUser > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxUserPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }

}
