export class UrlMetadata{
    static NonAuthentication = [
        "/registration"
    ]

    static OPERATION = {
      EDIT: "edit"
    }

    static URL = {
      CONTACT_US: "https://www.nyxhub.co/contact-us",
      PRICING: "https://www.nyxhub.co/pricing"
    }
}
