import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

constructor(private http: HttpClient) { }

  sendWelcomeEmail(organizationName:any, email:string){
    return this.http.post(environment.AZURE_APIM_HOST + `/api/Email/${organizationName}/Welcome`, email);
  }

  changeCreditCardEmail(organizationName:any){
    return this.http.get(environment.AZURE_APIM_HOST + `/api/Email/${organizationName}/ChangeCardDetails`);
  }
}
