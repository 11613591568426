import { Component } from '@angular/core';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-nyx-overall-summary',
  templateUrl: './nyx-overall-summary.component.html',
  styleUrls: ['./nyx-overall-summary.component.scss']
})
export class NyxOverallSummaryComponent {
  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  navigationInfo: any = {
    pageName: 'Overall Summary',
    pageRouting: ''
  }
}
