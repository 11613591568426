import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { Subscription } from 'rxjs';
import { RecordEventService } from 'src/app/core/services/record.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends BaseUrlState {
  model: any[] = [];
  nyxObjectMenu: any[] = [];
  nyxDashboardMenu: any[] = [];
  nyxReportMenu: any[] = [];
  latestCreatedDashboard: any;
  menuUpdate: Subscription;

  menuConfig: any = {
    dashboard: true,
    report: true,
    record: true,
    setup: true,

    // Setup - SubMenu
    objectManager: true,
    settings: false,
    users: true,
    roles: true,
    permissions: true,
    automation: true,
    webhook: true,
    logs: true,
    queryEditor: true,
    overallSummary: true,
    apiAccess: true,
    plan: true,
    billing: true,
    documentation: true,
    parameterizedQuery: true,

    //Comming soon
    app: true,
    marketplace: true,
  }

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public menuService: MenuService,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
    this.pagedRecord.pageSize = 100;
    this.pagedRecord.maxPageSize = 100;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.nyxAPIService.getBillingByOrganizationName(this.urlState.organizationName!).subscribe((result:any) =>{

      if(result != null){
        if(result.paymentType == GeneralConstant.ORGANIZATION.DIRECT_ENGAGE){
          this.menuConfig.plan = false;
          this.menuConfig.billing = false;
        }

        // Plan & Billing won't show if user != owner
        this.nyxAPIService.checkUserIsOganizationOwner(this.urlState.organizationName!).subscribe((isOwner:any) => {
          if(!isOwner){
            this.menuConfig.plan = false;
            this.menuConfig.billing = false;
          }

          this.menuUpdate = this.menuService.menuUpdate.subscribe((load:boolean) => {
            if(load){
              this.getNyxObjectIntoMenu();
              this.getNyxDashboardIntoMenu();
              this.getNyxReportIntoMenu();
              this.buildMenuModel();
            }
          });

          this.getNyxObjectIntoMenu();
          this.getNyxDashboardIntoMenu();
          this.getNyxReportIntoMenu();
          this.buildMenuModel();
        });
      }
    });
  }

  getNyxObjectIntoMenu() {
    // Clear data before push value
    this.nyxObjectMenu = [];
    // if(this.urlState.organizationName != null && this.urlState.organizationName != undefined){
    this.nyxAPIService
    .getNyxRecordListing(this.urlState.organizationName!, this.pagedRecord.pageNumber, this.pagedRecord.maxPageSize, this.pagedRecord.pageSize)
      .subscribe((data: any) => {
        // Get Objects and loop each object into ObjectMenu
        data.record.forEach((obj: any) => {
          this.nyxObjectMenu.push({
            label: obj.name,
            icon: 'pi pi-fw pi-list',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/app/nyx-record/' +
                obj.id +
                '/overview',
            ],
            parentLink:[
              '/' +
                this.urlState.organizationName +
                '/app/nyx-record/' +
                obj.id
                ,
              ],
              command: () => {
                this.recordEvent.resetObjectListingEvent(true);
              }
          });
        });
      });
    // }
  }

  getNyxDashboardIntoMenu() {
    // Clear data before push value
    this.nyxDashboardMenu = [];
    this.nyxAPIService
      .getNyxDashboardPageSize(this.urlState.organizationName!, this.pagedRecord.pageNumber, this.pagedRecord.maxPageSize, this.pagedRecord.pageSize)
      .subscribe((data: any) => {
        // Add create dashboard tab
        this.nyxDashboardMenu.push({
          label: 'Create Dashoard',
          icon: 'pi pi-fw pi-plus',
          routerLink: [
            '/' +
              this.urlState.organizationName +
              '/app/dashboard-manager/create',
          ]
        });

        data.record.forEach((dashboard: any) => {
          this.nyxDashboardMenu.push({
            label: dashboard.name,
            icon: 'pi pi-fw pi-list',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/app/dashboard-manager/' +
                dashboard.id +
                '/overview',
            ],
            parentLink:[
              '/' +
                this.urlState.organizationName +
                '/app/dashboard-manager/' +
                dashboard.id +
                '/overview',
            ]
          });
        });
      });
  }

  getNyxReportIntoMenu() {
    // Clear data before push value
    this.nyxReportMenu = [];
    this.nyxReportMenu.push({
      label: 'Overview',
      icon: 'pi pi-fw pi-book',
      routerLink: [
        '/' + this.urlState.organizationName + '/app/report-manager/overview',
      ],
    });

    this.nyxAPIService
      .getNyxReportPageSize(this.urlState.organizationName!, this.pagedRecord.pageNumber, this.pagedRecord.maxPageSize, this.pagedRecord.pageSize)
      .subscribe((data: any) => {
        data.record.forEach((report: any) => {
          this.nyxReportMenu.push({
            label: report.name,
            icon: 'pi pi-fw pi-list',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/app/report-manager/view/' +
                report.id +
                '/',
            ],
            parentLink: [
              '/' +
                this.urlState.organizationName +
                '/app/report-manager/view/' +
                report.id +
                '/',
            ],
          });
        });
      });
  }

  buildMenuModel() {
    this.model = [
      {
        label: 'Dashboards',
        icon: 'pi pi-th-large',
        items: [
          {
            label: 'Dashboards',
            icon: 'pi pi-fw pi-calendar',
            items: this.nyxDashboardMenu,
          },
        ],
        visible: this.menuConfig.dashboard
      },
      {
        label: 'Reports',
        icon: 'pi pi-fw pi-comments',
        items: [
          {
            label: 'Reports',
            icon: 'pi pi-fw pi-calendar',
            items: this.nyxReportMenu,
          },
        ],
        visible: this.menuConfig.report
      },
      {
        label: 'Records',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Records',
            icon: 'pi pi-fw pi-home',
            items: this.nyxObjectMenu,
          },
        ],
        visible: this.menuConfig.record
      },
      {
        label: 'Setup',
        icon: 'pi pi-fw pi-star-fill',
        items: [
          {
            label: 'Object Manager',
            icon: 'pi pi-fw pi-id-card',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-object-manager/overview',
            ],
            visible: this.menuConfig.objectManager
          },
          {
            label: 'Settings',
            icon: 'pi pi-fw pi-check-square',
            routerLink: ['/uikit/input'],
            visible: this.menuConfig.settings
          },
          {
            label: 'Users',
            icon: 'pi pi-fw pi-bookmark',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-user-manager/overview',
            ],
            visible: this.menuConfig.users
          },
          {
            label: 'Roles',
            icon: 'pi pi-fw pi-exclamation-circle',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-role-manager/overview',
            ],
            visible: this.menuConfig.roles
          },
          {
            label: 'Permissions',
            icon: 'pi pi-fw pi-box',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-permission-manager/overview',
            ],
            visible: this.menuConfig.permissions
          },
          {
            label: 'Automation',
            icon: 'pi pi-fw pi-table',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-automation-manager/overview',
            ],
            visible: this.menuConfig.automation
          },
          {
            label: 'Webhook',
            icon: 'pi pi-fw pi-list',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/webhook-manager/overview',
            ],
            visible: this.menuConfig.webhook
          },
          {
            label: 'Logs',
            icon: 'pi pi-fw pi-share-alt',
            routerLink: [
              '/' + this.urlState.organizationName + '/setup/nyx-logs',
            ],
            visible: this.menuConfig.logs
          },
          {
            label: 'Query Editor',
            icon: 'pi pi-fw pi-tablet',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/query-editor-manager/overview',
            ],
            visible: this.menuConfig.queryEditor
          },
          {
            label: 'Parameterized Query',
            icon: 'pi pi-fw pi-tablet',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/parameterized-query-manager/overview',
            ],
            visible: this.menuConfig.queryEditor
          },
          {
            label: 'Overall Summary',
            icon: 'pi pi-fw pi-clone',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/nyx-overall-summary/overview',
            ],
            visible: this.menuConfig.overallSummary
          },
          {
            label: 'API Access',
            icon: 'pi pi-fw pi-image',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/setup/api-access-manager/overview',
            ],
            visible: this.menuConfig.apiAccess
          },
          {
            label: 'Plan',
            icon: 'pi pi-chart-line',
            routerLink: [
              '/' +
                this.urlState.organizationName +
                '/app/plan-manager/listing',
            ],
            visible: this.menuConfig.plan
          },
          {
            label: 'Billing',
            icon: 'pi pi-money-bill',
            routerLink: ['/' + this.urlState.organizationName + '/app/billing'],
            visible: this.menuConfig.billing
          },
        ],
        visible: this.menuConfig.setup
      },
      {
        label: 'Documentation',
        icon: 'pi pi-percentage',
        items: [
          {
            label: 'Developer API',
            icon: 'pi pi-fw pi-info-circle',
            routerLink: [
              '/' + this.urlState.organizationName + '/documentation/apis/developer',
            ],
          },
          {
            label: 'Automation',
            icon: 'pi pi-fw pi-info-circle',
            routerLink: [
              '/' + this.urlState.organizationName + '/documentation/apis/automation',
            ],
          },
          {
            label: 'Parameterized Query API',
            icon: 'pi pi-fw pi-info-circle',
            routerLink: [
              '/' + this.urlState.organizationName + '/documentation/apis/parameterized-query',
            ],
          },
          {
            label: 'SQL',
            icon: 'pi pi-fw pi-info-circle',
            routerLink: [
              '/' + this.urlState.organizationName + '/documentation/apis/sql',
            ],
          },
        ],
        visible: this.menuConfig.documentation
      },
      {
        label: 'App',
        icon: 'pi pi-percentage',
        items: [
          {
            label: 'Comming Soon',
            icon: 'pi pi-percentage',
            routerLink: [],
          },
        ],
        visible: this.menuConfig.app
      },
      {
        label: 'Marketplace',
        icon: 'pi pi-percentage',
        items: [
          {
            label: 'Comming Soon',
            icon: 'pi pi-percentage',
            routerLink: [],
          },
        ],
        visible: this.menuConfig.marketplace
      },
    ];
  }
}
