<div class="grid">
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-4">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        <!-- <img src="assets/demo/images/avatar/circle/avatar-f-1.png" class="w-4rem h-4rem flex-shrink-0">
        <div class="flex flex-column align-items-center sm:align-items-start"><span
            class="text-900 font-bold text-4xl">Welcome Isabel</span>
          <p class="text-600 m-0">Your last login was on 04/05/2022 at 10:24 am</p>
        </div> -->
      </div>
      <div class="flex gap-2 sm:ml-auto">
        <p-button (click)="showEditDashboardDialog()" label="Edit"></p-button>
        <p-confirmPopup></p-confirmPopup>
        <p-button (click)="onDashboardOverviewDeleteButtonClicked($event, this.urlState.dashboardId)" label="Delete"></p-button>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let widget of widgetList">
    <div class="col-12 lg:col-6">
      <ng-container *ngIf="widget.chartType == 'Table'">
        <div class="card h-full">
          <app-table [widgetMetadata]="widget" [dataList]="widget" [currentPage]="currentPage"></app-table>
        </div>
      </ng-container>
      <ng-container *ngIf="widget.chartType == 'Line Graph'">
        <app-line-graph [widgetMetadata]="widget"></app-line-graph>
      </ng-container>
      <ng-container *ngIf="widget.chartType == 'Bar Chart'">
        <app-barchart [widgetMetadata]="widget"></app-barchart>
      </ng-container>
      <ng-container *ngIf="widget.chartType == 'Pie Chart'">
        <app-pie-chart [widgetMetadata]="widget"></app-pie-chart>
      </ng-container>
      <ng-container *ngIf="widget.chartType == 'Polar Area Chart'">
        <app-polar-area-chart [widgetMetadata]="widget"></app-polar-area-chart>
      </ng-container>
      <ng-container *ngIf="widget.chartType == 'Doughnut Chart'">
        <app-doughnut-chart [widgetMetadata]="widget"></app-doughnut-chart>
      </ng-container>
    </div>
  </ng-container>

  <div class="col-12 lg:col-6">
    <div class="card">
      <h5>{{labelConstant.LABEL.WIDGET}}</h5>
      <p-button (click)="navigateToWidgetDetailPage()" label="Create Widget"></p-button>
  </div>
  </div>
</div>


<app-modal-upsert-dashboard (callParentCloseModal)="closeEditDashboardDialog()" [modalShow]="editDashboardModalShow"
modalOperation="edit"></app-modal-upsert-dashboard>