export class LabelConstant{
    // label
    static REGISTER = {
        REGISTER_LABEL : 'Register',
        REGISTER_DESC : 'Lets get started',
        HAVE_ACCOUNT : 'Already have an account?'
    }

    static INVITATION = {
      INVITATION_LABEL : 'Register to join the organization',
      REGISTER_DESC : 'Lets get started',
      HAVE_ACCOUNT : 'Already have an account?'
  }

    static ERROR_PAGE = {
      LOGIN: 'Go to Login'
    }

    static RECORD = {
        RECORD_ID : 'Record ID',
        LAST_MODIFIED_BY : 'Last Modified By',
        LAST_MODIFIED_DATE : 'Last Modified Datetime',
        CREATED_BY : 'Created By',
        CREATED_DATETIME : 'Created Datetime',
        RECORD_VERSION_DATA: 'Version Data',
        RECORD_VIEW_NAME: 'View Name'
    }

    static REPORT ={
        REPORT_LABEL : 'Report',
        ADD_REPORT_LABEL : 'Add Report',
        PREVIEW_DATA_LABEL : 'Preview Data',
    }

    static OBJECT = {
        OBJECT_LABEL : 'New Object',
        NEW_OBJECT_LABEL : 'General Setting',
        FIELD_LABEL : 'Field Label',
        FIELD_API_LABEL : 'Field API Name',
        FIELD_TYPE_LABEL : 'Field Type',
        DEFAULT_VALUE_LABEL : 'Default Value',
        REQUIRED_LABEL : 'Required?',
        NUM_FIELD : '# of Fields'
    }

    static ROLE ={
        SYS_ROLE : 'System Role',
        ROLE_NAME : 'Role Name'
    }

    static PERMISSION = {
        ACCESS : 'Access',
        READ : 'Read',
        UPDATE : 'Update',
        DELETE : 'Delete',
        SENSITIVE_READ : 'Sensitive Read'
    }

    static WEBHOOK ={
        WEBHOOK_LABEL : 'Webhook',
        WEBHOOK_INFO_LABEL : 'Webhook Information',
        WEBHOOK_CONFIG_LABEL : 'Webhook Configuration',
        WEBHOOK_PROTOCOL_LABEL : 'Protocol',
        WEBHOOK_LOGS_LABEL: 'Webhook Logs',
        HEADERS_LABEL : 'Headers',
        URL_LABEL : 'URL',
        IS_ENABLED : 'Is Enabled',
        ON_CREATE : 'On Create',
        ON_UPDATE : 'On Update',
        ON_DELETE : 'On Delete',
        ENABLED : 'Enabled',
        EVENT_LABEL: 'Event',
        STATUS_LABEL: 'Status',
        ADD: 'Add Webhook'
    }

    static PARAMETERIZED_QUERY ={
        TITLE_LABEL : 'Parameterized Query',
        ID_LABEL : 'ID',
        NAME_LABEL : 'Name',
        DESCRIPTION_LABEL : 'Description',
        INFO_LABEL : 'Parameterized Query Information',
        EDITOR_LABEL : 'Editor',
        CONFIG_LABEL : 'Parameterized Query Configuration',
        WEBHOOK_PROTOCOL_LABEL : 'Protocol',
        WEBHOOK_LOGS_LABEL: 'Webhook Logs',
        HEADERS_LABEL : 'Headers',
        URL_LABEL : 'URL',
        IS_ENABLED : 'Is Enabled',
        ENABLED : 'Enabled',
        ADD: 'Add Parameterized Query'
    }

    static LOGS = {
        LOGS_LABEL : 'Logs',
        MODULE_LABEL : 'Module',
        OPERATION_LABEL : 'Operation',
        CREATED_TIME : 'Created Time',
    }

    static API ={
        API_ACCESS_LABEL : 'Api Access',
        ADD_API_LABEL : 'Add API Access',
        API_KEY_LABEL : 'API Key',
        ACTIVE_LABEL : 'Active',
    }

    static AUTOMATION = {
        RELOAD: 'Reload definition',
        FIELD: 'Field',
        VALUE: 'Value',
        CONFIGURATION: 'Configuration',
        CREATE: 'Create',
        UPDATE: 'Update',
        DELETE: 'Delete',
        FILTER_FIELDS: 'Filter fields',
        OPERATOR: 'Operator',
        EVENT_TYPE: 'Event type',
        CODE_EDITOR: 'Code Editor',
        AUTOMATION: 'Automation',
        AUTOMATION_INFO: 'Automation Information',
    }

    static CHART = {
        CHART_LABEL: 'Chart configuration',
        X_AXIS: 'X Axis',
        X_AXIS_REQUIRED: 'X Axis is required',
        Y_AXIS: 'Y Axis',
        Y_AXIS_REQUIRED: 'Y Axis is required',
    }

    static DASHBOARD = {
        WIDGET_CONFIG: 'Widget configuration',
        WIDGET_NAME: 'Widget Name',
        WIDGET_REQUIRED: 'Widget Name is required',
        WIDGET_INVALID: 'Widget Name should have maximum 100 symbols',
        REPORT: 'Reports',
        REPORT_REQUIRED: 'Report is required',
        CHART_TYPE: 'Select chart type',
        CHART_REQUIRED: 'Chart is required',
        CHART_CONFIG: 'Chart configuration',
        COLUMN_REQUIRED: 'Column is required',
        ADD_DASHBOARD: 'Add Dashboard'
    }

    static INVOICE = {
      AMOUNT: 'Amount',
      PAID: 'Paid',
      INVOICE_ID: 'Invoice id',
      DESCRIPTION: 'Description',
      CREATED_AT: 'Created at'
    }

    static LABEL={
        OBJECT : 'Object',
        LABEL : 'Label',
        USER : 'User',
        PERMISSION : 'Permission',
        CREATE_DATE : 'Create Date',
        ROLE : 'Role',
        NAME : 'Name',
        EMAIL : 'Email',
        IS_ADMIN : 'Is Admin',
        ACTION : 'Actions',
        DESCRIPTION : 'Description',
        VERSIONING : 'Versioning',
        DISCARD: 'Discard',
        SAVE_CHANGES: 'Save Changes',
        WIDGET : 'Widget',
        SUBMIT : 'Submit',
        CREATED_AT: 'Created At',
        CREATED_BY: 'Created By',
        ENABLED: 'Enabled',
    }

    static PLAN = {
      NO_UPCOMING_INVOICE: 'No Upcoming Invoice',
      START_COMMUNITY_PLAN: 'Your new plan will be start after '
    }
}
