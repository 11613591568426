import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { Router } from '@angular/router';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { environment } from 'src/environments/environment';
import { IdConstant } from 'src/app/core/constants/id-constant';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  loginForm: boolean = true;
  organizationForm: boolean = false;
  completedForm: boolean = false;
  validateLoginFields: boolean = false;
  validateOrganizationFields: boolean = false;
  registrationData: any = {
    email: '',
    displayName: '',
    password: '',
    organizationName: '',
    organizationPlan: 'COMMUNITY'
  }

  loginFormSubmit: boolean = false;
  organizationFormSubmit: boolean = false;
  submitted: boolean = false;
  registrationErrorMsg: string = '';
  loading: boolean = false;

  passwordMatch: boolean = false;

  registrationForm: FormGroup;

  constructor(private nyxService: NyxAPIService,
    private router: Router,
    private notificationService: GlobalNotificationService){}

  ngOnInit() {
    this.registrationForm = new FormGroup({
      email: new FormControl('', Validators.required),
      displayName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      organizationName: new FormControl('', [Validators.required, Validators.pattern(/^[a-z]+$/)]),
    });
  }

  confirmPassword(event:Event){
    if(this.registrationFormControl['password']?.value == this.registrationFormControl['confirmPassword']?.value){
      this.passwordMatch = true;
    }else{
      this.passwordMatch = false;
    }
  }

  get registrationFormControl() {
    return this.registrationForm.controls;
  }

  submit(){
    this.submitted = true;
    if(this.registrationForm.valid && this.passwordMatch){
      this.loading = true;
      let registrationPayload = {
        Email: this.registrationForm.value.email,
        DisplayName: this.registrationForm.value.displayName,
        Password: this.registrationForm.value.password,
        OrganizationName: this.registrationForm.value.organizationName,
        OrganizationPlanId: IdConstant.GENERAL.EMPTY_GUID
      }

      this.nyxService
        .createNewOrganization(registrationPayload).subscribe((res:any) => {
          console.log("Response from service: ", res);

          this.loading = false;
          if(res.statusCode == 'OK'){
            window.location.href = `${environment.PORTAL_NYXHUB_HOST}/${res.data.name}`;
          }else{
            this.registrationErrorMsg = res.errorMessage;
          }
      });
    }
  }
}
