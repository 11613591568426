<div class="col-12">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Webhook ({{webhookList.totalCount}} records)</div>
      <div class="inline-flex align-items-center">
        <div class="flex flex-wrap gap-2">
          <button pButton pRipple type="button" label="Add Webhook" (click)="addNewWebhookButtonClick()"></button>
        </div>
      </div>
    </div>

    <div class="card-body py-4">
      <p-table #dt1 [value]="webhookList.record" [loading]="loading" [rowHover]="true"
        styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.NAME}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.OBJECT}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.IS_ENABLED}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.ON_CREATE}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.ON_UPDATE}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.WEBHOOK.ON_DELETE}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.ACTION}}
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-webhook let-i="rowIndex">
          <tr>
            <td>{{webhook.name}}</td>
            <td>{{webhook.nyxObject.name}}</td>
            <td>{{webhook.enabled}}</td>
            <td>
              <label class="form-check form-check-sm form-check-custom form-check-solid">
                <p-checkbox #checkboxes [(ngModel)]="webhook.onCreate" binary="true" inputId="webhookCreate"
                  [disabled]="true"></p-checkbox>
              </label>
            </td>
            <td>
              <label class="form-check form-check-sm form-check-custom form-check-solid">
                <p-checkbox #checkboxes [(ngModel)]="webhook.onUpdate" binary="true" inputId="webhookCreate"
                  [disabled]="true"></p-checkbox>
              </label>
            </td>
            <td>
              <label class="form-check form-check-sm form-check-custom form-check-solid">
                <p-checkbox #checkboxes [(ngModel)]="webhook.onDelete" binary="true" inputId="webhookCreate"
                  [disabled]="true"></p-checkbox>
              </label>
            </td>
            <td class="flex flex-wrap gap-2">
              <p-confirmPopup></p-confirmPopup>
              <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text"
                [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/webhook-manager/webhook-log/' + webhook.id + '/overview']"></button>
              <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/webhook-manager/edit/' + webhook.id]"></button>
              <button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="onWebhookListingDeleteButtonClicked($event, webhook.id)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalWebhook"></p-paginator>
    </div>
  </div>
</div>