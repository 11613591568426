<div class="col-12">
    <div class="card">
        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
            <div class="text-900 text-xl font-semibold mb-3 md:mb-0">
                {{nyxObject?.name}} - View Listing
                <small>({{nyxViewRecords?.length}} views)</small>
            </div>

            <div class="text-900 text-xl font-semibold mb-3 md:mb-0"></div>

            <div class="inline-flex align-items-center">
                <div class="flex flex-wrap gap-2">
                    <button pButton pRipple type="button" label="New View" class="p-button-primary"
                        [routerLink]="['/' + this.urlState.organizationName + '/app/nyx-record-view-manager/' + this.urlState.objectId + '/create']"></button>
                </div>
            </div>
        </div>

        <p-table #dt1 [value]="nyxViewRecords" dataKey="id" [rows]="10" [loading]="loading" [rowHover]="true"
            styleClass="p-datatable-gridlines" [paginator]="true"
            [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.RECORD.RECORD_ID}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.NAME}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.RECORD.LAST_MODIFIED_DATE}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.RECORD.LAST_MODIFIED_BY}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            As Default
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.ACTION}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-view>
                <tr>
                    <td>{{view.id}}</td>
                    <td>{{view.name}}</td>
                    <td>{{view.modifiedBy}}</td>
                    <td>{{view.modifiedDatetime  + 'Z' | date: generalConstant.TABLE_DATA.DATE}}</td>
                    <td>
                        <div>
                            <p-inputSwitch class="p-inputswitch" [(ngModel)]="view.isDefault" 
                                (onChange)="onSetDefaultView(view.id, view.isDefault)"></p-inputSwitch>
                        </div>
                    </td>
                    <td>
                        <p-confirmPopup></p-confirmPopup>
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                            [routerLink]="['/'+this.urlState.organizationName+'/app/nyx-record-view-manager/' + this.urlState.objectId + '/edit/' + view.id]"></button>
                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-danger p-button-text"
                            (click)="onNyxRecordViewListingDeleteButtonClicked($event, view.id)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr>
                    <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>