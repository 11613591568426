import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NavTabNavigationComponent } from './nav-tab-navigation/nav-tab-navigation.component';
import { NyxObjectSchemaListingComponent } from './nyx-object-schema-listing/nyx-object-schema-listing.component';
import { NyxSetupTabPaneComponent } from './nyx-setup-tab-pane/nyx-setup-tab-pane.component';
import { NyxObjectFieldDetailComponent } from './nyx-object-field-detail/nyx-object-field-detail.component';
import { NyxObjectsOverviewComponent } from './nyx-objects-overview/nyx-objects-overview.component';
import { ModalNyxCreateObjectComponent } from './modal-nyx-create-object/modal-nyx-create-object.component';
import { NyxObjectsOverview2Component } from './nyx-objects-overview2/nyx-objects-overview2.component';
import { NyxRecordListingComponent } from './nyx-record-listing/nyx-record-listing.component';
import { NyxTabPaneComponent } from './nyx-tab-pane/nyx-tab-pane.component';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { NyxTextFieldComponent } from './nyx-fields/nyx-text-field/nyx-text-field.component';
import { NyxTextareaFieldComponent } from './nyx-fields/nyx-textarea-field/nyx-textarea-field.component';
import { NyxEmailFieldComponent } from './nyx-fields/nyx-email-field/nyx-email-field.component';
import { NyxNumberFieldComponent } from './nyx-fields/nyx-number-field/nyx-number-field.component';
import { NyxCheckboxFieldComponent } from './nyx-fields/nyx-checkbox-field/nyx-checkbox-field.component';
import { NyxDateFieldComponent } from './nyx-fields/nyx-date-field/nyx-date-field.component';
import { NyxDatetimeFieldComponent } from './nyx-fields/nyx-datetime-field/nyx-datetime-field.component';
import { NyxIntegerFieldComponent } from './nyx-fields/nyx-integer-field/nyx-integer-field.component';
import { NyxTagFieldComponent } from './nyx-fields/nyx-tag-field/nyx-tag-field.component';
import { TagInputModule } from 'ngx-chips';
import { ModalNyxLayoutCreateSectionComponent } from './modal-nyx-layout-create-section/modal-nyx-layout-create-section.component';
import { NyxFieldRendererComponent } from './nyx-field-renderer/nyx-field-renderer.component';
import { NyxRecordDetailComponent } from './nyx-record-detail/nyx-record-detail.component';
import { NyxRecordVersionsComponent } from './nyx-record-versions/nyx-record-versions.component';
import { NyxDropdownFieldComponent } from './nyx-fields/nyx-dropdown-field/nyx-dropdown-field.component';
import { Select2Module } from 'ng-select2-component';
import { NyxPermissionListingComponent } from './nyx-permission-listing/nyx-permission-listing.component';
import { NyxRoleListingComponent } from './nyx-role-listing/nyx-role-listing.component';
import { NyxUserListingComponent } from './nyx-user-listing/nyx-user-listing.component';
import { ModalNyxPermissionManagementComponent } from './modal-nyx-permission-management/modal-nyx-permission-management.component';
import { NyxUserDetailComponent } from './nyx-user-detail/nyx-user-detail.component';
import { NyxRoleDetailComponent } from './nyx-role-detail/nyx-role-detail.component';
import { NyxPermissionDetailComponent } from './nyx-permission-detail/nyx-permission-detail.component';
import { AutomationListingComponent } from './automation-listing/automation-listing.component';
import { WebhookListingComponent } from './webhook-listing/webhook-listing.component';
import { WebhookDetailComponent } from './webhook-detail/webhook-detail.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { NyxLookupFieldComponent } from './nyx-fields/nyx-lookup-field/nyx-lookup-field.component';
import { NyxFormulaFieldComponent } from './nyx-fields/nyx-formula-field/nyx-formula-field.component';
import { NyxLogsListingComponent } from './nyx-logs-listing/nyx-logs-listing.component';
import { TextFieldConfigurationComponent } from './fields-configuration/text-field-configuration/text-field-configuration.component';
import { EmailFieldConfigurationComponent } from './fields-configuration/email-field-configuration/email-field-configuration.component';
import { NumericFieldConfigurationComponent } from './fields-configuration/numeric-field-configuration/numeric-field-configuration.component';
import { DatetimeFieldConfigurationComponent } from './fields-configuration/datetime-field-configuration/datetime-field-configuration.component';
import { DropdownFieldConfigurationComponent } from './fields-configuration/dropdown-field-configuration/dropdown-field-configuration.component';
import { LookupFieldConfigurationComponent } from './fields-configuration/lookup-field-configuration/lookup-field-configuration.component';
import { FormulaFieldConfigurationComponent } from './fields-configuration/formula-field-configuration/formula-field-configuration.component';
import { RecordRelationListComponent } from './record-relation-list/record-relation-list.component';
import { DashboardTabPaneComponent } from './dashboard-tab-pane/dashboard-tab-pane.component';
import { ReportListingComponent } from './report-listing/report-listing.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { DashboardListingComponent } from './dashboard-listing/dashboard-listing.component';
import { BarchartComponent } from './charts/barchart/barchart.component';
import { TableComponent } from './charts/table/table.component';
import { LineGraphComponent } from './charts/line-graph/line-graph.component';
import { ReportViewComponent } from './report-view/report-view.component';
import { DashboardToolbarComponent } from './toolbars/dashboard-toolbar/dashboard-toolbar.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { NyxRecordViewDetailComponent } from './nyx-record-view-detail/nyx-record-view-detail.component';
import { NyxRecordViewListingComponent } from './nyx-record-view-listing/nyx-record-view-listing.component';
import { CalendarModule } from 'primeng/calendar';
import { MessageBoxComponent } from './message-box/message-box.component';
import { DropdownModule } from 'primeng/dropdown';
import { QueryEditorComponent } from './query-editor/query-editor.component';
import { StepsModule } from 'primeng/steps';
import { MatStepperModule } from '@angular/material/stepper';
import { UniqueIdConfigurationComponent } from './fields-configuration/unique-id-configuration/unique-id-configuration.component';
import { ModalUpsertDashboardComponent } from './modal-upsert-dashboard/modal-upsert-dashboard.component';
import { NyxUniqueIdFieldComponent } from './nyx-fields/nyx-unique-id-field/nyx-unique-id-field.component';
import { NyxOverallSummaryListingComponent } from './nyx-overall-summary-listing/nyx-overall-summary-listing.component';
import { ApiAccessListingComponent } from './api-access-listing/api-access-listing.component';
import { ApiAccessDetailComponent } from './api-access-detail/api-access-detail.component';
import { ToastModule } from 'primeng/toast';
import { WebhookLogListingComponent } from './webhook-log-listing/webhook-log-listing.component';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';
import { ChartModule } from 'primeng/chart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NyxRecordKanbanViewListComponent } from './nyx-record-kanban-view/nyx-record-kanban-view-list/nyx-record-kanban-view-list.component';
import { NyxRecordKanbanViewCardComponent } from './nyx-record-kanban-view/nyx-record-kanban-view-card/nyx-record-kanban-view-card.component';
import { InplaceModule } from 'primeng/inplace';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { NgxStripeModule } from 'ngx-stripe';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { PolarAreaChartComponent } from './charts/polar-area-chart/polar-area-chart.component';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { NyxAttachmentFieldComponent } from './nyx-fields/nyx-attachment-field/nyx-attachment-field.component';
import { AttachmentConfigurationComponent } from './fields-configuration/attachment-configuration/attachment-configuration.component';
import { FileUploadModule } from 'primeng/fileupload';
import { NyxRadioFieldComponent } from './nyx-fields/nyx-radio-field/nyx-radio-field.component';
import { NyxRecordVersionsSidebarComponent } from './nyx-record-versions-sidebar/nyx-record-versions-sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { BillingComponent } from './billing/billing.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InvoiceComponent } from '../pages/invoice/invoice.component';
import { PlanListingComponent } from './plan-listing/plan-listing.component';
import { NyxRecordKanbanViewSidebarComponent } from './nyx-record-kanban-view/nyx-record-kanban-view-sidebar/nyx-record-kanban-view-sidebar.component';
import { WidgetDetailComponent } from './widget-detail/widget-detail.component';
import { PaginatorModule } from 'primeng/paginator';
import { environment } from 'src/environments/environment';
import { ModalImportRecordComponent } from './modal-import-record/modal-import-record.component';
import { ModalImportObjectComponent } from './modal-import-object/modal-import-object.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ParameterizedQueryDetailComponent } from './parameterized-query-detail/parameterized-query-detail.component';
import { ParameterizedQueryListingComponent } from './parameterized-query-listing/parameterized-query-listing.component';
import { AutomationDetailComponent } from './automation-detail/automation-detail.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AutomationLogListingComponent } from './automation-log-listing/automation-log-listing.component';

@NgModule({
  declarations: [
    NavTabNavigationComponent,
    NyxObjectSchemaListingComponent,
    NyxSetupTabPaneComponent,
    NyxObjectFieldDetailComponent,
    NyxObjectsOverviewComponent,
    ModalNyxCreateObjectComponent,
    NyxObjectsOverview2Component,
    NyxRecordListingComponent,
    NyxTabPaneComponent,
    AsideNavComponent,
    NyxTextFieldComponent,
    NyxTextareaFieldComponent,
    NyxEmailFieldComponent,
    NyxNumberFieldComponent,
    NyxCheckboxFieldComponent,
    NyxRadioFieldComponent,
    NyxDateFieldComponent,
    NyxDatetimeFieldComponent,
    NyxIntegerFieldComponent,
    NyxTagFieldComponent,
    ModalNyxLayoutCreateSectionComponent,
    NyxFieldRendererComponent,
    NyxRecordDetailComponent,
    NyxRecordVersionsComponent,
    NyxDropdownFieldComponent,
    NyxPermissionListingComponent,
    NyxRoleListingComponent,
    NyxUserListingComponent,
    ModalNyxPermissionManagementComponent,
    NyxUserDetailComponent,
    NyxRoleDetailComponent,
    NyxPermissionDetailComponent,
    AutomationListingComponent,
    WebhookListingComponent,
    WebhookDetailComponent,
    NyxLookupFieldComponent,
    NyxFormulaFieldComponent,
    NyxLogsListingComponent,
    TextFieldConfigurationComponent,
    EmailFieldConfigurationComponent,
    NumericFieldConfigurationComponent,
    DatetimeFieldConfigurationComponent,
    DropdownFieldConfigurationComponent,
    LookupFieldConfigurationComponent,
    FormulaFieldConfigurationComponent,
    RecordRelationListComponent,
    DashboardTabPaneComponent,
    ReportListingComponent,
    ReportDetailComponent,
    DashboardListingComponent,
    BarchartComponent,
    TableComponent,
    LineGraphComponent,
    ReportViewComponent,
    DashboardToolbarComponent,
    DashboardOverviewComponent,
    NyxRecordViewDetailComponent,
    NyxRecordViewListingComponent,
    MessageBoxComponent,
    QueryEditorComponent,
    UniqueIdConfigurationComponent,
    ModalUpsertDashboardComponent,
    NyxUniqueIdFieldComponent,
    NyxOverallSummaryListingComponent,
    ApiAccessListingComponent,
    ApiAccessDetailComponent,
    WebhookLogListingComponent,
    NyxRecordKanbanViewListComponent,
    NyxRecordKanbanViewCardComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DoughnutChartComponent,
    AttachmentConfigurationComponent,
    NyxAttachmentFieldComponent,
    PlanListingComponent,
    NyxRecordVersionsSidebarComponent,
    BillingComponent,
    InvoiceComponent,
    NyxRecordKanbanViewSidebarComponent,
    WidgetDetailComponent,
    ModalImportRecordComponent,
    ModalImportObjectComponent,
    ParameterizedQueryDetailComponent,
    ParameterizedQueryListingComponent,
    AutomationDetailComponent,
    AutomationLogListingComponent

  ],
  imports: [
    MultiSelectModule,
    TooltipModule,
    CommonModule,
    RouterModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    TagInputModule,
    Select2Module,
    CalendarModule,
    DropdownModule,
    StepsModule,
    MatStepperModule,
    ToastModule,
    TableModule,
    TabViewModule,
    ChipModule,
    InputTextModule,
    DialogModule,
    CheckboxModule,
    InputSwitchModule,
    MessagesModule,
    InputTextareaModule,
    InputNumberModule,
    ChipsModule,
    ChartModule,
    RadioButtonModule,
    ButtonModule,
    MenuModule,
    AccordionModule,
    PanelModule,
    SelectButtonModule,
    InplaceModule,
    DragDropModule,
    AvatarModule,
    AvatarGroupModule,
    FileUploadModule,
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    SidebarModule,
    TabMenuModule,
    ConfirmPopupModule,
    PaginatorModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    CodemirrorModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    NavTabNavigationComponent,
    NyxObjectSchemaListingComponent,
    NyxSetupTabPaneComponent,
    NyxObjectFieldDetailComponent,
    NyxObjectsOverviewComponent,
    ModalNyxCreateObjectComponent,
    NyxObjectsOverview2Component,
    NyxRecordListingComponent,
    NyxTabPaneComponent,
    AsideNavComponent,
    NyxTextFieldComponent,
    NyxTextareaFieldComponent,
    NyxEmailFieldComponent,
    NyxNumberFieldComponent,
    NyxCheckboxFieldComponent,
    NyxRadioFieldComponent,
    NyxDateFieldComponent,
    NyxDatetimeFieldComponent,
    NyxIntegerFieldComponent,
    NyxTagFieldComponent,
    ModalNyxLayoutCreateSectionComponent,
    NyxFieldRendererComponent,
    NyxRecordDetailComponent,
    NyxRecordVersionsComponent,
    NyxPermissionListingComponent,
    NyxRoleListingComponent,
    NyxUserListingComponent,
    ModalNyxPermissionManagementComponent,
    NyxUserDetailComponent,
    NyxRoleDetailComponent,
    NyxPermissionDetailComponent,
    WebhookListingComponent,
    WebhookDetailComponent,
    NyxLogsListingComponent,
    TextFieldConfigurationComponent,
    RecordRelationListComponent,
    DashboardTabPaneComponent,
    ReportListingComponent,
    ReportDetailComponent,
    DashboardListingComponent,
    BarchartComponent,
    ReportViewComponent,
    DashboardToolbarComponent,
    DashboardOverviewComponent,
    NyxRecordViewDetailComponent,
    NyxRecordViewListingComponent,
    MessageBoxComponent,
    QueryEditorComponent,
    UniqueIdConfigurationComponent,
    ModalUpsertDashboardComponent,
    NyxUniqueIdFieldComponent,
    NyxOverallSummaryListingComponent,
    ApiAccessListingComponent,
    ApiAccessDetailComponent,
    WebhookLogListingComponent,
    TabViewModule,
    ButtonModule,
    ProgressSpinnerModule,
    NyxRecordKanbanViewListComponent,
    NyxRecordKanbanViewCardComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DoughnutChartComponent,
    AttachmentConfigurationComponent,
    NyxAttachmentFieldComponent,
    PlanListingComponent,
    NyxRecordVersionsSidebarComponent,
    BillingComponent,
    InvoiceComponent,
    NyxRecordKanbanViewSidebarComponent,
    WidgetDetailComponent,
    ModalImportRecordComponent,
    ModalImportObjectComponent,
    ParameterizedQueryDetailComponent,
    ParameterizedQueryListingComponent,
    AutomationDetailComponent,
    AutomationListingComponent,
    AutomationLogListingComponent

  ],
  providers: [
  ],
})
export class SharedModule {}
