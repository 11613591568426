import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { RegistrationComponent } from './pages/registration/registration.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./pages/loader/loader.module').then((m) => m.LoaderModule),
  },
  {
    path: 'auth',
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    loadChildren: () =>
      import('./pages/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'invitation',
    component: InvitationComponent,
    loadChildren: () =>
      import('./pages/invitation/invitation.module').then(
        (m) => m.InvitationModule
      ),
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
    loadChildren: () =>
      import('./pages/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      ),
  },
  {
    path: ':organization',
    data: { breadcrumbUrlParamMap: 'organization' },
    component: MainLayoutComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/overview/overview.module').then(
            (m) => m.OverviewModule
          ),
      },
      {
        path: ':section/dashboard-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/dashboard-manager/dashboard-manager.module').then(
            (m) => m.DashboardManagerModule
          ),
      },
      {
        path: ':section/nyx-record',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-record/nyx-record.module').then(
            (m) => m.NyxRecordModule
          ),
      },
      {
        path: ':section/nyx-record-view-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-record-view-manager/nyx-record-view-manager.module').then(
            (m) => m.NyxRecordViewManagerModule
          ),
      },
      {
        path: ':section/nyx-object-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-object-manager/nyx-object-manager.module').then(
            (m) => m.NyxObjectManagerModule
          ),
      },
      {
        path: ':section/nyx-permission-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-permission-manager/nyx-permission-manager.module').then(
            (m) => m.NyxPermissionManagerModule
          ),
      },
      {
        path: ':section/nyx-role-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-role-manager/nyx-role-manager.module').then(
            (m) => m.NyxRoleManagerModule
          ),
      },
      {
        path: ':section/nyx-user-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-user-manager/nyx-user-manager.module').then(
            (m) => m.NyxUserManagerModule
          ),
      },
      {
        path: ':section/nyx-automation-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/automation-manager/automation-manager.module').then(
            (m) => m.AutomationManagerModule
          ),
      },
      {
        path: ':section/webhook-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/webhook-manager/webhook-manager.module').then(
            (m) => m.WebhookManagerModule
          ),
      },
      {
        path: ':section/nyx-logs',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-logs/nyx-logs.module').then(
            (m) => m.NyxLogsModule
          ),
      },
      {
        path: ':section/report-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/report-manager/report-manager.module').then(
            (m) => m.ReportManagerModule
          ),
      },
      {
        path: ':section/query-editor-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/query-editor-manager/query-editor-manager.module').then(
            (m) => m.QueryEditorManagerModule
          ),
      },
      {
        path: ':section/parameterized-query-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/parameterized-query-manager/parameterized-query-manager.module').then(
            (m) => m.ParameterizedQueryManagerModule
          ),
      },
      {
        path: ':section/nyx-overall-summary',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/nyx-overall-summary/nyx-overall-summary.module').then(
            (m) => m.NyxOverallSummaryModule
          ),
      },
      {
        path: ':section/api-access-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/api-access-manager/api-access-manager.module').then(
            (m) => m.ApiAccessManagerModule
          ),
      },
      {
        path: ':section/payment',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/payment-manager/payment-manager.module').then(
            (m) => m.PaymentManagerModule
          ),
      },
      {
        path: ':section/plan-manager',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/plan-manager/plan-manager.module').then(
            (m) => m.PlanManagerModule
          ),
      },
      {
        path: ':section/apis',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/document-manager/document-manager.module').then(
            (m) => m.DocumentManagerModule
          ),
      },
      {
        path: ':section/billing',
        data: { breadcrumbUrlParamMap: 'section' },
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./pages/billing-manager/billing-manager.module').then(
            (m) => m.BillingManagerModule
          ),
      },
    ],
    // loadChildren: () =>
    //   import('./pages/overview/overview.module').then((m) => m.OverviewModule),
  },
  {
    path: 'document',
    data: { breadcrumbUrlParamMap: 'section' },
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./pages/document-manager/document-manager.module').then(
        (m) => m.DocumentManagerModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
