import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-automation-listing',
  templateUrl: './automation-listing.component.html',
  styleUrls: ['./automation-listing.component.scss']
})
export class AutomationListingComponent extends BaseUrlState{

  messageBoxConstant = MessageBoxConstant;
  generalConstant = GeneralConstant;
  labelConstant = LabelConstant

  automationList: any = [];

  totalAutomation:any;
  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getNyxAutomationWithPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  getNyxAutomationWithPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxAutomationsWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.loading = false;
      this.automationList = data;
      this.totalAutomation = this.automationList.totalCount;
    });
  }

  deleteAutomation(thisContext: any, id: any) {
      thisContext.nyxAPIService
      .deleteNyxAutomation(thisContext.urlState.organizationName!, id)
      .subscribe((data: any) => {
        thisContext.getNyxAutomationWithPagination(thisContext.pagedRecord.pageNumber,thisContext.pagedRecord.pageSize);
        thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.AUTOMATION_MESSAGE.DELETE_AUTOMATION_SUCCESS));

      }, (error: any) => {
        console.log('deleteNyxAutomation error ', error);
        thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.AUTOMATION_MESSAGE.DELETE_AUTOMATION_ERROR);
      });
  }

  onDeleteButtonClick(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.AUTOMATION_MESSAGE.DELETE_AUTOMATION_CONFIRMATION, this, this.deleteAutomation, id, null);
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalAutomation > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxAutomationWithPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  redirectToCreate(){
    this.router.navigate([`/${this.urlState.organizationName}/${this.urlState.sectionName}/nyx-automation-manager/create`]);
  }
}
