import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-formula-field-configuration',
  templateUrl: './formula-field-configuration.component.html',
  styleUrls: ['./formula-field-configuration.component.scss']
})
export class FormulaFieldConfigurationComponent implements OnInit {
  
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = ""

  constructor(){}

  get fieldFormControl() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.setFormulaFieldAsRequired();
  }

  setFormulaFieldAsRequired(){
    this.fieldFormControl.formula.setValidators([Validators.required]);
  }
}
