import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NewFieldEventService } from 'src/app/core/services/event.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { PermissionEventService } from 'src/app/core/services/permission.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-permission-listing',
  templateUrl: './nyx-permission-listing.component.html',
  styleUrls: ['./nyx-permission-listing.component.scss'],
})

export class NyxPermissionListingComponent extends BaseUrlState {
  messageBoxConstant = MessageBoxConstant;
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  nyxPermissions: any = [];
  
  totalPermission: any;
  

  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
    private permissionEventService: PermissionEventService
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // this.getNyxPermissions();
    this.subscribeToRefreshPermissionListing();
    this.getNyxPermissionPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    this.loading = false;
  }

  subscribeToRefreshPermissionListing() {
    this.permissionEventService.newPermissionSubject.subscribe((value) => {
      if (value) {
        this.getNyxPermissionPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
      }
    });
  }

  // Get Nyx Permission data
  // getNyxPermissions() {
  //   this.nyxAPIService
  //     .getNyxPermissions(this.urlState.organizationName!)
  //     .subscribe((data: any) => {
  //       this.getNyxPermissionPagination(this.PageNumber, this.MaxPageSize);
  //       this.loading = false;
  //     });
  // }

  getNyxPermissionPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxPermissionsWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.nyxPermissions = data;
      this.totalPermission = this.nyxPermissions.totalCount;
      console.log(this.nyxPermissions);
    });
  }
  editNyxPermission(id: string) {
    this.permissionEventService.editPermissionEvent(id);
    console.log(id);
  }

  deleteNyxPermission(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxPermission(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.getNyxPermissionPagination(thisContext.pagedRecord.pageNumber, thisContext.pagedRecord.pageSize);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PERMISSION_MESSAGE.DELETE_PERMISSION_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PERMISSION_MESSAGE.DELETE_PERMISSION_ERROR);
    });
  }

  onNyxPermissionListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.PERMISSION_MESSAGE.DELETE_PERMISSION_CONFIRMATION, this, this.deleteNyxPermission, id, null)
  }

  addPermissionButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-permission-manager/create`]);
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalPermission > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxPermissionPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }
}
