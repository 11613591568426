<div class="col-12">
	<div class="card">
	  <!-- <h5>{{nyxObject?.name}}</h5>
		<span>({{nyxObjectRecords.length}} records)</span> -->
		<div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
			<div class="text-900 text-xl font-semibold mb-3 md:mb-0">{{nyxObject?.name}} ({{nyxObjectRecords.totalCount}} records)</div>
		</div>
		
		<div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
			<span class="p-input-icon-left">
				<i class="pi pi-search"></i>
				<input type="search" pInputText placeholder="Search by Record ID/Name" [(ngModel)]="componentState.searchKeyword" (input)="onSearchKeyUp(1, pagedRecord.pageSize, $event)">
			</span>

			<div class="inline-flex align-items-center">
				<div class="flex flex-wrap gap-2">
					<button pButton pRipple type="button" label="New Record" class="p-button-primary"
					[routerLink]="['/' + urlState.organizationName + '/app/nyx-record/' + urlState.objectId + '/create']"></button>

					<p-menu #viewMenu [popup]="true" [model]="viewMenuItems"></p-menu>
					<button type="button" pButton icon="pi pi-chevron-down" label="View" (click)="viewMenu.toggle($event)" style="width:auto"></button>
					
					<p-menu #quickMenu [popup]="true" [model]="quickMenuItems"></p-menu>
					<button type="button" pButton icon="pi pi-chevron-down" label="Quick Actions" (click)="quickMenu.toggle($event)" style="width:auto"></button>
				</div>
			</div>
		</div>

	  	<p-table #dt1 [value]="nyxObjectRecords.record" dataKey="id" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
			<!-- <ng-template pTemplate="caption">
			<div class="flex justify-content-between flex-column sm:flex-row">
				<button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
				<span class="p-input-icon-left mb-2">
				<i class="pi pi-search"></i>
				<input pInputText type="text" #filter (input)="onGlobalFilter(dt1, $event)" placeholder="Search Keyword" class="w-full"/>
				</span>
			</div>
			</ng-template> -->
			<ng-template pTemplate="header">
				<tr>
					<th style="min-width: 12rem" *ngFor="let field of currentFields">
					<div class="flex justify-content-between align-items-center">
						{{field.Label}}
					</div>
					</th>
					<th style="min-width: 12rem">
						<div class="flex justify-content-between align-items-center">
							{{labelConstant.LABEL.ACTION}}
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-record>
				<tr>
					<td *ngFor="let field of currentFields">
						{{ 
							(datetimeGroup[field.FieldAPIName] ? (record[field.FieldAPIName] + 'Z' | date: generalConstant.TABLE_DATA.DATE) : record[field.FieldAPIName]) || 
							(datetimeGroup[field.FieldAPIName] ? (record.data[field.FieldAPIName] + 'Z' | date: generalConstant.TABLE_DATA.DATE) : record.data[field.FieldAPIName]) ||
							(record.lookupnames[field.FieldAPIName])
						}}
					</td>
					<td class="flex flex-wrap gap-2">
						<p-confirmPopup></p-confirmPopup>
						<button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
						[routerLink]="['/'+this.urlState.organizationName+'/app/nyx-record/' + urlState.objectId + '/edit/' + record.id]"></button>
						<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"
						(click)="onNyxRecordListingDeleteButtonClicked($event, record.id)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="loadingbody">
				<tr>
					<td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
				</tr>
			</ng-template>
		</p-table>
		<p-paginator #paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="nyxObjectRecords.totalCount"></p-paginator>
	</div>

  </div>
