<!--begin::Tab pane-->
<div role="tabpanel">
    <!--begin::Wrapper-->
    <div class="m-0">
        <!--begin::Toolbar-->
        <div class="d-flex mb-10">
            &nbsp;
        </div>
        <!--end::Toolbar-->
        <div class="m-0">
            <!--begin::Heading-->
            <h1 class="text-gray-800 fw-semibold mb-6 mx-5">Records</h1>
            <!--end::Heading-->
            <!--begin::Items-->
            <div class="mb-10">
                <!--begin::Item-->
                <a class="custom-list d-flex align-items-center px-5 py-4 " *ngFor="let nyxObject of nyxObjectList" [routerLink]="['/' + state.organizationName + '/app/nyx-record/' + nyxObject.id + '/overview']">
                    <div class="symbol symbol-40px me-5">
                        <span class="symbol-label">
                            <img src="assets/media/svg/brand-logos/bebo.svg" class="h-50 align-self-center" alt="" />
                        </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <h5 class="custom-list-title fw-semibold text-gray-800 mb-1">{{nyxObject.name}}</h5>
                    </div>
                </a>
                <!--end::Item-->
            </div>
            <!--end::Items-->
        </div>
    </div>
    <!--end::Wrapper-->
</div>
<!--end::Tab pane-->
