import { Component, OnInit, AfterViewInit, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Component({
  template: '',
})

@Injectable({providedIn: 'root'})
export class NyxConfirmationService {
  constructor(private confirmationService: ConfirmationService){}

  deleteConfirm(event: Event, message:string, thisContext:any, acceptCallback:any, acceptCallbackArg:any, rejectCallback:any) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: message,
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger p-button-sm',
        accept: () => {
          if(acceptCallback != null && acceptCallback != undefined ){
            acceptCallback(thisContext, acceptCallbackArg);
          }
        },
        reject: () => {
          if(rejectCallback != null && rejectCallback != undefined ){
            rejectCallback(thisContext);
          }
        }
    });
}
}
