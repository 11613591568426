import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NyxAPIService {
  errorPageMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public viewWidgetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getErrorPageMessage(message: string){
    this.errorPageMessage.next(message);
  }

  onLoadWidgetEvent(load: boolean){
    this.viewWidgetSubject.next(load);
  }

  getHelloWorld() {
    return this.http.get(environment.AZURE_APIM_HOST)
  }

  getOrganizationAccess() {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxAccess/Organization`)
  }

  getNyxObjects(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/GetNyxObjects`)
  }

  getNyxObjectsWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/GetNyxObjectsByOrganizationName?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxObjectById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/GetNyxObjectById/${id}`)
  }

  createNyxObject(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/CreateNyxObject`, payload);
  }

  getNyxObjectRecordsByObjectId(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/list/${id}`)
  }

  getNyxConfigurationFieldTypes(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxConfiguration/${organizationName}/FieldTypes`);
  }

  getNyxFieldByFieldAPIName(organizationName: string, id: any, fieldAPIName: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxField/${organizationName}/${id}/${fieldAPIName}`);
  }

  createNyxField(organizationName: string, id: any, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxField/${organizationName}/${id}`, payload);
  }

  updateNyxField(organizationName: string, id: any, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxField/${organizationName}/${id}`, payload);
  }

  getNyxRecord(organizationName: string, id: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/detail/${id}`);
  }

  getNyxRecordListing(organizationName: any, PageNumber: any, MaxPageSize: any,PageSize:any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (MaxPageSize == null || MaxPageSize < 0) {
      MaxPageSize = 100;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 100;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/GetNyxObjectsByOrganizationName?maxPageSize=${MaxPageSize}&pageNumber=${PageNumber}&pageSize=${PageSize}`);
  }

  getNyxRecordWithPagination(organizationName: any, id: string, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/List/${id}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxSearchRecordWithPagination(organizationName: any, id: string, PageNumber: any, PageSize: any, Search: any){
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/GetSearch/${id}?pageSize=${PageSize}&pageNumber=${PageNumber}&search=${Search}`);
  }

  getRelatedRecord(organizationName: string, childObjectId: any, chidlFieldAPIName: any, id: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/RelatedRecords/${childObjectId}/${chidlFieldAPIName}/${id}`);
  }

  createNyxRecord(organizationName: string, id: any, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/Create/${id}`, payload);
  }

  deleteNyxRecord(organizationName: string, objectId: any, id: any) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/Delete/${objectId}/${id}`);
  }

  updateNyxRecord(organizationName: string, objectId: any, id: any, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/Update/${objectId}/${id}`, payload);
  }

  deleteNyxField(organizationName: string, id: any, fieldAPIName: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxField/${organizationName}/${id}/${fieldAPIName}`);
  }

  updateNyxLayout(organizationName: string, id: any, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxLayout/${organizationName}/Update/${id}`, payload);
  }

  resetNyxLayout(organizationName: string, id: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxLayout/${organizationName}/Reset/${id}`, {});
  }

  getNyxRecordVersions(organizationName: string, id: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/Versions/${id}`, {});
  }

  searchNyxRecord(organizationName: string, id: any, searchKeyword: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecord/${organizationName}/search/${id}/${searchKeyword}`);
  }

  getNyxPermissions(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}`);
  }

  getNyxPermissionsWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxPermission(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}/${id}`);
  }

  createNyxPermissions(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}`, payload);
  }

  updateNyxPermissions(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}/${id}`, payload);
  }

  deleteNyxPermission(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}/${id}`);
  }

  getNyxRoles(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}`);
  }

  getNyxRolesWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxPermissionListing(organizationName: any, PageNumber: any, MaxPageSize: any,PageSize:any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (MaxPageSize == null || MaxPageSize < 0) {
      MaxPageSize = 100;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 100;
    }
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxPermission/${organizationName}?maxPageSize=${MaxPageSize}&pageNumber=${PageNumber}&pageSize=${PageSize}`);
  }

  getNyxRole(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}/${id}`);
  }

  createNyxRoles(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}`, payload);
  }

  updateNyxRole(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}/${id}`, payload);
  }

  getUserAllOrganization(organizationName: any) {
    return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/GetUserAllOrganization`);
  }

  getNyxUsers(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/GetUsersByOrganizationName`);
  }

  getNyxUserWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/GetUsersByOrganizationName?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxRoleListing(organizationName: any, PageNumber: any, MaxPageSize: any,PageSize:any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (MaxPageSize == null || MaxPageSize < 0) {
      MaxPageSize = 100;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 100;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}?maxPageSize=${MaxPageSize}&pageNumber=${PageNumber}&pageSize=${PageSize}`);
  }

  getNyxUser(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/GetUserWithRoleById/${id}`);
  }

  checkUserIsOganizationOwner(organizationName: string){
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/CheckUserIsOganizationOwner`);
  }

  inviteNyxUser(organizationName: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/InviteNyxUser`, payload);
  }

  getNyxOrganizationByName(organizationName: string){
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxOrganization/GetNyxOrganizationByName/${organizationName}`);
  }

  verifyInvitationLink(payload:any){
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxOrganization/VerifyInvitationLink`, payload);
  }

  createInviteUser(payload:any){
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxOrganization/CreateInviteUser`, payload);
  }

  deleteNyxUser(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxUser/${organizationName}/DeleteNyxUser/${id}`);
  }

  deleteNyxRole(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxRole/${organizationName}/${id}`);
  }

  addNewUserRole(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxUserRole/${organizationName}`, payload);
  }

  deleteNewUserRole(organizationName: string, userId: string, roleId: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxUserRole/${organizationName}/${userId}/${roleId}`);
  }

  addNewRolePermission(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxRolePermission/${organizationName}`, payload);
  }

  deleteNewRolePermission(organizationName: string, userId: string, roleId: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxRolePermission/${organizationName}/${userId}/${roleId}`);
  }

  createNewOrganization(data: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxOrganization/CreateNewOrganization`, data);
  }

  getNyxWebhooks(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/GetNyxWebhookByOrganizationName`)
  }

  getNyxWebhooksWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/GetNyxWebhookByOrganizationName?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxWebhookById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/GetNyxWebhookById/${id}`)
  }

  getNyxWebhookLogById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/GetNyxWebhookLogById/${id}`)
  }

  createNyxWebhook(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/CreateNyxWebhook`, payload);
  }

  updateNyxWebhook(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/UpdateNyxWebhook/${id}`, payload);
  }

  deleteNyxWebhook(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxWebhook/${organizationName}/DeleteNyxWebhook/${id}`);
  }

  getNyxAutomations(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}`)
  }

  getNyxAutomationsWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/GetNyxAutomationByOrganizationNameWithPagination?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxAutomationById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/GetNyxAutomationById/${id}`)
  }

  getNyxAutomationBlobContent(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/GetNyxAutomationBlobContent/${id}`)
  }

  createNyxAutomation(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/CreateNyxAutomation`, payload);
  }

  updateNyxAutomation(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/UpdateNyxAutomation/${id}`, payload);
  }

  deleteNyxAutomation(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxAutomation/${organizationName}/DeleteNyxAutomation/${id}`);
  }

  getNyxAutomationLogListing(organizationName: string, id: string): Observable<any> {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxFile/${organizationName}/GetBlobList/${id}`);
  }




  getNyxLogs(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxLog/${organizationName}`);
  }

  getNyxLogWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxLog/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getFileDownload(organizationName: string, id: string, fileType: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/export/${organizationName}/Record/${id}/${fileType}`, {
      observe: 'events',
      responseType: 'blob'
    });
  }

  getReportDownload(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/export/${organizationName}/Report/${id}`, {
      observe: 'events',
      responseType: 'blob'
    });
  }

  getLogsDownload(organizationName: string){
    return this.http.get(environment.AZURE_APIM_HOST + `/api/export/${organizationName}/Logs`, {
      observe: 'events',
      responseType: 'blob'
    });
  }

  getSchemaDownload(organizationName: string, id: string){
    return this.http.get(environment.AZURE_APIM_HOST + `/api/export/${organizationName}/Object/${id}`, {
      observe: 'events',
      responseType: 'blob'
    });
  }

  getReportListing(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/GetReportingList`)
  }

  getNyxReportWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/GetReportingList?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxReportPageSize(organizationName: any, PageNumber: any, MaxPageSize: any,PageSize:any){
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (MaxPageSize == null || MaxPageSize < 0) {
      MaxPageSize = 100;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 100;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/GetReportingList?maxPageSize=${MaxPageSize}&pageNumber=${PageNumber}&pageSize=${PageSize}`);
  }

  getReportById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/GetReportingById/${id}`)
  }

  createReport(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/CreateReporting`, payload);
  }

  updateReport(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/UpdateReporting/${id}`, payload);
  }

  deleteReport(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/DeleteReporting/${id}`);
  }

  cacheClearByReportId(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxReporting/${organizationName}/CacheClearByReportId/${id}`);
  }

  validateAndPreviewQuery(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxSQL/${organizationName}/Preview`, payload);
  }

  getReportQueryDataByReportId(organizationName: string, reportId: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxSQL/${organizationName}/getReportQueryDataByReportId/${reportId}`);
  }

  getNyxRecordViewByObjectId(organizationName: string, Id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/ByObject/${Id}`);
  }
  getNyxRecordViewById(organizationName: string, Id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/${Id}`);
  }

  updateNyxRecordView(organizationName: string, id: string, payload: string) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/${id}`, payload);
  }

  onSetDefaultView(organizationName: string, objectId: string, id: string, isDefault: boolean) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/onSetDefault/${objectId}/${id}`, isDefault);
  }

  createRecordView(organizationName: string, id: string, payload: string) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/${id}`, payload);
  }

  deleteNyxRecordView(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxRecordView/${organizationName}/${id}`);
  }

  deleteNyxObject(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxObjectManager/${organizationName}/DeleteNyxObject/${id}`);
  }

  getDashboardListing(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}`)
  }

  getNyxDashboardWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getNyxDashboardPageSize(organizationName: any, PageNumber: any, MaxPageSize: any,PageSize:any){
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (MaxPageSize == null || MaxPageSize < 0) {
      MaxPageSize = 100;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 100;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}?maxPageSize=${MaxPageSize}&pageNumber=${PageNumber}&pageSize=${PageSize}`);
  }

  getDashboardById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}/${id}`)
  }

  createDashboard(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}`, payload);
  }

  updateDashboard(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}/${id}`, payload);
  }

  deleteDashboard(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxDashboard/${organizationName}/${id}`);
  }

  getNyxOverallSummary(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxOverallSummary/${organizationName}`);
  }

  getAllAPIAccess(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}`);
  }

  getNyxApiWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getAPIAccessById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}/${id}`)
  }

  createAPIAccess(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}`, payload);
  }

  updateAPIAccess(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}/${id}`, payload);
  }

  deleteAPIAccess(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/APIAccess/${organizationName}/${id}`);
  }

  getNyxWidgetByDashboard(organizationName: string, id: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWidget/${organizationName}/GetNyxWidgetByDashboard/${id}`)
  }

  getNyxWidgetById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/NyxWidget/${organizationName}/GetNyxWidgetById/${id}`)
  }

  createNyxWidget(organizationName: any, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxWidget/${organizationName}/CreateNyxWidget`, payload);
  }

  updateNyxWidget(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/NyxWidget/${organizationName}/UpdateNyxWidget/${id}`, payload);
  }

  deleteNyxWidgetById(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxWidget/${organizationName}/Delete/${id}`)
  }

  getPlanListing(organizationName: any) {
    return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/GetPlanListing`);
  }

  getPlanById(organizationName: any, id: any) {
    return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/GetPlanById/${id}`);
  }

  getBillingByOrganizationName(organizationName: any) {
    return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/GetBillingByOrganizationName`);
  }

  updateBillingGetProrationInvoice(organizationName: any, id: string, payload: any) {
    return this.http.put<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/UpdateBillingGetProrationInvoice/${id}`, payload);
  }

  getBillingById(organizationName: any, id: any) {
    return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/GetBillingById/${id}`);
  }

  changeTrialSubscription(organizationName: any, id: string, payload: any) {
    return this.http.put<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/ChangeTrialSubscription/${id}`, payload);
  }

  createSubscription(organizationName: any, payload: any) {
    return this.http.post<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/CreateSubscription`, payload);
  }

  previewProration(organizationName: any, payload: any) {
    return this.http.post<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/PreviewProration`, payload);
  }

  upgradeSubscription(organizationName: any, id: any, payload: any) {
    return this.http.put<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/UpgradeSubscription/${id}`, payload);
  }

  downgradeToTrialSubscription(organizationName: any, id: any, payload:any) {
    return this.http.put<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/DowngradeToTrialSubscription/${id}`, payload);
  }

  downgradePlan(organizationName: any, id: any, payload:any) {
    return this.http.put<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/DowngradePlan/${id}`, payload);
  }

  checkIsUpgrade(organizationName: any, id: any, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/CheckIsUpgrade/${id}`, payload);
  }

  // cancelSubscriptionApi(organizationName: any, id: any) {
  //   return this.http.get<PaymentIntent>(environment.AZURE_APIM_HOST + `/api/Payment/${organizationName}/CancelSubscription/${id}`);
  // }

  getAPIProfile(organizationName: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/Profile/${organizationName}`);
  }

  uploadNyxFile(organizationName: any, objectId: any, payload: any) {
    const formData = new FormData();
    payload.forEach((file: any) => {
      formData.append('files', file, file.name);
    });
    return this.http.post(environment.AZURE_APIM_HOST + `/api/NyxFile/${organizationName}/Upload/${objectId}`, formData);
  }

  importObject(organizationName: any, formData: any){
    return this.http.post(environment.AZURE_APIM_HOST+`/api/Import/${organizationName}/Object`, formData);
  }

  importRecord(organizationName: any, objectId:any, formData: any){
    return this.http.post(environment.AZURE_APIM_HOST+`/api/Import/${organizationName}/Record/${objectId}`, formData);
  }

  downloadFile(organizationName: any, objectId: any, filename: any) {
    return this.http.get<any>(environment.AZURE_APIM_HOST + `/api/NyxFile/${organizationName}/GetBlob/${objectId}/${filename}`);
  }

  deleteFile(organizationName: any, objectId: any, filename: any) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/NyxFile/${organizationName}/Delete/${objectId}/${filename}`);
  }

  getOrganizationInvoices(organizationName: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/Billing/${organizationName}/GetOrganizationInvoices`);
  }

  getStripeCustomerById(organizationName: any, customerId: any) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/Billing/${organizationName}/GetStripeCustomerById/${customerId}`);
  }

  // createPaymentMethod(organizationName:any, payload:any){
  //   return this.http.post(environment.AZURE_APIM_HOST + `/api/Billing/${organizationName}/CreatePaymentMethod`, payload);
  // }

  // createCardToken(organizationName:any, payload:any){
  //   return this.http.post(environment.AZURE_APIM_HOST + `/api/Billing/${organizationName}/CreateCardToken`, payload);
  // }

  updateStripeCustomer(organizationName: any, id: any, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/Billing/${organizationName}/UpdateStripeCustomer/${id}`, payload);
  }

  getParameterizedQueryWithPagination(organizationName: any, PageNumber: any, PageSize: any) {
    if (PageNumber == null || PageNumber < 0) {
      PageNumber = 1;
    }

    if (PageSize == null || PageSize < 0) {
      PageSize = 10;
    }

    return this.http.get(environment.AZURE_APIM_HOST + `/api/ParameterizedQueryManagement/${organizationName}?pageSize=${PageSize}&pageNumber=${PageNumber}`);
  }

  getParameterizedQueryById(organizationName: string, id: string) {
    return this.http.get(environment.AZURE_APIM_HOST + `/api/ParameterizedQueryManagement/${organizationName}/${id}`)
  }

  createParameterizedQuery(organizationName: string, payload: any) {
    return this.http.post(environment.AZURE_APIM_HOST + `/api/ParameterizedQueryManagement/${organizationName}`, payload);
  }

  updateParameterizedQuery(organizationName: string, id: string, payload: any) {
    return this.http.put(environment.AZURE_APIM_HOST + `/api/ParameterizedQueryManagement/${organizationName}/${id}`, payload);
  }

  deleteParameterizedQuery(organizationName: string, id: string) {
    return this.http.delete(environment.AZURE_APIM_HOST + `/api/ParameterizedQueryManagement/${organizationName}/${id}`);
  }

}
