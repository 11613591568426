import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  @Input() message: any = '';
  @Input() status: any = '';

  messageClass: string;
  textClass: string;

  ngOnInit(): void {
    console.log('----------message box-----------');
    console.log('message box message ', this.message)
    console.log('message box status ', this.status)

    if(this.status == 'Success'){
      this.messageClass = 'bg-light-primary rounded border-primary';
      this.textClass = 'text-primary';

    }else if(this.status == 'Failed'){
      this.messageClass = 'bg-light-danger rounded border-danger';
      this.textClass = 'text-danger';

      // (this.status == 'Warning')
    }else{
      this.messageClass = 'bg-light-warning rounded border-warning';
      this.textClass = 'text-warning';

    }
  }
}
