/*
import { Component } from '@angular/core';
*/
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-automation-log-listing',
  templateUrl: './automation-log-listing.component.html',
  styleUrl: './automation-log-listing.component.scss'
})
export class AutomationLogListingComponent extends BaseUrlState {
  automationLogListing: any=[];
  totalAutomation:any;
  loading:boolean = true;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  automationId: any = '';
  automationData: any;

  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  
  constructor(
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,

  ) {
    super(activatedRoute);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getAutomationLogListing();
    this.loading = false;
  }

  

  getAutomationLogListing(){
    this.nyxAPIService.getNyxAutomationLogListing(this.urlState.organizationName!, this.urlState.automationId!).subscribe((data:any) => {
      this.automationLogListing = data;
      this.totalAutomation = this.automationLogListing.totalCount;
      this.loading = false;
      console.log("checkdataTS1 ",data);
    })
  }

  onNyxRecordListingDeleteButtonClicked(event: Event, sasUrl: string) {
    const a = document.createElement('a');
    a.href = sasUrl;
    a.download = sasUrl; // set a name for the file
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
  

  

/*
  getNyxAutomationLogPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxAutomationLogListingWithPagination(this.urlState.organizationName!, this.urlState.automationId!, PageNumber, PageSize).subscribe((response:any) => {
      this.automationLogListing = response;
      this.totalAutomation = this.automationLogListing.totalCount;
    });
  }

 
  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalAutomation > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxAutomationLogPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  

  
  
*/

}
