<form [formGroup]="formGroup">
    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="isRequired" class="col-12 mb-2 md:col-2 md:mb-0">
                Required?
            </label>
            <div class="col-12 md:col-10">
                <p-inputSwitch inputId="isRequired" name="isRequired" formControlName="isRequired"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="defaultValue" class="col-12 mb-2 md:col-2 md:mb-0">
                Default Value
            </label>
            <div class="col-12 md:col-10">

                <input pInputText id="defaultValue" type="text" formControlName="defaultValue"
                    *ngIf="selectedFieldType.fieldTypeName == 'textbox' || selectedFieldType.fieldType == 'textbox'" />

                <textarea pInputTextarea rows="3" id="defaultValue" type="text" formControlName="defaultValue"
                    *ngIf="selectedFieldType.fieldTypeName == 'textarea' || selectedFieldType.fieldType == 'textarea'"></textarea>

            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="regexRestriction" class="col-12 mb-2 md:col-2 md:mb-0">
                Regex Restriction
            </label>
            <div class="col-12 md:col-10">
                <input pInputText id="regexRestriction" type="text" formControlName="regexRestriction" />
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />

    <!-- <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span class="required">Object</span>
            </label>
        </div>
        <div class="col-md-9">
            <select name="objectId" formControlName="objectId" class="form-select form-select-solid"
                data-control="select2" data-hide-search="true" [ngClass]="{
                    'is-invalid': createFieldFormControl.objectId.invalid,
                    'is-valid': createFieldFormControl.objectId.valid
                }">
                <option *ngFor="let object of nyxObjectList" value="{{object.id}}">
                    {{object.name}}</option>
            </select>

            <span class="text-danger"
                *ngIf="(createFieldFormControl.objectId.touched || submitted) && createFieldFormControl.objectId.errors?.required">
                Lookup object is required
            </span>
        </div>
    </div>

    <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span class="required">Formula</span>
            </label>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control form-control-lg form-control-solid" name="formula"
                formControlName="formula" placeholder="" value="" [ngClass]="{
                    'is-invalid': createFieldFormControl.formula.invalid,
                    'is-valid': createFieldFormControl.formula.valid
                }" />

            <span class="text-danger"
                *ngIf="(createFieldFormControl.formula.touched || submitted) && createFieldFormControl.formula.errors?.required">
                Formula required
            </span>
        </div>
    </div>

    <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span class="required">Value Range</span>
            </label>
        </div>
        <div class="col-md-9">
            <div class="row fv-row">
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-lg form-control-solid" name="minValue"
                    formControlName="minValue" placeholder="min value" value="" />
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-lg form-control-solid" name="maxValue"
                    formControlName="maxValue" placeholder="max value" value="" />
                </div>
            </div>
        </div>
    </div>

    <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span>Is Data Mask?</span>
            </label>
        </div>
        <div class="col-md-9">
            <label class="form-check form-switch form-check-custom form-check-solid">
                <input class="form-check-input" type="checkbox" value="1" name="isDataMask"
                    formControlName="isDataMask" />
            </label>
        </div>
    </div>

    <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span>Is Multiple Options</span>
            </label>
        </div>
        <div class="col-md-9">
            <label class="form-check form-switch form-check-custom form-check-solid">
                <input class="form-check-input" type="checkbox" value="1" name="isMultipleOptions"
                    formControlName="isMultipleOptions" />
            </label>
        </div>
    </div>

    <div class="row fv-row mb-7">
        <div class="col-md-3 text-md-end">
            <label class="fs-6 fw-semibold form-label mt-3">
                <span>Option List</span>
            </label>
        </div>
        <div class="col-md-9">
            <tag-input formControlName="options" [modelAsStrings]="true" placeholder="Enter your value ..." secondaryPlaceholder="Enter your value ..."></tag-input>
        </div>
    </div> -->
</form>