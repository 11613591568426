import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PaymentIntent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  StripeCardComponent,
  StripeCardNumberComponent,
  StripeService,
} from 'ngx-stripe';
import { ConfirmationService, MenuItem, Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { switchMap, Observable } from 'rxjs';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { IdConstant } from 'src/app/core/constants/id-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GlobalNotification, GlobalNotificationService, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { CMSService } from 'src/app/core/services/cms.service';
import { EmailService } from 'src/app/core/services/email.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { UrlMetadata } from 'src/app/core/constants/urlMetadata';

@Component({
  selector: 'app-plan-listing',
  templateUrl: './plan-listing.component.html',
  styleUrls: ['./plan-listing.component.scss']
})
export class PlanListingComponent extends BaseUrlState {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  generalConstant = GeneralConstant;
  labelConstant = LabelConstant;
  idConstant = IdConstant;
  messageBoxConstant = MessageBoxConstant;

  planList: any = [];
  planDescriptionLabel: any = [];
  subscriptionResponse: any;
  showPaymentDetails: boolean = false;
  isUpdateSubscription: boolean = false;
  invoice: any;
  userBillingData: any;
  selectedPlanId: string = '';
  selectedPriceId: string = '';
  submitted: boolean = false;
  stepState = 0;
  activeIndex: number = 0;
  items: MenuItem[] | undefined;
  messages: Message[] | undefined;
  currentIsCommunityPlan: boolean = false;
  paymentIntent: any;
  isDowngradePlan: boolean = false;
  currentPlan: any;
  loading: boolean = false;
  isYearlyPlan: boolean = false;
  payload: any;
  invoiceMessage: string = '';

  cmsParentId:any = "c2273aca-aedb-41be-8329-8cd4b4ab3e32";

  paymentForm: FormGroup = this.fb.group({
    name: [''],
    email: [''],
    amount: [0],
  });

   // Hardcode plan configuration (Not in CMS) - For testing purpose
   oneDollarPlan = {
    "contentType": "planFeatures",
    "name": "1 Dollar Annual Plan",
    "createDate": "2023-12-15T14:38:26.787Z",
    "updateDate": "2024-03-14T13:55:02.88Z",
    "route": {
        "path": "/billing-plan/free/",
        "startItem": {
            "id": "0f412e3b-bfeb-4b58-a61c-dfe34a7e0a82",
            "path": "nyxhub"
        }
    },
    "id": "f60963e2-897c-48e5-b08e-0b2c446699cf",

    // Plan Info
    "properties": {
        "numberOfObjects": "10",
        "numberOfRecordsPerObject": "1,000",
        "numberOfFieldPerObject": "10",
        "recordVersioningTotalObject": "N/A",
        "standardFields": "Y",
        "advancedFields": "Y (limit to 1 per subject)",
        "advancedFieldsAttachmentIdGeneratorUniqueFieldText": "Y (limit to 1 per subject)",
        "securedFieldsFieldMasking": "N/A",
        "attachmentFieldStorage": "50MB",
        "reportingPerDashboard": "1",
        "dashboard": "1",
        "automationWorkflow": "N/A",
        "webhook": "N/A",
        "logs": "N/A",
        "bulkDataImportExport": "N/A",
        "usersTeam": 1,
        "rolesPermissions": "Y",
        "enterpriseActiveDirectorySSO": "N/A",
        "isolatedTenantDedicateEnvironment": "N/A",
        "testingEnvironment": "N/A",
        "support": "N/A",
        "monthlyPriceId": "price_1Op5miISdo95pdiJ3fLgk6bC",
        "monthlyPriceInUSD": "$1",
        "monthlyPlanId": "e729047e-4d32-4e52-b173-1e8370016441",
        "annualPriceId": "price_1Op5miISdo95pdiJ3fLgk6bC",
        "annualPriceInUSD": "$1",
        "annualPlanId": "e729047e-4d32-4e52-b173-1e8370016441",
        "seq": 5,
        "planDescription": "For anyone getting started with smaller projects.",
        "guarantee": "No credit card required",
        "validityMonth": "forever",
        "validityYear": "forever",
        "activeButtonDesription": "Get started - it's free",
        "buttonLink": "https://portal.nyxhub.co/registration"
    },
    "cultures": {}
}

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontWeight: 400,
        fontFamily: 'Circular',
        fontSize: '14px',
        iconColor: '#666EE8',
        color: '#002333',
        '::placeholder': {
          color: '#919191',
        },
      },
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private nyxApiService: NyxAPIService,
    private router: Router,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private notificationService: GlobalNotificationService,
    private cmsService: CMSService,
    public override activatedRoute: ActivatedRoute,
    public emailService: EmailService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    super(activatedRoute);
  }

  override ngOnInit(){
    super.ngOnInit();

    this.initPlan();
    this.initUserBilling();
  }

  initPlan(){
    this.cmsService.getChildrenContentById(this.cmsParentId, 20).subscribe((data:any) => {
      data.items.forEach((value: any, index: number) => {
        if(value.contentType == 'planFeatures'){
          this.planList.push(data.items[index]);
        }else if(value.contentType == 'planDescriptionLabel'){
          this.planDescriptionLabel = data.items[index];
        }
      });

      // For testing purpose
      this.planList.push(this.oneDollarPlan);
    });
  }

  initUserBilling(){
    this.nyxApiService.getBillingByOrganizationName(this.urlState.organizationName).subscribe((result: any) => {
      this.userBillingData = result;

      // get user name & email
      this.getUserData(result);

      this.isYearlyPlan = (
        result.planId == IdConstant.PLAN.COMMUNITY_PLAN_ID ||
        result.planId == IdConstant.PLAN.MONTHLY_STARTUP_PLAN_ID ||
        result.planId == IdConstant.PLAN.MONTHLY_PROFESSIONAL_PLAN_ID ||
        result.planId == IdConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID
        ) ? false : true;


      this.currentIsCommunityPlan = this.userBillingData.planId == IdConstant.PLAN.COMMUNITY_PLAN_ID;
      this.getCurrentPlanSeqFromDb(this.userBillingData.planId);

      if(!this.currentIsCommunityPlan){
          this.isUpdateSubscription = true;
          this.items = [
            {
                label: 'Update Plan'
            },
            {
                label: 'Invoice'
            }
          ];

      }else{
        this.isUpdateSubscription = false;
        this.items = [
          {
              label: 'Select Plan'
          },
          {
              label: 'Payment Details'
          },
          {
              label: 'Invoice'
          }
        ];
      }
    });
  }

  getUserData(billing:any){
    this.nyxApiService.getNyxUser(this.urlState.organizationName!, billing.nyxUserId).subscribe((result:any) => {
      this.paymentForm.patchValue({
        name: result.displayName,
        email: result.email
      });
    });
  }

  getCurrentPlanSeqFromDb(planId: string){
    this.nyxApiService.getPlanById(this.urlState.organizationName, planId).subscribe((result:any) => {
      this.currentPlan = result;
    });
  }

  get paymentFormControl(){
    return this.paymentForm.controls;
  }

  goToPreviousStep(){
    this.stepState -= 1;
    this.onActiveIndexChange(this.stepState);
  }

  onActiveIndexChange(event: number) {
    this.messages = [];
    this.activeIndex = event;
  }

  selectedPlanEvent(planId: any, priceId: any, planPrice: any){
    this.isDowngradePlan = false;
    this.selectedPlanId = planId;
    this.selectedPriceId = priceId;
    this.loading = true;

    this.paymentForm.patchValue({amount: planPrice});

    var priceAmount = 0;
    if(planPrice == 'Free'){
      priceAmount = 0;
    }else{
      // check price with decimal
      //
      priceAmount = planPrice.replace('$','').replace(',','') * 100;
    }

    this.payload = {
      PlanId: this.selectedPlanId,
      PriceId: this.selectedPriceId,
      CustomerId: this.userBillingData.stripeUserId,
      DowngradePlan: this.isDowngradePlan,
      Price: priceAmount,
      SubscriptionScheduleId: this.userBillingData.stripeSubscriptionScheduleId,
      // TestClockId: "", // test clocks use only
      Time: 1709272292 // test clocks use only
    };

    // Upgrade Community Plan to other - update to stop trial & create new sub
    if(this.currentIsCommunityPlan){ //this.userBillingData.stripeClientSecret == null
      this.nyxApiService.changeTrialSubscription(this.urlState.organizationName, this.userBillingData.id, this.payload).subscribe((result: any) => {

        this.loading = false;
        this.subscriptionResponse = result;
        this.stepState = 1;
        this.onActiveIndexChange(this.stepState);

      }, (err: any) => {
        console.log('updateSubscription error ', err);
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR ,NotificationMessage.GENERAL.ERROR, err.error.errorMessage));
      });

    }else{
      this.nyxApiService.checkIsUpgrade(this.urlState.organizationName, this.userBillingData.id, this.payload).subscribe((isUpgrade:any) => {

        if(isUpgrade){
          this.nyxApiService.upgradeSubscription(this.urlState.organizationName, this.userBillingData.id, this.payload).subscribe((result: any) => {
            this.loading = false;
            console.log('invoice ', result);
            this.stepState = 2;
            this.onActiveIndexChange(this.stepState);

            if(result != null){
              this.invoice = result;
              this.invoiceMessage = MessageBoxConstant.PLAN.SUCCESS_UPGRADE_PLAN;
              this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PLAN.SUCCESS_UPGRADE_PLAN));
            }

          }, (err: any) => {
            this.loading = false;
            console.log('upgrade subscription error ', err);
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR, NotificationMessage.GENERAL.ERROR, MessageBoxConstant.PLAN.FAILED_TO_UPGRADE_PLAN));
          });
        }else{

          // show confirmation popup message
          this.confirmationService.confirm({
            header: MessageBoxConstant.PLAN.CANCEL_PLAN_CONFIRMATION_POPUP,
            message: MessageBoxConstant.PLAN.CANCEL_PLAN_CONFIRMATION,
            accept: () => {

              this.loading = true;
              this.nyxApiService.downgradeToTrialSubscription(this.urlState.organizationName, this.userBillingData.id, this.payload).subscribe((result: any) => {
                this.loading = false;
                this.stepState = 2;
                this.onActiveIndexChange(this.stepState);
                this.isDowngradePlan = true;

                this.invoiceMessage = MessageBoxConstant.PLAN.SUCCESS_DOWNGRADE_PLAN;
                this.messageService.add({ severity: MessageBoxConstant.STATUS.INFO, summary: MessageBoxConstant.STATUS.SUCCESS, detail: MessageBoxConstant.PLAN.SUCCESS_DOWNGRADE_PLAN, life: 3000 });

              }, (err: any) => {
                this.loading = false;
                console.log('downgrade subscription error ', err);
                this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR, NotificationMessage.GENERAL.ERROR, MessageBoxConstant.PLAN.FAILED_TO_DOWNGRADE_PLAN));
              });

            },
            reject: () => {
              this.loading = false;
            }
          });
        }
      });
    }
  }

  selectedEnterprisePlanEvent(){
    window.location.href = UrlMetadata.URL.CONTACT_US;
  }

  submitPaymentDetail(){
    this.loading = true;
    this.isDowngradePlan = false;
    this.submitted = true;

    this.stripeService.confirmCardPayment(this.subscriptionResponse.latestInvoice.paymentIntent.clientSecret, {
      payment_method: {
        card: this.card.element,
        billing_details: {
          name: this.paymentForm.value.name,
        },
      },
    }).subscribe((result:any) => {
      console.log('confirmCardPayment result ', result)
      this.loading = false;

      if(result.error != undefined){
        this.messages = [
          { severity: 'error', summary: 'Error', detail: result.error.message },
        ];

      }else{
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.stepState = 2;
          this.onActiveIndexChange(this.stepState);
          this.invoiceMessage = MessageBoxConstant.PLAN.PAYMENT_PROCESSING;
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PLAN.PAYMENT_PROCESSING));
        }
      }
    });
  }

  viewPlanDetails(){
    window.location.href = UrlMetadata.URL.PRICING;
  }

  redirectToBillingPage(){
    this.router.navigate([`/${this.urlState.organizationName!}/${this.urlState.sectionName!}/billing`]);
  }

}
