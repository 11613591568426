import { Component } from '@angular/core';

@Component({
  selector: 'app-nyx-role-manager',
  templateUrl: './nyx-role-manager.component.html',
  styleUrls: ['./nyx-role-manager.component.scss']
})
export class NyxRoleManagerComponent {

}
