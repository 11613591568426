import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-email-field-configuration',
  templateUrl: './email-field-configuration.component.html',
  styleUrls: ['./email-field-configuration.component.scss']
})
export class EmailFieldConfigurationComponent {
  
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = ""

  get fieldFormControl() {
    return this.formGroup.controls;
  }
}
