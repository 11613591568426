import { EventEmitter } from '@angular/core';

export enum GlobalNotificationSeverity {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export class NotificationMessage{
  static GENERAL = {
    SUCCESS: 'Success',
    ERROR: 'Error',
  }

  static LAYOUT = {
    SUCCESS_SAVED: 'Layout saved',
    SUCCESS_RESET: 'Layout reset'
  }
}

export class GlobalNotification {
  constructor(public severity: GlobalNotificationSeverity, public summary: string, public detail: string){}
}

export class GlobalNotificationService {
  public notificationEmitter$: EventEmitter<GlobalNotification>;

  constructor() {
    this.notificationEmitter$ = new EventEmitter();
  }
  displayMessage(notification: GlobalNotification): void {
    this.notificationEmitter$.emit(notification);
  }

  displayErrorMessage(error:any, defaultErrorMessage:string): void{
    let errorNotification = null;
    if(error?.errorMessage != undefined && error?.errorMessage != null && error?.errorMessage != ''){
      errorNotification = new GlobalNotification(GlobalNotificationSeverity.ERROR ,NotificationMessage.GENERAL.ERROR, error.errorMessage)
    }else{
      errorNotification = new GlobalNotification(GlobalNotificationSeverity.ERROR ,NotificationMessage.GENERAL.ERROR, defaultErrorMessage);
    }

    this.notificationEmitter$.emit(errorNotification);
  }
}
