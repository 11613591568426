import { Component } from '@angular/core';

@Component({
  selector: 'app-nyx-record',
  templateUrl: './nyx-record.component.html',
  styleUrls: ['./nyx-record.component.scss']
})
export class NyxRecordComponent {

}
