import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { GlobalNotificationService, GlobalNotification, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-parameterized-query-listing',
  templateUrl: './parameterized-query-listing.component.html',
  styleUrl: './parameterized-query-listing.component.scss'
})
export class ParameterizedQueryListingComponent extends BaseUrlState {
  messageBoxConstant = MessageBoxConstant;
  generalConstant = GeneralConstant;
  labelConstant = LabelConstant
  
  parameterizedQueries: any = [];
  
  totalParameterizedQueries:any;
  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getParameterizedQueryPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    this.loading = false;
  }

  getParameterizedQueryPagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getParameterizedQueryWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.parameterizedQueries = data;
      this.totalParameterizedQueries = this.parameterizedQueries.totalCount;
      console.log(this.parameterizedQueries);
    });
  }

  addNewParameterizedQueryButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/parameterized-query-manager/create`]);
  }

  onParameterizedQueryDeleteButtonClick(thisContext: any, id: any) {
    thisContext.nyxAPIService
      .deleteParameterizedQuery(thisContext.urlState.organizationName!, id)
      .subscribe((data: any) => {
        thisContext.getParameterizedQueryPagination(thisContext.pagedRecord.pageNumber,thisContext.pagedRecord.pageSize);
        thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.DELETE_PARAMETERIZED_QUERY_SUCCESS));

      }, (error: any) => {
        thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.DELETE_PARAMETERIZED_QUERY_ERROR);
      });
  }


  onParameterizedQueriesingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.DELETE_PARAMETERIZED_QUERY_CONFIRMATION, this, this.onParameterizedQueryDeleteButtonClick, id, null)
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalParameterizedQueries > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }
    this.getParameterizedQueryPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }
}
