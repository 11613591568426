<div class="col-12">
  <!-- Loading -->
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <!-- Confirmation Popup -->
  <div class="card flex justify-content-center">
    <p-toast></p-toast>
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message>
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-question text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <button pButton label="Confirm" (click)="cd.accept()" class="w-8rem"></button>
                    <button pButton label="Cancel" (click)="cd.reject()" class="p-button-outlined w-8rem "></button>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
  </div>

  <div class="card card-w-title flex flex-wrap">
      <div class="col-12 md:col-12 h-full">
          <h5>Steps</h5>
          <p-steps [model]="items" styleClass="mt-4" [readonly]="false" [activeIndex]="stepState" (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
      </div>
      <div class="col-12 md:col-12 mt-5 md:mt-0">
        <ng-container *ngIf="stepState == 0">
          <div class="px-4 py-8 md:px-6 lg:px-8">
            <div class="text-900 font-bold text-6xl mb-5 text-center">Pricing Plans</div>
            <div class="toggle-btn ext-toggle-btn toggle-btn-md mb-4 text-center">
              <span class="text-700 text-3xl toggler-txt m-2">Billed Monthly</span>
                <p-inputSwitch
                [(ngModel)]="isYearlyPlan">
                </p-inputSwitch>
              <span class="text-700 text-3xl toggler-txt m-2">Billed Yearly</span>
            </div>
            <div class="text-700 text-xl mb-6 text-center line-height-3">
              <p style="color: #1680fb;" (click)="viewPlanDetails()">
                Click to view plan details
              </p>
            </div>

            <div class="grid">
              <ng-container *ngFor="let plan of planList; let i=index">
                <div class="col-12 lg:col-3" [style]="plan.properties.annualPlanId != idConstant.PLAN.TESTING_ANNUAL_PLAN_ID ? '': 'display: none'">
                    <div class="p-3 h-full">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <div class="text-900 font-medium text-xl mb-2">{{ plan.name }}</div>
                            <div class="text-600">Plan description</div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center  mb-3">
                              <ng-container *ngIf="!isYearlyPlan">
                                <span class="font-bold text-2xl text-900">{{ plan.properties.monthlyPriceInUSD }}</span>
                                <span class="ml-2 font-medium text-600">{{ planDescriptionLabel.properties.monthlyPriceInUSD }}</span>
                              </ng-container>
                              <ng-container *ngIf="isYearlyPlan">
                                <span class="font-bold text-2xl text-900">{{ plan.properties.annualPriceInUSD }}</span>
                                <span class="ml-2 font-medium text-600">{{ planDescriptionLabel.properties.annualPriceInUSD }}</span>
                              </ng-container>
                            </div>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <ng-container *ngIf="userBillingData != undefined && userBillingData != null && currentPlan != undefined">

                              <!-- to show/hide selected plan based on monthly/annual -->
                              <ng-container *ngIf="isYearlyPlan">
                                <!-- Current Plan != Community && selected plan is monthly should show cancel current plan & disabled button in yearly plan -->
                                <ng-container *ngIf="(userBillingData.planId == plan.properties.monthlyPlanId) && plan.properties.monthlyPlanId != idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" class="p-button-danger pt-3 pb-3" disabled="true"></button>
                                </ng-container>
                                <!-- End Current Plan != Community -->

                                <!-- Enterprise Plan - selected plan != monthly & annual Enterprise -->
                                <ng-container *ngIf="(userBillingData.planId != idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID && userBillingData.planId != idConstant.PLAN.YEARLY_ENTERPRISE_PLAN_ID) &&
                                 plan.properties.annualPlanId == idConstant.PLAN.YEARLY_ENTERPRISE_PLAN_ID">
                                  <button pButton pRipple label="Contact Us" class="p-3 w-full mt-auto" (click)="selectedEnterprisePlanEvent()"></button>
                                </ng-container>
                                <!-- End Enterprise Plan -->
                              </ng-container>

                              <!-- to show/hide selected plan based on monthly/annual -->
                              <ng-container *ngIf="!isYearlyPlan">
                                <!-- Current Plan != Community && selected plan is annual should show cancel current plan & disabled button in monthly plan -->
                                <ng-container *ngIf="(userBillingData.planId == plan.properties.annualPlanId) && plan.properties.monthlyPlanId != idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" class="p-button-danger pt-3 pb-3" disabled="true"></button>
                                </ng-container>
                                <!-- End Current Plan != Community -->

                                <!-- Enterprise Plan -->
                                <ng-container *ngIf="(userBillingData.planId != idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID && userBillingData.planId != idConstant.PLAN.YEARLY_ENTERPRISE_PLAN_ID) &&
                                 plan.properties.monthlyPlanId == idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID">
                                  <button pButton pRipple label="Contact Us" class="p-3 w-full mt-auto" (click)="selectedEnterprisePlanEvent()"></button>
                                </ng-container>
                                <!-- End Enterprise Plan -->
                              </ng-container>


                              <!-- Monthly Plan -->
                              <ng-container *ngIf="!isYearlyPlan">
                                <!-- Current Plan = Community -->
                                <ng-container *ngIf="userBillingData.planId == plan.properties.monthlyPlanId && plan.properties.monthlyPlanId == idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" class="p-button-danger pt-3 pb-3" disabled="true"></button>
                                </ng-container>
                                <!-- End Current Plan = Community -->
                                <!-- Current Plan != Community -->
                                <ng-container *ngIf="userBillingData.planId == plan.properties.monthlyPlanId && plan.properties.monthlyPlanId != idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" (click)="selectedPlanEvent(plan.properties.monthlyPlanId, plan.properties.monthlyPriceId, plan.properties.monthlyPriceInUSD)" class="p-button-danger pt-3 pb-3"></button>
                                </ng-container>
                                <!-- End Current Plan != Community -->
                                <!-- Current Plan Seq < Plan Seq -->
                                <ng-container *ngIf="userBillingData.planId != plan.properties.monthlyPlanId && plan.properties.seq > currentPlan.seq && plan.properties.monthlyPlanId != idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID && plan.properties.annualPlanId != idConstant.PLAN.TESTING_ANNUAL_PLAN_ID">
                                  <button pButton pRipple label="Upgrade Plan" (click)="selectedPlanEvent(plan.properties.monthlyPlanId, plan.properties.monthlyPriceId, plan.properties.monthlyPriceInUSD)" class="p-3 w-full mt-auto"></button>
                                </ng-container>
                                <!-- End Current Plan Seq < Plan Seq -->

                                <!-- disabled testing 1 dollar annual plan button in monthly plan-->
                                <ng-container *ngIf="userBillingData.planId != plan.properties.monthlyPlanId && plan.properties.seq > currentPlan.seq && plan.properties.monthlyPlanId != idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID && plan.properties.annualPlanId == idConstant.PLAN.TESTING_ANNUAL_PLAN_ID">
                                  <button pButton pRipple label="Upgrade Plan" (click)="selectedPlanEvent(plan.properties.monthlyPlanId, plan.properties.monthlyPriceId, plan.properties.monthlyPriceInUSD)" disabled="true" class="p-3 w-full mt-auto"></button>
                                </ng-container>
                                <!-- end disabled testing 1 dollar annual plan button in monthly plan-->

                                <!-- Current Plan Seq > Plan Seq -->
                                <ng-container *ngIf="userBillingData.planId != plan.properties.monthlyPlanId && plan.properties.seq < currentPlan.seq && plan.properties.monthlyPlanId != idConstant.PLAN.MONTHLY_ENTERPRISE_PLAN_ID">
                                  <button pButton pRipple label="Downgrade Plan" class="p-3 w-full mt-auto" disabled="true"></button>
                                </ng-container>
                                <!-- End Current Plan Seq > Plan Seq -->
                              </ng-container>
                              <!-- End Monthly Plan -->

                              <!-- Yearly Plan -->
                              <ng-container *ngIf="isYearlyPlan">
                                <!-- Current Plan = Community -->
                                <ng-container *ngIf="userBillingData.planId == plan.properties.monthlyPlanId && plan.properties.monthlyPlanId == idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" class="p-button-danger pt-3 pb-3" disabled="true"></button>
                                </ng-container>
                                <!-- End Current Plan = Community -->
                                <!-- Current Plan != Community -->
                                <ng-container *ngIf="userBillingData.planId == plan.properties.annualPlanId && plan.properties.annualPlanId != idConstant.PLAN.COMMUNITY_PLAN_ID">
                                  <button pButton pRipple label="Cancel Current Plan" (click)="selectedPlanEvent(plan.properties.annualPlanId, plan.properties.annualPriceId, plan.properties.annualPriceInUSD)" class="p-button-danger pt-3 pb-3"></button>
                                </ng-container>
                                <!-- End Current Plan != Community -->
                                <!-- Current Plan Seq < Plan Seq -->
                                <ng-container *ngIf="userBillingData.planId != plan.properties.annualPlanId && plan.properties.seq > currentPlan.seq && plan.properties.annualPlanId != idConstant.PLAN.YEARLY_ENTERPRISE_PLAN_ID">
                                  <button pButton pRipple label="Upgrade Plan" (click)="selectedPlanEvent(plan.properties.annualPlanId, plan.properties.annualPriceId, plan.properties.annualPriceInUSD)" class="p-3 w-full mt-auto"></button>
                                </ng-container>
                                <!-- End Current Plan Seq < Plan Seq -->
                                <!-- Current Plan Seq > Plan Seq -->
                                <ng-container *ngIf="userBillingData.planId != plan.properties.annualPlanId && plan.properties.seq < currentPlan.seq && plan.properties.annualPlanId != idConstant.PLAN.YEARLY_ENTERPRISE_PLAN_ID">
                                  <button pButton pRipple label="Downgrade Plan" class="p-3 w-full mt-auto" disabled="true"></button>
                                </ng-container>
                              </ng-container>
                              <!-- End Yearly Plan -->

                            </ng-container>
                        </div>
                    </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- !isUpdateSubscription &&  -->
        <ng-container *ngIf="stepState == 1">
          <div class="mt-6" [formGroup]="paymentForm">
            <span class="text-900 text-xl font-bold mb-4 block">Payment Details</span>
            <div class="grid">
                <div class="col-12 lg:col-2">
                    <!-- <div class="text-900 font-medium text-xl mb-3">Profile</div> -->
                    <p class="m-0 p-0 text-600 line-height-3 mr-3">Enter payment details</p>
                </div>
                <div class="col-12 lg:col-10">
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                            <label for="nickname" class="font-medium text-900">Name</label>
                            <input id="nickname" type="text" pInputText formControlName="name" [readOnly]="true">
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <label for="email" class="font-medium text-900">Email</label>
                            <input id="email" type="text" pInputText formControlName="email" [readOnly]="true">
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                          <label for="amount" class="font-medium text-900">Amount</label>
                            <input id="amount" type="text" pInputText formControlName="amount" [readOnly]="true">
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                          <ngx-stripe-card
                          [options]="cardOptions"
                          [elementsOptions]="elementsOptions"
                          ></ngx-stripe-card>
                        </div>

                        <div class="col-12">
                          <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
                        </div>

                        <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
                        <div class="col-12 mb-2 md:col-2 md:mb-0">
                          <button pButton pRipple type="button" type="reset" label="Back" class="p-button-secondary"
                            (click)="goToPreviousStep()"></button>
                        </div>
                        <div class="col-12 mb-2 md:col-2 md:mb-0">
                          <button pButton pRipple type="button" label="Pay" (click)="submitPaymentDetail()"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>

        <ng-container *ngIf="(stepState == 2 || (isUpdateSubscription && stepState == 1)) && invoice != null">
          <div class="py-8 px-6 md:px-8 overflow-auto">
            <div class="flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max">
              <div class="flex flex-column">
                <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1548 9.65956L23.9913 4.86169L5.54723 14.5106L0.924465 12.0851L23.9913 0L37.801 7.23403L33.1548 9.65956ZM23.9931 19.3085L42.4255 9.65955L47.0717 12.0851L23.9931 24.1595L10.1952 16.9361L14.8297 14.5106L23.9931 19.3085ZM4.6345 25.8937L0 23.4681V37.9149L23.0669 50V45.1489L4.6345 35.4894V25.8937ZM18.4324 28.2658L0 18.6169V13.7658L23.0669 25.8403V40.2977L18.4324 37.8615V28.2658ZM38.7301 23.468V18.6169L24.9205 25.8403V49.9999L29.555 47.5743V28.2659L38.7301 23.468ZM43.3546 35.4892V16.1914L48.0008 13.7659V37.9148L34.1912 45.1488V40.2977L43.3546 35.4892Z" fill="var(--primary-color)"/>
                </svg>
                <div class="my-3 text-4xl font-bold text-900">{{ invoice.accountName }}</div>
                <span class="mb-2">148 Hope Lane</span>
                <span>Palo Alto, CA 94304.</span>
              </div>
              <div class="flex flex-column mt-5 md:mt-0">
                <div class="text-2xl font-semibold text-left md:text-right mb-3">INVOICE</div>
                <div class="flex flex-column">
                  <div class="flex justify-content-between align-items-center mb-2">
                    <span class="font-semibold mr-6">DATE</span>
                    <span>{{ invoice.stripeResponse.date | date: 'dd/MM/yyyy' }}</span>
                  </div>
                  <div class="flex justify-content-between align-items-center mb-2">
                    <span class="font-semibold mr-6">INVOICE #</span>
                    <span>8523</span>
                  </div>
                  <div class="flex justify-content-between align-items-center">
                    <span class="font-semibold mr-6">CUSTOMER ID</span>
                    <span>{{ invoice.customerId }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-5 mb-8 flex flex-column">
              <div class="mb-3 text-2xl font-medium">BILL TO</div>
              <ng-container *ngIf="invoice.customerAddress != null">
                <span class="mb-2">{{ invoice.customerName + ', ' + invoice.customerAddress.line1 }}, </span>
                <span class="mb-2">{{ invoice.customerAddress.line2 + ' ' + invoice.customerAddress.city + ' ' + invoice.customerAddress.postalCode}},</span>
                <span class="mb-2">{{ invoice.customerAddress.state + ' ' + invoice.customerAddress.country }}.</span>
              </ng-container>
              <ng-container *ngIf="invoice.customerAddress == null">
                <span class="mb-2">{{ invoice.customerName }} </span>
              </ng-container>
            </div>

            <div class="overflow-x-auto">
              <table class="w-full" style="border-collapse: collapse; table-layout: auto">
                <thead>
                  <tr>
                    <th class="text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Description</th>
                    <th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">Quantity</th>
                    <th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">Unit Price</th>
                    <th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Amount (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left py-3 border-bottom-1 surface-border white-space-nowrap">{{ invoice.periodStart | date:'short' }} to {{ invoice.periodEnd | date:'short' }}</td>
                  </tr>
                  <ng-container *ngFor="let plan of invoice.lines">
                    <tr>
                      <td class="text-left py-3 border-bottom-1 surface-border white-space-nowrap">{{ plan.description }}</td>
                      <td class="text-right py-3 border-bottom-1 surface-border px-3">{{ plan.quantity }}</td>
                      <td class="text-right py-3 border-bottom-1 surface-border px-3"></td>
                      <td class="text-right py-3 border-bottom-1 surface-border">{{ plan.amount/ 100 }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8">
              <div class="font-semibold mb-3 md:mb-0">NOTES</div>
              <div class="flex flex-column">
                <div class="flex justify-content-between align-items-center mb-2">
                  <span class="font-semibold mr-6">SUBTOTAL</span>
                  <span>{{ invoice.subtotal/ 100 }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                  <span class="font-semibold mr-6">TOTAL EXCLUDING TAX</span>
                  <span>{{ invoice.totalExcludingTax/ 100 }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                  <span class="font-semibold mr-6">TAX</span>
                  <ng-container *ngIf="invoice.tax != null">
                    <span>{{ invoice.tax/ 100 }}</span>
                  </ng-container>
                  <ng-container *ngIf="invoice.tax == null">
                    <span>0</span>
                  </ng-container>
                </div>
                <div class="flex justify-content-between align-items-center">
                  <span class="font-semibold mr-6">TOTAL</span>
                  <span>{{ invoice.total/ 100 }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-2">
                  <span class="font-semibold mr-6">AMOUNT DUE</span>
                  <span>{{ invoice.amountDue/ 100 }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="stepState == 2 && invoice == null">
          <div class="text-700 text-2xl text-center line-height-3 mt-5 mb-6">{{ invoiceMessage }}</div>
          <!-- <div class="text-700 text-xl mb-6 text-center line-height-3">{{ labelConstant.PLAN.START_COMMUNITY_PLAN }} {{ invoice.effectiveAt | date:'short' }}</div> -->
          <div class="field grid">
            <div class="col-12 mb-2 md:col-5 md:mb-0"></div>
            <div class="p-fluid p-formgrid grid col-2">
              <div class="field col">
                <button pButton pRipple type="button" label="View Billing"
                (click)="redirectToBillingPage()"></button>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="isSubmit"></i>
              </div>
            </div>
            <div class="col-12 mb-2 md:col-5 md:mb-0"></div>
          </div>
        </ng-container>
      </div>
  </div>
</div>
