import { GeneralConstant } from './../../core/constants/general-constant';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends BaseUrlState {

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
  loading: boolean = true;
  invoiceList: any;

  constructor(
    private nyxApiService: NyxAPIService,
    private router: Router,
    private stripeService: StripeService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(){
    super.ngOnInit();
    this.initInvoice();
  }

  initInvoice(){
    this.nyxApiService.getOrganizationInvoices(this.urlState.organizationName!).subscribe((result) => {
      console.log(result);

      this.invoiceList = result;
      this.loading = false;
    })
  }
}
