import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('Reacquiring token sliently.');
                    if (error.error.statusCode == 401) {
                        this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest)
                            .subscribe((response: AuthenticationResult) => {
                                console.log(response);
                                this.authService.instance.setActiveAccount(response.account);
                                console.log('response from acquireTokenSilent', response);
                                console.log(this.authService.instance.getAllAccounts());
                                window.location.reload();
                            }, err => {
                                console.log('Not able to get token silent. direct to login page');
                                this.authService.loginRedirect();
                            });

                            console.log('Error 401: redirect to login');
                            this.authService.loginRedirect();
                    }
                    return throwError(error);
                })
            )
    }
}