import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { MenuService } from 'src/app/core/services/menu.service';


@Component({
  selector: 'app-modal-nyx-create-object',
  templateUrl: './modal-nyx-create-object.component.html',
  styleUrls: ['./modal-nyx-create-object.component.scss'],
})
export class ModalNyxCreateObjectComponent extends BaseUrlState {
  @ViewChild('modalCreateObjectCloseButton', { static: false }) modalCreateObjectCloseButton: ElementRef;

  @Input() modalShow: boolean = false;
  @Output() modalShowEvent: any = new EventEmitter();

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  submitted = false;
  createObjectForm: FormGroup;
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private notificationService: GlobalNotificationService,
    public menuService: MenuService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.loading = true;
  }

  get createObjectFormControl() {
    return this.createObjectForm.controls;
  }

  initForm() {
    this.createObjectForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^[a-z0-9_]+$/)]],
      description: [''],
      versioning: [false],
    });
  }

  submit() {
    this.submitted = true;
    if (this.createObjectForm.valid) {
      var createObjectPayload = {
        Name: this.createObjectForm.value.name,
        Description: this.createObjectForm.value.description,
        Versioning: this.createObjectForm.value.versioning,
        schema: {},
      };

      this.nyxAPIService
        .createNyxObject(this.urlState.organizationName!, createObjectPayload)
        .subscribe((data: any) => {
          // this.navigateToNyxObjectSchemaListingPage(data.id);
          console.log("create object data", data);
          this.initForm();
          this.closeModal();
          this.menuService.onLoadMenu(true);
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.OBJECT_MESSAGE.CREATE_OBJECT_SUCCESS.replace('{{ name }}', data.name)));

        }, (error: any) => {
          // this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR ,NotificationMessage.GENERAL.ERROR, MessageBoxConstant.OBJECT_MESSAGE.CREATE_OBJECT_ERROR));
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECT_MESSAGE.CREATE_OBJECT_ERROR);
          console.log(error);
        });
    }
  }

  closeModal() {
    this.modalShow = false;
    this.modalShowEvent.emit(this.modalShow);
  }

  navigateToNyxObjectSchemaListingPage(id: any) {
    this.router.navigate([`${this.urlState.organizationName!}/setup/nyx-object-manager/${id}`]);
  }
}
