import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';

@Component({
  selector: 'app-nyx-overall-summary-listing',
  templateUrl: './nyx-overall-summary-listing.component.html',
  styleUrls: ['./nyx-overall-summary-listing.component.scss']
})
export class NyxOverallSummaryListingComponent extends BaseUrlState {

  nyxOverallSummary:any;
  metricLabels: any ={
    numberOfObjects: 'Total Object',
    numberOfRecords: 'Total Record',
    numberOfUsers: 'Total User',
    numberOfPermissions: 'Total Permission',
    numberOfRecordView: 'Total Record View',
    numberOfRoles: 'Total Role',
    numberOfDashboards: 'Total Dashboard',
    numberOfReportings: 'Total Reporting',
    numberOfWidgets: 'Total Widget',
    numberOfWebhooks: 'Total Webhook',
    numberOfAPIAccess: 'Total API Access',
    numberOfAutomations: 'Total Automation',
    numberOfParameterizedQuery: "Total Parameterized Query"
  }

  metrics:any[] = [
    "numberOfDashboards", "numberOfReportings", "numberOfWidgets",
    "numberOfObjects", "numberOfRecords", "numberOfRecordView",
    "numberOfUsers", "numberOfRoles", "numberOfPermissions",
    "numberOfWebhooks", "numberOfAPIAccess", "numberOfAutomations", "numberOfParameterizedQuery"
  ];


  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.getOverallSummary();
  }

  getOverallSummary(){
    // Get OverallSummary data from through backend API
    this.nyxAPIService.getNyxOverallSummary(this.urlState.organizationName!).subscribe((data: any) => {
      console.log(data);
      this.nyxOverallSummary = data;
    });
  }

  calculateColorCode(usage:number, limit:number){
    var percentage = (usage / limit ) * 100;
    if(percentage <= 50 ){
      return "bg-green-500";
    }else if(percentage > 50 && percentage <= 90 ){
      return "bg-yellow-500";
    }else{
      return "bg-red-500";
    }
  }

  calculateStyleWidthPercentage(usage:number, limit:number){
    var percentage = (usage / limit ) * 100;
    if( percentage > 100 )
      return '100%';
    if( percentage < 0 )
      return '0%';
    return Math.ceil(percentage) + '%';
  }
}
