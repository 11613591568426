<div class="d-flex flex-shrink-0">
    <div class="d-flex align-items-center ms-3">
        <a class="btn btn-primary ps-7" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end">Actions
            <i class="ki-duotone ki-down fs-2 me-0"></i></a>
        <!--begin::Menu-->
        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6"
            data-kt-menu="true">
            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <div class="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">Dashboards</div>
            </div>
            <!--end::Menu item-->
            <ng-container *ngFor="let dashboard of dashboardList">
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                  <a (click)="navigateToDashboard(dashboard.id)" class="menu-link px-5">{{ dashboard.name }}</a>
              </div>
              <!--end::Menu item-->
            </ng-container>

            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <div class="menu-content text-muted pb-2 px-5 fs-7 text-uppercase"></div>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5">
                <button type="button" class="btn btn-light-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_create_dashboard">
                    <i class="ki-duotone ki-add-files fs-3">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                    </i>{{labelConstant.DASHBOARD.ADD_DASHBOARD}}</button>
            </div>
        </div>
    </div>
</div>
