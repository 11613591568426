import { Component, Input } from '@angular/core';
import { KanbanCard } from 'src/app/core/models/kanban.model';

@Component({
  selector: 'app-nyx-record-kanban-view-card',
  templateUrl: './nyx-record-kanban-view-card.component.html',
  styleUrls: ['./nyx-record-kanban-view-card.component.scss']
})
export class NyxRecordKanbanViewCardComponent {

  @Input() card!: KanbanCard;
}
