<ng-container
  *ngIf="urlState.operation == generalConstant.PAGE_MODE.CREATE || urlState.operation == generalConstant.PAGE_MODE.EDIT ">
  <div class="grid" [formGroup]="userForm">
    <div class="col-12 md:col-12 mt-3">
      <div class="card p-fluid">
        <h5>{{labelConstant.LABEL.USER}}</h5>
        <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.NAME}}</label>
          <div class="col-12 md:col-10">
            <input pInputText type="text" id="name" formControlName="name" placeholder="" value="" [ngClass]="{
                          'ng-dirty ng-invalid': userFormControl.name.invalid,
                          'is-valid': userFormControl.name.valid
                        }" />
            <small id="username-help" class="p-error"
              *ngIf="(userFormControl.name.touched || submitted) && userFormControl.name.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
            </small>

          </div>
        </div>

        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.EMAIL}}</label>
          <div class="col-12 md:col-10">
            <input pInputText type="text" id="email" formControlName="email" placeholder="" value="" [ngClass]="{
                            'ng-dirty ng-invalid': userFormControl.email.invalid,
                            'is-valid': userFormControl.email.valid
                          }" [email]="true" />
            <small id="email-help" class="p-error"
              *ngIf="(userFormControl.email.touched || submitted) && userFormControl.email.errors?.required">
              {{generalConstant.REQUIRED_MESSAGE.EMAIL_REQUIRED}}
            </small>
            <small id="email-help" class="p-error"
              *ngIf="(userFormControl.email.touched || submitted) && userFormControl.email.errors?.email">
              {{generalConstant.INVALID_MESSAGE.EMAIL_INVALID}}
            </small>

          </div>
        </div>

        <div class="field grid mt-5">
          <!-- <div class="mt-6 field grid col-12 md:col-2"> -->
            <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.ROLE}}</label>
          <!-- </div> -->
          <div class="col-12 md:col-10">
            <div class="grid formgrid">
              <div class="col-12 md:col-4" *ngFor="let role of roleList">
                <div class="field-checkbox">
                  <p-inputSwitch class="p-2" [inputId]="role.id" [name]="role.id" [formControlName]="role.id"
                    (onChange)="onRoleUpdated($event,role.id)"></p-inputSwitch>
                  <label [htmlFor]="role.id">{{role.name}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <td class="field grid" *ngIf="urlState.operation! == 'create'">
          <div class="col-12 mb-2 md:col-8 md:mb-0"></div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" label="Invite" (click)="submit()"></button>
          </div>
          <div class="col-12 mb-2 md:col-2 md:mb-0">
            <button pButton pRipple type="button" type="reset" label="Cancel" class="p-button-secondary"
              (click)="redirectToUserListingPage()"></button>
          </div>
        </td>

      </div>

    </div>

  </div>
</ng-container>
<ng-container>
