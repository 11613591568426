<div class="col-12" *ngIf="automationList != null">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Automation ({{automationList.totalCount}} records)</div>
      <div class="inline-flex align-items-center">
        <div class="flex flex-wrap gap-2">
          <button pButton pRipple type="button" label="New Automation" (click)="redirectToCreate()"></button>
        </div>
      </div>
    </div>

    <div class="card-body py-4">
      <p-table #dt1 [value]="automationList.record" [loading]="loading" [rowHover]="true"
        styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.NAME}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.OBJECT}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.ENABLED}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.CREATED_AT}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.CREATED_BY}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.ACTION}}
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-automation let-i="rowIndex">
          <tr>
            <td>{{automation.name}}</td>
            <td>{{automation.nyxObject.name}}</td>
            <td>{{automation.enabled}}</td>
            <td>{{automation.createdDatetime | date: generalConstant.TABLE_DATA.DATE }}</td>
            <td>{{automation.createdBy}}</td>
            <td class="flex flex-wrap gap-2">
              <p-confirmPopup></p-confirmPopup>
              <!-- <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text"
                [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/nyx-automation-manager/webhook-log/' + automation.id + '/overview']"></button> -->
              <button pButton pRipple type="button" icon="pi pi-info-circle"
                  class="p-button-rounded p-button-text"
                  [routerLink]="['/' + urlState.organizationName + '/' + urlState.sectionName + '/nyx-automation-manager/log/listing/'+ automation.id]"></button>
              <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                [routerLink]="['/' + urlState.organizationName + '/' + urlState.sectionName +'/nyx-automation-manager/edit/' + automation.id]"></button>
              <button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="onDeleteButtonClick($event, automation.id)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalAutomation"></p-paginator>
    </div>
  </div>
</div>
