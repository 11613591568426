<form [formGroup]="formGroup">
    <p-dropdown
        [formControlName]="controlName"
        placeholder="Select"
        styleClass="col-md-12"
        autoWidth="false"
        [options]="availableFields" 
        optionLabel="label"
        optionValue="value"
        name="selectedField"
        [filter]="true"
        filterBy="index"
        [ngClass]="{'ng-invalid ng-dirty': formGroup.controls[controlName]?.invalid && (formGroup.controls[controlName].touched || formSubmitted), 'is-valid': formGroup.controls[controlName]?.valid}">
    </p-dropdown>
    <small class="p-error" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.required">
        {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
    </small>
</form>