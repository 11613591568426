import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-nyx-date-field',
  templateUrl: './nyx-date-field.component.html',
  styleUrls: ['./nyx-date-field.component.scss']
})
export class NyxDateFieldComponent {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
}
