<form [formGroup]="formGroup">
    <div class="mt-3" *ngIf="(selectedFieldType.fieldTypeName || selectedFieldType.fieldType) == 'dropdown'">
        <div class="field grid">
            <label htmlFor="isRequired" class="col-12 mb-2 md:col-2 md:mb-0">
                Required?
            </label>
            <div class="col-12 md:col-10">
                <p-inputSwitch inputId="isRequired" name="isRequired" formControlName="isRequired"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3" *ngIf="(selectedFieldType.fieldTypeName || selectedFieldType.fieldType) == 'dropdown'">
        <div class="field grid">
            <label htmlFor="workflow" class="col-12 mb-2 md:col-2 md:mb-0">
                Workflow?
            </label>
            <div class="col-12 md:col-10" [ngClass]="{ 'disableInputSwitch': hasWorkflow}">
                <p-inputSwitch inputId="workflow" name="workflow" formControlName="workflow"></p-inputSwitch>
            </div>
        </div>
    </div>

    <div class="mt-3" *ngIf="(selectedFieldType.fieldTypeName || selectedFieldType.fieldType) == 'dropdown'">
        <div class="field grid">
            <label htmlFor="defaultValue" class="col-12 mb-2 md:col-2 md:mb-0">
                Default Value
            </label>
            <div class="col-12 md:col-10">
                <input pInputText id="defaultValue" type="text" formControlName="defaultValue"/>
                <small>
                    This Value must include in the Option_Value
                </small>
            </div>
        </div>
    </div>

    <div class="mt-3" *ngIf="(selectedFieldType.fieldTypeName || selectedFieldType.fieldType) == 'dropdown'">
        <div class="field grid">
            <label htmlFor="options" class="col-12 mb-2 md:col-2 md:mb-0">
                Option Value
            </label>
            <div class="col-12 md:col-10">
                <p-chips formControlName="options"></p-chips>
                <small>
                    Enter to add option value.
                </small>
            </div>
        </div>
    </div>

    <div class="mt-3" *ngIf="(selectedFieldType.fieldTypeName || selectedFieldType.fieldType) == 'checkbox'">
        <div class="field grid">
            <label htmlFor="checkboxValues" class="col-12 mb-2 md:col-2 md:mb-0">
                Checkbox Value
            </label>
            <div class="col-12 md:col-10">
                <p-chips formControlName="checkboxValues"></p-chips>
                <small>
                    Enter to add checkbox value.
                </small>
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />
</form>