<form [formGroup]="formGroup" >
    <div>

        <div class="p-2 border-1 surface-border flex align-items-center justify-content-between hover:surface-100 cursor-pointer border-round" 
        [ngClass]="{'custom-invalid': formGroup.controls[controlName].invalid && (formGroup.controls[controlName].touched || formSubmitted), '': formGroup.controls[controlName].valid}">
            <div class="flex align-items-center">

                <div class="flex flex-column mx-3" *ngIf="componentState.lookupRecord.id != '' && !componentState.isLoadingLookupRecord">
                    <span>({{componentState.lookupObject?.name}}) {{componentState.lookupRecord.name}}</span>
                </div>

                <div class="flex flex-column mx-3">
                    <span *ngIf="componentState.lookupRecord.id == '' && !componentState.isLoadingLookupRecord">
                        {{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}
                    </span>
                </div>
                
            </div>
            <span class="text-600 text-lg font-semibold" *ngIf="!isVersion">
                <button pButton pRipple icon="pi pi-search" class="p-button-text p-button-rounded"
                    (click)="openModal()"></button>
                <button pButton pRipple icon="pi pi-times" class="p-button-danger p-button-text p-button-rounded mr-2"
                    (click)="onDeleteLookupRecord()"></button>
            </span>
        </div>
        <small class="p-error p-2" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.required">This field isrequired </small>
        <small class="p-error p-2" *ngIf="recordNotFound">Record not exist in the Object</small>
        
        <div class="flex-grow-1 me-2" [hidden]="!componentState.isLoadingLookupRecord">
            <span class="translate-middle-y lh-0 me-5" data-kt-search-element="spinner">
                <span class="spinner-border h-15px w-15px align-middle text-muted"></span>
            </span>
            <a href="#" class="fw-bold text-gray-800 text-hover-primary fs-6">{{generalConstant.TABLE_DATA.LOADING}}</a>
        </div>

        <span class="fw-bold text-dark py-1">

            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" data-bs-toggle="modal"
                [attr.data-bs-target]="'#kt_modal_lookup_search' + controlName">
                <i class="ki-duotone ki-pencil fs-2">
                    <span class="path1"></span>
                    <span class="path2"></span>
                </i>
            </a>
        </span>
    </div>

    <input type="hidden" class="form-control form-control-solid" [formControlName]="controlName" value="" />
</form>

<p-dialog header="Create Dashboard" [(visible)]="visible" [breakpoints]="{'960px': '75vw', '640px': '90vw'}"
    [style]="{width: '36rem'}" [draggable]="false" [resizable]="false" [modal]="true" (onHide)="closeModal()"
    [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">Search {{componentState.lookupObject?.name}} records</span>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid">
            <div class="field grid">
                <div class="col-12">
                    <input pInputText type="text" [(ngModel)]="componentState.searchKeyword" value=""
                        placeholder="Search by name or ID" (keyup)="onSearchKeyUp($event)" />

                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="componentState.isSearching"></i>
                </div>

            </div>
        </div>

        <div data-kt-search-element="results" *ngIf="componentState.searchResult.length != 0">

            <div class="flex align-items-center mb-4 font-bold">
                <span class="text-xl text-900 mr-4">Results ({{componentState.searchResult.length}})</span>
            </div>
            <ul class="list-none p-0 m-0">
                <li *ngFor="let result of componentState.searchResult" pRipple
                    class="cursor-pointer flex p-3 mb-3 border-1 surface-border border-round"
                    (click)="selectLookupButtonClick(result.id, result.name)">
                    <div>
                        <span class="font-semibold text-900">{{result.name}}</span>
                        <p class="font-semibold text-600 m-0 text-sm">{{result.id}}</p>
                    </div>
                </li>
            </ul>

        </div>
        <div data-kt-search-element="empty" *ngIf="componentState.searchResult.length == 0">
            <div>
                <span class="font-semibold text-900">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</span>
                <p class="line-height-3 mb-0 my-3">{{generalConstant.TABLE_DATA.SEARCH_BY_ID}}</p>
            </div>
        </div>
    </ng-template>
</p-dialog>