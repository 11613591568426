import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { ActivatedRoute } from '@angular/router';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nyx-checkbox-field',
  templateUrl: './nyx-checkbox-field.component.html',
  styleUrls: ['./nyx-checkbox-field.component.scss']
})
export class NyxCheckboxFieldComponent extends BaseUrlState implements OnDestroy{
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";

  arrayControlName: FormArray;
  cbArr: any[]=[];

  hasCheckboxValue: any[] = [];
  convertedObject: any = {};

  recordDataReady: Subscription;

  constructor(
    public override activatedRoute: ActivatedRoute,
    public recordEvent: RecordEventService
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    this.arrayControlName = this.formGroup.controls[this.controlName] as FormArray;
    // this.cbArr = this.arrayControlName?.value;

    this.eventSubjectSubscription();
  }

  ngOnDestroy(): void {
    this.recordDataReady.unsubscribe();
  }

  eventSubjectSubscription(){
    this.recordDataReady = this.recordEvent.recordDataReadySubject.subscribe((load:any) => {
      if(load && (this.urlState.operation! == 'kanban' || this.urlState.operation! == 'edit')){
        this.nyxFieldSchema.CheckboxValues.forEach((value:any) => {
          if(this.arrayControlName?.value.includes(value)){
            this.hasCheckboxValue.push({
              [value]: true
            });
          }else{
            this.hasCheckboxValue.push({
              [value]: false
            });
          }
        });
    
        this.convertedObject = this.hasCheckboxValue.reduce((acc, obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          return { ...acc, [key]: value };
        }, {});
    
        console.log("Check ArryValue: ", this.convertedObject);
      }
    });
  }

  checkboxValues($event:any,value:any){
    if($event.checked && !this.cbArr.includes(value)){
      this.cbArr.push(value);
    }else{
      this.cbArr = this.cbArr.filter(cb => cb != value);
    }

    this.arrayControlName.patchValue(this.cbArr);

    console.log("Check Event", $event);
    console.log("Check Data - arrayControlName: ", this.arrayControlName.value);
    // console.log("Check Data - OnChange CB: ", this.formGroup.get(this.controlName));
  }
}
