import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GlobalNotificationService, GlobalNotification, GlobalNotificationSeverity, NotificationMessage } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-parameterized-query-detail',
  templateUrl: './parameterized-query-detail.component.html',
  styleUrl: './parameterized-query-detail.component.scss'
})
export class ParameterizedQueryDetailComponent extends BaseUrlState {
  generalConstant = GeneralConstant;
  labelConstant = LabelConstant;

  parameterizedQueryData: any = {};
  parameterizedQueryForm: FormGroup;
  parameterizedQueryResult: any = {};
  submitted = false;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.urlState.operation = this.activatedRoute.snapshot.paramMap.get('operation');
    this.urlState.parameterizedQueryId = this.activatedRoute.snapshot.paramMap.get('parameterizedQueryId');
    this.initForm();

    if (this.urlState.operation == 'edit') {
      // Add loading
      setTimeout(() => {
        this.initParameterizedQuery();
      }, 500);
    }
  }

  get parameterizedQueryFormControl() {
    return this.parameterizedQueryForm.controls;
  }

  initParameterizedQuery() {
    this.nyxAPIService
      .getParameterizedQueryById(this.urlState.organizationName!, this.urlState.parameterizedQueryId!)
      .subscribe((data: any) => {
        this.parameterizedQueryData = data;
        this.setParameterizedQueryIntoForm(data);
      });
  }

  initForm() {
    this.parameterizedQueryForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.maxLength(100)]],
      userQuery: ['', [Validators.required]],
      enabled: [false],
    });
  }

  setParameterizedQueryIntoForm(data: any) {
    console.log(data);

    this.parameterizedQueryForm.patchValue({
      ['userQuery']: data.userQuery,
      ['name']: data.name,
      ['description']: data.description,
      ['enabled']: data.enabled,
    });
  }

  submit() {
    this.submitted = true;
    if (this.parameterizedQueryForm.valid) {
      var newParameterizedQueryPayload = {
        Name: this.parameterizedQueryForm.value.name,
        Description: this.parameterizedQueryForm.value.description,
        Enabled: this.parameterizedQueryForm.value.enabled,
        UserQuery: this.parameterizedQueryForm.value.userQuery,
      };



      if (this.urlState.operation == 'create') {
        this.nyxAPIService
          .createParameterizedQuery(this.urlState.organizationName!, newParameterizedQueryPayload)
          .subscribe((data: any) => {
            this.redirectToParameterizedQueryListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.CREATE_PARAMETERIZED_QUERY_SUCCESS.replace('{{ name }}', data.name)));
          }, (error: any) => {
            this.parameterizedQueryResult = error?.error;
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.CREATE_PARAMETERIZED_QUERY_ERROR);
          });
      } else {
        this.nyxAPIService
          .updateParameterizedQuery(
            this.urlState.organizationName!,
            this.urlState.parameterizedQueryId!,
            newParameterizedQueryPayload
          )
          .subscribe((data: any) => {
            this.redirectToParameterizedQueryListingPage();
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.EDIT_PARAMETERIZED_QUERY_SUCCESS));
          }, (error: any) => {
            this.parameterizedQueryResult = error?.error;
            this.notificationService.displayErrorMessage(null, MessageBoxConstant.PARAMTERIZED_QUERY_MESSAGE.EDIT_PARAMETERIZED_QUERY_ERROR);
          });
      }
    } else {
      console.log(this.parameterizedQueryForm)
    }
  }

  discard() {
    this.parameterizedQueryForm.patchValue({
      ['userQuery']: '',
      ['name']: '',
      ['description']: '',
      ['enabled']: false,
    });

    this.redirectToParameterizedQueryListingPage();

  }

  redirectToParameterizedQueryListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName!}/setup/parameterized-query-manager/overview`,
    ]);
  }
}
