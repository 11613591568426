import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.scss']
})
export class QueryEditorComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  queryValue: string = '';
  isSubmit: boolean = false;
  validatedQueryResult = {
    status: ''
  };
  currentPage: string = 'query_editor';

  constructor(
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  onValidateAndPreviewQueryButtonClick(){
    this.validatedQueryResult.status = '';
    console.log(this.queryValue);
    if(this.queryValue != ''){
      this.isSubmit = true;
      var payload = {
        UserQuery: this.queryValue,
        IsPreview: false
      }
      this.nyxAPIService.validateAndPreviewQuery(this.urlState.organizationName!, payload).subscribe((data:any)=>{
        this.validatedQueryResult = data;
        this.isSubmit = false;
      });
    }
  }
}
