<form [formGroup]="formGroup">
    <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="true" [formControlName]="controlName" value=""
    [ngClass]="{'ng-invalid ng-dirty': formGroup.controls[controlName].invalid && (formGroup.controls[controlName].touched || formSubmitted), 'is-valid': formGroup.controls[controlName].valid}"></p-inputNumber>

    <small class="p-error" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.required">
        This field is required
    </small>
    <small class="p-error" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.max">
        This value's maximun is {{nyxFieldSchema.MaxValue}}
    </small>
    <small class="p-error" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.min">
        This value's minimun is {{nyxFieldSchema.MinValue}}
    </small>
</form>

<!--
    -- Current remove this attribute, because attribute [useGrouping]="false" will crash with these 2 attribute
    [max]="nyxFieldSchema.MaxValue != '' ? nyxFieldSchema.MaxValue : null" 
    [min]="nyxFieldSchema.MinValue != '' ? nyxFieldSchema.MinValue : null" 
-->