<div [formGroup]="formGroup">
    <app-nyx-text-field *ngIf="nyxFieldSchema.FieldType == 'textbox'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-text-field>
    <app-nyx-textarea-field *ngIf="nyxFieldSchema.FieldType == 'textarea'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-textarea-field>
    <app-nyx-email-field *ngIf="nyxFieldSchema.FieldType == 'email'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-email-field>
    <app-nyx-date-field *ngIf="nyxFieldSchema.FieldType == 'date'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-date-field>
    <app-nyx-datetime-field *ngIf="nyxFieldSchema.FieldType == 'datetime'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-datetime-field>
    <app-nyx-number-field *ngIf="nyxFieldSchema.FieldType == 'number'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-number-field>
    <app-nyx-integer-field *ngIf="nyxFieldSchema.FieldType == 'integer'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-integer-field>
    <!-- <app-nyx-checkbox-field *ngIf="nyxFieldSchema.FieldType == 'checkbox'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-checkbox-field> -->
    <app-nyx-radio-field *ngIf="nyxFieldSchema.FieldType == 'radio'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-radio-field>
    <app-nyx-dropdown-field *ngIf="nyxFieldSchema.FieldType == 'dropdown'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-dropdown-field>
    <app-nyx-tag-field *ngIf="nyxFieldSchema.FieldType == 'tags'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-tag-field>
    <app-nyx-lookup-field *ngIf="nyxFieldSchema.FieldType == 'lookup'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema" [isVersion]="isVersion"></app-nyx-lookup-field>
    <app-nyx-formula-field *ngIf="nyxFieldSchema.FieldType == 'formula'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-formula-field>
    <app-nyx-unique-id-field *ngIf="nyxFieldSchema.FieldType == 'uniqueid'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema"></app-nyx-unique-id-field>
    <app-nyx-attachment-field *ngIf="nyxFieldSchema.FieldType == 'attachment'" [formSubmitted]="formSubmitted" [formGroup]="formGroup" [controlName]="controlName" [nyxObject]="nyxObject" [nyxFieldSchema]="nyxFieldSchema" [isVersion]="isVersion"></app-nyx-attachment-field>
</div>


