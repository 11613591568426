import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrls: ['./dashboard-toolbar.component.scss']
})
export class DashboardToolbarComponent implements OnInit {

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }
  dashboardList: any = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nyxAPIService: NyxAPIService
  ) {}

  ngOnInit(): void {
    this.getStateFromUrl();
    this.initDashboardData();
  }

  getStateFromUrl(){
    //Get from current route
    var currentRouteOrganizationParam:any = this.activatedRoute.snapshot.paramMap.get('organization');
    if( currentRouteOrganizationParam == null ){
      //If current route param not available, get from parent
      currentRouteOrganizationParam = this.activatedRoute?.parent?.snapshot.paramMap.get('organization');
    }
    this.state.organizationName = currentRouteOrganizationParam;

    var currentRouteSectionNameParam:any = this.activatedRoute.snapshot.paramMap.get('section');
    if( currentRouteSectionNameParam == null ){
      //If current route param not available, get from parent
      currentRouteSectionNameParam = this.activatedRoute?.parent?.snapshot.paramMap.get('section');
    }

    this.state.sectionName = currentRouteSectionNameParam;
    this.state.objectId = this.activatedRoute.snapshot.paramMap.get('id')!;
    this.state.recordId = this.activatedRoute.snapshot.paramMap.get('recordId')!;
  }

  onAddNewWidgetButtonClick(){
    this.router.navigate([`/${this.state.organizationName}/${this.state.sectionName}/dashboard-manager/create`]);
  }

  initDashboardData(){
    this.nyxAPIService.getDashboardListing(this.state.organizationName).subscribe((result: any) => {
      if(result != null && result != undefined){
        this.dashboardList = result;
        console.log('dashboard: ', result)
      }
    });
  }

  navigateToDashboard(id: any){
    this.router.navigate(['/' + this.state.organizationName + '/' + this.state.sectionName + '/dashboard-manager/' + id + '/overview']);
  }

}
