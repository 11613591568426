import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { PermissionEventService } from 'src/app/core/services/permission.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from '../../core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-role-listing',
  templateUrl: './nyx-role-listing.component.html',
  styleUrls: ['./nyx-role-listing.component.scss']
})
export class NyxRoleListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  roleList: any = [];
  roleId: any = "";

  totalRole:any;

  loading: boolean = true;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
  
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
    this.getNyxRolePagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    this.loading = false;
    //this.subscribeToEditPermission();
    // this.initRoleList();
    // this.initForm();
  }

  // initRoleList() {
  //   this.nyxAPIService
  //     .getNyxRoles(this.urlState.organizationName!)
  //     .subscribe((data: any) => {
  //       this.totalRole = data.length;
  //       this.getNyxRolePagination(this.PageNumber, this.MaxPageSize);
  //       this.loading = false;
  //     });
  // }

  
  getNyxRolePagination(PageNumber: any, PageSize: any) {
    this.nyxAPIService.getNyxRolesWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
      this.roleList = data;
      this.totalRole = this.roleList.totalCount;
      console.log(this.roleList);
    });
  }

  addRoleButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-role-manager/create`]);
  }

  onRoleDeleteButtonClick(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteNyxRole(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.getNyxRolePagination(thisContext.pagedRecord.pageNumber,thisContext.pagedRecord.pageSize);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.ROLE_MESSAGE.DELETE_ROLE_SUCCESS));
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.ROLE_MESSAGE.DELETE_ROLE_ERROR);
    });
  }

  onNyxRoleListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.ROLE_MESSAGE.DELETE_ROLE_CONFIRMATION, this, this.onRoleDeleteButtonClick, id, null)
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalRole > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxRolePagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }
}
