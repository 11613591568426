<div class="col-12">
    <div class="card">
        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
            <div class="text-900 text-xl font-semibold mb-3 md:mb-0">{{labelConstant.PARAMETERIZED_QUERY.TITLE_LABEL}}
                ({{parameterizedQueries.totalCount}} records)</div>
            <div class="inline-flex align-items-center">
                <div class="flex flex-wrap gap-2">
                    <button pButton pRipple type="button" [label]="labelConstant.PARAMETERIZED_QUERY.ADD"
                        (click)="addNewParameterizedQueryButtonClick()"></button>
                </div>
            </div>
        </div>

        <div class="card-body py-4">
            <p-table #dt1 [value]="parameterizedQueries.record" [loading]="loading" [rowHover]="true"
                styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                {{labelConstant.PARAMETERIZED_QUERY.ID_LABEL}}
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                {{labelConstant.PARAMETERIZED_QUERY.NAME_LABEL}}
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                {{labelConstant.PARAMETERIZED_QUERY.DESCRIPTION_LABEL}}
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                {{labelConstant.PARAMETERIZED_QUERY.IS_ENABLED}}
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                {{labelConstant.LABEL.ACTION}}
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-parameterizedQuery let-i="rowIndex">
                    <tr>
                        <td>{{parameterizedQuery.id}}</td>
                        <td>{{parameterizedQuery.name}}</td>
                        <td>{{parameterizedQuery.description}}</td>
                        <td>{{parameterizedQuery.enabled}}</td>
                        <td class="flex flex-wrap gap-2">
                            <p-confirmPopup></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                class="p-button-rounded p-button-text"
                                [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/parameterized-query-manager/edit/' + parameterizedQuery.id]"></button>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="onParameterizedQueriesingDeleteButtonClicked($event, parameterizedQuery.id)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="8">{{generalConstant.TABLE_DATA.LOADING}}</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize"
                [totalRecords]="totalParameterizedQueries"></p-paginator>
        </div>
    </div>
</div>