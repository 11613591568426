import { Component, Input, OnDestroy } from '@angular/core';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { FormGroup } from '@angular/forms';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nyx-record-kanban-view-sidebar',
  templateUrl: './nyx-record-kanban-view-sidebar.component.html',
  styleUrls: ['./nyx-record-kanban-view-sidebar.component.scss']
})
export class NyxRecordKanbanViewSidebarComponent extends BaseUrlState implements OnDestroy {

  labelConstant = LabelConstant;
  @Input() nyxRecordData:any;
  @Input() fieldsLabel:any;
  @Input() datetimeGroup:any;
  @Input() recordId:any;

  workflow: any = {
    isWorkflow: false,
    workflowStages: [],
    activeIndex: ''
  };

  isFormReady = false;
  nyxObject: any = null;
  nyxRecord: any = null;
  schemas: any[] = [];
  schemaKeyValuePair: any = {};
  submitted = false;

  nyxFieldTypes: any = [];
  deleteFileCheck:any = false;

  nyxRecordFormGroup: FormGroup;

  formSubscribe: Subscription;

  sidebarVisible: boolean = false;
  showSidebarSubject: Subscription;

  constructor(
    protected router: Router,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.showSidebarSubject = this.recordEvent.showSidebarSubject.subscribe(show => {
      if(this.urlState.operation! == 'kanban'){
        this.sidebarVisible = show;
      }
    });
  }

  ngOnDestroy(): void {
    this.showSidebarSubject.unsubscribe();
  }
}
