<p-dialog #dd [(visible)]="modalShow" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [style]="{width: '36rem'}"
    [modal]="true" (onHide)="closeModal()" [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">{{labelConstant.OBJECT.OBJECT_LABEL}} </span>
    </ng-template>
    <ng-template pTemplate="content">
        <form novalidate="novalidate" id="kt_modal_create_app_form" [formGroup]="createObjectForm"
            (ngSubmit)="submit()">
            <div class="p-fluid">
                <div class="mt-3 ml-2">
                    <div class="field grid">
                        <label htmlFor="name" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.NAME}}</label>
                        <div class="col-14 md:col-12 mr-2 mt-2">
                            <input pInputText type="text" id="name" formControlName="name" placeholder=""
                                aria-describedby="username-help" value="" [ngClass]="{
                                'ng-dirty ng-invalid': createObjectFormControl.name.invalid,
                                'is-valid': createObjectFormControl.name.valid
                              }" />
                            <small id="username-help" class="p-error"
                                *ngIf="(createObjectFormControl.name.touched || submitted) && createObjectFormControl.name.errors?.required">
                                {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
                            </small>
                            <small id="username-help" class="p-error"
                                *ngIf="createObjectFormControl.name.errors?.pattern">
                                {{generalConstant.REQUIRED_MESSAGE.OBJECT_REQUIRED_MESSAGE}}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="mt-3 ml-2">
                    <div class="field grid">
                        <label htmlFor="description"
                            class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.DESCRIPTION}}</label>
                        <div class="col-14 md:col-12 mr-2 mt-2">
                            <input pInputText #description type="text" id="description" formControlName="description"
                                placeholder="" value="" [ngClass]="{
                                'ng-dirty ng-invalid': createObjectFormControl.description.invalid,
                                'is-valid': createObjectFormControl.description.valid
                              }" />

                            <small id="username-help" class="p-error"
                                *ngIf="(createObjectFormControl.name.touched || submitted) && createObjectFormControl.name.errors?.required">
                                {{generalConstant.REQUIRED_MESSAGE.DESCRIPTION_REQUIRED}}
                            </small>
                        </div>
                    </div>
                </div>


                <div class="mt-3 ml-2">
                    <div class="field grid">
                        <label htmlFor="versioning" class="col-12 mb-2 md:col-2 md:mb-0"
                            onchange="onChangeVersioning()">{{labelConstant.LABEL.VERSIONING}}</label>
                        <div class="col-12 md:col-10">
                            <p-inputSwitch class="p-2" formControlName="versioning" name="versioning"></p-inputSwitch>
                        </div>
                    </div>
                </div>


            </div>
            <div class="p-fluid col-12 align-items-center">
                <button pButton type="submit" label="Submit" class="p-element p-ripple p-button p-component"></button>
            </div>
        </form>
    </ng-template>
</p-dialog>