<div class="card card-flush">
    <!--begin::Card header-->
    <div class="card-header mt-6">
        <!--begin::Card title-->
        <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1 me-5">
            </div>
        </div>
        <!--end::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
            <!--begin::Button-->
            <button type="button" class="btn btn-light-primary" (click)="onAddNewDashboardButtonClick()">
                <i class="ki-duotone ki-plus-square fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                </i>{{labelConstant.DASHBOARD.ADD_DASHBOARD}}</button>
            <!--end::Button-->
        </div>
        <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0" id="kt_dasboard_table">
            <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-125px">{{labelConstant.LABEL.NAME}}</th>
                    <th class="min-w-125px">{{labelConstant.LABEL.DESCRIPTION}}</th>
                    <th class="min-w-125px">{{labelConstant.LABEL.CREATED_BY}}</th>
                    <th class="min-w-125px">{{labelConstant.LABEL.CREATE_DATE}}</th>
                    <th class="text-end min-w-100px">{{labelConstant.LABEL.ACTION}}</th>
                </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
                <!-- <tr *ngFor="let report of reportListing">
                    <td>{{report.name}}</td>
                    <td>
                        {{report.description}}
                    </td>
                    <td>{{report.createdBy}}</td>
                    <td>{{report.createdDatetime}}</td>
                    <td class="text-end">
                        <button class="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                            [routerLink]="['/' + state.organizationName + '/'+ state.sectionName +'/dashboard-manager/report/view/' + report.id]">
                            <i class="ki-duotone ki-chart-simple fs-2x">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                            </i>
                        </button>
                        <button class="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                            [routerLink]="['/' + state.organizationName + '/'+ state.sectionName +'/dashboard-manager/report/edit/' + report.id]">
                            <i class="ki-duotone ki-pencil fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </button>
                        <button class="btn btn-icon btn-active-light-primary w-30px h-30px"
                            (click)="deleteReport(report.id)">
                            <i class="ki-duotone ki-trash fs-3">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </button>
                    </td>
                </tr> -->
            </tbody>
        </table>
        <!--end::Table-->
    </div>
    <!--end::Card body-->
</div>