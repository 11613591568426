import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
    constructor(private http: HttpClient) {}

    getContentById(id:any){
        return this.http.get(`${environment.CMS_HOST}/umbraco/delivery/api/v1/content/item/${id}?Api-Key=${environment.CMS_API_KEY}`);
    }

    getChildrenContentById(id:any, maxRecord: number){
        return this.http.get(`${environment.CMS_HOST}/umbraco/delivery/api/v1/content/?Api-Key=${environment.CMS_API_KEY}&fetch=children:${id}&expand=all&sort=createDate:asc&skip=0&take=${maxRecord}`);
    }

    getDescendantsById(id:any){
        return this.http.get(`${environment.CMS_HOST}/umbraco/delivery/api/v1/content/?Api-Key=${environment.CMS_API_KEY}&fetch=descendants:${id}&sort=createDate:asc`);
    }
}
