export const environment = {
  appVersion: 'v0.0.1',
  appEnv: 'development',

  CMS_HOST: "https://cms.asteriskdigital.com.my",
  CMS_API_KEY: "AC4VQMHvKUdHeCK",
  AZURE_APIM_HOST: 'https://dev-api.nyxhub.co/core',
  AZURE_APIM_HOST2: 'https://localhost:50656',
  APZURE_APIM_KEY: '7196e124da6240cfabba6fdc2b301cdb',
  PORTAL_NYXHUB_HOST: "https://dev-portal.nyxhub.co",
  STRIPE_KEY: 'pk_test_51NpjtdISdo95pdiJspsMtLfHhiALUWR7fiF6vOGDchYkdOJKLk0P3ZGQUwjebXemCFN5u9bgI8GAiM9afEYPgcmx00RYnCmD2s',
  msalConfig: {
    auth: {
      clientId: 'fd96e626-0aa7-439d-bd1e-3ffd1fae1d07',
      authority: 'https://nyxhub.b2clogin.com/nyxhub.onmicrosoft.com/B2C_1_SignIn_Flow',
      authorityDomain: 'nyxhub.b2clogin.com'

    },
  },
  apiConfig: {
    scopes: [],
    uri: 'nyxapi/api',
  },
};
