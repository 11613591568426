import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { DatePipe } from '@angular/common';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nyx-record-detail',
  templateUrl: './nyx-record-detail.component.html',
  styleUrls: ['./nyx-record-detail.component.scss']
})
export class NyxRecordDetailComponent extends BaseUrlState implements OnDestroy {
  @Output() passNavigationInfo = new EventEmitter<any>();
  @Input() originPage:any;

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  workflow: any = {
    isWorkflow: false,
    workflowStages: [],
    activeIndex: ''
  };

  isFormReady = false;
  nyxObject: any = null;
  nyxRecord: any = null;
  schemas: any[] = [];
  schemaKeyValuePair: any = {};
  submitted = false;

  nyxFieldTypes: any = [];
  deleteFileCheck: any = false;

  nyxRecordFormGroup: FormGroup;
  tagsFormGroup: FormGroup;
  isVersion: boolean = false;

  // Use for unsubscription
  viewRecord: Subscription;
  recordVersions: Subscription;
  recordDeleteAttachment: Subscription;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private notificationService: GlobalNotificationService,
    private datePipe: DatePipe,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // This is to handle when navigate from parent record to child related record
    this.activatedRoute.params.subscribe((params: any) => {
      super.ngOnInit();

      this.updateLatestUrlState(params);
      this.eventSubjectSubscription();
      this.initConfiguration();
    });
  }

  ngOnDestroy(): void {
    this.viewRecord.unsubscribe();
    this.recordVersions.unsubscribe();
    this.recordDeleteAttachment.unsubscribe();
  }

  updateLatestUrlState(params:any){
    this.urlState.objectId! = params.objectId;
    this.urlState.recordId! = params.recordId;
    this.urlState.operation! = params.operation;
  }

  eventSubjectSubscription(){
    this.viewRecord = this.recordEvent.viewRecordSubject.subscribe((id: any) => {
      if(!this.IsNullOrEmpty(id) && this.urlState.operation! == 'kanban'){
        this.urlState.recordId! = id;
      }
    });

    this.recordVersions = this.recordEvent.recordVersionsSubject.subscribe((res: any) => {
      if(this.urlState.operation! == 'edit' && res.operation == 'versions' && res.data != null){
        this.isVersion = true;
        this.nyxRecord = res.data;
      }else if(this.urlState.operation! == 'edit' && res.operation == 'versionsHide' && res.data == null){
        this.isVersion = false;
        this.getNyxObject();
      }
    });

    this.recordDeleteAttachment = this.recordEvent.recordDeleteAttachmentSubject.subscribe((response:any) => {
      if(response.filename.length > 0 && response.operation == this.urlState.operation!){
        this.deletefile(response.filename);
      }
    });
  }

  async initConfiguration() {
    await this.nyxAPIService
      .getNyxConfigurationFieldTypes(this.urlState.organizationName!)
      .subscribe((data: any) => {
        this.nyxFieldTypes = data.fields;
        this.getNyxObject();
      });
  }

  async initNyxFormGroup() {
    const group: any = {};
    group["recordName"] = this.isVersion ? new FormControl({value: "",disabled: true}, Validators.required) : new FormControl("", Validators.required);

    await this.schemas.forEach((schema) => {

      let defaultValue: any =  !this.IsNullOrEmpty(schema[1].DefaultValue) ? schema[1].DefaultValue : "";
      defaultValue = this.isVersion ? {value: defaultValue, disabled: true} : defaultValue;

      if (schema[1].FieldType != 'formula') {
        if (schema[1].FieldType == 'integer' || schema[1].FieldType == 'number') {
          group[schema[0]] = schema[1].IsRequired
            ? new FormControl(defaultValue, [Validators.required, Validators.max(schema[1].MaxValue), Validators.min(schema[1].MinValue)])
            : new FormControl(defaultValue, [Validators.max(schema[1].MaxValue), Validators.min(schema[1].MinValue)]);
        } else if ((schema[1].FieldType == 'textbox' || schema[1].FieldType == 'textarea') && schema[1].RegexRestriction != "") {
          const regex = new RegExp(`^${schema[1].RegexRestriction}$`);
          group[schema[0]] = schema[1].IsRequired
            ? new FormControl(defaultValue, [Validators.required, Validators.pattern(regex)])
            : new FormControl(defaultValue, [Validators.pattern(regex)]);
        } else if ((schema[1].FieldType == 'datetime' || schema[1].FieldType == 'date')) {
          let value = defaultValue == null || defaultValue == "" ? "" : new Date(defaultValue);
          let finalValue = this.isVersion ? {value: value, disabled: true} : value;
          group[schema[0]] = schema[1].IsRequired ? new FormControl(finalValue, Validators.required) : new FormControl(finalValue);
        } else if (schema[1].FieldType == 'attachment') {
          // Attahcment field is not Required
          group[schema[0]] = new FormControl([]);
          group[schema[0] + "_blobs"] = new FormControl([]);
        } else {
          group[schema[0]] = schema[1].IsRequired ? new FormControl(defaultValue, Validators.required) : new FormControl(defaultValue);
        }

      } else {
        group[schema[0]] = new FormControl({ value: '', disabled: true });
      }

      if (schema[1].FieldType == 'dropdown') {
        this.workflow.isWorkflow = schema[1].Workflow;

        if (this.workflow.isWorkflow) {
          let i = 0;
          this.workflow.workflowStages = [];
          schema[1].Options.forEach((option: any) => {
            this.workflow.workflowStages?.push({
              index: i,
              label: option,
            });
            i++;
          });

          this.workflow.activeIndex = this.workflow.workflowStages.find((x: any) => x.label == schema[1].DefaultValue)?.index;
        }
      }
    });

    this.nyxRecordFormGroup = this.fb.group(group);
  }

  async getNyxObject() {
    await this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxObject = data;

      // onDeleteAttachment to hide sidebar (KanabnView)
      if(this.deleteFileCheck && this.originPage === 'kanban') this.recordEvent.showSidebarEvent(false);

      //Page Info
      const pageInfo = {
        name: this.nyxObject.name,
        versioning: this.nyxObject.versioning,
        pageMode: this.urlState.operation
      }

      this.passNavigationInfo.emit(pageInfo);

      let schema = this.nyxObject.schema;

      //Convert into key value pair
      for (var i = 0; i < schema.length; i++) {
        this.schemaKeyValuePair[this.nyxObject.schema[i].FieldAPIName] = this.nyxObject.schema[i];
      }

      //Convert key value pair into array
      //[ [key, schemaObject], [key, schemaObject],... ]
      this.schemas = Object.entries(this.schemaKeyValuePair);

      this.initNyxFormGroup();
      if (this.urlState.operation! != 'create') {
        this.deleteFileCheck = false;
        this.getNyxRecord();
      }
    });
  }

  getNyxRecord() {
    this.nyxAPIService.getNyxRecord(this.urlState.organizationName!, this.urlState.recordId).subscribe((data: any) => {
      if(data != null){
        this.nyxRecord = this.isVersion ? this.nyxRecord : data;
      }
      this.setNyxRecordIntoForm();
    });
  }

  setNyxRecordIntoForm() {
    if(this.nyxRecord != null){
      this.nyxRecordFormGroup.patchValue({
        ['recordName']: this.nyxRecord.name,
      });

      this.schemas.forEach((schema: any) => {

        if (schema[1].FieldType == 'datetime') {
          // Check Datetime hasString value then convert to Date format, if noString Value convert to empty string
          let valueDatetime = this.nyxRecord.data[schema[0]];
          let convertDatetime = valueDatetime != null && valueDatetime != undefined ? new Date(this.nyxRecord.data[schema[0]]) : '';

          this.nyxRecordFormGroup.patchValue({
            [schema[0]]: convertDatetime,
          });
        } else if (schema[1].FieldType == 'date') {
          // Check Date hasString value then convert to Date format, if noString Value convert to empty string
          let valueDate = this.nyxRecord.data[schema[0]];
          let convertDate = valueDate != null && valueDate != undefined ? new Date(valueDate) : "";

          this.nyxRecordFormGroup.patchValue({
            [schema[0]]: convertDate,
          });
        } else if (schema[1].FieldType == 'tags'){
          this.nyxRecordFormGroup.patchValue({
            [schema[0]]: !this.IsNullOrEmpty(this.nyxRecord.data[schema[0]]) ? this.nyxRecord.data[schema[0]].split(';').filter(Boolean) : []
          });
        } else {
          this.nyxRecordFormGroup.patchValue({
            [schema[0]]: !this.IsNullOrEmpty(this.nyxRecord.data[schema[0]]) ? this.nyxRecord.data[schema[0]] : schema[1].DefaultValue,
          });
        }

        //in PageMode-Edit need this code to set the StepChecked
        if (schema[1].FieldType == 'dropdown') {
          let optionValue = this.nyxRecord.data[schema[1].FieldAPIName];
          this.workflow.activeIndex = this.workflow.workflowStages.find((x: any) => x.label == optionValue)?.index;
        }
      });

      this.recordEvent.onLoadRecordReadyEvent(true);
    }
  }

  get nyxRecordFormControls() {
    return this.nyxRecordFormGroup.controls;
  }

  private IsNullOrEmpty(value: any){
    if(value == null || value == undefined || value == ""){
      return true;
    }else{
      return false;
    }
  }

  submit(isDeleteAttachment: boolean) {
    this.submitted = true;
    let hasFileUpload = false;
    // Temperary bypass for the Delete File Method
    if (this.nyxRecordFormGroup != undefined && this.nyxRecordFormGroup.valid) {
      var nyxRecordPayload: any = {
        Name: this.nyxRecordFormGroup.controls["recordName"].value,
        Data: {},
        IsDeleteAttachment: isDeleteAttachment
      };

      var filePayload: any[] = [];

      this.schemas.forEach((schema) => {

        if (schema[1].FieldType == 'datetime') {
          nyxRecordPayload.Data[schema[0]] = this.datePipe.transform(
            this.nyxRecordFormGroup.controls[schema[0]].value,
            'yyyy-MM-ddTHH:mm:ss',
            'UTC+8') || '' ; // +Z get UTC time, add here to aplly for create & edit
        } else if (schema[1].FieldType == 'date') {
          nyxRecordPayload.Data[schema[0]] = this.datePipe.transform(
            this.nyxRecordFormGroup.controls[schema[0]].value,
            'yyyy-MM-dd') || '';
        } else if (schema[1].FieldType == 'attachment') {
          if (this.deleteFileCheck) {
            nyxRecordPayload.Data[schema[0]] = this.nyxRecordFormGroup.controls[schema[0]].value.length > 0 ? this.nyxRecordFormGroup.controls[schema[0]].value : [];
          }

          if(this.nyxRecordFormGroup.controls[schema[0] + "_blobs"].value.length > 0 && !hasFileUpload){
            hasFileUpload = true;
          }
          // Specify the Filename in customData & BlobFile to upload
          let setArray:any[] = [schema[1].FieldAPIName, this.nyxRecordFormGroup.controls[schema[0] + "_blobs"].value];
          filePayload.push(setArray);
        } else if(schema[1].FieldType == 'integer'){
          nyxRecordPayload.Data[schema[0]] = !this.IsNullOrEmpty(this.nyxRecordFormGroup.controls[schema[0]].value) ? this.nyxRecordFormGroup.controls[schema[0]].value : "0";
        } else if(schema[1].FieldType == 'number'){
          nyxRecordPayload.Data[schema[0]] = !this.IsNullOrEmpty(this.nyxRecordFormGroup.controls[schema[0]].value) ? this.nyxRecordFormGroup.controls[schema[0]].value : "0.0";
        }else if (schema[1].FieldType == 'tags'){
          let value = this.nyxRecordFormGroup.controls[schema[0]].value.length > 0 ? this.nyxRecordFormGroup.controls[schema[0]].value.join(';') + ';' : "";
          nyxRecordPayload.Data[schema[0]] = value;
        } else {
          nyxRecordPayload.Data[schema[0]] = !this.IsNullOrEmpty(this.nyxRecordFormGroup.controls[schema[0]].value) ? this.nyxRecordFormGroup.controls[schema[0]].value : "";
        }

      });

      if (hasFileUpload && !this.deleteFileCheck) {
        const uploadPromises = [];

        for(let i=0; i < filePayload.length; i++){
          let uploadNyxFileService = this.nyxAPIService.uploadNyxFile(this.urlState.organizationName!, this.urlState.objectId!, filePayload[i][1]);
          //Done: Need to handle multiple files / multiple attachment fields
          const promise = new Promise((resolve) => {
            uploadNyxFileService.subscribe((data: any) => {
              if (data.length > 0) {
                // Append attachment to the payload before submit
                let attachmentArray: any[] = [];

                for(let j=0;j<data.length;j++){
                  let lastSlashIndex = data[j].blob.name.lastIndexOf("/");
                  let filename = data[j].blob.name.substring(lastSlashIndex + 1);

                  if (this.nyxRecordFormGroup.controls[filePayload[i][0]]?.value.length > 0) {
                    attachmentArray = this.nyxRecordFormGroup.controls[filePayload[i][0]]?.value;
                    attachmentArray.push(filename);
                  } else {
                    attachmentArray.push(filename);
                  }
                }
                nyxRecordPayload.Data[filePayload[i][0]] = attachmentArray;
              }
              resolve(nyxRecordPayload);
            });
          });
          uploadPromises.push(promise);
        }

        Promise.all(uploadPromises).then(() => {
          this.submitRecord(nyxRecordPayload);
        });
      } else {
        // Submit without attachment (When attachment field is not available) / Delete Attachment
        this.submitRecord(nyxRecordPayload);
      }
    }
  }

  submitRecord(nyxRecordPayload: any) {

    var nyxRecordPromise = this.urlState.operation == 'create' ?
      this.nyxAPIService.createNyxRecord(this.urlState.organizationName!, this.urlState.objectId, nyxRecordPayload) :
      this.nyxAPIService.updateNyxRecord(this.urlState.organizationName!, this.urlState.objectId, this.urlState.recordId, nyxRecordPayload);


    if (this.urlState.operation == 'create') {
      nyxRecordPromise
        .subscribe((data: any) => {
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_SUCCESS.replace('{{ name }}', data.name)));
          // Create record will direct navigate to Listing page
          this.navigateToNyxRecordListingPage(data.id);
        }, (error: any) => {
          console.log(error);
          console.log(error?.errorMessage);
          if (error?.errorMessage != null && error?.errorMessage != undefined) {
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR, NotificationMessage.GENERAL.ERROR, error?.errorMessage));
          } else {
            console.log("Check error: ", error?.error);
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.CREATE_RECORD_ERROR);
          }
        });
    } else {
      nyxRecordPromise.subscribe((data: any) => {

        let message = this.deleteFileCheck ? MessageBoxConstant.RECORD_MESSAGE.DELETE_FILE_SUCCESS : MessageBoxConstant.RECORD_MESSAGE.EDIT_RECORD_SUCCESS;
        this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, message));
        // Edit record will check delete Attachment then refresh or navigate
        this.deleteFileCheck && this.urlState.operation == 'edit' ? this.getNyxObject() : this.navigateToNyxRecordListingPage(data.id);
      }, (error: any) => {
        console.log(error);
        console.log(error.error.errorMessage);
        if (error.error.errorMessage != null && error.error.errorMessage != undefined) {
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.ERROR, NotificationMessage.GENERAL.ERROR, error.error.errorMessage));
        } else {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.EDIT_RECORD_ERROR);
        }
      });
    }
  }

  deletefile(filename: any) {
    this.schemas.forEach((schema) => {
      if (schema[1].FieldType == 'attachment' && this.urlState.operation != 'create') {
        let attachmentField = this.nyxRecordFormGroup.controls[schema[0]].value;
        if(attachmentField.includes(filename)){
          const filter = this.nyxRecordFormGroup.controls[schema[0]].value.filter((file: any) => file != filename);
          if(filter.length >= 0){
            this.nyxRecordFormGroup.controls[schema[0]].setValue(filter);
            this.nyxAPIService.deleteFile(this.urlState.organizationName!, this.urlState.objectId!, filename).subscribe();
            this.deleteFileCheck = true;
          }else{
            //To-DO: error message handling
            console.log("Fail to delete");
          }
        }
      }
    });

    if(this.deleteFileCheck){
      this.submit(true);
    }
  }

  // for cancel button
  resetFormInput() {
    if (this.urlState.operation == 'kanban') {
      this.recordEvent.showSidebarEvent(false);
      this.getNyxRecord();
    }

    let route = this.urlState.operation == 'kanban' ?
    `${this.urlState.organizationName!}/app/nyx-record/${this.urlState.objectId}/kanban` :
    `${this.urlState.organizationName!}/app/nyx-record/${this.urlState.objectId}/overview`;

    this.router.navigate([route]);

    //Reset recordVersion params
    this.recordEvent.recordVersionsEvent({operation:'',data:null});
  }

  navigateToNyxRecordListingPage(id: any) {
    if (this.urlState.operation == 'kanban') {
      this.recordEvent.showSidebarEvent(false);
      this.getNyxObject();
    }

    let route = this.urlState.operation == 'kanban' ?
    `${this.urlState.organizationName!}/app/nyx-record/${this.urlState.objectId}/kanban` :
    `${this.urlState.organizationName!}/app/nyx-record/${this.urlState.objectId}/overview`;

    this.router.navigate([route]);

    //Reset recordVersion params
    this.recordEvent.recordVersionsEvent({operation:'',data:null});
  }
}
