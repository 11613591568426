<div *ngFor="let key of groupedByKeyList" class="mb-3">
    <p-panel header="{{key}}" [toggleable]="true">


        <ng-container *ngIf="groupedByRelatedRecord[key].relatedRecordList.length > 0">
            <!-- testing -->
            <!-- <div class="custom-button-container ">
                <p-menu #viewMenu [popup]="true" [model]="viewMenuItems"></p-menu>
                <button type="button" pButton icon="pi pi-chevron-down" label="View" (click)="viewMenu.toggle($event)"
                    style="width:auto"></button>
            </div> -->

            <div class="custom-header">
                <div class="custom-button-container">
                    <p-menu #viewMenu [popup]="true" [model]="groupedByViewMenuItems[key]"></p-menu>
                    <button type="button" pButton icon="pi pi-chevron-down" label="View"
                        (click)="viewMenu.toggle($event)" style="width:auto"></button>
                </div>
            </div>

            <p-table [value]="groupedByRelatedRecord[key].relatedRecordList" dataKey="id" [rows]="10"
                [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="true"
                [globalFilterFields]="['name','country.name','representative.name','status']" responsiveLayout="scroll">
                <!-- <ng-template pTemplate="header">
                    <tr>
                        <th>Recod Id</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </ng-template> -->

                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 12rem" *ngFor="let field of groupedByCurrentFields[key]">
                            <div class="flex justify-content-between align-items-center">
                                {{field.Label}}
                            </div>
                        </th>
                        <th style="min-width: 12rem">
                            <div class="flex justify-content-between align-items-center">
                                Action
                            </div>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-record>
                    <tr>
                        <!-- <td>{{record.id}}</td>
                        <td>{{record.name}}</td> -->

                        <td *ngFor="let field of groupedByCurrentFields[key]">
                            {{
                                (datetimeGroup[field.FieldAPIName] ? (record[field.FieldAPIName] + 'Z' | date: generalConstant.TABLE_DATA.DATE) : record[field.FieldAPIName]) ||
                                (datetimeGroup[field.FieldAPIName] ? (record.data[field.FieldAPIName] + 'Z' | date: generalConstant.TABLE_DATA.DATE) : record.data[field.FieldAPIName])
                            }}
                        </td>
                        <td class="text-right">
                            <button pButton pRipple icon="pi pi-search" class="p-button-text p-button-rounded"
                                (click)="onViewRecordButtonClicked(groupedByRelatedRecord[key].objectMetadata.id, record.id)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
        <ng-container *ngIf="groupedByRelatedRecord[key].relatedRecordList.length == 0">
            <p>No records</p>
        </ng-container>
    </p-panel>
</div>