import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NewFieldEventService {

   public newFieldSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   public createNewField(): void { 
      this.newFieldSubject.next(true);
   }
}

@Injectable()
export class NewLayoutSectionEventService {

   public newLayoutSectionSubject: BehaviorSubject<NewLayoutSectionEventArg> = new BehaviorSubject<NewLayoutSectionEventArg>(new NewLayoutSectionEventArg );

   public createNewLayoutSectionField(sectionArg:NewLayoutSectionEventArg): void { 
      this.newLayoutSectionSubject.next(sectionArg);
   }
}

export class NewLayoutSectionEventArg{
   SectionName:string;
   ColumnType:string
}