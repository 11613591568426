import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NewFieldEventService } from 'src/app/core/services/event.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { PermissionEventService } from 'src/app/core/services/permission.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-modal-nyx-permission-management',
  templateUrl: './modal-nyx-permission-management.component.html',
  styleUrls: ['./modal-nyx-permission-management.component.scss'],
})
export class ModalNyxPermissionManagementComponent {
  @ViewChild('modalPermissionCloseButton', { static: false })
  modalPermissionCloseButton: ElementRef;

  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  objectList: any = [];
  permissionId:any = "";

  permissionForm: FormGroup;

  submitted = false;
  operation = 'create';

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private activatedRoute: ActivatedRoute,
    private permissionEventService: PermissionEventService
  ) {}

  ngOnInit(): void {
    this.subscribeToEditPermission();
    this.getStateFromUrl();
    this.initConfiguration();
    this.initForm();
  }

  getStateFromUrl() {
    //Get from current route
    var currentRouteOrganizationParam: any =
      this.activatedRoute.snapshot.paramMap.get('organization');
    if (currentRouteOrganizationParam == null) {
      //If current route param not available, get from parent
      currentRouteOrganizationParam =
        this.activatedRoute?.parent?.snapshot.paramMap.get('organization');
    }
    this.state.organizationName = currentRouteOrganizationParam;
  }

  get permissionFormControl() {
    return this.permissionForm.controls;
  }

  initConfiguration() {
    this.nyxAPIService
      .getNyxObjects(this.state.organizationName)
      .subscribe((data: any) => {
        this.objectList = data;
      });
  }

  initForm() {
    this.permissionForm = this.fb.group({
      objectId: ['', [Validators.required, Validators.maxLength(100)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      create: [false],
      read: [false],
      update: [false],
      delete: [false],
      sensitiveRead: [false],
    });
  }

  submit() {
    this.submitted = true;
    if (this.permissionForm.valid) {
      var permissionPayload = {
        ObjectId: this.permissionForm.value.objectId,
        Name: this.permissionForm.value.name,
        Create: this.permissionForm.value.create,
        Read: this.permissionForm.value.read,
        Update: this.permissionForm.value.update,
        Delete: this.permissionForm.value.delete,
        SensitiveRead: this.permissionForm.value.sensitiveRead,
      };

      if (this.operation == 'create') {
        this.nyxAPIService
          .createNyxPermissions(this.state.organizationName, permissionPayload)
          .subscribe((data: any) => {
            this.closeModal();
            this.notifyNewPermissionEventService();
            this.initForm();
          });
      } else {
        this.nyxAPIService
          .updateNyxPermissions(this.state.organizationName, this.permissionId, permissionPayload)
          .subscribe((data: any) => {
            this.operation = 'create';
            this.closeModal();
            this.notifyNewPermissionEventService();
            this.initForm();
          });
      }
    }
  }

  closeModal() {
    this.modalPermissionCloseButton.nativeElement.click();
  }

  notifyNewPermissionEventService() {
    this.permissionEventService.createNewPermissionEvent();
  }

  subscribeToEditPermission() {
    this.permissionEventService.editPermissionSubject.subscribe(
      (id: string) => {
        if (id != '') {
          this.permissionId = id;
          this.operation = 'edit';
          this.getPermissionById(id);
        }
      }
    );
  }

  getPermissionById(id: string) {
    this.nyxAPIService
      .getNyxPermission(this.state.organizationName, id)
      .subscribe((data: any) => {
        this.setNyxPermissionIntoForm(data);
      });
  }

  setNyxPermissionIntoForm(data: any) {
    this.permissionForm.patchValue({
      ['objectId']: data.nyxObjectId,
      ['name']: data.name,
      ['create']: data.create,
      ['read']: data.read,
      ['update']: data.update,
      ['delete']: data.delete,
      ['sensitiveRead']: data.sensitiveRead,
    });
  }
}
