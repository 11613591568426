<form [formGroup]="formGroup">
    <input pInputText type="text" [formControlName]="controlName" value=""
    [ngClass]="{'ng-invalid ng-dirty': formGroup.controls[controlName].invalid && (formGroup.controls[controlName].touched || formSubmitted), 'is-valid': formGroup.controls[controlName].valid}"/>
    <small class="p-error" *ngIf="(formGroup.controls[controlName].touched || formSubmitted) && formGroup.controls[controlName].errors?.required">
        {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
    </small>
    <small class="p-error" *ngIf="formGroup.controls[controlName].errors?.pattern">
        {{generalConstant.INVALID_MESSAGE.FIELD_RESTRICT}}
    </small>
</form>
