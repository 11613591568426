import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-unique-id-configuration',
  templateUrl: './unique-id-configuration.component.html',
  styleUrls: ['./unique-id-configuration.component.scss']
})
export class UniqueIdConfigurationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = "";
  @Input() submitted:boolean = false;

  paddingCount: string = "";
  displayNum: string = "";
  defaultNum: string = "1";

  ngOnInit(): void {
    //To set value for the field from Form Group Control
    this.paddingCount = this.fieldFormControl.seqNumberPadding.value;
    this.inputText();
  }

  get fieldFormControl() {
    return this.formGroup.controls;
  }

  inputText(){
    this.paddingCount = this.defaultNum.padStart(this.paddingCount.length, '0').replace(/./g, '0');
    this.displayNum = this.defaultNum.padStart(this.paddingCount.length, '0');
  }
}
