import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface RecordData {
   operation: string;
   data: any;
}

interface DeleteAttachment {
   filename: string;
   operation: string;
}

@Injectable()
export class RecordEventService {

   public viewRecordSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
   public recordVersionsSubject: BehaviorSubject<RecordData> = new BehaviorSubject<RecordData>({
      operation: "",
      data: null
   });
   public recordDeleteAttachmentSubject: BehaviorSubject<DeleteAttachment> = new BehaviorSubject<DeleteAttachment>({
      filename: "",
      operation: ""
   });
   public showSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public showSidebarVersioningSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public recordDataReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public recordDataSetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public showImportRecordDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public showImportObjectDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public resetObjectListing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   public viewRecordIdEvent(id: string){
      this.viewRecordSubject.next(id);
   }

   public recordVersionsEvent(event: RecordData){
      this.recordVersionsSubject.next(event);
   }

   public recordDeleteAttachmentEvent(attachment: DeleteAttachment){
      this.recordDeleteAttachmentSubject.next(attachment);
   }

   public showSidebarEvent(show:boolean): void{
      this.showSidebarSubject.next(show);
   }

   public showSidebarVersioningEvent(show:boolean): void{
      this.showSidebarVersioningSubject.next(show);
   }

   public onLoadRecordReadyEvent(load:boolean): void{
      this.recordDataReadySubject.next(load);
   }

   public showImportRecordDialogEvent(show:boolean): void{
      this.showImportRecordDialog.next(show);
   }

   public showImportObjectDialogEvent(show:boolean): void{
      this.showImportObjectDialog.next(show);
   }

   public resetObjectListingEvent(set:boolean): void{
      this.resetObjectListing.next(set);
   }
}
