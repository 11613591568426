<ng-container>
  <div class="mt-6" [formGroup]="billingDetailsForm">
    <span class="text-900 text-xl font-bold mb-4 block">Billing Details</span>
    <div class="grid">
        <div class="col-12 lg:col-2">
            <!-- <div class="text-900 font-medium text-xl mb-3">Profile</div> -->
            <p class="m-0 p-0 text-600 line-height-3 mr-3">Update current billing details</p>
        </div>
        <div class="col-12 lg:col-10">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 md:col-6">
                    <label for="addressLine1" class="font-medium text-900">Address line 1</label>
                    <input id="addressLine1" type="text" pInputText formControlName="addressLine1">
                    <small id="user-help" class="p-error" *ngIf="(billingDetailsFormControl.addressLine1.touched || settingSubmitted) && billingDetailsFormControl.addressLine1.errors?.required">
                      {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                    </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="addressLine2" class="font-medium text-900">Address line 2</label>
                    <input id="addressLine2" type="text" pInputText formControlName="addressLine2">
                    <!-- <small id="username-help" class="p-error" *ngIf="(billingDetailsFormControl.addressLine2.touched || settingSubmitted) && billingDetailsFormControl.addressLine2.errors?.required">
                      {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                    </small> -->
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="city" class="font-medium text-900">City</label>
                  <input id="city" type="text" pInputText formControlName="city">
                  <small id="username-help" class="p-error" *ngIf="(billingDetailsFormControl.city.touched || settingSubmitted) && billingDetailsFormControl.city.errors?.required">
                    {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                  </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="postcode" class="font-medium text-900">Post code</label>
                  <input id="postcode" type="text" pInputText formControlName="postcode">
                  <small id="username-help" class="p-error" *ngIf="(billingDetailsFormControl.postcode.touched || settingSubmitted) && billingDetailsFormControl.postcode.errors?.required">
                    {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                  </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="state" class="font-medium text-900">State</label>
                  <input id="state" type="text" pInputText formControlName="state">
                  <small id="username-help" class="p-error" *ngIf="(billingDetailsFormControl.state.touched || settingSubmitted) && billingDetailsFormControl.state.errors?.required">
                    {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                  </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="country" class="font-medium text-900">Country</label>
                  <p-dropdown
                  formControlName="country"
                  placeholder="Select your country"
                  styleClass="w-full"
                  autoWidth="false"
                  [options]="countryList"
                  name="selectedField"
                  filterBy="index"></p-dropdown>
                  <small id="username-help" class="p-error" *ngIf="(billingDetailsFormControl.country.touched || settingSubmitted) && billingDetailsFormControl.country.errors?.required">
                    {{generalConstant.REQUIRED_MESSAGE.FIELD_REQUIRED}}
                  </small>
                </div>

                <div class="col-12 lg:col-9 flex flex-column">
                </div>
                <div class="col-12 lg:col-3 flex flex-column">
                  <button pButton pRipple label="Update Billing Details" class="w-auto mt-3" (click)="submitSetting()"></button>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="mt-6" [formGroup]="paymentDetails">
    <span class="text-900 text-xl font-bold mb-4 block">Payment Details</span>
    <div class="grid">
        <div class="col-12 lg:col-2">
            <p class="m-0 p-0 text-600 line-height-3 mr-3">Update current payment details</p>
        </div>
        <div class="col-12 lg:col-10">
            <div class="grid formgrid p-fluid">
                <div class="col-12 lg:col-9 flex flex-column">
                </div>
                <div class="col-12 lg:col-3 flex flex-column">
                  <button pButton pRipple label="Update Payment Details" class="w-auto mt-3" (click)="updatePaymentMethod()"></button>
                </div>
            </div>
        </div>
        <div class="col-12">
          <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
        </div>
    </div>
  </div>

  <!-- <div class="mt-6" [formGroup]="paymentDetails">
        <div class="col-12 lg:col-10 flex flex-column mt-5 md:mt-0">
          <div class="col-12 lg:col-9 flex flex-column mt-5 md:mt-0">
          </div>
          <div class="col-12 lg:col-3 flex flex-column mt-5 md:mt-0">
            <button pButton pRipple label="Update Payment Details" class="w-auto mt-3" (click)="submitBilling()"></button>
          </div>
        </div>

        <div class="col-12 lg:col-10">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 md:col-6">
                    <label for="nickname" class="font-medium text-900">Name</label>
                    <input id="nickname" type="text" pInputText formControlName="name">
                    <small id="username-help" class="p-error" *ngIf="(paymentDetailsFormControl.name.touched || billingSubmitted) && paymentDetailsFormControl.name.errors?.required">
                      {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
                    </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="email" class="font-medium text-900">Email</label>
                    <input id="email" type="text" pInputText formControlName="email">
                    <small id="username-help" class="p-error" *ngIf="(paymentDetailsFormControl.email.touched || billingSubmitted) && paymentDetailsFormControl.email.errors?.required">
                      {{generalConstant.REQUIRED_MESSAGE.EMAIL_REQUIRED}}
                    </small>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <ngx-stripe-card
                  [options]="cardOptions"
                  [elementsOptions]="elementsOptions"
                  (change)="onChangeCardDetail($event)"
                  ></ngx-stripe-card>
                  <small id="username-help" class="p-error" *ngIf="!stripeCardValid">
                    {{ stripeCardErrorMessage }}
                  </small>
                  <label for="card" class="font-medium text-900">Card</label>
                  <input id="card" type="text" pInputText placeholder="Card number" formControlName="card">
                </div>
                <div class="field mb-4 col-12 md:col-3 mt-4">
                  <input id="card" type="text" pInputText placeholder="MM" formControlName="expMonth">
                </div>
                <div class="field mb-4 col-12 md:col-3 mt-4">
                  <input id="card" type="text" pInputText placeholder="YY" formControlName="expYear">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <input id="card" type="text" pInputText placeholder="CVC" formControlName="cvc">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="card" class="font-medium text-900">Current Card: **** **** **** {{cardLastFourDigit}}</label>
                </div>

                <div class="col-12">
                  <button pButton pRipple label="Update Payment Details" class="w-auto mt-3" (click)="submitBilling()" [disabled]="!paymentDetails.valid"></button>
                </div>
            </div>
        </div>
  </div> -->
</ng-container>
