import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { ActivatedRoute } from '@angular/router';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-nyx-record-versions-sidebar',
  templateUrl: './nyx-record-versions-sidebar.component.html',
  styleUrls: ['./nyx-record-versions-sidebar.component.scss']
})
export class NyxRecordVersionsSidebarComponent extends BaseUrlState implements OnDestroy {
  @Input() versionData: any = {};
  @Input() fieldsLabel:any;
  @Input() datetimeGroup:any;
  @Output() passNavigationInfo = new EventEmitter<any>();

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  sidebarVisible: boolean = false;
  showSidebarVersions: Subscription;

  constructor(
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.showSidebarVersions = this.recordEvent.showSidebarVersioningSubject.subscribe(show => {
      if(this.urlState.operation! == 'edit'){
        this.sidebarVisible = show;
      }
    });
  }

  onHide(){
    let objectData = {
      operation: "versionsHide",
      data: null
    }

    this.recordEvent.recordVersionsEvent(objectData);
    this.recordEvent.showSidebarVersioningEvent(false);
  }

  ngOnDestroy(): void {
    this.showSidebarVersions.unsubscribe();
  }
}
