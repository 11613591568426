import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseLayout } from 'src/app/core/bases/BaseLayout';
import { UrlState } from 'src/app/core/states/urlState';
import { MessageService } from 'primeng/api';
import {
  GlobalNotification,
  GlobalNotificationService,
} from 'src/app/core/services/notification.service';
import { Subscription } from 'rxjs';
import { SidebarComponent } from 'src/app/shared/global/sidebar/sidebar.component';
import { TopbarComponent } from 'src/app/shared/global/topbar/topbar.component';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MenuService } from 'src/app/core/services/menu.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [MessageService],
})
export class MainLayoutComponent
  extends BaseLayout
  implements OnInit, OnDestroy
{
  overlayMenuOpenSubscription: Subscription;
  menuOutsideClickListener: any;
  menuScrollListener: any;
  @ViewChild(SidebarComponent) appSidebar!: SidebarComponent;
  @ViewChild(TopbarComponent) appTopbar!: TopbarComponent;

  state: UrlState = {
    organizationName: '',
    sectionName: 'app',
    objectId: '',
    recordId: '',
  };

  constructor(
    private http: HttpClient,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private notificationService: GlobalNotificationService,
    private menuService: MenuService,
    private layoutService: LayoutService
  ) {
    super();
    notificationService.notificationEmitter$.subscribe((x) =>
      this.displayNotificationMessage(x)
    );
  }

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  displayNotificationMessage(notification: GlobalNotification) {
    this.messageService.add({
      severity: notification.severity,
      summary: notification.summary,
      detail: notification.detail,
    });
  }

  getStateFromUrl() {
    //Get from current route
    var currentRouteOrganizationParam: any =
      this.activatedRoute.snapshot.paramMap.get('organization');
    if (currentRouteOrganizationParam == null) {
      //If current route param not available, get from parent
      currentRouteOrganizationParam =
        this.activatedRoute?.parent?.snapshot.paramMap.get('organization');
    }
    this.state.organizationName = currentRouteOrganizationParam;
    this.state.sectionName =
      this.activatedRoute.snapshot.paramMap.get('section');
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.menuService.reset();
    if(this.menuOutsideClickListener) {
        this.menuOutsideClickListener();
        this.menuOutsideClickListener = null;
    }
    if (this.menuScrollListener) {
        this.menuScrollListener();
        this.menuScrollListener = null;
    }
    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-light': this.layoutService.config.colorScheme === 'light',
      'layout-dim': this.layoutService.config.colorScheme === 'dim',
      'layout-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-colorscheme-menu':
        this.layoutService.config.menuTheme === 'colorScheme',
      'layout-primarycolor-menu':
        this.layoutService.config.menuTheme === 'primaryColor',
      'layout-transparent-menu':
        this.layoutService.config.menuTheme === 'transparent',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config.menuMode === 'slim-plus',
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-reveal': this.layoutService.config.menuMode === 'reveal',
      'layout-drawer': this.layoutService.config.menuMode === 'drawer',
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored,
    };
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }
  }
}
