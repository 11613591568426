import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { DatePipe } from '@angular/common';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-nyx-logs-listing',
  templateUrl: './nyx-logs-listing.component.html',
  styleUrls: ['./nyx-logs-listing.component.scss']
})
export class NyxLogsListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  logsList: any = [];

  totalLogs: any;
  loading: boolean = true;

  constructor(
    private nyxService: NyxAPIService,
    private datePipe: DatePipe,
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getLogs();
    this.getNyxLogPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  getLogs(){
    this.nyxService.getNyxLogs(this.urlState.organizationName!).subscribe((data:any) => {
      this.logsList = data;
      this.loading = false;
    });
  }
  getNyxLogPagination(PageNumber: any, PageSize: any) {
    this.nyxService.getNyxLogWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data:any) => {
      this.logsList = data;
      this.totalLogs = this.logsList.totalCount;
  
    });
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalLogs > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page", this.pagedRecord.pageNumber);

    this.getNyxLogPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }

  downloadFile() {
    this.nyxService.getLogsDownload(this.urlState.organizationName!).subscribe((response:any) => {
      if(response.body != undefined){
        // Get current Datetime and convert to specific format
        const currentDatetime = new Date();
        const finalDatetime = this.datePipe.transform(currentDatetime, 'yyyyMMdd');
        // Create a temporary element to download the file
        const downloadFile = new Blob([response.body], {type: response.body.type});
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'logs_' + finalDatetime;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });
  }
}
