<p-dialog #dd [(visible)]="modalShow" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [style]="{width: '36rem'}"
    [modal]="true" (onHide)="closeModal()" [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">New Section</span>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid">
            <div class="field grid">
                <label htmlFor="label" class="col-12 mb-3 md:col-3 md:mb-0">Section Name</label>
                <div class="col-12 md:col-9">
                    <input pInputText type="text" id="label" name="label" placeholder="" value=""
                        [(ngModel)]="newLayoutSection.SectionName" />
                </div>
            </div>

            <div class="field grid">
                <label htmlFor="columnType" class="col-12 mb-3 md:col-3 md:mb-0">Column Type</label>
                <div class="col-12 md:col-9">
                    <p-selectButton [options]="columnTypeOptions" [(ngModel)]="newLayoutSection.ColumnType" optionLabel="name" optionValue="value"></p-selectButton>
                </div>
            </div>
            
        </div>
        <div class="p-fluid col-12 align-items-center">
            <button pButton type="button" label="Create" class="p-element p-ripple p-button p-component" (click)="onCreateNewLayoutSectionButtonClicked()"></button>
        </div>
    </ng-template>
</p-dialog>
