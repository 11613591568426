import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-nyx-tag-field',
  templateUrl: './nyx-tag-field.component.html',
  styleUrls: ['./nyx-tag-field.component.scss']
})
export class NyxTagFieldComponent{
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";

}
