import { Component } from '@angular/core';

@Component({
  selector: 'app-nyx-record-view-manager',
  templateUrl: './nyx-record-view-manager.component.html',
  styleUrls: ['./nyx-record-view-manager.component.scss']
})
export class NyxRecordViewManagerComponent {

}
