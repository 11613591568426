<div class="grid">
  <div class="col-12 md:col-12 mt-1">
      <div class="p-fluid">
        <div class="field grid mt-5">
          <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{generalConstant.QUERY.QUERY_LABEL}}</label>
          <div class="col-12 md:col-10">
            <textarea type="text" class="p-inputtextarea p-inputtext p-component p-element" rows="5"
            [(ngModel)]="queryValue" value=""
            placeholder="select <field api name>, <field api name>, <field api name> &#10;from <object name> &#10;where <field api name> &#10;group by <field api name>"></textarea>
            <small class="p-error" *ngIf="queryValue == '' && isSubmit">
              {{generalConstant.REQUIRED_MESSAGE.QUERY_REQUIRED}}
            </small>
            <small class="p-error" *ngIf="validatedQueryResult.status == 'ERROR'">
                {{validatedQueryResult.errorMessage}}
            </small>
          </div>
        </div>

        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-0 mt-3">
            <div class="inline-flex align-items-center">
            </div>
            <div class="p-fluid p-formgrid grid col-2">
              <div class="field col">
                <button pButton pRipple type="button" label="Validate & Preview Query"
                (click)="onValidateAndPreviewQueryButtonClick()"></button>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="isSubmit"></i>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>

<ng-container *ngIf="validatedQueryResult.status == 'OK'">
  <app-table [dataList]="validatedQueryResult" [currentPage]="currentPage"></app-table>
</ng-container>
