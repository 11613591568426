import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-field-configuration',
  templateUrl: './text-field-configuration.component.html',
  styleUrls: ['./text-field-configuration.component.scss']
})
export class TextFieldConfigurationComponent {
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() selectedFieldType:any = "";

}
