import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { KanbanList, KanbanCard } from 'src/app/core/models/kanban.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { RecordEventService } from 'src/app/core/services/record.service';

@Component({
  selector: 'app-nyx-record-kanban-view-list',
  templateUrl: './nyx-record-kanban-view-list.component.html',
  styleUrls: ['./nyx-record-kanban-view-list.component.scss']
})
export class NyxRecordKanbanViewListComponent extends BaseUrlState {

  @Input() list!: KanbanList;
  @Input() listIds!: string[];

  timeout: any = null;
  isMobileDevice: boolean = false;

  @ViewChild('inputEl') inputEl!: ElementRef;
  @ViewChild('listEl') listEl!: ElementRef;

  sidebarRecordId: any = "";
  sidebarRecordName: any = "";

  constructor(
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  dropCard(event: CdkDragDrop<KanbanCard[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  focus() {
    this.timeout = setTimeout(() => this.inputEl.nativeElement.focus(), 1);
  }

  insertHeight(event: any) {
    event.container.element.nativeElement.style.minHeight = '10rem';
  }

  removeHeight(event: any) {
    event.container.element.nativeElement.style.minHeight = '2rem';
  }

  onCardClick(card:any){
    // let objectData = {
    //   id: card.id,
    //   operation: "kanban",
    //   data: card
    // }

    this.recordEvent.viewRecordIdEvent(card.id);
    this.recordEvent.showSidebarEvent(true);
  }

}
