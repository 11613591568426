import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { RecordEventService } from 'src/app/core/services/record.service';
import { Subscription } from 'rxjs';
import { GlobalNotificationService } from 'src/app/core/services/notification.service';



interface ExistingFiles {
  name: string;
  fullname: string;
}

@Component({
  selector: 'app-nyx-attachment-field',
  templateUrl: './nyx-attachment-field.component.html',
  styleUrls: ['./nyx-attachment-field.component.scss']
})
export class NyxAttachmentFieldComponent extends BaseUrlState implements OnDestroy {
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";
  @Input() isVersion:boolean = false;

  uploadedFiles: any[] = [];
  extensionAllowed: any;
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  existingFiles: ExistingFiles[] = [];

  showUpload:boolean = false;
  fileModeCheck: number;
  currentFile: number;

  nyxConfirmation:any;
  viewRecord: Subscription;
  recordDataReady: Subscription;

  constructor(
    private notificationService: GlobalNotificationService,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public recordEvent: RecordEventService,
    public override activatedRoute: ActivatedRoute
  ){
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //Todo: add checking or backend add default value
    if(this.nyxFieldSchema.FileExtension.some(Boolean)){
      this.extensionAllowed = this.nyxFieldSchema.FileExtension.map((x:any) => "."+ x);
      this.extensionAllowed = this.extensionAllowed.join(',');
    }else{
      this.extensionAllowed = "";
    }

    this.eventSubjectSubscription();
    
  }

  ngOnDestroy(): void {
    this.viewRecord.unsubscribe();
    this.recordDataReady.unsubscribe();
  }

  eventSubjectSubscription(){
    this.viewRecord = this.recordEvent.viewRecordSubject.subscribe((id:any) => {
      if(this.urlState.recordId! == null && (this.urlState.operation! == 'kanban' || this.urlState.operation! == 'edit')){
        this.urlState.recordId! = id;
      }
    });

    this.recordDataReady = this.recordEvent.recordDataReadySubject.subscribe((load:any) => {
      if(load && (this.urlState.operation! == 'kanban' || this.urlState.operation! == 'edit')){
        const data = this.formGroup.controls[this.controlName]?.value;
        if(data != null && data != undefined){

          // Show upload element based on file length
          this.fileModeCheck = this.nyxFieldSchema.FileMode - data.length;
          if(data.length < this.nyxFieldSchema.FileMode){
            this.showUpload = true;
          }else{
            this.showUpload = false;
          }
    
          // Patch existing files
          if(data.length > 0){
            data.forEach((file:any) => {
              let lastIndexDot = file.lastIndexOf("_");
              this.existingFiles.push({
                name: file.substring(lastIndexDot+1),
                fullname: file
              });
            });
          }
        }else{
          this.showUpload = true;
        }
      }else if(this.urlState.operation == 'create' && !this.isVersion){
        this.fileModeCheck = this.nyxFieldSchema.FileMode;
        this.showUpload = true;
      }
    });
  }

  onFileSelect(event: any){
    for(let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.formGroup.controls[this.controlName + "_blobs"].setValue(event.currentFiles);
  }

  onFileClear(event: any){
    this.formGroup.controls[this.controlName + "_blobs"].setValue([]);
  }

  // Behavior Subject 
  onNyxAttachmentFieldDelete(thisContext:any, attachemntInfo:any){
    thisContext.recordEvent.recordDeleteAttachmentEvent(attachemntInfo);
  }

  onNyxAttachmentFieldDeleteButtonClicked(event: Event, id:any){
    let attachemntInfo = {
      filename: id,
      operation: this.urlState.operation!
    }
    this.nyxConfirmation = this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.FILE_ATTACHMENT_MESSAGE.DELETE_FILE_ATTACHMENT_CONFIRMATION, this, this.onNyxAttachmentFieldDelete, attachemntInfo, null);
  }

  downloadFile(filename:any){
    this.nyxAPIService.downloadFile(this.urlState.organizationName!, this.urlState.objectId!, filename).subscribe((data:any) => {
      window.open(data);
    }, (error: any) => {
      this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.ATTACHMENT_NOT_FOUND);
    });
  }
}
