<div class="col-12">
    <div class="card">
        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
            <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Role ({{roleList.totalCount}} records)</div>
            <div class="inline-flex align-items-center">
                <div class="flex flex-wrap gap-2">
                    <button pButton pRipple type="button" label="Add Role" (click)="addRoleButtonClick()"></button>
                </div>
            </div>
        </div>
        <p-table #dt1 [value]="roleList.record" [loading]="loading" [rowHover]="true"
            styleClass="p-datatable-gridlines" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.ROLE.SYS_ROLE}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.IS_ADMIN}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.CREATE_DATE}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.ACTION}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
                <tr>
                    <td>{{role.name}}</td>
                    <td>
                        {{role.isSystemDefault}}
                    </td>
                    <td>{{role.createdDatetime +'Z' | date: generalConstant.TABLE_DATA.DATE }}</td>

                    <td class="flex flex-wrap gap-2">
                        <p-confirmPopup></p-confirmPopup>
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
                            [routerLink]="['/' + urlState.organizationName + '/'+ urlState.sectionName +'/nyx-role-manager/edit/' + role.id]"
                            [disabled]="role.isSystemDefault"></button>
                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-danger p-button-text"
                            (click)="onNyxRoleListingDeleteButtonClicked($event, role.id)"
                            [disabled]="role.isSystemDefault"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalRole"></p-paginator>
    </div>
</div>