<ng-container *ngIf="pageMode == generalConstant.PAGE_MODE.CREATE || pageMode == generalConstant.PAGE_MODE.EDIT ">
  <div class="grid" [formGroup]="apiAccessForm">
    <div class="col-12 md:col-12 mt-3">
        <div class="card p-fluid">
          <h5>{{labelConstant.API.API_ACCESS_LABEL}}</h5>
          <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
          <div class="field grid mt-5">
            <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.NAME}}</label>
            <div class="col-12 md:col-10">
                <input pInputText type="text" id="name" formControlName="name" placeholder=""  value="" [ngClass]="{
                        'ng-dirty ng-invalid': apiAccessFormControl.name.invalid,
                        'is-valid': apiAccessFormControl.name.valid
                      }"
                      />
                    <small id="username-help" class="p-error" *ngIf="(apiAccessFormControl.name.touched || submitted) && apiAccessFormControl.name.errors?.required">
                      {{generalConstant.REQUIRED_MESSAGE.NAME_REQUIRED}}
                    </small>

            </div>
          </div>

            <div class="field grid">
                <label htmlFor="name3" class="col-12 mb-2 md:col-2 md:mb-0">{{labelConstant.LABEL.DESCRIPTION}}</label>
                <div class="col-12 md:col-10">
                  <input pInputText id="name3" type="text" formControlName="description" />
                </div>
            </div>

                <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3 mt-5">
                    <div class="inline-flex align-items-center">
                    </div>
                    <div class="p-fluid p-formgrid grid col-2">
                      <div class="field col">
                        <button pButton pRipple type="button" label="Submit"
                        (click)="submit()"></button>
                      </div>
                    </div>
                  </div>

        </div> 

    </div>

  </div>
</ng-container>
