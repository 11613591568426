import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-nyx-datetime-field',
  templateUrl: './nyx-datetime-field.component.html',
  styleUrls: ['./nyx-datetime-field.component.scss']
})
export class NyxDatetimeFieldComponent{
  @Input() nyxObject:any = {};
  @Input() nyxFieldSchema:any = {};
  @Input() formGroup: FormGroup;
  @Input() formSubmitted:boolean = false;
  @Input() controlName:string = "";
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
}
