import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-tab-navigation',
  templateUrl: './nav-tab-navigation.component.html',
  styleUrls: ['./nav-tab-navigation.component.scss']
})
export class NavTabNavigationComponent {

}
