import { Component } from '@angular/core';
import { BaseLayout } from 'src/app/core/bases/BaseLayout';
declare function setBgCoverCenterLayout():any;

@Component({
  selector: 'app-bg-cover-center-layout',
  templateUrl: './bg-cover-center-layout.component.html',
  styleUrls: ['./bg-cover-center-layout.component.scss']
})
export class BgCoverCenterLayoutComponent extends BaseLayout {

  constructor(){
    super();
  }

}
