<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="formula" class="col-12 mb-2 md:col-2 md:mb-0">
                Formula
            </label>
            <div class="col-12 md:col-10">
                
                <input pInputText id="formula" type="text" formControlName="formula" [ngClass]="{
                    'ng-invalid ng-dirty': fieldFormControl.formula?.invalid,
                    '': fieldFormControl.formula?.valid
                }"/>

                <small class="p-error"
                *ngIf="(fieldFormControl.formula?.touched || formSubmitted) && fieldFormControl.formula.errors?.email">
                    Formula expression is required.
                </small>

            </div>
        </div>
    </div>

    <input type="hidden" formControlName="isRequired" value="false" />
    <input type="hidden" formControlName="defaultValue" value="" />
    <input type="hidden" formControlName="regexRestriction" value="" />
    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
    <input type="hidden" formControlName="seqPrefix" value="" />
    <input type="hidden" formControlName="seqNumberPadding" value="" />
</form>