<div class="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0" id="kt_aside_nav">
    <div class="hover-scroll-overlay-y mb-5 scroll-ms px-5" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_aside_nav" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-offset="0px">
        <ul class="nav flex-column w-100" id="kt_aside_nav_tabs">
            <li class="nav-item mb-2" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="right" data-bs-dismiss="click" pTooltip="Records" >
                <a class="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light" [ngClass]="state.sectionName == 'app' ? 'active': ''" data-bs-toggle="tab" href="#kt_aside_nav_tab_nyx_records">
                    <i class="ki-duotone ki-element-11 fs-2x">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                    </i>
                </a>
            </li>
            <li class="nav-item mb-2" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="right" data-bs-dismiss="click" pTooltip="Dashboard">
                <a class="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light" [ngClass]="state.sectionName == 'dashboard' ? 'active': ''" data-bs-toggle="tab" href="#kt_aside_nav_tab_nyx_dashboard">
                    <i class="ki-duotone ki-chart-simple fs-2x">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                        <span class="path4"></span>
                    </i>
                </a>
            </li>
            <li class="nav-item mb-2" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="right" data-bs-dismiss="click" pTooltip="Setup">
                <a class="nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light" [ngClass]="state.sectionName == 'setup' ? 'active': ''" data-bs-toggle="tab" href="#kt_aside_nav_tab_nyx_objects">
                    <i class="ki-duotone ki-abstract-26 fs-2x">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                </a>
            </li>
        </ul>
    </div>
</div>