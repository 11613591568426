import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-nyx-user-detail',
  templateUrl: './nyx-user-detail.component.html',
  styleUrls: ['./nyx-user-detail.component.scss']
})
export class NyxUserDetailComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  state: UrlState = {
    organizationName: '',
    sectionName: '',
    objectId: '',
    recordId: '',
  };

  roleList: any = [];
  userData: any = {};

  userForm: FormGroup;

  MaxPageSize: number = 100;
  PageNumber: number=1;
  PageSize: number=100;

  submitted = false;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.initConfiguration();

  }

  get userFormControl() {
    return this.userForm.controls;
  }


  async initConfiguration() {
    await this.nyxAPIService.getNyxRoleListing(this.urlState.organizationName!, this.PageNumber, this.MaxPageSize,this.PageSize)
        .subscribe((data: any) => {
        this.roleList = data.record;
        this.initForm();
      });
  }

  initForm() {
    const group: any = {};

    group['name'] = this.urlState.operation! == 'edit' ?
      new FormControl({ value: "", disabled: true }) :
      new FormControl("", [Validators.required, Validators.maxLength(100)]);

    group['email'] = this.urlState.operation! == 'edit' ?
      new FormControl({ value: "", disabled: true }) :
      new FormControl("", [Validators.required, Validators.email]);
    
      this.roleList.forEach((role: any) => {
      group[role.id] = new FormControl("");
    })

    

    this.userForm = this.fb.group(group);

    if (this.urlState.operation! == 'edit') {
      this.initUser();
    }
  }

  initUser() {
    this.nyxAPIService.getNyxUser(this.urlState.organizationName!, this.urlState.userId!).subscribe((data: any) => {
      this.userData = data;
      this.setUserDataIntoForm(this.userData);
    });
  }

  setUserDataIntoForm(data: any) {
    this.userForm.patchValue({
      ['name']: data.displayName,
      ['email']: data.email
    });

    this.roleList.forEach((role: any) => {

      if (data.nyxUserRoles.some((x: any) => x.nyxRoleId == role.id)) {
        this.userForm.patchValue({
          [role.id]: true
        });
      } else {
        this.userForm.patchValue({
          [role.id]: false
        });
      }
    });
  }

  onRoleUpdated(event: any, value: any) {
    if (this.urlState.operation! == 'edit') {
      if (event.checked) {
        var newUserRolePayload = {
          UserId: this.urlState.userId!,
          RoleId: value
        };
        this.nyxAPIService
          .addNewUserRole(this.urlState.organizationName!, newUserRolePayload)
          .subscribe((data: any) => {
            console.log(data);
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.USER_MESSAGE.EDIT_USER_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.USER_MESSAGE.EDIT_USER_ERROR);
          });
      } else {
        //Remove user role
        this.nyxAPIService
          .deleteNewUserRole(this.urlState.organizationName!, this.urlState.userId!, value)
          .subscribe((data: any) => {
            console.log(data);
            this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.USER_MESSAGE.REMOVE_USER_SUCCESS));
          }, (error: any) => {
            this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.USER_MESSAGE.REMOVE_USER_ERROR);
          });
      }
    }
  }

  submit() {
    this.submitted = true;
    if (this.userForm.valid) {

      let finalRoles: any[] = [];

      this.roleList.forEach((role: any) => {
        if (this.userForm.controls[role.id].value) {
          finalRoles.push(role.id);
        }
      });

      var inviteUserPayload = {
        Email: this.userForm.value.email,
        DisplayName: this.userForm.value.name,
        RoleIds: finalRoles,
      };

      this.nyxAPIService.inviteNyxUser(this.urlState.organizationName!, inviteUserPayload).subscribe((data: any) => {
        this.redirectToUserListingPage();
        if(data != null){
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.USER_MESSAGE.CREATE_USER_SUCCESS.replace('{{ name }}', data.displayName)));
        }else{
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.USER_MESSAGE.INVITE_USER_SUCCESS));
        }

      }, (error: any) => {
        this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.USER_MESSAGE.INVITE_USER_ERROR);
        console.log(error);
      });
    }
  }

  redirectToUserListingPage() {
    this.router.navigate([`/${this.urlState.organizationName!}/setup/nyx-user-manager/overview`]);
  }
}
