import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';

interface PageEvent {
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-api-access-listing',
  templateUrl: './api-access-listing.component.html',
  styleUrls: ['./api-access-listing.component.scss']
})
export class ApiAccessListingComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  reportId: any = "";
  submitted = false;
  pageMode = "edit";
  dataListing: any = [];
  validatedQueryResult = {};
  messageBox = {
    showMessageBox: false,
    messageBoxMessage: '',
    messageBoxStatus: ''
  }
  loading: boolean = true;

  totalApiAcess: any;
  // MaxPageSize: number = 100;
  // PageNumber: number = 1;

  // first: number = 0;

  // rows: number = 10;

  constructor(
    private notificationService: GlobalNotificationService,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // this.initAPIAccessData();
    this.getNyxApiPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
  }

  // initAPIAccessData() {
  //   if (this.urlState.organizationName != null && this.urlState.organizationName != undefined) {
  //     this.nyxAPIService.getAllAPIAccess(this.urlState.organizationName!).subscribe((data: any) => {
  //       // this.totalApiAcess = data.length;
  //       this.dataListing = data;
  //       this.totalApiAcess = this.dataListing.totalCount;
  //       this.loading = false;

  //       // console.log("totalApiAcess",this.totalApiAcess);

  //     });
  //   }
  // }

  getNyxApiPagination(PageNumber: any, PageSize: any) {
    if (this.urlState.organizationName != null && this.urlState.organizationName != undefined) {
      this.nyxAPIService.getNyxApiWithPagination(this.urlState.organizationName!, PageNumber, PageSize).subscribe((data) => {
        this.dataListing = data;
        this.totalApiAcess = this.dataListing.totalCount;
        console.log("dataListing", this.dataListing);

        this.loading = false;
      });
    }
  }

  changeAPIAccessKeyStatus(data: any) {
    console.log("api data payload", data)
    var payload = {
      Name: data.name,
      Description: data.description,
      IsActive: false
    }

    this.nyxAPIService.updateAPIAccess(this.urlState.organizationName!, data.id, payload).subscribe((data: any) => {
      this.getNyxApiPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);
    });
  }

  deleteAPIAccessKey(thisContext: any, keyId: string) {
    thisContext.nyxAPIService.deleteAPIAccess(thisContext.urlState.organizationName!, keyId).subscribe((data: any) => {
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.APIACCESS_MESSAGE.DELETE_APIACCESS_SUCCESS));
      thisContext.getNyxApiPagination(thisContext.pagedRecord.pageNumber, thisContext.pagedRecord.pageSize);

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.APIACCESS_MESSAGE.DELETE_APIACCESS_ERROR);
    });
  }

  onApiAccessListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.APIACCESS_MESSAGE.DELETE_APIACCESS_CONFIRMATION, this, this.deleteAPIAccessKey, id, null)
  }

  onAddNewAPIAccessButtonClick() {
    this.router.navigate([`/${this.urlState.organizationName}/${this.urlState.sectionName}/api-access-manager/create`]);
  }

  onPageChange(event: PageEvent) {
    this.pagedRecord.pageSize = event.rows;

    if (this.totalApiAcess > 10) {
      this.pagedRecord.pageNumber = event.page + 1;
    } else {
      this.pagedRecord.pageNumber = event.page;
    }

    console.log("PageNumber next page",this.pagedRecord.pageNumber);

    this.getNyxApiPagination(this.pagedRecord.pageNumber, this.pagedRecord.pageSize);

  }

}
