<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>
        <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
        <div class="layout-content">
			<p-toast></p-toast>
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profile-menu></app-profile-menu>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>
