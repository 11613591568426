<div class="p-fluid card">
    <form [formGroup]="parameterizedQueryForm">
        <div class="grid grid-nogutter">
            <div class="col-12 px-4 mt-4 md:mt-6 md:px-6">
                <span class="text-900 block font-bold text-xl">{{labelConstant.PARAMETERIZED_QUERY.TITLE_LABEL}}</span>
            </div>
            <div class="col-12 lg:col-6 h-full px-4 py-4 md:px-6">
                <div class="grid formgrid">
                    <div class="col-12 field mb-4">
                        <span
                            class="text-900 text-2xl block font-medium">{{labelConstant.PARAMETERIZED_QUERY.INFO_LABEL}}</span>
                    </div>

                    <div class="col-12 field mb-4">
                        <label htmlFor="name3">Name</label>
                        <input id="description" placeholder="" type="text" class="p-inputtext w-full mb-0"
                            formControlName="name">
                        <small class="p-error"
                            *ngIf="(parameterizedQueryFormControl.name.touched || submitted) && parameterizedQueryFormControl.name.errors?.required">
                            {{generalConstant.REQUIRED_MESSAGE.PARAMETERIZED_QUERY_REQUIRED}}
                        </small>
                    </div>

                    <div class="col-12 field mb-0">
                        <label htmlFor="name3">{{labelConstant.LABEL.DESCRIPTION}}</label>
                        <input id="description" placeholder="" type="text" class="p-inputtext w-full mb-4"
                            formControlName="description">
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 px-4 py-4 md:px-6">
                <div class="py-2 mt-2">
                    <div class="col-12 flex justify-content-between align-items-center mb-3">
                        <span class="text-900 font-medium">{{labelConstant.WEBHOOK.ENABLED}}</span>
                        <p-inputSwitch class="p-2" formControlName="enabled"></p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-12 h-full px-4 py-4 md:px-6">
                <div class="grid formgrid">
                    <div class="col-12 field mb-4">
                        <span
                            class="text-900 text-2xl block font-medium">{{labelConstant.PARAMETERIZED_QUERY.EDITOR_LABEL}}</span>
                    </div>

                    <div class="col-12 lg:col-12 field mb-4">
                        <textarea type="text" class="p-inputtextarea p-inputtext p-component p-element" rows="5"
                            formControlName="userQuery" value=""
                            placeholder="select <field api name>, <field api name>, <field api name> &#10;from <object name> &#10;where <field api name> = @p0 &#10;group by <field api name> having <field api name> = @p1"></textarea>
                        <small class="p-error"
                            *ngIf="(parameterizedQueryFormControl.userQuery.touched || submitted) && parameterizedQueryFormControl.userQuery.errors?.required">
                            {{generalConstant.REQUIRED_MESSAGE.QUERY_REQUIRED}}
                        </small>
                        <small class="p-error" *ngIf="parameterizedQueryResult.statusCode == 'ERROR'">
                            {{parameterizedQueryResult.errorMessage}}
                        </small> 
                       
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-12 h-full px-4 py-4 md:px-6">
                <div class="grid formgrid">
                    <div
                        class="col-12 flex flex-column lg:flex-row justify-content-center align-items-center lg:justify-content-end my-6">
                        <button pButton pRipple
                            class="p-button-primary w-full lg:w-auto flex-order-1 lg:flex-order-1 lg:mr-4"
                            label="Save Changes" (click)="submit()"></button>
                        <button pButton pRipple
                            class="p-button-secondary p-button-outlined mt-3 lg:mt-0 w-full lg:w-auto flex-order-2 lg:flex-order-2 "
                            label="Cancel" (click)="discard()"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>