import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';

@Component({
  selector: 'app-nyx-object-manager',
  templateUrl: './nyx-object-manager.component.html',
  styleUrls: ['./nyx-object-manager.component.scss']
})
export class NyxObjectManagerComponent implements OnInit {
  state:UrlState = {
    organizationName: "",
    sectionName: "",
    objectId: "",
    recordId: ""
  }

  constructor(private nyxAPIService: NyxAPIService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  getStateFromUrl(){
    this.state.organizationName = this.activatedRoute.snapshot.paramMap.get('organization')!;
  }
}
