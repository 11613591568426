<p-dialog header="Import Record" [(visible)]="modalShow" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [style]="{width: '48rem'}"
[draggable]="false" [resizable]="false" [modal]="true" (onHide)="closeModal()" [closable]="true">
    <form [formGroup]="importForm">
        <div class="col-12 p-fluid">
            <div class="field grid">
                <p-fileUpload #recordFileUpload class="p-fileUpload col-12 mb-2 md:col-12 md:mb-0" name="demo[]" [multiple]="true" accept=".json,.xls,.xlsx" fileLimit="1" [maxFileSize]="1000000"
                [showUploadButton]="false" (onClear)="clearFileUpload()" (onRemove)="clearFileUpload()" (onSelect)="onSelectFile($event)">
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadedFiles.length" class="">
                            <li *ngFor="let file of uploadedFiles" style="display: none;">{{file.name}} - {{file.size}} bytes</li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
                <input type="hidden" formControlName="importFile">
                <small class="p-2">
                    {{generalConstant.HINT_MESSAGE.RECORD_FILE_IMPORT_HINT}}
                </small>
                <br>
                <small class="p-error p-2" *ngIf="(importForm.get('importFile')?.touched || formSubmitted) && importForm.get('importFile')?.errors?.required">
                    {{generalConstant.REQUIRED_MESSAGE.FILE_REQUIRED}}
                </small>
            </div>
            <div class="field grid">
                <div class="col-12 mb-2 md:col-12 md:mb-0">
                    <button pButton pRipple type="button" label="Import" (click)="onUpload()"></button>
                </div>
            </div>
        </div>
    </form>
</p-dialog>
