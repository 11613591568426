<form [formGroup]="formGroup">

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="seqPrefix" class="col-12 mb-2 md:col-2 md:mb-0">
                Prefix
            </label>
            <div class="col-12 md:col-10">
                <input pInputText id="seqPrefix" type="text" formControlName="seqPrefix"
                [ngClass]="{'ng-invalid ng-dirty': fieldFormControl.seqPrefix?.invalid, '': fieldFormControl.seqPrefix?.valid}"/>

                <small class="p-error"
                *ngIf="(fieldFormControl.seqPrefix?.touched || submitted) && fieldFormControl.seqPrefix.errors?.pattern">
                    The maximum character is 20.
                </small>
            </div>
        </div>
    </div>

    <div class="mt-3">
        <div class="field grid">
            <label htmlFor="seqNumberPadding" class="col-12 mb-2 md:col-2 md:mb-0">
                Number Padding
            </label>
            <div class="col-12 md:col-10">
                <input pInputText id="seqNumberPadding" type="text" formControlName="seqNumberPadding" [(ngModel)]="paddingCount" (input)="inputText()"
                [ngClass]="{'ng-invalid ng-dirty': fieldFormControl.seqNumberPadding?.invalid, '': fieldFormControl.seqNumberPadding?.valid}"/>


                <small *ngIf="fieldFormControl.seqNumberPadding?.value != ''">
                    The number padding will be {{ displayNum }}
                </small>

                <small class="p-error"
                *ngIf="(fieldFormControl.seqNumberPadding?.touched || formSubmitted) && fieldFormControl.seqNumberPadding.errors?.pattern">
                This field only allow number 0 and maximun 8 digit.
                </small>
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="regexRestriction" value="" />
    <input type="hidden" formControlName="objectId" value="00000000-0000-0000-0000-000000000000" />
    <input type="hidden" formControlName="formula" value="" />
    <input type="hidden" formControlName="minValue" value="" />
    <input type="hidden" formControlName="maxValue" value="" />
    <input type="hidden" formControlName="isDataMask" value="false" />
    <input type="hidden" formControlName="isMultipleOptions" value="false" />
    <input type="hidden" formControlName="options" value="" />
    <input type="hidden" formControlName="workflow" value="false" />
</form>