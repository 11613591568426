import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GlobalNotificationService } from 'src/app/core/services/notification.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  loginForm: boolean = true;
  organizationForm: boolean = false;
  completedForm: boolean = false;
  validateLoginFields: boolean = false;
  validateOrganizationFields: boolean = false;
  invitationToken: any = '';

  loginFormSubmit: boolean = false;
  organizationFormSubmit: boolean = false;
  submitted: boolean = false;
  registrationErrorMsg: string = '';
  loading: boolean = false;

  passwordMatch: boolean = false;
  registrationForm: FormGroup;
  messages: Message[] | undefined;

  constructor(private nyxService: NyxAPIService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: GlobalNotificationService){}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((param:any) => {
      this.invitationToken = param.get('token');
    });

    this.initVerifyInvatationLink();

    this.registrationForm = new FormGroup({
      email: new FormControl('', Validators.required),
      displayName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  initVerifyInvatationLink(){
    var payload = {
      EncryptionString: this.invitationToken
    }

    this.nyxService.verifyInvitationLink(payload).subscribe((result: any) => {
      console.log('link verified ', result);

      if(!result || result == undefined || result == null){
        this.nyxService.getErrorPageMessage(MessageBoxConstant.INVITATION_MESSAGE.VERIFIED_FAILED);
        this.router.navigate(['/error-page']);
      }
    }, (err: any) => {
      console.log('verifyInvitationLink ', err.error.errorMessage);
      this.nyxService.getErrorPageMessage(MessageBoxConstant.INVITATION_MESSAGE.VERIFIED_FAILED);
      this.router.navigate(['/error-page']);
    });
  }

  confirmPassword(event:Event){
    if(this.registrationFormControl['password']?.value != this.registrationFormControl['confirmPassword']?.value){
      this.passwordMatch = true;
    }else{
      this.passwordMatch = false;
    }
  }

  get registrationFormControl() {
    return this.registrationForm.controls;
  }

  submit(){
    this.submitted = true;
    this.loading = true;
    this.registrationErrorMsg = '';

    if(this.registrationForm.valid){
      let registrationPayload = {
        Email: this.registrationForm.value.email,
        DisplayName: this.registrationForm.value.displayName,
        Password: this.registrationForm.value.password,
        EncryptionString: this.invitationToken
      }

      this.nyxService.createInviteUser(registrationPayload).subscribe((res:any) => {

        console.log('res', res)
        this.loading = false;
        if(res.statusCode == 'OK'){
          window.location.href = `${environment.PORTAL_NYXHUB_HOST}/${res.data.nyxOrganization.name}`;
        }else{
          this.registrationErrorMsg = res.errorMessage;
        }
      },
      (err:any) => {
        console.log(err);
        this.loading = false;
        this.registrationErrorMsg = err.error.errorMessage;
      });
    }
  }
}
