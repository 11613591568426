import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

@Component({
  selector: 'app-api-access-detail',
  templateUrl: './api-access-detail.component.html',
  styleUrls: ['./api-access-detail.component.scss']
})
export class ApiAccessDetailComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;
  messageBoxConstant = MessageBoxConstant;

  operation: any = "";
  apiAccessId: any = "";
  submitted = false;
  apiAccessForm: FormGroup;
  pageMode = "edit";
  apiAccessData: any = {};
  messageBox = {
    showMessageBox: false,
    messageBoxMessage: '',
    messageBoxStatus: ''
  }

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.operation = this.activatedRoute.snapshot.paramMap.get('operation');
    this.apiAccessId = this.activatedRoute.snapshot.paramMap.get('apiAccessId')!;
    this.initForm();

    this.pageMode = this.operation;
    if (this.pageMode == 'edit') {
      this.initapiAccessFormData();
    }
  }

  initapiAccessFormData() {
    this.nyxAPIService.getAPIAccessById(this.urlState.organizationName!, this.apiAccessId).subscribe((data: any) => {
      this.apiAccessData = data;
      console.log(data);
      this.patchAPIAcceessDataIntoForm();
    });
  }

  patchAPIAcceessDataIntoForm() {
    this.apiAccessForm.patchValue({
      ['name']: this.apiAccessData.name,
      ['description']: this.apiAccessData.description
    });
  }

  get apiAccessFormControl() {
    return this.apiAccessForm.controls;
  }

  initForm() {
    if (this.urlState.operation! == 'edit') {
      this.apiAccessForm = this.fb.group({
        name: [{ value: "", disabled: this.pageMode == 'edit' }, [Validators.required, Validators.maxLength(100)]],
        description: ['', [Validators.maxLength(100)]],
      });
    } else {
      this.apiAccessForm = this.fb.group({
        name: ["", [Validators.required, Validators.maxLength(100)]],
        description: ['', [Validators.maxLength(100)]],
      });
    }

  }


  submit() {
    this.submitted = true;
    console.log("q", this.pageMode);
    if (this.apiAccessForm.valid) {
      var payload = {
        Name: this.apiAccessForm.value.name,
        Description: this.apiAccessForm.value.description,
        IsActive: true
      };

      let apiAccessPromise = this.pageMode == 'edit' ? this.nyxAPIService.updateAPIAccess(this.urlState.organizationName!, this.apiAccessId, payload) : this.nyxAPIService.createAPIAccess(this.urlState.organizationName!, payload)
      console.log(this.urlState.organizationName!);

      if (this.pageMode == 'create') {
        apiAccessPromise.subscribe((data: any) => {
          console.log("data", data);
          this.navigateToAPIAccessListingPage();
          this.initForm();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.APIACCESS_MESSAGE.CREATE_APIACCESS_SUCCESS.replace('{{ name }}', data.name)));
        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.APIACCESS_MESSAGE.CREATE_APIACCESS_ERROR);
        });
      } else {
        apiAccessPromise.subscribe((data: any) => {
          console.log("data", data);
          this.navigateToAPIAccessListingPage();
          this.initForm();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.APIACCESS_MESSAGE.EDIT_APIACCESS_SUCCESS));
        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.APIACCESS_MESSAGE.EDIT_APIACCESS_ERROR);
        });
      }
    }
  }

  navigateToAPIAccessListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName!}/${this.urlState.sectionName!}/api-access-manager/overview`,
    ]);
  }

}
