import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-query-editor-manager',
  templateUrl: './query-editor-manager.component.html',
  styleUrls: ['./query-editor-manager.component.scss']
})
export class QueryEditorManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
