import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { LayoutService } from 'src/app/core/services/layout.service';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent extends BaseUrlState {

  organzationAccessList: any = [];
  organizationProfile: any = [];
  userBilling: any = [];

  constructor(
    public layoutService: LayoutService,
    private nyxAPIService: NyxAPIService,
    private authService: MsalService,
    public override activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.getOrganizationAccess();
    this.getAPIProfile();
  }

  getOrganizationAccess() {
    this.nyxAPIService.getOrganizationAccess().subscribe((result) => {
      this.organzationAccessList = result;
    });

    // get organization plan
    this.nyxAPIService.getUserAllOrganization(this.urlState.organizationName!).subscribe((result) => {
      this.userBilling = result;
    });
  }

  redirectToOrgDashboard(orgName: any) {
    window.location.href = '/' + orgName + '/app/dashboard-manager/overview';
  }

  getAPIProfile() {
    this.nyxAPIService.getAPIProfile(this.urlState.organizationName!).subscribe((result) => {
      this.organizationProfile = result;
    });
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }
}
