import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;

@Component({
    template: '',
})
export class BaseLayout {

    constructor() { }
}
