import { MessageBoxConstant } from './../../core/constants/message-box-constant';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from './../../core/services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { UrlMetadata } from 'src/app/core/constants/urlMetadata';
import { MenuService } from 'src/app/core/services/menu.service';

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss']
})
export class DashboardOverviewComponent extends BaseUrlState {
  labelConstant = LabelConstant;

  latestCreatedDashboard: any;
  widgetList: any;
  currentPage: string = GeneralConstant.DASHBOARD.DASHBOARD_OVERVIEW;
  editDashboardModalShow: boolean = false;
  modalOperation: string = UrlMetadata.OPERATION.EDIT;

  constructor(
    private notificationService: GlobalNotificationService,
    private nyxAPIService: NyxAPIService,
    private router: Router,
    private nyxConfirmationService: NyxConfirmationService,
    public override activatedRoute: ActivatedRoute,
    public menuService: MenuService) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.params.subscribe((param) => {
      this.urlState.dashboardId = this.activatedRoute.snapshot.paramMap.get('dashboardId');
      this.initDashboardData();
    });

    this.nyxAPIService.viewWidgetSubject.subscribe((widget:any) => {
      if(widget){
        this.initDashboardData();
      }
    });
  }

  initDashboardData() {
    if (this.urlState.dashboardId != "" && this.urlState.dashboardId != null) {
      this.getDashboardWidget(this.urlState.dashboardId);

    } else {
      this.nyxAPIService.getDashboardListing(this.urlState.organizationName!).subscribe((result: any) => {
        if (result != undefined && result.length > 0) {
          this.getLatestDashboard(result);
          this.navigateDefaultDashboard(this.latestCreatedDashboard.id);

          // Get the dashboard widgets & pass to widget component
          this.getDashboardWidget(this.latestCreatedDashboard.id);
        }
      });
    }
  }

  // This function to identify the latest dashboard as the first dashboard to show
  getLatestDashboard(result: any) {
    // Get the default or check the latest created dashboard id
    const defaultDashboard = result.filter((x: any) => x.isDefault == true);

    if (defaultDashboard.length != 1) {
      let latestDashboardDatetime = '';
      let latestDashboard = '';

      result.forEach((dashboard: any) => {
        if (latestDashboardDatetime == '') {
          latestDashboardDatetime = dashboard.createdDatetime;
          latestDashboard = dashboard;
        } else {
          latestDashboardDatetime = dashboard.createdDatetime > latestDashboardDatetime ? dashboard.createdDatetime : latestDashboardDatetime;
          latestDashboard = dashboard.createdDatetime > latestDashboardDatetime ? dashboard : latestDashboard;
        }

        this.latestCreatedDashboard = latestDashboard;

      });
    } else {
      this.latestCreatedDashboard = defaultDashboard[0];
    }
  }

  showEditDashboardDialog() {
    this.editDashboardModalShow = true;
  }

  closeEditDashboardDialog() {
    this.editDashboardModalShow = false;
  }

  navigateToWidgetDetailPage() {
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + this.urlState.dashboardId + '/overview/widget/create']);
  }

  navigateDefaultDashboard(dashboardId: any) {
    this.router.navigate(['/' + this.urlState.organizationName! + '/' + this.urlState.sectionName! + '/dashboard-manager/' + dashboardId + '/overview']);
  }

  getDashboardWidget(dashboardId: any) {
    if (dashboardId != "" && dashboardId != undefined) {
      this.nyxAPIService.getNyxWidgetByDashboard(this.urlState.organizationName!, dashboardId).subscribe((result) => {
        this.widgetList = result;
      })
    }
  }

  deleteDashboard(thisContext: any, id: string) {
    thisContext.nyxAPIService.deleteDashboard(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.menuService.onLoadMenu(true);
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.DASHBOARD_MESSAGE.DELETE_DASHBOARD_SUCCESS));

    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.DASHBOARD_MESSAGE.DELETE_DASHBOARD_ERROR);
    })
  }

  onDashboardOverviewDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.DASHBOARD_MESSAGE.DELETE_DASHBOARD_CONFIRMATION, this, this.deleteDashboard, id, null)
  }
}
