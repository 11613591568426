import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LabelConstant } from '../constants/label-constant';

//Todo: Add others params into this class (e.g.: dashboardId, ... )
export class UrlState {
    constructor(
      public organizationName: string | null = null,
      public sectionName: string | null = null,
      public objectId: string | null = null,
      public recordId: string | null = null,
      public userId: string | null = null,
      public roleId: string | null = null,
      public operation:string | null = null,
      public dashboardId: string | null = null,
      public reportingId: string | null = null,
      public widgetId: string | null = null,
      public automationId: string | null = null,
      public parameterizedQueryId: string | null = null
    ) {}
  }

export class PagedRecord {
  constructor(
    public totalRecords: number = 0,
    public pageSize: number = 10,
    public maxPageSize: number = 100,
    public pageNumber: number = 1,
  ) {}
}

export class SystemDefaultFields {
  constructor(
    public fieldGroup: any[] = [
      { FieldAPIName: 'id', Label: (LabelConstant.RECORD.RECORD_ID) },
      { FieldAPIName: 'name', Label: (LabelConstant.LABEL.NAME) },
      { FieldAPIName: 'createdBy', Label: (LabelConstant.LABEL.CREATED_BY) },
      { FieldAPIName: 'createdDatetime', Label: (LabelConstant.RECORD.CREATED_DATETIME) },
      { FieldAPIName: 'modifiedBy', Label: (LabelConstant.RECORD.LAST_MODIFIED_BY) },
      { FieldAPIName: 'modifiedDatetime', Label: (LabelConstant.RECORD.LAST_MODIFIED_DATE) }
    ]
  ) {}
}

@Component({
  template: '',
})

export class BaseUrlState implements OnInit {
  urlState: UrlState = new UrlState();
  pagedRecord: PagedRecord = new PagedRecord();
  systemDefaultFields: SystemDefaultFields = new SystemDefaultFields();

  constructor(public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getStateFromUrl();
  }

  private getStateFromUrl() {
    this.urlState.organizationName = this.activatedRoute.snapshot.paramMap.get('organization');
    this.urlState.sectionName = this.activatedRoute.snapshot.paramMap.get('section');
    this.urlState.objectId = this.activatedRoute.snapshot.paramMap.get('objectId');
    this.urlState.recordId = this.activatedRoute.snapshot.paramMap.get('recordId');
    this.urlState.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    this.urlState.roleId = this.activatedRoute.snapshot.paramMap.get('roleId');
    this.urlState.operation = this.activatedRoute.snapshot.paramMap.get('operation');
    this.urlState.dashboardId = this.activatedRoute.snapshot.paramMap.get('dashboardId');
    this.urlState.reportingId = this.activatedRoute.snapshot.paramMap.get('reportingId');
    this.urlState.widgetId = this.activatedRoute.snapshot.paramMap.get('widgetId');
    this.urlState.automationId = this.activatedRoute.snapshot.paramMap.get('automationId');
    this.urlState.parameterizedQueryId = this.activatedRoute.snapshot.paramMap.get('parameterizedQueryId');
    this.urlState.operation = this.activatedRoute.snapshot.paramMap.get('operation');
  }
}
