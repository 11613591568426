<div class="col-12">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Object ({{nyxObjectList.totalCount}} objects)</div>
      <div class="flex flex-wrap gap-2">
        <button pButton pRipple type="button" label="New Object" (click)="onCreateNewObject($event)"></button>
        <button pButton pRipple type="button" label="Import Object" (click)="importJson()"></button>
      </div>
      <!-- <div class="card-toolbar">
            <a href="#" class="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6" data-bs-toggle="modal" data-bs-target="#kt_modal_nyx_create_object" id="kt_modal_nyx_create_object_button">
                <i class="ki-duotone ki-document fs-2 text-primary me-0 me-md-2">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
                <span class="d-none d-md-inline">New Object</span>
              </a>
        </div> -->
    </div>

    <p-table #dt1 [value]="nyxObjectList.record" [loading]="loading" [rowHover]="true" styleClass="p-datatable-gridlines"
      responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.NAME}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.DESCRIPTION}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.VERSIONING}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.OBJECT.NUM_FIELD}}
            </div>
          </th>
          <th style="min-width: 12rem">
            <div class="flex justify-content-between align-items-center">
              {{labelConstant.LABEL.ACTION}}
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-obj>
        <tr>
          <td>{{obj.name}}</td>
          <td>{{obj.description}}</td>
          <td>{{obj.versioning}}</td>
          <td>{{ getSchemaTotalFields(obj.schema) }}</td>
          <td class="flex flex-wrap gap-2">
            <p-confirmPopup></p-confirmPopup>
            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text"
              [routerLink]="['/' + this.urlState.organizationName + '/setup/nyx-object-manager/' + obj.id]"></button>
            <button pButton pRipple type="button" icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              (click)="onNyxObjectDeleteButtonClicked($event, obj.id)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- <div class="card flex justify-content-center"> -->
    <p-paginator (onPageChange)="onPageChange($event)" [rows]="pagedRecord.pageSize" [totalRecords]="totalObject"></p-paginator>
  </div>
  <!-- </div> -->
</div>

<app-modal-nyx-create-object [modalShow]="modalShow"
  (modalShowEvent)="onCloseModal($event)"></app-modal-nyx-create-object>