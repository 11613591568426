import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class PermissionEventService {

   public newPermissionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   public editPermissionSubject:BehaviorSubject<string> = new BehaviorSubject<string>("");
   
   public createNewPermissionEvent(): void { 
      this.newPermissionSubject.next(true);
   }

   public editPermissionEvent(id:string): void{
      this.editPermissionSubject.next(id);
   }
}
