import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { UrlState } from 'src/app/core/states/urlState';
import { GeneralConstant } from 'src/app/core/constants/general-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { NyxConfirmationService } from 'src/app/core/services/confirmation.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';


@Component({
  selector: 'app-nyx-record-view-listing',
  templateUrl: './nyx-record-view-listing.component.html',
  styleUrls: ['./nyx-record-view-listing.component.scss']
})
export class NyxRecordViewListingComponent extends BaseUrlState {
  @Output() passNavigationInfo = new EventEmitter();
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  nyxObject: any;
  nyxObjectRecords: any = [];
  nyxViewRecords: any = []

  routeSub: Subscription;

  loading: boolean = true;

  constructor(
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private nyxConfirmationService: NyxConfirmationService,
    private notificationService: GlobalNotificationService,
    public override activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.routeSub = this.activatedRoute.params.subscribe((params: any) => {
      this.urlState.recordId = params['id'];
      this.getNyxObject();
      this.getNyxObjectRecords();
      this.getNyxViewByObjectId();
    });

    this.loading = false;
  }

  getNyxObject() {
    this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxObject = data;
      this.passNavigationInfo.emit(this.nyxObject.name);
    });
  }

  getNyxObjectRecords() {
    this.nyxAPIService.getNyxObjectRecordsByObjectId(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxObjectRecords = data.record;
    });
  }

  getNyxViewByObjectId() {
    this.nyxAPIService.getNyxRecordViewByObjectId(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data: any) => {
      this.nyxViewRecords = data;
    });
  }

  onSetDefaultView(id: any, isDefault: boolean) {
    this.nyxAPIService.onSetDefaultView(this.urlState.organizationName!, this.urlState.objectId!, id, isDefault).subscribe((data: any) => {
      this.nyxViewRecords = data;
    });
  }

  onNyxRecordViewDelete(thisContext: any, id: any) {
    thisContext.nyxAPIService.deleteNyxRecordView(thisContext.urlState.organizationName!, id).subscribe((data: any) => {
      thisContext.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_SUCCESS));
      thisContext.getNyxViewByObjectId();
    }, (error: any) => {
      thisContext.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_ERROR);
    });
  }

  onNyxRecordViewListingDeleteButtonClicked(event: Event, id: any) {
    this.nyxConfirmationService.deleteConfirm(event, MessageBoxConstant.RECORD_MESSAGE.DELETE_RECORD_CONFIRMATION, this, this.onNyxRecordViewDelete, id, null)
  }

  downloadFile(event: Event, fileType: any) {
    event.preventDefault();
    this.nyxAPIService.getFileDownload(this.urlState.organizationName!, this.urlState.objectId!, fileType);
  }
}
