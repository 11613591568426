import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseUrlState } from 'src/app/core/bases/BaseURLState';
import { NyxAPIService } from 'src/app/core/services/nyx-api.service';
import { DatePipe } from '@angular/common';
import { GlobalNotification, NotificationMessage, GlobalNotificationService, GlobalNotificationSeverity } from 'src/app/core/services/notification.service';
import { MessageBoxConstant } from 'src/app/core/constants/message-box-constant';
import { LabelConstant } from 'src/app/core/constants/label-constant';
import { GeneralConstant } from 'src/app/core/constants/general-constant';

interface Fields {
  index: Number;
  label: String;
  value: String;
}

@Component({
  selector: 'app-nyx-object-field-detail',
  templateUrl: './nyx-object-field-detail.component.html',
  styleUrls: ['./nyx-object-field-detail.component.scss']
})
export class NyxObjectFieldDetailComponent extends BaseUrlState {
  labelConstant = LabelConstant;
  generalConstant = GeneralConstant;

  submitted = false;
  fieldForm: FormGroup;
  nyxField: any = null;
  schemas: any = null;
  schemaFields: any = null;
  nyxObject: any = null;

  availableFieldType: Fields[] = [];
  selectedFieldTypeDD: Fields = this.availableFieldType[0];

  nyxFieldTypes: any = [];
  selectedFieldType: any;
  fieldType: any = '';
  hasWorkflow: boolean = false;
  validateDefaultField: boolean = false;

  constructor(
    private notificationService: GlobalNotificationService,
    private fb: FormBuilder,
    protected router: Router,
    private nyxAPIService: NyxAPIService,
    private datePipe: DatePipe,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(activatedRoute);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.getNyxObject();
    this.initConfiguration();
  }

  get fieldFormControl() {
    return this.fieldForm.controls;
  }

  initConfiguration() {
    this.nyxAPIService
      .getNyxConfigurationFieldTypes(this.urlState.organizationName!)
      .subscribe((data: any) => {
        this.nyxFieldTypes = data.fields;

        let i = 0;
        this.nyxFieldTypes.forEach((field: any) => {
          this.availableFieldType.push({
            index: i,
            label: field.fieldTypeName,
            value: field.fieldTypeName
          });
          i++;
        });
      });
  }

  async getNyxObject() {
    await this.nyxAPIService.getNyxObjectById(this.urlState.organizationName!, this.urlState.objectId!).subscribe((data) => {
      this.nyxObject = data;

      this.nyxObject.schema.forEach((field: any) => {
        if (field.FieldType == 'dropdown' && field.Workflow) {
          this.hasWorkflow = true;
        }
      });

      // Check hasWorkflow to prevent multiple workflow in 1 object
      this.initForm();

      if (this.urlState.operation! == 'edit') {
        this.getNyxSchema();
      }
    });
  }

  initForm() {
    this.fieldForm = this.fb.group({
      label: ['', [Validators.required, Validators.maxLength(100)]],
      fieldAPIName: [{ value: '', disabled: true }, [Validators.required]],
      fieldType: ['', [Validators.required]],
      defaultValue: [''],
      isRequired: [false],
      regexRestriction: [''],
      objectId: ['00000000-0000-0000-0000-000000000000'], //Need to make this control as required if type is lookup
      formula: [''], //Need to make this control as required if type is formula
      minValue: [''],
      maxValue: [''],
      isDataMask: [false],
      options: new FormControl<string[]>([]),
      isMultipleOptions: [false],
      workflow: this.hasWorkflow && this.urlState.operation! == 'create' ? new FormControl({ value: false, disabled: true }) : [false],
      seqPrefix: [''],
      seqNumberPadding: [''],
      fileMode: [1],
      fileExtension: new FormControl<string[] | []>([]),
    });
  }

  getNyxSchema() {
    this.nyxAPIService.getNyxFieldByFieldAPIName(this.urlState.organizationName!, this.urlState.objectId!, this.urlState.recordId!).subscribe((data) => {
      this.nyxField = data;
      this.schemaFields = this.nyxField.fieldSchema;

      this.selectedFieldType = this.schemaFields;
      this.fieldType = this.selectedFieldType.fieldType;

      setTimeout(() => {
        this.disableFieldConfiguration();
        this.setSchemaIntoForm();
      }, 1000);

    }, (error: any) => {
      this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECTFIELD_MESSAGE.GET_OBJECTFIELD_ERROR);
    });
  }

  setSchemaIntoForm() {
    if (this.schemaFields != null || this.schemaFields != undefined) {
      this.fieldForm.patchValue({
        ['label']: this.schemaFields.label,
        ['fieldAPIName']: this.schemaFields.fieldAPIName,
        ['fieldType']: this.schemaFields.fieldType,
        ['defaultValue']: this.schemaFields.defaultValue,
        ['isRequired']: this.schemaFields.isRequired,
        ['regexRestriction']: this.schemaFields.regexRestriction,
        ['objectId']: this.schemaFields.objectId,
        ['formula']: this.schemaFields.formula,
        ['minValue']: this.schemaFields.minValue,
        ['maxValue']: this.schemaFields.maxValue,
        ['isDataMask']: this.schemaFields.isDataMask,
        ['options']: this.schemaFields.options,
        ['isMultipleOptions']: this.schemaFields.isMultipleOptions,
        ['workflow']: this.schemaFields.workflow,
        ['seqPrefix']: this.schemaFields.seqPrefix,
        ['seqNumberPadding']: this.schemaFields.seqNumberPadding,
        ['fileMode']: this.schemaFields.fileMode,
        ['fileExtension']: this.schemaFields.fileExtension != null && this.schemaFields.fileExtension != undefined ? this.schemaFields.fileExtension : []
      });

      if (this.schemaFields.fieldType == 'uniqueid') {
        this.fieldForm.get('seqPrefix')?.setValidators(Validators.pattern(/^.{0,20}$/));
        this.fieldForm.get('seqNumberPadding')?.setValidators([Validators.pattern(/^0{0,8}$/)]);

        this.fieldForm.disable();
      } else if (this.schemaFields.fieldType == 'datetime' || this.schemaFields.fieldType == 'date') {
        // Patch the date and datetime when default value is available
        // If we patch the empty value, date and datetime calender picker will show NaN
        if (this.schemaFields.defaultValue != '') {
          this.fieldForm.get('defaultValue')?.patchValue(new Date(this.schemaFields.defaultValue));
        }
      } else if (this.schemaFields.fieldType == 'attachment') {
        this.fieldForm.get('fileMode')?.setValidators([Validators.required, Validators.min(1)]);
      }
    }
  }



  disableFieldConfiguration() {
    // Disable field type drop down to avoid type being changed to another non-supported type
    this.fieldForm.controls['fieldType'].disable();
  }

  onLabelInputChanged($event: any) {
    // Only convert label to field api name during creation.
    // Prevent field api name during edit which will cause the data stored corrupted
    if (this.urlState.operation! == 'create') {
      var fieldAPIName = $event.target.value;
      var sanitizedFieldAPIName = this.sanitizeLabelValue(fieldAPIName);
      // Validate Field API Name with system default field name
      let validateFieldName = this.systemDefaultFields.fieldGroup.find(x => x.FieldAPIName.toLowerCase() == sanitizedFieldAPIName);
      this.validateDefaultField = validateFieldName != undefined && validateFieldName != null ? true : false;
      this.fieldForm.get('fieldAPIName')?.setValue(sanitizedFieldAPIName);
    }
  }

  sanitizeLabelValue(value: string) {
    const noSpecialCharacters = value.replace(/[^a-zA-Z0-9 ]/g, ' ').trim();
    return noSpecialCharacters.replace(/\s+/g, '_').toLowerCase();
  }

  onFieldTypeSelected($event: any) {

    var selectedFieldTypeName = $event.value;
    for (var i = 0; i < this.nyxFieldTypes.length; i++) {
      if (this.nyxFieldTypes[i].fieldTypeName === selectedFieldTypeName) {
        this.selectedFieldType = this.nyxFieldTypes[i];
        this.fieldType = this.selectedFieldType.fieldTypeName;
        break;
      }
    }

    if (selectedFieldTypeName == 'uniqueid') {
      this.fieldForm.get('seqPrefix')?.setValidators(Validators.pattern(/^.{0,20}$/));
      this.fieldForm.get('seqNumberPadding')?.setValidators([Validators.pattern(/^0{0,8}$/)]);
    }
  }

  submit() {
    this.submitted = true;
    if (this.fieldForm.valid && !this.validateDefaultField) {
      var fieldAPIName;
      fieldAPIName = this.urlState.operation! == 'create' ? this.sanitizeLabelValue(this.fieldForm.value.label) : this.fieldForm.get('fieldAPIName')?.value;
      var nyxFieldPayload = {
        FieldMetadata: {
          //due to the disable function makes the this fieldType become null when passing to backend, this changes is to pass the value to do validation in backend
          FieldType: this.fieldForm.controls['fieldType'].value,
          FieldAPIName: fieldAPIName,
          Label: this.fieldForm.value.label,
          DefaultValue: this.fieldForm.value.defaultValue != null && this.fieldForm.value.defaultValue != '' ? this.fieldForm.value.defaultValue.toString() : '',
          IsRequired: this.fieldForm.value.isRequired,
          RegexRestriction: this.fieldForm.value.regexRestriction,
          ObjectId: this.fieldForm.value.objectId,
          Formula: this.fieldForm.value.formula,
          MinValue: this.fieldForm.value.minValue == null ? '' : this.fieldForm.value.minValue.toString(),
          MaxValue: this.fieldForm.value.maxValue == null ? '' : this.fieldForm.value.maxValue.toString(),
          IsDataMask: this.fieldForm.value.isDataMask,
          Options: this.fieldForm.value.options == '' ? [] : this.fieldForm.value.options,
          IsMultipleOptions: this.fieldForm.value.isMultipleOptions,
          Workflow: this.fieldForm.value.workflow,
          SeqPrefix: this.fieldForm.value.seqPrefix,
          SeqNumberPadding: this.fieldForm.value.seqNumberPadding,
          FileMode: this.fieldForm.value.fileMode,
          FileExtension: this.fieldForm.value.fileExtension.length > 0 ? this.fieldForm.value.fileExtension : []
        },
      };

      // Further process the date and datetime value format
      if (this.fieldForm.value.defaultValue != null && this.fieldForm.value.defaultValue != '') {
        if (this.fieldForm.controls['fieldType'].value == 'datetime') {
          nyxFieldPayload.FieldMetadata.DefaultValue = this.datePipe.transform(nyxFieldPayload.FieldMetadata.DefaultValue, 'yyyy-MM-ddTHH:mm:ss');
        } else if (this.fieldForm.controls['fieldType'].value == 'date') {
          nyxFieldPayload.FieldMetadata.DefaultValue = this.datePipe.transform(nyxFieldPayload.FieldMetadata.DefaultValue, 'yyyy-MM-dd');
        }
      }

      let nyxFieldsPromise = this.urlState.operation! == 'create' ? this.nyxAPIService.createNyxField(this.urlState.organizationName!, this.urlState.objectId!, nyxFieldPayload) : this.nyxAPIService.updateNyxField(this.urlState.organizationName!, this.urlState.objectId!, nyxFieldPayload)
      if (this.urlState.operation! == 'create') {
        nyxFieldsPromise.subscribe((data: any) => {
          this.navigateToNyxObjectSchemaListingPage();
          this.initForm();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.OBJECTFIELD_MESSAGE.CREATE_OBJECTFIELD_SUCCESS));

        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECTFIELD_MESSAGE.CREATE_OBJECTFIELD_ERROR);
        });
      } else {
        nyxFieldsPromise.subscribe((data: any) => {
          this.navigateToNyxObjectSchemaListingPage();
          this.initForm();
          this.notificationService.displayMessage(new GlobalNotification(GlobalNotificationSeverity.SUCCESS, NotificationMessage.GENERAL.SUCCESS, MessageBoxConstant.OBJECTFIELD_MESSAGE.EDIT_OBJECTFIELD_SUCCESS));

        }, (error: any) => {
          this.notificationService.displayErrorMessage(error?.error, MessageBoxConstant.OBJECTFIELD_MESSAGE.EDIT_OBJECTFIELD_ERROR);
        });
      }
    }
  }

  navigateToNyxObjectSchemaListingPage() {
    this.router.navigate([
      `/${this.urlState.organizationName!}/setup/nyx-object-manager/${this.urlState.objectId!}`,
    ]);
  }

  //cancel button
  resetFormInput() {
    this.navigateToNyxObjectSchemaListingPage();
  }

}
