<div class="modal fade" id="kt_modal_permission" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Add a Permission</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-permissions-modal-action="close" #modalPermissionCloseButton>
                    <i class="ki-duotone ki-cross fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                </div>
                <!--end::Close-->
            </div>
            <!--end::Modal header-->
            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_modal_permission_form" class="form" [formGroup]="permissionForm" (ngSubmit)="submit()">
                    <!--begin::Input group-->
                    <div class="fv-row mb-7">
                        <!--begin::Label-->
                        <label class="fs-6 fw-semibold form-label mb-2">
                            <span class="required">Permission Name</span>
                            <span class="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true"
                                data-bs-content="Permission names is required to be unique.">
                                <i class="ki-duotone ki-information fs-7">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </i>
                            </span>
                        </label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <input type="text" class="form-control form-control-lg form-control-solid" formControlName="name" placeholder="Enter a permission name" value="" [ngClass]="{
                                'is-invalid': permissionFormControl.name.invalid,
                                'is-valid': permissionFormControl.name.valid
                              }"/>

                              <span class="text-danger"
                                *ngIf="(permissionFormControl.name.touched || submitted) && permissionFormControl.name.errors?.required">
                                    Label is required
                                </span>
                        <!--end::Input-->
                    </div>
                    <div class="fv-row mb-7">
                        <!--begin::Label-->
                        <label class="fs-6 fw-semibold form-label mb-2">
                            <span class="required">Object</span>
                            <span class="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true"
                                data-bs-content="Object is required.">
                                <i class="ki-duotone ki-information fs-7">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                </i>
                            </span>
                        </label>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select name="objectId" formControlName="objectId" class="form-select form-select-solid" data-control="select2" data-hide-search="true" [ngClass]="{
                            'is-invalid': permissionFormControl.objectId.invalid,
                            'is-valid': permissionFormControl.objectId.valid
                          }">
                            <option *ngFor="let object of objectList" value="{{object.id}}">{{object.name}}</option>
                        </select>
                        <!--end::Input-->
                    </div>
                    <div class="fv-row mb-7">
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table class="table align-middle table-row-dashed fs-6 gy-5">
                                <!--begin::Table body-->
                                <tbody class="text-gray-600 fw-semibold">
                                    <tr>
                                        <td>
                                            <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="read" formControlName="read" />
                                                <span class="form-check-label">Read</span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="create" formControlName="create" />
                                                <span class="form-check-label">Write</span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="update" formControlName="update" />
                                                <span class="form-check-label">Update</span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="delete" formControlName="delete" />
                                                <span class="form-check-label">Delete</span>
                                            </label>
                                        </td>
                                        <td>
                                            <label class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="sensitiveRead" formControlName="sensitiveRead" />
                                                <span class="form-check-label">Sensitive Data Read</span>
                                            </label>
                                        </td>
                                        <!--end::Input group-->
                                    </tr>
                                </tbody>
                                <!--end::Table body-->
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="text-center pt-15">
                        <button type="submit" class="btn btn-primary" data-kt-permissions-modal-action="submit">
                            <span class="indicator-label">Submit</span>
                            <span class="indicator-progress">Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                        <button type="reset" class="btn btn-light me-3"
                        data-kt-permissions-modal-action="cancel">Cancel</button>
                    </div>
                    <!--end::Actions-->
                </form>
                <!--end::Form-->
            </div>
            <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
</div>