<div class="d-flex flex-wrap flex-stack mb-6">
    <h3 class="fw-bold my-2">Nyx Objects</h3>
</div>
<div class="row g-6 g-xl-9">
    <div class="col-sm-6 col-xl-4" *ngFor="let object of nyxObjectList" (click)="onNyxObjectClicked(object.id)">
        <!--begin::Card-->
        <div class="card h-100">
            <!--begin::Card header-->
            <div class="card-header flex-nowrap border-0 pt-9">
                <!--begin::Card title-->
                <div class="card-title m-0">
                    <!--begin::Icon-->
                    <div class="symbol symbol-45px w-45px bg-light me-5">
                        <img src="assets/media/svg/brand-logos/twitch.svg" alt="image" class="p-3" />
                    </div>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <a href="#" class="fs-4 fw-semibold text-hover-primary fw-bold text-gray-600 m-0">{{object.name}}</a>
                    <!--end::Title-->
                </div>
                <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
                <!--begin::Stats-->
                <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
                    <i class="ki-duotone ki-Up-right fs-3 me-1 text-danger"></i>
                    <!--begin::Label-->
                    <div class="fw-semibold text-gray-400">{{object.description}} (150+ records)</div>
                    <!--end::Label-->
                </div>
                <!--end::Stats-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
    </div>
</div>