<div class="col-12">

    <div class="card">
        <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
            <div class="text-900 text-xl font-semibold mb-3 md:mb-0">Fields ({{schemas.length}} records)</div>
            <div class="inline-flex align-items-center">
                <div class="flex flex-wrap gap-2">
                    <button pButton pRipple type="button" label="New Field"
                        [routerLink]="['/' + this.urlState.organizationName + '/setup/nyx-object-manager', this.urlState.objectId, generalConstant.PAGE_MODE.CREATE]"></button>
                    <button pButton pRipple type="button" label="Layout"
                        [routerLink]="['/' + this.urlState.organizationName + '/setup/nyx-object-manager', this.urlState.objectId, 'layout']"></button>
                    <button pButton pRipple type="button" label="Export as Json"
                        (click)="downloadSchma()"></button>
                </div>
            </div>
        </div>

        <p-table #dt1 [value]="schemas" [rows]="10" [loading]="loading" [rowHover]="true"
            styleClass="p-datatable-gridlines" [paginator]="true" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.LABEL}}
                        </div>
                    </th>

                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.OBJECT.FIELD_API_LABEL}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.OBJECT.FIELD_TYPE_LABEL}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.OBJECT.DEFAULT_VALUE_LABEL}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.OBJECT.REQUIRED_LABEL}}
                        </div>
                    </th>
                    <th style="min-width: 12rem">
                        <div class="flex justify-content-between align-items-center">
                            {{labelConstant.LABEL.ACTION}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-schema>
                <tr>
                    <td>{{schema[1].Label}}</td>
                    <td>{{schema[1].FieldAPIName}}</td>
                    <td>{{schema[1].FieldType}}</td>

                    <td *ngIf="schema[1].FieldType != 'datetime'">{{schema[1].DefaultValue}}</td>
                    <td *ngIf="schema[1].FieldType == 'datetime'">{{schema[1].DefaultValue | date: generalConstant.TABLE_DATA.DATE }}</td>
                    <!-- <td *ngIf="schema[1].FieldType == 'date'">{{schema[1].DefaultValue | date:'dd MMM yyyy'}}</td> -->

                    <td>{{schema[1].IsRequired}}</td>
                    <td>
                        <div class="flex flex-wrap gap-2">
                            <p-confirmPopup></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                class="p-button-rounded p-button-text"
                                [routerLink]="['/'+this.urlState.organizationName+'/setup/nyx-object-manager/' + this.urlState.objectId + '/edit/' + schema[1].FieldAPIName]"></button>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger p-button-text"
                                (click)="onObjectSchemaListingDeleteButtonClicked($event, schema[1].FieldAPIName)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>