<div class="col-12" *ngIf="automationLogListing != null">
  <div class="card">
    <div class="flex flex-column md:flex-row md:align-items-start md:justify-content-between mb-3">
      <div class="text-900 text-xl font-semibold mb-3 md:mb-0">{{labelConstant.AUTOMATION.AUTOMATION}} Logs </div>
    </div>

    <div class="card-body py-4">
      <p-table #dt1 [value]="automationLogListing" [loading]="loading" [rowHover]="true"
        styleClass="p-datatable-gridlines" responsiveLayout="scroll" [paginator]="true" [rows]="10" pageLinks="5" >
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 35rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.NAME}}
              </div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">
                {{labelConstant.LABEL.ACTION}}
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-automation let-i="rowIndex">
          <tr>
            <td>{{ automation.fileName }}</td>
            <td class="flex flex-wrap gap-2">
              <button pButton pRipple type="button" icon="pi pi-download" class="p-button-rounded p-button p-button-text"
						(click)="onNyxRecordListingDeleteButtonClicked($event, automation.sasUrl)"></button>

            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">{{generalConstant.TABLE_DATA.NO_RECORD_FOUND}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-paginatorList>
          <span>
            {{'Showing'}} {{paginatorList.totalRecords < 1 ? 0 : paginatorList.first + 1}} {{'to'}}
            {{paginatorList.totalRecords > 0 ? ((paginatorList.rows+ paginatorList.first) <= paginatorList.totalRecords ? (paginatorList.rows + paginatorList.first) :  paginatorList.totalRecords) : 0}}
            {{'of'}} {{paginatorList.totalRecords ? paginatorList.totalRecords : 0}} {{'records'}} 
          </span>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
